import React from 'react'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import TextField from 'components/TextField'
import useReport from 'repositories/useReport'
import Modal from 'components/Modal'
import Button from 'components/Button'
import UploadButton from 'components/UploadButton'

import isEmpty from 'lodash/isEmpty'

import {
  initialValues,
  validation,
  TFormReport
} from './validations'
import {
  FormBox,
  ContainerBtn,
} from './styles'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  productSelected: number
  projectSelected: string
}

const ModalNewAttach = ({ open, handleClose, id, productSelected, projectSelected }: TProps) => {

  const [form] = Form.useForm<TFormReport>()
  const { t } = useTranslation('namespaces')
  const reportApi = useReport()

  const onFinish = async(values:TFormReport) => {
    if(isEmpty(values.file[0])){
      return
    }

    const params = {
      name: values?.name,
      file: values.file[0],
      project: projectSelected,
      productSelected
    }
    await reportApi.createAttachment(params, handleClose)
  }

  return (
    <Modal
      id={id}
      title={t('new something', { ns: namespaces.common, label: t('attachment', { ns: namespaces.common }) })}
      isModalVisible={open}
      handleClose={ handleClose}
    >
      <FormBox>
        <Form
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          name='form-report'
          form={form}
          autoComplete='off'
          onFinish={ onFinish}
        >
          <TextField
            label={t('name', { ns: namespaces.common })}
            type='text'
            name='name'
            rules={ validation('name', t)}
            id='input-name'
          />
          <UploadButton
            disable={false}
            rules={validation('file', t)}
            name='file'
            label={t('file', { ns: namespaces.common })}
          />

          <ContainerBtn>
            <Button
              id='button-cancel'
              type='default'
              label={t('cancel', { ns: namespaces.common })}
              onClick={ handleClose }
              htmlType='reset'
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalNewAttach
