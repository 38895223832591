export const namespaces = {
  pages: {
    dashboard: 'dashboard',
    login: 'login',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    field: 'field',
    monitoring: 'monitoring',
    cropRotationst: 'cropRotationst',
    crop: 'crop',
    analyticalProducts: 'analyticalProducts',
    member: 'member',
    farmGroups: 'farmGroups',
    users: 'users',
    reports: 'reports',
    projects: 'projects',
    projectController: 'projectController',
    scout: 'scout'
  },
  modals: {
    editUser: 'editUser',
    createRotationst: 'createRotationst',
    newEnvironmentMap: 'newEnvironmentMap',
    createCrop: 'createCrop',
    memberForm: 'memberForm'
  },
  common: 'common',
  layout: 'layout',
  errors: 'errors'
}

export const languages = {
  pt: 'pt-BR',
  en: 'en',
  es: 'es',
}