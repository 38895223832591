import React, { useMemo, Fragment, useState, useCallback } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { TGroups, TGetLotes } from 'types/types'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useGlobalContext } from 'hooks/useGlobalContext'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import reduce from 'lodash/reduce'

import { Card, Menu, SubMenu, Item, SearchInput } from '../styles'

type TProps = {
  groups: TGroups[] | [] | undefined
  project:string
  getLotes({ type, id }:TGetLotes):void
  setLabel:(name:string)=>void
  disabledGroups:boolean
}

const ListFarms = ({ groups, project, getLotes, setLabel, disabledGroups = false }:TProps) => {

  const { t } = useTranslation('namespaces')
  const { idFarmSelected } = useGlobalContext()

  const [search, setSearch] = useState<string>('')

  const formatArrayGroups = useMemo(
    () => {
      return groups
    }, [groups]
  )

  const filterGroups = useCallback(
    (groups:any) => {
      if (!search && search.length < 3) return formatArrayGroups

      return reduce(groups, (filtered:any, group:TGroups) => {
        const farms = filter(group.farms, item => item.name && item.name.toUpperCase().includes(search.toUpperCase()))
        const children = filterGroups(group.groups)

        if (
          farms.length > 0 ||
            children.length > 0 ||
            (group.name && group.name.toUpperCase().includes(search.toUpperCase()))
        ) {
          filtered.push({
            id: group.id,
            name: group.name,
            isGroup: true,
            farms,
            groups: children,
          })
        }
        return filtered
      }, [])
    },
    [formatArrayGroups, search],
  )

  const handleGetFields = (type:'farm' | 'group' | 'all', id:number | null, name:string) => {
    getLotes({ project, type, id })
    setLabel(name)
  }

  const optionsGroups = useMemo(() => filterGroups(formatArrayGroups), [formatArrayGroups, search])

  const renderSubgroups = (children: any) => {

    return !isEmpty(children) &&
        <SubMenu>
          {map(children, (item, index) => (
            <Fragment key={index}>
              <Item
                main
                onClick={() => { !disabledGroups && handleGetFields('group', item.id, item.name)} }
              >{item.name}</Item>
              <Menu>
                <SubMenu>
                  {map(item.farms, ({ name, id }, index) => (
                    <Item
                      key={index}
                      onClick={() => idFarmSelected !== id && handleGetFields('farm', id, name)}
                    >{name}</Item>
                  ))}
                </SubMenu>
              </Menu>
              {item.groups && renderSubgroups(item.groups)}
            </Fragment>
          ))}
        </SubMenu>
  }

  return (
    <Card>
      <SearchInput
        id='input-search-farm'
        name='searchFarm'
        value={search}
        onChange={e => setSearch(e.target.value)}
        prefix={<SearchOutlined />}
      />
      <Menu>
        {map(optionsGroups, ({ name, id, groups, farms }, index) => (
          <Fragment key={index}>
            <Item
              main
              onClick={() => {!disabledGroups && handleGetFields('group', id, name)}}
            >
              {name}
            </Item>
            { !isEmpty(farms) && (
              map(farms, ({ name, id }, index) => (
                <Fragment key={index}>
                  <Item
                    onClick={() => handleGetFields('farm', id, name) }
                  >
                    {name}
                  </Item>
                </Fragment>
              ))
            )}
            { !isEmpty(groups) && renderSubgroups(groups)}
          </Fragment>
        ))}
        <Item
          main
          onClick={() => { !disabledGroups && handleGetFields('all', null, t('all farms', { ns: namespaces.common })) } }
        >
          {t('all farms', { ns: namespaces.common })}
        </Item>
      </Menu>
    </Card>
  )

}

export default ListFarms