import React, { useMemo, useState, useEffect } from 'react'
import Modal from 'components/Modal'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import { useGlobalContext } from 'hooks/useGlobalContext'
import useNewProduct from 'repositories/useNewProduct'
import useGroups from 'repositories/useGroups'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import find from 'lodash/find'

import TextField from 'components/TextField'
import SelectField from 'components/SelectField'
import UploadButton from 'components/UploadButton'
import Button from 'components/Button'

import { TPayloadNewProduct } from 'types/newProduct'
import { TFields, TSelectOption } from 'types/types'

import { initialValues, validation } from './validations'

import { FormBox, UploadBtnInfo, Text, Title, ContainerBtn, BtnSave } from './styles'

type TProps = {
  open: boolean;
  handleClose: () => void;
  id: string;
  newProduct?: boolean
  callback:()=>void
  projectSelected: string
  fieldSelected?: TFields
};

const NewProduct = ({ open, handleClose, id, newProduct = false, projectSelected, fieldSelected, callback }: TProps) => {
  const { t } = useTranslation()
  const { groupsSelected } = useGlobalContext()
  const global = useGlobalContext()
  const productApi = useNewProduct()
  const groupsApi = useGroups()
  const [form] = Form.useForm<TPayloadNewProduct>()

  const [allGroups, setAllGroups] = useState<any[]>()
  const [fields, setFields] = useState<any[]>()
  const [groupSelected, setGroupSelected] = useState<number>()
  const [farmSelected, setFarmSelected] = useState<number>()
  const [currentFieldSelected, setCurrentFieldSelected] = useState<number>()

  const typeProductOptions = [
    { label: 'Fertianalise', value: 'fertianalises' },
  ]

  const onFinish = async (values: TPayloadNewProduct) => {
    const params = {
      ...values,
      file: values.fileList[0],
    }

    const response = await productApi.createNewProjects(
      params,
      projectSelected
    )

    if (response.status === 200) {
      handleClose()
      callback()
    }
  }

  const getAllGroups = async() => {
    const response = await groupsApi.getFields({ project: projectSelected, type: 'all' })
    global.saveGroupsSelected(response?.data.groups)
  }
  useEffect( () => {
    getAllGroups()
  }, [])

  const valuesForm = useMemo<TPayloadNewProduct>(() => {

    if (!isEmpty(fieldSelected) && !newProduct) {
      setGroupSelected(fieldSelected?.group.id)
      setFarmSelected( fieldSelected?.farm.id)
      setCurrentFieldSelected(fieldSelected?.id)

      return {
        ...initialValues,
        farm_id: fieldSelected?.farm.id,
        field_id: fieldSelected?.id,
        group_id: fieldSelected?.group.id,
      } as TPayloadNewProduct
    }

    return initialValues
  }, [])

  const optionsGroup = useMemo(
    () => {
      const arrayGroups = [] as any
      map(allGroups, group => {
        const getRecursiveGroups = (_gs: any) => {
          if(!isEmpty(_gs.groups)) {
            _gs.groups.map((_g: any ) => getRecursiveGroups(_g))
          }
          arrayGroups.push({ value: _gs.id, label: _gs.name, farms: _gs?.farms })
        }
        if(!isEmpty(group.groups)) {
          group.groups.map((g: any) => getRecursiveGroups(g))
        }
        arrayGroups.push({ value: group.id, label: group.name, farms: group?.farms })
      })
      return arrayGroups
    }, [allGroups, groupSelected])

  const optionsFarm = useMemo(
    () => {
      const currentGroup = find(optionsGroup, ({ value }) => value === groupSelected)

      return map(currentGroup?.farms, item => ({ value: item.id, label: item.name }))

    }, [groupSelected, optionsGroup])

  const optionsField = useMemo<TSelectOption[]>(
    () => map(fields, item => ({ value: item.id, label: item.name }))
    , [fields, optionsFarm])

  const getLotesOfFarm = async (id:any) => {
    const response = await groupsApi.getAllFields(projectSelected, 'farm', id)
    setFields(response?.data)
  }

  const handleGroup = (value:number) => {
    setGroupSelected(value)
    setFarmSelected(undefined)
    setCurrentFieldSelected(undefined)
    form.setFieldsValue({ farm_id: undefined, field_id: undefined })
  }

  const handleFarmAndFields = (value:number) => {
    setFarmSelected(value)
    getLotesOfFarm(value)
    setCurrentFieldSelected(undefined)
    form.setFieldsValue({ field_id: undefined })
  }
  useEffect(() => {
    setAllGroups(groupsSelected)

    if (!isEmpty(fieldSelected) && !newProduct) {
      getLotesOfFarm(fieldSelected?.farm?.id)
    }
  }, [groupsSelected, fieldSelected])

  return (
    <Modal
      id={id}
      title={t('title new product', { ns: namespaces.pages.field })}
      isModalVisible={open}
      handleClose={handleClose}
    >
      <FormBox>
        <Form
          initialValues={valuesForm}
          labelCol={{ span: 6 }}
          name='form-new-products'
          onFinish={onFinish}
          form={form}
          autoComplete='off'
        >
          <SelectField
            name='productType'
            label={`${t('type product', { ns: namespaces.pages.field })} *`}
            id='select-product-type'
            rules={validation('productType', t)}
            options={typeProductOptions}
          />
          <SelectField
            name='group_id'
            label={`${t('group farms', { ns: namespaces.pages.field })} *`}
            id='select-group-farms'
            disabled={!newProduct}
            rules={validation('group_id', t)}
            options={optionsGroup}
            value={groupSelected}
            handleChange={e => handleGroup(e)}
          />
          <SelectField
            name='farm_id'
            label={`${t('farm', { ns: namespaces.common })} *`}
            id='select-farm'
            rules={validation('farm_id', t)}
            disabled={!newProduct}
            options={optionsFarm}
            value={farmSelected}
            handleChange={e => handleFarmAndFields(e)}
          />

          <SelectField
            name='field_id'
            label={`${t('field', { ns: namespaces.pages.field })} *`}
            id='select-field'
            rules={validation('field_id', t)}
            options={optionsField}
            disabled={!newProduct}
            value={currentFieldSelected}
            handleChange={setCurrentFieldSelected}
          />

          <TextField
            name='year'
            label={`${t('year', { ns: namespaces.common })} *`}
            placeholder={t('select year', { ns: namespaces.common })}
            id='select-product-type'
            type='number'
            rules={validation('year', t)}
          />
          <UploadBtnInfo>
            <Text>
              {t('supported files are in the format', {
                ns: namespaces.pages.field,
              })}
              :{' '}
            </Text>
            <Title>.CSV / .XLS *</Title>
          </UploadBtnInfo>

          <UploadButton
            disable={false}
            rules={validation('fileList', t)}
            name='fileList'
            label=''
            accept='.csv, .xls'
            type='primary'
          />
          <ContainerBtn>
            <BtnSave>
              <Button
                id='button-confirm'
                type='primary'
                label={t('save', { ns: namespaces.common })}
                htmlType='submit'
              />
            </BtnSave>
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default NewProduct
