import styled from 'styled-components'

import { Divider as hr } from 'antd'

type THrProps ={
  height?: string
  orientation?: 'left' | 'right' | 'center'
}

export const Line = styled(hr)<THrProps>`
    background-color: ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    height: ${({ height }) => height ? height : '2em'};
    margin: 16px 0;
`