import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { TFields } from 'types/types'
import { PALETTE_NDVI } from 'constants/palette'
import { formatDecimalNumber } from 'utils/helpers'
import { theme } from 'styles'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

import SelectField from 'components/SelectField'

import { Container, AccordionMain, AccordionBody, PanelHeader, List, Item, Palette, Icons } from './styles'

type TFieldGroup = {
  growth: string
  areaTotal: number
  fields: TFields[]
  idsFields: Array<number>
}

type TProps={
  fields: TFields[]
  selectIdField(ids:Array<number>):void
  fieldSelected: Array<number> | []
}

const DescriptionFarm = ({ fields, selectIdField, fieldSelected }:TProps) => {

  const { t } = useTranslation('namespaces')

  const [field, setField] = useState<TFieldGroup[]>()
  const [orderByItem, setOrderByItem] = useState<string>('name')
  const [orderNdvi, setOrderNdvi] = useState<'asc'| 'desc'>('desc')
  const [orderName, setOrderName] = useState<'asc'| 'desc'>('asc')
  const [orderArea, setOrderArea] = useState<'asc'| 'desc'>('desc')

  const options = useMemo(() => map(fields, (field:TFields) => ({ label: field.name, value: field.id })), [fields])

  const getField = useCallback((fields:any, type:string) => {

    const itemFilter = isEmpty(fieldSelected) ? fields : filter(fields,
      field => find(fieldSelected, key => field.id === key))

    switch (type) {
      case 'ndvi':
        return orderBy(itemFilter, o => o?.ndvi?.data?.median ? parseFloat(o?.ndvi?.data?.median) : '', [orderNdvi])

      case 'area':
        return orderBy(itemFilter, o => parseFloat(o.area), [orderArea])

      default:
        return orderBy(itemFilter, ['name'], [orderName])
    }
  }, [orderArea, orderName, orderNdvi, fieldSelected])

  const filterField = useMemo(
    () => {

      return map(field,
        (item:TFieldGroup) => ({ ...item,
          fields: getField(item.fields, orderByItem)
        }))

    }, [fieldSelected, field, orderByItem, orderArea, orderName, orderNdvi])

  const renderIconArea = useMemo(
    () => {
      if(orderArea === 'asc' ){
        return <CaretUpOutlined />
      }

      return <CaretDownOutlined />

    }, [orderArea])

  const renderIconNdvi = useMemo(
    () => {
      if( orderNdvi === 'asc' ){
        return <CaretUpOutlined />
      }

      return <CaretDownOutlined />

    }, [ orderNdvi])

  const renderIconName = useMemo(
    () => {
      if( orderName === 'asc' ){
        return <CaretUpOutlined />
      }

      return <CaretDownOutlined />

    }, [ orderName])

  const renderHeader = ({ growth = '', area = 0 }:{growth:string, area: number}) => {
    const label = growth !== 'undefined' ? growth : t('not informed', { ns: namespaces.common })

    return(
      <PanelHeader>
        <div>
          <span>{t('culture two', { ns: namespaces.common })}:</span>
          <span>{ label }</span>
        </div>
        <div>
          <span style={{ textAlign: 'end' }}>{t('processed area', { ns: namespaces.common })}</span>
          <span style={{ textAlign: 'end' }}>{parseFloat(area.toString()).toFixed(2)} ha</span>
        </div>
      </PanelHeader>
    )}

  const formaterField = useCallback(
    () => {
      const idsFields:Array<number> = []
      const groupFields = groupBy(fields, 'growth.name')

      forEach(groupFields, (fields:any) => {
        forEach(fields, ({ id }) => {
          idsFields.push(id)
        })
      })
      const formaterObj = map(groupFields, (item:any, index:string) => {
        let areaTotal:number = 0
        forEach(item, ({ area }) => areaTotal = areaTotal + area)
        return{ growth: index, areaTotal, fields: item, idsFields }
      })
      setField(formaterObj)

    }, [fields])

  useEffect(() => {
    if(!isEmpty(fields)){
      formaterField()
    }
  }, [fields])

  return (
    <Container>
      <SelectField
        name='field'
        placeholder={t('select fields', { ns: namespaces.pages.monitoring } )}
        id='select-field'
        options={options}
        handleChange={selectIdField}
        multiple
      />
      <AccordionMain accordion defaultActiveKey={[0]}>
        {map(filterField, (item:any, index) => {
          return !isEmpty(item.fields) && (
            <AccordionBody header={renderHeader({ growth: item.growth, area: item.areaTotal })} key={index}>
              <List key={index}>
                <Item
                  onClick={() => {
                    setOrderByItem('ndvi')
                    setOrderNdvi(old => old === 'asc' ? 'desc' : 'asc')
                    setOrderArea('desc')
                    setOrderName('desc')
                  }}
                  colorYellow
                >
                  <span> <Icons> NDVI {orderByItem === 'ndvi' && renderIconNdvi } </Icons> </span>
                </Item>
                <Item
                  width='150px'
                  colorYellow
                  onClick={() => {
                    setOrderByItem('name')
                    setOrderName(old => old === 'asc' ? 'desc' : 'asc')
                    setOrderNdvi('desc')
                    setOrderArea('desc')
                  }}
                >
                  <span> <Icons> {t('field', { ns: namespaces.common })} {orderByItem === 'name' && renderIconName } </Icons></span>
                </Item>
                <Item
                  width='170px'
                  colorYellow
                  onClick={() => {
                    setOrderByItem('area')
                    setOrderArea(old => old === 'asc' ? 'desc' : 'asc')
                    setOrderNdvi('desc')
                    setOrderName('desc')
                  }}
                >
                  <span> <Icons> {t('processed area', { ns: namespaces.common })} {orderByItem === 'area' && renderIconArea } </Icons></span>
                </Item>
              </List>
              { map(item.fields, ({ name, area, ndvi }, index) => {

                const ndviRange = ndvi && parseFloat(ndvi.data.median.toString()).toFixed(1)

                return(
                  <List key={index}>
                    <Item colorYellow>
                      <span>
                        <Palette color={ ndvi ? PALETTE_NDVI[ndviRange] : theme.colors.THEME }>
                          <div />
                          <span>{ndvi ? formatDecimalNumber(parseFloat(ndvi.data.median.toString()).toFixed(2)) : ' --- '}</span>
                        </Palette>
                      </span>
                    </Item>
                    <Item width='170px'>
                      <span>{name}</span>
                    </Item>
                    <Item width='140px' justify='center'>
                      <span>{area ? parseFloat(area.toString()).toFixed(2) : 0} ha</span>
                    </Item>
                  </List>
                )})}
            </AccordionBody>
          )})}
      </AccordionMain>
    </Container>
  )
}

export default DescriptionFarm

