import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import { find } from 'lodash'

import { formatDateInputDate } from 'utils/helpers'

import { DialogContext } from 'dialog/context'

import useGrowths from 'repositories/useGrowths'

import { TFormCrop } from 'types/crops'

import Modal from 'components/Modal'
import Button from 'components/Button'
import TextField from 'components/TextField'
import SelectField from 'components/SelectField'
import InputDate from 'components/InputDate'
import ModalDeleteCrop from '../ModalDeleteCrop'

import {
  initialValues,
  validation,
} from './validations'
import {
  FormBox,
  ContainerBtn,
  RangeData
} from './styles'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  handleSave(values:TFormCrop, id?:any):void
  crop: TFormCrop
  deleteCrop(id:any):void
}

const ModalCreateCrop = ({ open, handleClose, id, handleSave, crop, deleteCrop }: TProps) => {

  const [form] = Form.useForm<TFormCrop>()
  const { t } = useTranslation('namespaces')
  const growthsApi = useGrowths()
  const { createDialog } = useContext(DialogContext)

  const [allGrowths, setAllGrowths] = useState<any[]>()

  const modeEdit = useMemo(() => !isEmpty(crop), [crop])

  const cropsOptions = useMemo<any[]>(
    () => map(allGrowths, (item:any) => ({ value: item?.id, label: item?.name }))
    , [allGrowths])


  const formValues = useMemo(() => {
    if(!isEmpty(crop)){
      return { ...crop,
        start_at: formatDateInputDate(crop?.start_at),
        end_at: formatDateInputDate(crop?.end_at),
      }
    }
    return initialValues
  }, [crop])

  const onFinish = (values:TFormCrop) => {
    const payload = {
      ...values,
      growth_id: find(allGrowths, growth => growth.id === values?.growth_id)?.id
    }
    if(modeEdit){
      handleSave(payload, crop?.key)
    }else{
      handleSave(values)
    }
    handleClose()
  }

  const closeModal = () => {
    handleClose()
  }

  const handleDelete = () => {
    createDialog({
      id: 'modal-delete-crop',
      open: true,
      Component: ModalDeleteCrop,
      props: {
        title: t('delete', { ns: namespaces.common }),
        message: t('are you sure you want to delete the crop?', { ns: namespaces.modals.createCrop }),
        handleSave: () => {
          deleteCrop(crop?.key)
          handleClose()
        }
      }
    })
  }

  useEffect(() => {
    const request = async () => {
      const response = await growthsApi.getAll()
      setAllGrowths(response)
    }
    request()

    return () => {
      setAllGrowths([])
    }
  }, [])

  return (
    <Modal
      id={id}
      title={modeEdit ? t('change the information of a crop', { ns: namespaces.modals.createCrop }) : t('register the information of a new crop', { ns: namespaces.modals.createCrop })}
      isModalVisible={open}
      handleClose={closeModal}
    >
      <FormBox>
        <Form
          initialValues={formValues}
          labelCol={{ span: 6 }}
          name='form-rotationst'
          form={form}
          autoComplete='off'
          onFinish={ onFinish}
        >
          <TextField
            name='name'
            label={t('name', { ns: namespaces.common })}
            id='input-name'
            placeholder={t('name', { ns: namespaces.common })}
            rules={validation('name', t)}
          />
          <SelectField
            name='growth_id'
            rules={validation('growth_id', t)}
            label={t('culture', { ns: namespaces.common })}
            id='select-culture'
            placeholder={t('select one', { ns: namespaces.modals.createCrop, anything: t('culture', { ns: namespaces.common }) })}
            options={cropsOptions}
          />

          <RangeData>
            <InputDate
              id='range-start_at'
              name='start_at'
              label={t('starts at', { ns: namespaces.common })}
              rules={validation('start_at', t)}
            />
            <InputDate
              id='range-end_at'
              name='end_at'
              label={t('ends at', { ns: namespaces.common })}
              rules={validation('end_at', t)}
            />
          </RangeData>

          <ContainerBtn>
            <Button
              id='button-cancel-or-delete'
              type={modeEdit ? 'dashed' : 'default'}
              label={ modeEdit ? t('delete', { ns: namespaces.common }) : t('cancel', { ns: namespaces.common })}
              onClick={() => modeEdit ? handleDelete() : closeModal()}
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalCreateCrop
