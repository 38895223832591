/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react'
import TimeLineField from 'components/TimeLineField'
import { map } from 'lodash'

import SoloCheck from 'components/Icons/SoloCheck'
import NutriAgro from 'components/Icons/NutriAgro'
import Signematoide from 'components/Icons/Signematoide'
import YeldComponent from 'components/Icons/YeldComponent'
import Survey from 'components/Icons/Survey'
import BugIcon from 'components/Icons/BugIcon'
import HealthIcon from 'components/Icons/HealthIcon'
import WeedIcon from 'components/Icons/WeedIcon'
import { TReports } from 'types/types'
import { TScout } from 'types/report'

type TDataReports = {
  infoTimeLineReports: [] | undefined;
  reportSelected: TReports & TScout | undefined
  setReportSelected:React.Dispatch<React.SetStateAction<TReports & TScout | undefined>>
};

type TObjectsReports = {
  date: String,
  icon: string | JSX.Element
}

const ReportCarroussel = ({ infoTimeLineReports, reportSelected, setReportSelected }: TDataReports) => {
  const [dataToCarrousel, setDataToCarroussel] = useState<TObjectsReports[]>()

  const checkKind = (item: String) => {
    switch (item) {
      case 'nutricheck':
        return <NutriAgro />
        break
      case 'siganematoide':
        return <Signematoide />
        break
      case 'solocheck':
        return <SoloCheck />
        break
      case 'yield_component':
        return <YeldComponent />
        break
      case 'scout':
        return <Survey />
        break
      case 'disease':
        return <HealthIcon />
        break
      case 'weed':
        return <WeedIcon />
        break
      case 'bug':
        return <BugIcon />
        break
      default:
        return ''
    }
  }

  const orderDates = (arr: TObjectsReports[]) => {
    return arr.sort((a: any, b: any) => {
      return a.date - b.date
    })
  }

  useEffect(() => {
    const filteredDatesAndIcons = () => {

      const objectsReports: TObjectsReports[] = map(infoTimeLineReports, (info: any) => { return { ...info, icon: checkKind(info.kind) }})
      const arrDates = orderDates(objectsReports)
      setDataToCarroussel(arrDates)
    }
    infoTimeLineReports && filteredDatesAndIcons()
  }, [infoTimeLineReports])

  return (
    <TimeLineField
      // infoTimeLine={[]}
      updateCarousel={() => {}}
      dateSelectedReport={reportSelected?.created_at_device}
      idReport={reportSelected?.id}
      infoTimeLineReports={dataToCarrousel}
      saveReportSelected={setReportSelected}
      onUpdateCalendar={() => {}}
      isReport={true}
      setDate={() => {}}
    />
  )
}

export default ReportCarroussel
