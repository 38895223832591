import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useAuth } from 'hooks/useAuth'

const useAuthRequest = () => {
  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')
  const { saveUserData } = useAuth()


  const editUser = async (params:any, userAvatar: any) => {
    const url = '/auth'

    const formData = new FormData()

    if (typeof userAvatar !== 'string') {
      formData.append('image', userAvatar)
    }
    formData.append('name', params.fullname)
    formData.append('locale', params.locale)

    const response: any = await rest.put(url, formData)

    if(response?.status === 200){
      saveUserData(response.data.data)
      notify({ message: t('profile updated successfully', { ns: namespaces.modals.editUser }), success: true })
    }
  }

  const editUserPassword = async (newPassword:string) => {
    const url = '/auth/password'

    const payload = {
      password: newPassword,
      password_confirmation: newPassword,
    }

    const response = await rest.put(url, payload)

    if(response?.status === 200){
      notify({ message: t('password changed successfully', { ns: namespaces.modals.editUser }), success: true })
    }
  }



  return {
    editUser,
    editUserPassword
  }
}

export default useAuthRequest
