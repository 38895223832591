import React from 'react'

type TProps = {
  size?: number;
  colorOne?: string;
};

const CompressionIcon = ({
  size = 25,
  colorOne = '#f37422',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 19.22 20.11'
    >
      <g id='Compactação' transform='translate(-7.8 -7.4)'>
        <path id='Caminho_79553' data-name='Caminho 79553' d='M23.18,14.98l-2.17-1.34L18.75,15.1l2.13,1.37Z' fill={colorOne} />
        <path id='Caminho_79554' data-name='Caminho 79554' d='M20.99,11.91l2.22-1.44L20.84,9.22l-2.11,1.29Z' fill={colorOne} />
        <path id='Caminho_79555' data-name='Caminho 79555' d='M19.54,17.35l-2.13-1.38-2.13,1.38,2.13,1.38Z' fill={colorOne} />
        <path id='Caminho_79556' data-name='Caminho 79556' d='M24.54,14.1l2.48-1.61-2.33-1.23-2.32,1.5Z' fill={colorOne} />
        <path id='Caminho_79557' data-name='Caminho 79557' d='M11.51,14.9l2.42,1.57,2.13-1.37L13.7,13.57Z' fill={colorOne} />
        <path id='Caminho_79558' data-name='Caminho 79558' d='M19.63,12.79l-2.3-1.43-2.24,1.36,2.32,1.51Z' fill={colorOne} />
        <path id='Caminho_79559' data-name='Caminho 79559' d='M15.94,10.5,13.92,9.25l-2.31,1.22,2.11,1.37Z' fill={colorOne} />
        <path id='Caminho_79561' data-name='Caminho 79561' d='M17.41,24.86l-7.35-4.77L7.8,21.28l9.61,6.23,9.6-6.23-2.25-1.19Z' fill={colorOne} />
        <path id='Caminho_79562' data-name='Caminho 79562' d='M27.01,16.89,24.76,15.7l-7.35,4.77L10.06,15.7,7.8,16.89l9.61,6.23Z' fill={colorOne} />
        <path id='Caminho_79563' data-name='Caminho 79563' d='M19.34,8.43,17.41,7.4,15.42,8.46l1.92,1.18Z' fill={colorOne} />
        <path id='Caminho_79564' data-name='Caminho 79564' d='M12.34,12.69l-2.21-1.43L7.8,12.49l2.35,1.53Z' fill={colorOne} />
      </g>
    </svg>
  )
}

export default CompressionIcon