import React from 'react'

type TProps = {
  size?: number;
  color?: string;
};

const ArrowDownIcon = ({
  size = 18,
  color = '#F37422',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 18 18'
    >
      <path d='M10.5938 0.578125L6 5.17188L1.40625 0.578125L0 1.98438L6 7.98438L12 1.98438L10.5938 0.578125Z' fill={color} />
    </svg>
  )
}

export default ArrowDownIcon