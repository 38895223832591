import React from 'react'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import TextField from 'components/TextField'
import Modal from 'components/Modal'
import Button from 'components/Button'

import {
  initialValues,
  validation,
  TForm
} from './validations'

import {
  FormBox,
  ContainerBtn,
  Text,
} from './styles'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  handleSave():void
  title: string
}

const ModalDeleteUser = ({ open, handleClose, id, handleSave, title }: TProps) => {

  const { t } = useTranslation('namespaces')
  const [form] = Form.useForm<TForm>()

  const onFinish = () => {
    handleSave()
    handleClose()
  }

  return (
    <Modal
      id={id}
      title={title}
      isModalVisible={open}
      handleClose={ handleClose}
    >
      <FormBox>
        <Form
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          name='form-report'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
        >
          <Text> {t('are you sure you want to delete', { ns: namespaces.common, label: t('user', { ns: namespaces.pages.users }) }) }</Text>
          <Text> {t('to complete type and confirm', { ns: namespaces.common, label: t('delete', { ns: namespaces.common }).toUpperCase() })}</Text>
          <TextField
            type='text'
            name='delete'
            rules={ validation('delete', t)}
            id='input-delete'
          />
          <ContainerBtn>
            <Button
              id='button-cancel'
              type='default'
              label={t('cancel', { ns: namespaces.common })}
              onClick={ handleClose }
              htmlType='reset'
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalDeleteUser
