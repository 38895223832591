import styled from 'styled-components'

export const FormBox = styled.div`
    margin: 20px 20px auto 20px;
    width: auto;
    background-color: ${({ theme }) => theme.colors.THEME};
    border-radius: 4px;

    label{
      font-size: 16px;
    }

    span {
      cursor: pointer;
    }
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  margin-top: 20px;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.WHITE};
  margin: 0;
  padding: 0.5rem 0;
`