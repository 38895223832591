import useRest from 'hooks/useRest'
import forEach from 'lodash/forEach'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

const useScout = () => {
  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const updateScout = async (params:any, id:number, callBack:Function) => {

    const url = `v3/report_scouts/${id}`
    const formData = new FormData()

    formData.append('report_scout[farm_id]', params.farm)
    formData.append('report_scout[field_id]', params.field)
    formData.append('report_scout[crop_id]', params.crop)
    formData.append('report_scout[growth_id]', params.culture)
    formData.append('report_scout[monitoring_date]', params.monitoring_date)
    formData.append('report_scout[image_type]', params.image_type)

    forEach(params.report_scout_points_attributes, (item:any, index:number) => {
      if(!item._destroy){
        formData.append(`report_scout[report_scout_points_attributes][${index}][id]`, item.id)
        formData.append(`report_scout[report_scout_points_attributes][${index}][growth_variety_id]`, item.growth_variety_id)
        formData.append(`report_scout[report_scout_points_attributes][${index}][growth_stage_id]`, item.growth_stage_id)
        formData.append(`report_scout[report_scout_points_attributes][${index}][growing_conditions]`, item.growing_conditions)
        formData.append(`report_scout[report_scout_points_attributes][${index}][stand]`, item.stand)
        formData.append(`report_scout[report_scout_points_attributes][${index}][observation]`, item.observation)

        forEach(item.report_scout_point_attachments_attributes, (img:any, key:number) => {
          if(img.new){
            formData.append(`report_scout[report_scout_points_attributes][${index}][report_scout_point_attachments_attributes][${key}][image]`, img.image)
            formData.append(`report_scout[report_scout_points_attributes][${index}][report_scout_point_attachments_attributes][${key}][rotation]`, img.rotation || 0)
          }
          if(img._destroy){
            formData.append(`report_scout[report_scout_points_attributes][${index}][report_scout_point_attachments_attributes][${key}][id]`, img.id)
            formData.append(`report_scout[report_scout_points_attributes][${index}][report_scout_point_attachments_attributes][${key}][_destroy]`, img._destroy)
          }
          if(!img.new){
            formData.append(`report_scout[report_scout_points_attributes][${index}][report_scout_point_attachments_attributes][${key}][id]`, img.id)
            formData.append(`report_scout[report_scout_points_attributes][${index}][report_scout_point_attachments_attributes][${key}][rotation]`, img.rotation || 0)
          }
        })
      }else{
        formData.append(`report_scout[report_scout_points_attributes][${index}][id]`, item.id)
        formData.append(`report_scout[report_scout_points_attributes][${index}][_destroy]`, item._destroy)
      }
    })

    const response = await rest.put(url, formData)

    if(response?.status === 200){
      callBack()
    }
  }

  const deleteScout = async (id:any, callBack:any) => {
    const url = `v3/report_scouts/${id}`
    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.common }), success: true })
      callBack(undefined)
    }
  }

  const getUrlFilePdf = async (id:any) => {
    const w = window.open('/__loader.html', '_blank') as Window

    const url = `v3/report_scouts/${id}/file`
    const response:any = await rest.get(url)

    if(response.data && response.status === 200){
      setTimeout(() => {
        w.location = response.data.url
      }, 100)
    }
  }

  return { updateScout, deleteScout, getUrlFilePdf }
}

export default useScout