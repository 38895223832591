import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal'
import { Form } from 'antd'
import useGroups from 'repositories/useGroups'
import useProject from 'repositories/useProjects'
import { useGlobalContext } from 'hooks/useGlobalContext'
import Button from 'components/Button'
import SelectField from 'components/SelectField'
import { FormBox, ContainerBtn } from './styles'
import { TProject } from 'types/types'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

type TProps = {
  open: boolean;
  handleClose: () => void;
  id: string;
  projectSelected:string;
}

import {
  initialValues,
  validation,
  TFormTransferGroup
} from './validations'
import { isEmpty } from 'lodash'

const ModalTransferGroup = ({ open, handleClose, id, projectSelected }: TProps) => {
  const global = useGlobalContext()
  const groupsApi = useGroups()
  const projectsApi = useProject()
  const [optionsProjects, setOptionsProjects] = useState<TProject[] | []>()
  const [form] = Form.useForm<TFormTransferGroup>()
  const { t } = useTranslation('namespaces')
  const group = global.oneGroupSelected
  const [valuesInput, setValuesInput] = useState<any>()

  const getAllGroups = async() => {
    const response = await groupsApi.getAllGroupsProject(projectSelected)
    global.saveGroupsSelected(response?.data.records)
  }

  const getAllProjects = async() => {
    const response = await projectsApi.getAllProject()
    setOptionsProjects(response?.data)
  }

  const optionsValue = optionsProjects?.map((option: TProject) => ({ 'label': option.name, 'value': option.id }))

  const onFinish = async() => {
    if(isEmpty(valuesInput)){
      return
    }

    await groupsApi.transferFarmGroup(projectSelected, valuesInput, group)
    getAllGroups()
    handleClose()
  }

  const onReset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if(projectSelected){
      getAllProjects()
    }
  }, [projectSelected])

  return (
    <Modal
      id={id}
      title={t('transfer group', { ns: namespaces.common })}
      isModalVisible={open}
      handleClose={handleClose}
    >
      <FormBox>
        <Form
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          name='form-transfer-group'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
        >
          <SelectField
            name='projects'
            label={t('search for a project', { ns: namespaces.pages.projects })}
            id='select-project'
            placeholder={t('select a project', { ns: namespaces.pages.field })}
            options={optionsValue ? optionsValue : []}
            rules={validation('projects', t)}
            showSearch
            handleChange={e => setValuesInput(e)}
          />
          <ContainerBtn>
            <div>
              <Button
                id='button-cancel'
                type='default'
                label={t('clean', { ns: namespaces.common })}
                onClick={onReset}
                htmlType='reset'
              />
              <Button
                id='button-confirm'
                type='primary'
                label={t('save', { ns: namespaces.common })}
                htmlType='submit'
              />
            </div>
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalTransferGroup