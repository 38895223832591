import styled from 'styled-components'
import { Card as AntCard } from 'antd'

export const Wrapper = styled.div`
  height: 100%;
  padding: 1.5rem 50px 1.5rem 50px;
  background-color: ${({ theme }) => theme.colors.LIGHT_THEME};
  overflow-y: auto;
  overflow-x: hidden;

  .ant-input-affix-wrapper{
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.THEME};
    border-bottom-right-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.YELLOW};
  }
`
export const Card = styled(AntCard)`
  align-items: center;
  background: ${({ theme }) => theme.colors.THEME};
  border: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  border-radius: 4px;
  position: relative;
  .ant-card-head{
    color: ${({ theme }) => theme.colors.YELLOW};
    border-bottom: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};

  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 24px 16px 24px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`
export const HeaderTitle = styled.h2`
  display: flex;
  justify-content: start;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.72;
  letter-spacing: normal;
  color: ${({ theme }) => `${theme.colors.YELLOW} !important`};
  margin-right: 10px;
`

export const Controllers = styled.div`
  display: flex;
  align-items: center;
  button {
    min-width: 150px;
  }
`
