import React from 'react'

import ModalConfirm from 'components/Modal/ModalConfirm'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  handleSave():void
  message:string
  title: string
}

const ModalDeleteMember = ({ open, handleClose, id, handleSave, message, title }: TProps) => {
  return (
    <ModalConfirm
      id={id}
      title={title}
      isModalVisible={open}
      handleClose={handleClose}
      message={message}
      handleConfirmation={() => {
        handleSave()
        handleClose()
      }}
    />
  )
}

export default ModalDeleteMember
