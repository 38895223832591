import React from 'react'

type TProps = {
  size?: number;
  color?: string;
};

const ClockIcon = ({
  size = 14,
  color = '#D9F0F9',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      version='1.1'
      viewBox='0 0 14 14'
    >
      <path d='M7 0.34375C3.3125 0.34375 0.34375 3.3125 0.34375 7C0.34375 10.6875 3.3125 13.6562 7 13.6562C10.6875 13.6562 13.6562 10.6875 13.6562 7C13.6562 3.3125 10.6875 0.34375 7 0.34375ZM7 12.3438C4.0625 12.3438 1.65625 9.9375 1.65625 7C1.65625 4.0625 4.0625 1.65625 7 1.65625C9.9375 1.65625 12.3438 4.0625 12.3438 7C12.3438 9.9375 9.9375 12.3438 7 12.3438ZM7.34375 3.65625H6.34375V7.65625L9.84375 9.78125L10.3438 8.9375L7.34375 7.15625V3.65625Z' fill={color} />
    </svg>
  )
}

export default ClockIcon