import React, { useState, useEffect } from 'react'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'

import { Container, Content, ContentOptions, Options } from './styles'
import { MonitoringIcon } from 'components/Icons'
import { TSelectOption } from 'types/types'

type TProps ={
  setModeNdvi:(mode:TSelectOption)=>void
  display: boolean
  options: TSelectOption[]
  pageMode?: boolean
  isMonitoring?: boolean
  modeNdvi: TSelectOption
}

const MenuNdvi = ({ setModeNdvi, display = true, options, pageMode, isMonitoring = false, modeNdvi }:TProps) => {

  const [selectedFilter, setSelectedFilter] = useState<string>('')
  const [showOptions, setShowOptions] = useState<boolean>(false)

  const selectOption = (item: any) => {
    setSelectedFilter(item.label)
    setModeNdvi(item)
    setShowOptions(!showOptions)
  }

  useEffect(() => {
    setSelectedFilter(modeNdvi.label)
  }, [modeNdvi])

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Container display={display} isMonitoring={isMonitoring} pageMode={pageMode}>

          <Content onClick={() => setShowOptions(!showOptions)}>
            <div>
              {isMonitoring && <MonitoringIcon />}
              <p>{selectedFilter}</p>
            </div>

            {showOptions ? <ArrowDownOutlined /> : <ArrowUpOutlined /> }
          </Content>
          {showOptions === true && isMonitoring ?
            options.map((item: TSelectOption) => {
              return (
                <ContentOptions key={item.value}>
                  {item.label}
                  {item.options?.map((item, index) => <Options isMonitoring key={index} onClick={() => selectOption(item)}>{item.label}</Options>)}
                </ContentOptions>
              )
            }) : showOptions === true && options?.map((item, index) => <Options key={index} onClick={() => selectOption(item)}>{item.label}</Options>)}
        </Container>
      </div>
    </>
  )
}

export default MenuNdvi