import React from 'react'

type TProps = {
  size?: number;
  colorOne?: string;
  colorTwo?: string;
};

const SoloCheck = ({
  size = 35,
  colorOne = '#F37422',
  colorTwo = '#f5f6f6',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 49.05 49.05'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <circle className='fil0' cx='24.52' cy='24.52' r='24.52' fill='none' />
        <g id='_4202627984'>
          <polyline stroke={colorOne} className='fil1 str0' fill='none' points='9.12,19.08 14.88,15.42 20.37,18.26 ' strokeWidth={2.11} />
          <polyline stroke={colorOne} className='fil1 str1' fill='none' points='23.18,13.46 20.04,13.55 17.63,16.18 ' strokeWidth={2.11} />
          <line stroke={colorOne} className='fil1 str2' fill='none' x1='19.02' y1='39.87' x2='15.54' y2= '32.39' strokeWidth={2.11} />
          <line stroke={colorOne} className='fil1 str3' fill='none' x1='19.92' y1='33' x2='17.42' y2= '34.85' strokeWidth={2.11} />
          <line stroke={colorOne} className='fil1 str4' fill='none' x1='40.41' y1='26.96' x2='33.03' y2= '31.49' strokeWidth={2.11} />
          <line stroke={colorOne} className='fil1 str5' fill='none' x1='33.75' y1='24.94' x2='36.47' y2= '29.38' strokeWidth={2.11} />
          <line stroke={colorOne} className='fil1 str6' fill='none' x1='35.29' y1='12.21' x2='33.55' y2= '15.61' strokeWidth={2.11} />
          <circle stroke={colorTwo} className='fil1 str7' fill='none' cx='24.52' cy='24.52' r='16.84' strokeWidth={2.17} />
          <polyline stroke={colorTwo} className='fil1 str8' fill='none' points='19.4,24.14 22.98,29 29.66,20.49 ' strokeWidth={3.26} />
        </g>
      </g>
    </svg>
  )
}

export default SoloCheck
