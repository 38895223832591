/* eslint-disable vars-on-top */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { isEmpty } from 'lodash'
import { List, Item } from './styles'

const ReportsContent = (content: any) => {
  const { t } = useTranslation('namespaces')

  const stripNUmber = (number?: any, decimals?: any, unit?: any) => {
    const pointMilions = (n: any) => {
      let resp = ''
      // eslint-disable-next-line no-var
      for (var i = n.length - 3; i > 0; i = i - 3) {
        resp = `.${n.substring(i, i + 3)}${resp}`
      }
      return n.substring(0, 3 + i) + resp
    }
    if (!number) return ''
    const x = parseFloat(number)
    let int = '', decimal = ''
    if (x % 1 > 0) {
      [int, decimal] = x.toFixed(decimals).split('.')
    }
    return pointMilions(int || String(number)) + (decimal ? (`,${decimal}`) : '') + (unit || '')
  }
  if(isEmpty(content)) return null
  return (
    <>

      <List>
        <Item colorYellow>
          {t('spacing', { ns: namespaces.pages.field })} (cm)
          <span>{stripNUmber(content?.content?.spacing)}</span>
        </Item>

        <Item colorYellow>
          {t('seeds', { ns: namespaces.pages.field })} (ha)
          <span>{content?.content?.average_seeds && stripNUmber(content?.content?.average_seeds)}</span>
        </Item>
      </List>

      <List>
        <Item colorYellow>
          {t('germination', { ns: namespaces.pages.field })} (%)
          <span>{content?.content?.percent_germination && content?.content?.percent_germination}</span>
        </Item>

        <Item colorYellow>
          {t('population', { ns: namespaces.pages.field })} (ha)
          <span>{stripNUmber(content?.content?.population)}</span>
        </Item>
      </List>

      <List>
        <Item colorYellow>
          {t('pods/plants', { ns: namespaces.pages.field })}
          <span>{content?.content?.average_pods && stripNUmber(content?.content?.average_pods)}</span>
        </Item>

        <Item colorYellow>
          {t('grains/pods', { ns: namespaces.pages.field })}
          <span>{stripNUmber(content?.content?.average_grains)}</span>
        </Item>
      </List>

      <List>
        <Item colorYellow>
          {t('grains weight', { ns: namespaces.pages.field })} (g)
          <span>{content?.content?.weight && stripNUmber(content?.content?.weight)}</span>
        </Item>

        <Item colorYellow>
          {t('yield', { ns: namespaces.pages.field })} (Kg/ha)
          <span>{stripNUmber(content?.content?.yield)}</span>
        </Item>
      </List>


    </>
  )
}

export default ReportsContent