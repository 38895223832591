enum Colors {
  BLACK = '#000000',
  SLIGHT_BLACK_OPACITY = '#10110dc4',
  WHITE = '#ffffff',
  WHITE_OPACITY = 'rgba(255, 255, 255, 0.1)',
  WHITE_OPACITY_TWO = 'rgba(255, 255, 255, 0)',
  SLIGHT_WHITE = '#F2F2F3',
  WEAK_WHITE ='#f5f6f6',
  GRAY='#cecece',
  BLACK_PEARL='#001529',
  YELLOW = '#f37422',//orange
  BLUE_DARK ='#001529',
  RED = '#c42525',
  SLIGHT_RED = '#DB2214',
  BLUE = '#1890ff',
  GREEN = '#A5CD39',
  BLUEGREEN = '#364972',
  MOSS_GREEN= '#3649727a',
  MOSS_GREEN_LIGHT= '#0aa69610',
  DARK_GREEN = '#4BB543',
  THEME = '#131e2a',
  LIGHT_THEME = '#21435f',
  THEME_OPACITY = '#131e2abf',
  THEME_TWO = '#082733',
  THEME_THREE = '#0b384a',
  THEME_TWO_BORDER = '#D9F0F9',
  BLUE_GRAY = '#354453',
  LIGHT_GRAY = '#E8E8E8;',
  MEDIUM_GRAY = '#868686',
  LARANJA_YVY = '#F37422',
  AZUL_YVY = '#131E2A',
  AZUL_YVY_WRAPPER_BACKGROUND='#0e1721',
  AZUL_SEC_YVY = '#21435f',
  MEDIUM_GRAY_OPACITY = 'rgba(235, 235, 242, 0.2)',
  DARK_BLUE = '#001529',
}

enum BreakPoints {
  SMALL_SCREEN = '500px',
  MEDIUM_SCREEN = '1187px',
  BS_MD = '767px',
  BS_LG = '992px'
}

type TTheme = {
  colors: typeof Colors
  breakPoints: typeof BreakPoints
}

export const theme: TTheme = {
  colors: Colors,
  breakPoints: BreakPoints
}
