import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const Container = styled.div``

export const Img = styled.img<{rotate:number}>`
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  width: 225px;
  height: 225px;
`

export const CarouselImage = styled(Swiper)`
  width: 225px;
  height: 225px;
`

export const ConfigCarousel = styled.div`
  width: 50px;
  height: 35px;
  background:${({ theme }) => theme.colors.SLIGHT_BLACK_OPACITY};
  position: absolute;
  z-index: 1;
  bottom: 10%;
  right: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Arrow = styled.div<{ disabled?: boolean }>`
 padding: 5px;
  
  svg {
    color: ${({ theme }) => theme.colors.YELLOW};
  }
`

export const Icons = styled.div`
  z-index: 1000;
  bottom: 11px;
  right: -90px;
  position: absolute;
  justify-content: space-around;
  display: flex;
  align-items: center;
  width: 100%;

  div{
    width: 30px;
    height: 35px;
    background: ${({ theme }) => theme.colors.SLIGHT_BLACK_OPACITY};
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      height: 1em;
      width: 1em;
    }
  }
`