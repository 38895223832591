import styled from 'styled-components'
import { Menu } from 'antd'

type TContainerProp = {
  iscollapsedsidebar: boolean;
};

export const Container = styled.aside<TContainerProp>`
  position: absolute;
  width: ${({ iscollapsedsidebar }) => (iscollapsedsidebar ? 'unset' : '240px')};
  background-color: ${({ theme }) => theme.colors.THEME};
  height: calc(100vh - 80px);
  padding-top: 20px;
  color: ${({ theme }) => theme.colors.YELLOW};
  border-top: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  z-index: 9999;
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.1);

  > ul {
    width: auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    width: 66px;
  }

  .ant-menu.ant-menu-inline-collapsed {
    width: 66px;
    margin-left: -12px;
  }
`

export const MenuItem = styled(Menu)`
  border-right: 0;

  ul li div {
    color: ${({ theme }) => theme.colors.YELLOW};

    span {
      margin-left: 10px;
    }

    i,
    i:hover {
      color: ${({ theme }) => theme.colors.YELLOW} !important;
    }
  }
`

export const ItemGroup = styled(Menu.ItemGroup)<TContainerProp>`
  background: ${({ theme }) => theme.colors.THEME};

  div {
    font-size: 13px;
    text-transform: uppercase;
    margin-left: 9px;
  }

  .ant-menu-submenu-title {
    padding-left: 16px !important;
  }

  .ant-menu-submenu-title:active,
  .ant-menu-submenu-title:hover {
    background: transparent;
    color: ${({ theme }) => theme.colors.YELLOW};
  }

  li > ul {
    margin: ${({ iscollapsedsidebar }) => iscollapsedsidebar ? 'unset' : '-15px 0 0 40px'} ;

    li > span {
      color: ${({ theme }) => theme.colors.WHITE};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    > div,
    ul li span {
      display: none;
    }

    .ant-menu-submenu-title {
      padding-left: 23px !important;
    }
  }

  .ant-menu-item-selected span {
    font-weight: bold !important;
  }

  li > .ant-menu-item,
  .ant-menu-item-selected {
    background: ${({ theme }) => `${theme.colors.MOSS_GREEN_LIGHT} !important`};
    position: relative;

    ::before {
      position: absolute;
      display: block;
      left: ${({ iscollapsedsidebar }) => iscollapsedsidebar ? 1 : 0} ;
      margin-left: ${({ iscollapsedsidebar }) => iscollapsedsidebar ? '-4px' : 'unset'} ;
      height: 100%;
      width:  4px;
      background-color: ${({ theme }) => theme.colors.BLUEGREEN};
      content: "";
    }

    ::after {
      display: none;
    }
  }

 .ant-menu-item {
    svg {
      margin-left: 9px;
      margin-bottom: ${({ iscollapsedsidebar }) => iscollapsedsidebar ? '-18px' : 'unset'} ;
    }
  }
`

export const Item = styled(Menu.Item)`
  margin: 0 !important;
  background-color: transparent !important;
  padding: 0 16px !important;
  height: 50px !important;

  span {
    color: ${({ theme }) => theme.colors.YELLOW};
    margin-left: 10px;
    font-size: 16px;
  }

  .ant-menu-item-group-title {
    padding: 0;
  }
`
