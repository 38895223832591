import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 5px 20px 5px 20px;
    overflow: auto;
    
`
export const Content = styled.div`
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-column-gap: 3rem;
   grid-row-gap: 1em;
   width: 100%;
`
export const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 380px;
    background-color: #0e1721;
    border-radius: 5px;
    
`

export const HeaderTitle = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
    padding: 10px;
    border-bottom: solid 0.5px #ccc;
`

export const ContentInformations = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 5px;
`

export const ImageViewControlers = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;

    p{
        color: ${({ theme }) => theme.colors.WHITE};
    }

    .upload-list-inline .ant-upload-list-item {
        float: left;
        width: 200px;
        margin-right: 8px;
    }

    .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
        float: right;
    }
`
export const Title = styled.span`
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: bold;
`
export const Viewer = styled.div`
    display: flex;
    width: 380px;
    height: 280px;
    background-color: #fff;
    border-radius: 5px;

    img {
        width: 100%;
    }
`
export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  width: 360px;
  justify-content: space-between;
  bottom: 0;
`
export const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
`

export const TextAreaInput = styled.input`
  width: 100%;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  background-color: ${({ theme }) => `${theme.colors.THEME} !important`};
  color: ${({ theme }) => `${theme.colors.WHITE} !important`};
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`