import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { TFormCrop } from 'types/crops'

export const initialValues: TFormCrop = {
  name: null,
  growth_id: null,
  start_at: null,
  end_at: null,
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'name':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('name', { ns: namespaces.common }) })
      }] as Rule[]

    case 'growth_id':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('culture', { ns: namespaces.common }) })
      }] as Rule[]

    case 'start_at':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('starts at', { ns: namespaces.common }) })
      }] as Rule[]

    case 'end_at':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('ends at', { ns: namespaces.common }) })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}
