
import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useProductContext } from 'hooks/useProductContext'
import { apiBackEnd } from 'services/axios'

type TParamsGetProductAnalysis = {
  q?:string
  page?:number
  size?:number
  typeProduct: string
  projectSelected?: string
  type?: string
  id?:number | null
}

const useProduct = () => {
  const { get, remove } = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const { setProductSelected, setProductProject } = useProductContext()

  const removeProduct = async (params:any, callback:()=>void) => {
    const url = `v2/companies/${params.project}/projects/${params.productSelected}`

    const response = await remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.common }), success: true })
      setProductSelected(0)
      setProductProject()
      callback()
    }
  }

  const getProduct = async ( params: TParamsGetProductAnalysis) => {
    let url = `v2/companies/${params.projectSelected}/projects/?page=${params.page}&size=${params.size}&q=${params.q}`

    if(params.type && params.id){
      url = `${url}&${params.type}_id=${params.id}`
    }

    const response = await get(url)
    if(response?.status === 200){

      return response.data
    }
  }

  const getProductAnalysis = async (productId: number, projectSelected:string) => {
    const url = `v2/companies/${projectSelected}/projects/${productId}/analysis`

    const response = await get(url)

    if(response?.status === 200){

      return response.data
    }
  }

  const getProductProject = async (productId: number, projectSelected:string) => {
    const url = `v2/companies/${projectSelected}/projects/${productId}`

    const response = await get(url)
    if(response?.status === 200){
      return response.data
    }
  }

  const getAllProductsByFieldId = async (productActived: string, projectSelected:string, fieldSelected:any) => {
    const url = `v2/companies/${projectSelected}/projects?field_id=${fieldSelected?.id}&type=${productActived}&page=1&size=100&farm_group_id=${fieldSelected?.group.id}`

    const response = await get(url)
    if(response?.status === 200){
      return response?.data.projects
    }
  }

  const getAllProductsByFieldIdReports = async (projectSelected:string, fieldSelected:number) => {
    const url = `v2/companies/${projectSelected}/projects?field_id=${fieldSelected}`

    const response = await get(url)
    if(response?.status === 200){
      return response?.data.projects
    }
  }

  const handleReportDownload = async (productSelected: number, reportType: string, locale: string, projectSelected: string ) => {
    const w = window.open('/__loader.html', '_blank') as Window
    const url = `/v2/companies/${projectSelected}/projects/${productSelected}/${reportType}?locale=${locale}`
    const response: any = await apiBackEnd.get(url, {
      responseType: 'blob',
    })

    if(response.data && response.status === 200){
      const url = window.URL.createObjectURL(response.data)
      w.location.href = url
    }
  }

  return {
    getProductAnalysis,
    getProductProject,
    getAllProductsByFieldId,
    removeProduct,
    handleReportDownload,
    getProduct,
    getAllProductsByFieldIdReports
  }
}

export default useProduct
