import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

export type TFormReport = {
  title: string
  suggestion: string
  content: string
}

export const initialValues: TFormReport = {
  title: '',
  suggestion: '',
  content: ''
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'title':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('title', { ns: namespaces.common }) })
      }] as Rule[]

    case 'suggestion':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('suggestion', { ns: namespaces.common }) })
      }] as Rule[]

    case 'content':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('content', { ns: namespaces.common }) })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}
