import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

import map from 'lodash/map'
import find from 'lodash/find'

import { formatDateInputDate } from 'utils/helpers'

import { DialogContext } from 'dialog/context'

import useCrops from 'repositories/useCrops'
import useGrowths from 'repositories/useGrowths'

import { Moment } from 'moment'
import { TSelectOption } from 'types/types'
import { TCrops } from 'types/crops'
import { TFormRotations } from 'types/rotations'

import Modal from 'components/Modal'
import Button from 'components/Button'
import TextField from 'components/TextField'
import SelectField from 'components/SelectField'
import InputDate from 'components/InputDate'
import ModalDeleteRotationst from '../ModalDeleteRotationst'

import {
  initialValues,
  validation,
} from './validations'
import {
  FormBox,
  ContainerBtn,
  RangeData
} from './styles'
import { TGrowth } from 'types/growths'
import isEmpty from 'lodash/isEmpty'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  handleSave(values:TFormRotations):void
  rotationst: TFormRotations
  deleteRotationst(id:any):void
  projectSelected:string
}

const ModalCreateRotationst = ({ open, handleClose, id, handleSave, rotationst, deleteRotationst, projectSelected }: TProps) => {

  const [form] = Form.useForm<TFormRotations>()
  const { t } = useTranslation('namespaces')
  const cropsApi = useCrops()
  const growthsApi = useGrowths()
  const { createDialog } = useContext(DialogContext)

  const [harvestDate, setHarvestDate] = useState<Moment | null>()
  const [plantingDate, setPlantingDate] = useState<Moment | null>()
  const [harvest, setHarvest] = useState<TCrops[] | []>([])
  const [idHarvestSelected, setIdHarvestSelected] = useState<any>()
  const [variety, setVariety] = useState<TGrowth[] | []>([])
  const [idVarietySelected, setIdVarietySelected] = useState<any>()

  const modeEdit = useMemo(() => !isEmpty(rotationst), [rotationst])

  const harvestOptions = useMemo<any[]>(
    () => map(harvest, (item:TCrops) => ({ value: item?.id, label: `${item?.name} (${item?.growth?.name})`, growthId: item?.growth?.id }))
    , [harvest])

  const getVariety = async (id:any) => {
    const response = await growthsApi.getAllGrowths(id)
    setVariety(response)
  }

  const formValues = useMemo(() => {
    if(modeEdit){
      const getOneHarvest = find(harvest, harvest => harvest.name === rotationst.harvest)

      setIdHarvestSelected(getOneHarvest?.id)
      getVariety(rotationst?.growthId)
      return { ...rotationst,
        harvest: `${rotationst?.harvest} (${rotationst?.tillage})`,
        harvestDate: formatDateInputDate(rotationst?.harvestDate),
        plantingDate: formatDateInputDate(rotationst?.plantingDate)
      }
    }
    return initialValues
  }, [rotationst, modeEdit, harvest])

  const varietyOptions = useMemo<TSelectOption[]>(
    () => map(variety, (item:TGrowth) => ({ value: item.id, label: item.name }))
    , [variety])

  const onFinish = (values:TFormRotations) => {
    if(modeEdit){
      const payload = {
        ...values,
        harvest: idHarvestSelected,
        variety: idVarietySelected,
        key: rotationst?.key
      }
      handleSave(payload)
    }else{
      handleSave(values)
    }

    handleClose()
  }

  const closeModal = () => {
    handleClose()
  }

  const handleDelete = () => {
    createDialog({
      id: 'modal-delete-rotationst',
      open: true,
      Component: ModalDeleteRotationst,
      props: {
        title: t('delete', { ns: namespaces.common }),
        message: t('are you sure you want to delete the growth rotation?', { ns: namespaces.modals.createRotationst }),
        handleSave: () => {
          deleteRotationst(rotationst?.key)
          handleClose()
        }
      }
    })
  }

  const getAllVariety = useCallback(
    (harvestSelected:any) => {
      const growths = find(harvestOptions, ({ value }:any) => value === harvestSelected)
      setIdHarvestSelected(harvestSelected)
      getVariety(growths?.growthId)
      setIdVarietySelected(undefined)
      form.setFieldsValue({ variety: undefined })
    }, [variety, idVarietySelected, harvestOptions])

  useEffect(() => {
    const request = async () => {
      const response = await cropsApi.getAllCrops({
        project: projectSelected,
        getType: 'all'
      })
      setHarvest(response)
    }
    request()
  }, [])

  return (
    <Modal
      id={id}
      title={t('register the information of a new crop rotation', { ns: namespaces.modals.createRotationst })}
      isModalVisible={open}
      handleClose={closeModal}
    >
      <FormBox>
        <Form
          initialValues={formValues}
          labelCol={{ span: 6 }}
          name='form-rotationst'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
        >
          <SelectField
            name='harvest'
            rules={validation('harvest', t)}
            label={t('crop', { ns: namespaces.common })}
            id='select-harvest'
            placeholder={t('select one', { ns: namespaces.modals.createRotationst, anything: t('crop', { ns: namespaces.common }) })}
            options={harvestOptions}
            handleChange={getAllVariety}
            value={idHarvestSelected}
            showSearch
          />
          <SelectField
            name='variety'
            label={t('variety', { ns: namespaces.pages.cropRotationst })}
            id='select-variety'
            placeholder={t('select one', { ns: namespaces.modals.createRotationst, anything: t('variety', { ns: namespaces.pages.cropRotationst }) })}
            options={varietyOptions}
            handleChange={setIdVarietySelected }
            value={idVarietySelected}
            showSearch
          />
          <RangeData>
            <InputDate
              id='range-date-rotationst'
              name='plantingDate'
              label={t('planting date', { ns: namespaces.pages.cropRotationst })}
              onChange={setHarvestDate}
              value={plantingDate}
            />
            <InputDate
              id='range-date-rotationst'
              name='harvestDate'
              label={t('harvest date', { ns: namespaces.pages.cropRotationst })}
              onChange={setPlantingDate}
              value={harvestDate}
            />
          </RangeData>
          <TextField
            name='productivity'
            label={t('productivity', { ns: namespaces.pages.cropRotationst })}
            id='input-productivity'
            placeholder={t('productivity', { ns: namespaces.pages.cropRotationst })}
            type='number'
          />
          <ContainerBtn>
            <Button
              id='button-cancel'
              type={modeEdit ? 'dashed' : 'default'}
              label={ modeEdit ? t('delete', { ns: namespaces.common }) : t('cancel', { ns: namespaces.common })}
              onClick={() => modeEdit ? handleDelete() : closeModal()}
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalCreateRotationst
