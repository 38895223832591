import styled from 'styled-components'
import { Upload } from 'antd'

export const Content = styled.div`
  padding: 1rem;
  border-bottom: solid 1px #bcbccb50;

  .ant-select-selector {
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }

  .ant-select-multiple .ant-select-selection-item {
    border: none;
    background: ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  }

  .ant-input-affix-wrapper{
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }
`

export const Title = styled.h3`
    color: ${({ theme }) => theme.colors.YELLOW};
    font-size: 16px;
`

export const TotalArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.05rem;

  h4 {
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: 400;
    font-size: 14px;
  }

  span {
    width: 100%;
    border-radius: 4px;
    border: solid 1px #bcbccb;
    padding: 10px 12px;
    background-color: rgba(39, 53, 59, 0.9);
    color: rgba(255, 255, 255, 0.4);
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
`

export const UploadBtn = styled(Upload)`
    cursor: pointer;
    padding: 0.75rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.WHITE} !important;
    }
    border: dashed 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    
    svg {
        margin: 0 1.5rem -2px 0;
        color: ${({ theme }) => theme.colors.YELLOW};
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: transparent;
    }
    .ant-upload-list-item, .ant-upload-span{
      margin: 0 ;
      svg {
        margin: 0 ;
      }
    }
`

export const SelectDropdown = styled.div`
  margin-top: 6px;
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
  background-color: rgba(9, 45, 61, 0.9);
  input.ant-input {
    background-color: rgba(9, 45, 61, 0.9);
    color: #fff;
    flex: auto;
  }
  a {
    color: #fff;
     flex: none; 
     padding: 8px; 
     display: block; 
     cursor: pointer 
    }
  }
`

export const FileName = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.YELLOW};
  }
`