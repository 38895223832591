import React, { useState, useMemo, useContext } from 'react'
import { Form, Radio, Space } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { DialogContext } from 'dialog/context'
import Modal from 'components/Modal'
import Button from 'components/Button'
import TextField from 'components/TextField'
import ModalDeleteMember from '../ModalDeleteMember'
import {
  initialValues,
  validation,
} from './validations'
import {
  FormBox,
  ContainerBtn,
  Title,
} from './styles'
import { TFormMember } from 'types/member'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  handleSave(values:TFormMember, id?:any):void
  member: any
  deleteMember(id:any):void
}

const ModalCreateMember = ({ open, handleClose, id, handleSave, member, deleteMember }: TProps) => {
  const [form] = Form.useForm<TFormMember>()
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const [userRole, setUserRole] = useState(member?.role || 'user')
  const modeEdit = useMemo(() => !isEmpty(member), [member])

  const formValues = useMemo(() => {
    if(!isEmpty(member)){
      return { ...member }
    }
    return initialValues
  }, [member])

  const onFinish = (values:TFormMember) => {
    const payload = {
      ...values,
      role: userRole,
    }
    if(modeEdit){
      handleSave(payload, member?.key)
    }else{
      handleSave(payload)
    }
    handleClose()
  }

  const closeModal = () => {
    handleClose()
  }

  const handleDelete = () => {
    createDialog({
      id: 'modal-delete-member',
      open: true,
      Component: ModalDeleteMember,
      props: {
        title: t('delete', { ns: namespaces.common }),
        message: t('are you sure you want to delete this member?', { ns: namespaces.modals.memberForm }),
        handleSave: () => {
          deleteMember(member?.key)
          handleClose()
        }
      }
    })
  }

  return (
    <Modal
      id={id}
      title={modeEdit ? t('change a members information', { ns: namespaces.modals.memberForm }) : t('register a new members information', { ns: namespaces.modals.memberForm })}
      isModalVisible={open}
      handleClose={closeModal}
    >
      <FormBox>
        <Form
          initialValues={formValues}
          labelCol={{ span: 6 }}
          name='form-rotationst'
          form={form}
          autoComplete='off'
          onFinish={ onFinish}
        >
          <TextField
            name='email'
            label={t('email', { ns: namespaces.common })}
            id='input-email'
            placeholder={t('email', { ns: namespaces.common })}
            rules={validation('email', t)}
            disabled={modeEdit}
          />
          <Radio.Group onChange={e => setUserRole(e.target.value)} value={userRole}>
            <Title>{t('access type', { ns: namespaces.modals.memberForm })} *</Title>
            <Space direction='vertical'>
              <Radio value='user'>
                <h3>
                    Regular
                </h3>
                <p>{t('access according to assigned permissions', { ns: namespaces.modals.memberForm })}</p>
              </Radio>
              <Radio value='admin'>
                <h3>
                    Admin
                </h3>
                <p>{t('full access to all farms', { ns: namespaces.modals.memberForm })}</p>
              </Radio>
            </Space>
          </Radio.Group>
          <ContainerBtn>
            <Button
              id='button-cancel-or-delete'
              type={modeEdit ? 'dashed' : 'default'}
              label={ modeEdit ? t('delete', { ns: namespaces.common }) : t('cancel', { ns: namespaces.common })}
              onClick={() => modeEdit ? handleDelete() : closeModal()}
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalCreateMember
