import styled from 'styled-components'
import { RiPencilFill } from 'react-icons/ri'
import { Button } from 'antd'

export const Wrapper = styled.div`
  .ant-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .ant-input-affix-wrapper{
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }

  .ant-select-selector {
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }
`

export const EditAvatar = styled.a`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  gap: 8px;
  padding-bottom: 8px;
  color: ${({ theme }) => theme.colors.YELLOW};
  :hover {
    color: ${({ theme }) => theme.colors.WHITE};
  }
`

export const SaveBtn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.BLUEGREEN};
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.WHITE};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 196px;
  height: 51px;
  text-transform: capitalize;
  margin-right: 0;
  margin-top: 16px;
`

export const PencilIcon = styled(RiPencilFill)`
  cursor: pointer;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.YELLOW} !important;
`