import React, { useState } from 'react'

import { Container } from './styles'

type Tprops = {
  getImg:(status:boolean)=>void
  pageMode: boolean
  isUltraSense:boolean
}

const ButtonUltraSense = ({ getImg, pageMode, isUltraSense }:Tprops) => {

  const [active, setactive] = useState<boolean>(isUltraSense)

  return (
    <Container mode={pageMode} active={active}>
      <button
        onClick={() => {
          getImg(active)
          setactive(old => !old)
        }}
      >UltraSense</button>
    </Container>
  )

}

export default ButtonUltraSense