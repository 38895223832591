import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

export type TFormReport = {
  type: string | null
  file: File[] | []
}

export const initialValues: TFormReport = {
  type: null,
  file: []
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'type':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('type', { ns: namespaces.common }) })
      }] as Rule[]

    case 'file':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('file', { ns: namespaces.common }) })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}
