import styled from 'styled-components'
import { Upload, Form } from 'antd'
import { MdDeleteForever } from 'react-icons/md'


export const UploadButton = styled(Upload)`
   cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.THEME} !important;
    margin-bottom: 1rem;

    span {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.WHITE} !important;
    }
    border: dashed 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    
    svg {
        margin: 0 1.5rem -2px 0;
        color: ${({ theme }) => theme.colors.YELLOW};
    }
`

export const FormItem = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  margin-bottom: 20px;

  .ant-form-item-label,
  label {
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  .ant-upload{
    width: 100%;

    span > div {
      padding: 0;
    }
  }

  .ant-upload-list{
    color: ${({ theme }) => theme.colors.WHITE};
    

    svg{
      color: ${({ theme }) => theme.colors.YELLOW};
    }

    .ant-upload-list-item-info{
      background: ${({ theme }) => `${theme.colors.THEME} !important`};
    }

    .ant-upload-span:hover{
      background: ${({ theme }) => `${theme.colors.THEME} !important`};
    }
  }
`

export const PreviewImage = styled.div`
  position: relative;
  width: 100%;
  max-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 180px !important;
    max-height: 120px !important;
    object-fit: contain;
  }
    svg {
    color: ${({ theme }) => theme.colors.YELLOW} !important;
  }
`

export const DeleteField = styled(MdDeleteForever)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 30px;
`
export const Wrapper = styled.div`
  .ant-upload-list-text-container {
    display: none !important;
  }
`