import React, { useCallback, useContext, useEffect, useState } from 'react'
import { apiBackEnd } from 'services/axios'
import { useHistory, useParams } from 'react-router-dom'
import { useGlobalContext } from 'hooks/useGlobalContext'
import MapPharms from 'components/MapPharms'
import { TFields } from 'types/types'
import Drawer from 'components/Drawer'
import { Container, MapContainer, ProductsCollapse, ProductPanel, Footer, ProductList, Product, ProductWrapper } from './styles'
import SoloCheck from 'components/Icons/SoloCheck'
import ProductAnalysisController from './ProductAnalysisController'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import Button from 'components/Button'
import { DialogContext } from 'dialog/context'
import ModalNewProduct from './ModalNewProduct'

import useProduct from 'repositories/useProduct'
import Charts from './Charts'
import { useProductContext } from 'hooks/useProductContext'

import isEmpty from 'lodash/isEmpty'
interface FieldProductsProps {
  listField: TFields[]
  productId: number
  projectSelected: string
  fieldDrawerOpen: boolean
}

const FieldProducts: React.FC<FieldProductsProps> = ({
  listField,
  productId,
  projectSelected,
  fieldDrawerOpen
}) => {

  const history = useHistory()
  const params:any = useParams()


  const [productAnalysis, setProductAnalysis] = useState<any>()
  const [productSelected, setProductSelected] = useState<any>()
  const [fieldSelected, setFieldSelected] = useState<any>([])
  const { setZoomMap } = useGlobalContext()


  const { createDialog } = useContext(DialogContext)

  const {
    setProductActived,
    productActived,
    setProductsAnalysisList,
    productsAnalysisList,
    productProject,
    setProductProject,
    fullscreen,
    setFullscreen
  } = useProductContext()

  const { getAllProductsByFieldId, getProductAnalysis, getProductProject } = useProduct()

  const { t } = useTranslation('namespaces')

  const getListProductSelected = async (productActiveted:string) => {
    if(productActived){
      const response = await getAllProductsByFieldId(productActiveted, projectSelected, listField[0])
      setProductsAnalysisList(response)
      setProductActived(productActived)
    }
  }

  const createNewProduct = useCallback(
    () => {
      createDialog({
        id: 'modal-create-product',
        open: false,
        Component: ModalNewProduct,
        props: {
          handleSave: () => {},
          projectSelected,
          callback: () => getListProductSelected(productActived),
          fieldSelected
        },
      })
    }, [fieldSelected])

  useEffect(() => {
    if(productSelected && projectSelected){
      const request = async () => {
        const analysisResponse = await getProductAnalysis(productSelected, projectSelected)
        setProductAnalysis(analysisResponse)
        const projectResponse = await getProductProject(productSelected, projectSelected)
        setProductProject(projectResponse)
      }
      request()
    }
  }, [productSelected, projectSelected, apiBackEnd.defaults.baseURL ])

  useEffect(() => {
    if(productProject && !isEmpty(listField) && productProject.field.id !== listField[0].id && isEmpty(productAnalysis)){
      setProductSelected(0)
      setProductAnalysis({})
      setProductProject()
    }
  }, [productProject, listField, productSelected, productAnalysis])

  useEffect(() => {
    if(!fullscreen || params?.products ) {
      setZoomMap(true)
    }
  }, [fullscreen, params, apiBackEnd.defaults.baseURL])

  useEffect(() => {
    if(productId){
      setProductSelected(productId)
    }
  }, [productId])

  useEffect(() => {
    setFieldSelected(listField[0])
  }, [listField])

  return (
    <Container>
      {productSelected && productAnalysis && productProject ? (
        <>
          {!fullscreen && (
            <ProductWrapper>
              <MapPharms
                modeNdvi={{ value: 'field monitoring', label: 'field monitoring' }}
                fieldsData={listField}
                projectSelected={projectSelected}
                newWindowClick={false}
                style={{
                  height: '353px',
                }}
                isMonitoring={true}
              />
              <Charts
                productAnalysis={productAnalysis}
                projectSelected={projectSelected}
                fieldDrawerOpen={fieldDrawerOpen}
              />
            </ProductWrapper>
          )}
          <Drawer fullscreen={fullscreen} drawerBarCallback={() => setFullscreen(!fullscreen)}>
            <ProductAnalysisController
              productAnalysis={productAnalysis}
              projectSelected={projectSelected}
              productSelected={productSelected}
              setProductSelected={setProductSelected}
              fieldDrawerOpen={fieldDrawerOpen}
            />
          </Drawer>
        </>
      ) : (
        <>
          <MapContainer>
            <MapPharms
              fieldsData={listField}
              projectSelected={projectSelected}
              modeNdvi={{ value: 'Cultura', label: t('culture', { ns: namespaces.common }) }}
              newWindowClick={false}
              style={{
                height: '100%',
              }}
            />
            <Footer />
          </MapContainer>
          <Drawer>
            <ProductsCollapse accordion ghost onChange={(e: any) => getListProductSelected(e)}>
              <ProductPanel
                header={'Ferti'}
                key={'Fertianalise'}
              >
                <ProductList>
                  {productsAnalysisList?.length > 0 ? (
                    <>
                      {productsAnalysisList.map((product: any) => (
                        <Product
                          key={product.id}
                          onClick={() => {
                            setProductSelected(product.id)
                            history.push(`/analytical-products/${projectSelected}/field/${fieldSelected.id}/products/${product.id}`)
                          }}
                        >
                          <SoloCheck />
                          <h4>{t('year', { ns: namespaces.common })}:</h4>
                          <p>{product.reference_year}</p>
                        </Product>
                      ))}
                    </>
                  ) : (
                    <Product>
                      <p>
                        {t('no items found', { ns: namespaces.common, label: t('product', { ns: namespaces.pages.field }) })}
                      </p>
                    </Product>
                  )}
                </ProductList>
              </ProductPanel>
              <Button
                id='button-new-product'
                type='primary'
                label={t('new product', { ns: namespaces.pages.field })}
                onClick={() => createNewProduct()}
              />
            </ProductsCollapse>
          </Drawer>
        </>
      )}
    </Container>
  )
}

export default FieldProducts