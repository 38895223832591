

import styled from 'styled-components'
import { Switch } from 'antd'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
      overflow-x: auto;
    border-top: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    border-bottom: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    ::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.colors.LIGHT_THEME};
    }
    ::-webkit-scrollbar {
        width: 1px;
        height: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.WHITE};
    }
    padding-right: 1.5rem;
`

export const TabsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  
`
export const TabWrapper = styled.div<{ isAtived: boolean}>`
    border-bottom: ${({ isAtived, theme }) => isAtived && `1px solid ${theme.colors.YELLOW}`};
    padding: 0.5rem;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    :first-child {
        margin-left: 1.5rem;
    }
`

export const TabTitle = styled.div<{ isRemovable?: boolean, isAtived: boolean}>`
    cursor: grab;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ theme, isAtived }) => isAtived ? theme.colors.YELLOW : theme.colors.MEDIUM_GRAY};
    color: ${({ isRemovable, theme }) => isRemovable && theme.colors.WHITE}};
    border: ${({ isRemovable, theme }) => isRemovable && `1px solid ${theme.colors.WHITE}`};
    padding: ${({ isRemovable }) => isRemovable && '0.25rem 0.5rem'};
    border-radius: 8px;
    position: relative;
    white-space: nowrap;
    svg {
        color: ${({ theme }) => theme.colors.YELLOW};
    }
`
export const AnalysisSwitch = styled(Switch)`
    .ant-switch-checked {
        background-color: ${({ theme }) => theme.colors.YELLOW} !important;
    }
`