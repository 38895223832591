
export const PALETTE_NDVI = {
  1.0: '#003200',
  0.9: '#003200',
  0.8: '#003200',
  0.7: '#23512E',
  0.6: '#36773E',
  0.5: '#36783E',
  0.4: '#5FB96B',
  0.3: '#90D182',
  0.2: '#C2E799',
  0.1: '#EBF6B0',
  0.0: '#FDFCD8',
} as any