import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { TPayloadNewProduct } from 'types/newProduct'


export const initialValues: TPayloadNewProduct = {
  productType: '',
  year: '',
  group_id: '',
  farm_id: '',
  field_id: '',
  fileList: []
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'productType':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('type product', { ns: namespaces.pages.field }) })
      }] as Rule[]

    case 'year':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('year', { ns: namespaces.common }) })
      }] as Rule[]

    case 'fileList':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('file', { ns: namespaces.common }) })
      }] as Rule[]

    case 'farm_id':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('name', { ns: namespaces.common }) })
      }] as Rule[]


    case 'field_id':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('name', { ns: namespaces.common }) })
      }] as Rule[]

    case 'group_id':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('name', { ns: namespaces.common }) })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}
