import React, { useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { TablePaginationConfig } from 'antd/lib/table'
import { DialogContext } from 'dialog/context'

import { EyeFilled } from '@ant-design/icons'
import { FaEnvelope } from 'react-icons/fa'
import { MdVpnKey } from 'react-icons/md'

import { TFormMember, TFormMemberPermissionArray } from 'types/member'
import { formatDateToView, removeTimeFromDateTime } from 'utils/helpers'

import map from 'lodash/map'

import DataTable from 'components/DataTable'
import Tooltip from 'components/Tooltip'
import useMembers from 'repositories/useMembers'
import ModalCreateMember from './ModalCreateMember'
import ModalEditMemberPermission from './ModalEditMemberPermission'

import { Container, ActionsWrapper, Label } from './styles'

const Members = () => {
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const { getAllMembers, createMember, resendConfirmation, updateMember, deleteMember, editMemberPermission } = useMembers()
  const { project }:any = useParams()
  const localLanguage = localStorage.getItem('agfarmusLanguage')

  const [makeRequest, setMakeRequest] = useState<boolean>(true)
  const [searchMembers, setSearchMembers] = useState<string>('')

  const filterData = (member:any) => {
    return map(member, item => ({
      name: item?.user?.name || ' - ',
      email: item?.user?.email || ' - ',
      role: item.role,
      created_at: item?.created_at || ' - ',
      key: item.id
    }))
  }

  const getRole = (role: string) => {
    const map: any = {
      user: {
        text: 'Regular',
        color: '#0aa69676'
      },
      admin: {
        text: 'Admin',
        color: '#1890ff'
      },
    }

    const { text, color } = map[role]
    return <Label color={color}>{text}</Label>
  }

  const getMembers = async (pagination:TablePaginationConfig, changeTotalPages:any) => {
    const resMembers = await getAllMembers({
      q: searchMembers,
      project,
      page: pagination.current,
      size: pagination.pageSize,
      getType: 'pagination'
    })

    changeTotalPages(resMembers?.meta?.total_count)
    setMakeRequest(false)
    return filterData(resMembers.members)
  }

  const handleCreateMember = useCallback(
    async (member:TFormMember) => {
      await createMember(member, project )
      setMakeRequest(true)
    }, [])

  const handleEditPermission = useCallback(
    async (permissions:TFormMemberPermissionArray, id:any) => {
      await editMemberPermission(permissions, project, id )
      setMakeRequest(true)
    }, [])

  const handleEditMember = useCallback(
    async (params:TFormMember, id:any) => {
      await updateMember(params, project, id )
      setMakeRequest(true)
    }, [])

  const handleDeleteMember = useCallback(
    async (id:any) => {
      await deleteMember(id, project)
      setMakeRequest(true)
    }, [])

  const createMemberModal = useCallback(
    () => {
      createDialog({
        id: 'modal-create-member',
        open: false,
        Component: ModalCreateMember,
        props: {
          handleSave: handleCreateMember
        }
      })
    }, [])

  const editPermissionModal = useCallback(
    row => {
      createDialog({
        id: 'modal-member-permission',
        open: false,
        Component: ModalEditMemberPermission,
        props: {
          member: row,
          projectSelected: project,
          handleSave: handleEditPermission
        }
      })
    }, [])

  const viewMemberModal = useCallback(
    (row:TFormMember) => {
      createDialog({
        id: 'modal-view-member',
        open: false,
        Component: ModalCreateMember,
        props: {
          member: row,
          disabled: true,
          deleteMember: handleDeleteMember,
          handleSave: handleEditMember
        }
      })
    }, [])

  const handleResendConfirmation = useCallback( async (memberKey: number) => {
    await resendConfirmation(project, memberKey)
  }, [])

  const columns = [
    {
      title: t('name', { ns: namespaces.common }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('email', { ns: namespaces.common }),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('user type', { ns: namespaces.common }),
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => {
        return getRole(role)
      }
    },
    {
      title: t('created at', { ns: namespaces.common }),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text)
      }
    },
    {
      title: t('actions', { ns: namespaces.common }),
      dataIndex: 'actions',
      key: 'actions',
      render: (text:string, record:any) => {
        return (
          <ActionsWrapper>
            <Tooltip title={t('resend confirmation', { ns: namespaces.pages.member })}>
              <FaEnvelope onClick={() => handleResendConfirmation(record.key)} />
            </Tooltip>
            {record.role === 'user' && (
              <Tooltip title={t('edit permissions', { ns: namespaces.pages.member })}>
                <MdVpnKey onClick={() => editPermissionModal(record)} />
              </Tooltip>
            )}
            <Tooltip title={t('edit member', { ns: namespaces.pages.member })}>
              <EyeFilled onClick={() => viewMemberModal(record)} />
            </Tooltip>
          </ActionsWrapper>)
      }
    },
  ]

  return (
    <Container>
      <DataTable
        getDataFromApi={getMembers}
        columns={columns}
        create={createMemberModal}
        labelBtnCreate={t('new something', { ns: namespaces.common, label: t('member', { ns: namespaces.pages.member }) })}
        title={t('view all members', { ns: namespaces.pages.member })}
        labelSearch={t('search for a member', { ns: namespaces.pages.member })}
        makeRequest={makeRequest}
        search={searchMembers}
        setSearch={setSearchMembers}
      />
    </Container>
  )
}

export default Members
