import styled from 'styled-components'

type Tprops = {
  mode: boolean
  active: boolean
}

export const Container = styled.div<Tprops>`
  position: absolute;
  z-index: 500;
  align-items: center;
  width: 200px;
  min-height: 56px;
  color: ${({ theme, active }) => active ? theme.colors.THEME : theme.colors.YELLOW};
  border-radius: 7px;
  margin-top: 11px;
  margin-left: ${({ mode }) => mode ? '40%' : '70%'};

  @media screen and (max-width: 1700px) {
    margin-left: ${({ mode }) => mode ? '38%' : '60%'};
  }

  @media screen and (max-width: 1550px) {
    margin-left: ${({ mode }) => mode ? '32%' : '60%'};
  }

  @media screen and (max-width: 1450px) {
    margin-left: ${({ mode }) => mode ? '34%' : '60%'};
  }

  @media screen and (max-width: 1350px) {
    margin-left: ${({ mode }) => mode ? '32%' : '60%'};
  }

  @media screen and (max-width: 1250px) {
    margin-left: ${({ mode }) => mode ? '28%' : '60%'};
  }

  button {
    background-color: ${({ theme, active }) => active ? theme.colors.YELLOW : theme.colors.THEME};
    border: ${({ theme }) => `1px solid ${theme.colors.YELLOW}`};
    height: 56px;
    width: 100px;
    border-radius: 7px;
  }
`