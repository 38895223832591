import React from 'react'

type TProps = {
  color?: string;
};

const DeleteIcon = ({
  color = '#F37422',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='18'
      fill='none'
      viewBox='0 0 14 18'
    >
      <path d='M10.9844 6V15.9844H3.01562V6H10.9844ZM9.48438 0H4.51562L3.48438 0.984375H0.015625V3H13.9844V0.984375H10.5156L9.48438 0ZM13 3.98438H1V15.9844C1 17.1094 1.89062 18 3.01562 18H10.9844C12.1094 18 13 17.1094 13 15.9844V3.98438Z' fill={color} />
    </svg>
  )
}

export default DeleteIcon