import React, { createContext, useContext, useState } from 'react'

type TProductContext = {
  productActived: string
  setProductActived: Function
  productsAnalysisList: Array<any>
  setProductsAnalysisList: Function
  productSelected: number
  setProductSelected: Function
  chartSelected: { menu: string, submenu: string }
  setChartSelected: Function
  productProject: any
  setProductProject: Function
  fullscreen: boolean
  setFullscreen: Function
  analysisWithCorrelation: boolean
  setAnalysisWithCorrelation: Function
}

const ProductContext = createContext<TProductContext>({} as TProductContext)

type TProps = {
  children: React.ReactNode
}

const ProductContextProvider = ({ children }: TProps) => {
  const [productActived, setProductActived] = useState('Fertianalise')
  const [productsAnalysisList, setProductsAnalysisList] = useState([])
  const [productSelected, setProductSelected] = useState(0)
  const [chartSelected, setChartSelected] = useState({ menu: 'analytical summary', submenu: 'all' })
  const [productProject, setProductProject] = useState()
  const [fullscreen, setFullscreen] = useState(false)
  const [analysisWithCorrelation, setAnalysisWithCorrelation] = useState(false)

  return (
    <ProductContext.Provider
      value={{
        productActived,
        setProductActived,
        productsAnalysisList,
        setProductsAnalysisList,
        productSelected,
        setProductSelected,
        chartSelected,
        setChartSelected,
        productProject,
        setProductProject,
        fullscreen,
        setFullscreen,
        analysisWithCorrelation,
        setAnalysisWithCorrelation,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

const useProductContext = (): TProductContext => {
  const context = useContext(ProductContext)

  return context
}

export { useProductContext, ProductContextProvider }
