import styled from 'styled-components'

export const FormBox = styled.div`
    margin: 20px 20px auto 0px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.THEME};
    border-radius: 4px;

    label{
      font-size: 16px;
    }

    span {
      cursor: pointer;
    }

    .ant-select {
    span {
      color: ${({ theme }) => `${theme.colors.WHITE} !important`};
    }
  }

  .ant-select-selector {
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }

  .ant-select-multiple .ant-select-selection-item {
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
    background: ${({ theme }) => theme.colors.THEME};
  }
  
  .ant-form-item-label {
    overflow: visible;
  }

  .ant-input-affix-wrapper{
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }
`

export const Title = styled.h3`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.YELLOW};
    margin: 0;
`

export const Text = styled.p`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.YELLOW};
`

export const UploadBtnInfo = styled.span`
    display: flex;
    gap: 0.75rem;
    margin-top: 20px;
`
export const ContainerBtn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;
  align-items: start;
  bottom: 0;
  margin-top: 20px;
`
export const BtnSave = styled.div`
  display: flex;
  width: 200px;
`