import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { apiBackEnd } from 'services/axios'

const useProject = () => {
  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')
  const global = useGlobalContext()
  const history = useHistory()

  const getAllProject = async () => {

    const url = 'v2/companies/all'
    const response = await rest.get(url)

    return response
  }

  const createProject = async (params:any) => {
    try{
      global.addLoading()
      const url = 'v2/companies'
      const response = await apiBackEnd.post(url, params)

      setTimeout(() => {
        if(response?.status === 200){
          notify({ message: t('item create with success', { ns: namespaces.errors }), success: true })
          history.push('/')
          global.removeLoading()
          return response?.data
        }
      }, 2000)

    } catch(error: any){
      global.removeLoading()
      if(error.response.status === 422) {
        const notify = () => toast.error( t('cnpj ruc is already being used by another project', { ns: namespaces.pages.projectController }))
        notify()
      } else {

        const notify = () => toast.error(t('default', { ns: namespaces.errors }))
        notify()
      }
    }
  }

  const updateProject = async (params:any, projectId:string) => {
    try{
      global.addLoading()
      const url = `v2/companies/${projectId}`
      const response = await apiBackEnd.put(url, params)

      setTimeout(() => {
        if(response?.status === 200){
          notify({ message: t('item updated successfully', { ns: namespaces.errors }), success: true })
          history.push('/')
          global.removeLoading()
          return response?.data
        }
      }, 2000)

    } catch(error: any){
      global.removeLoading()
      if(error.response.status === 422) {
        const notify = () => toast.error( t('cnpj ruc is already being used by another project', { ns: namespaces.pages.projectController }))
        notify()
      } else {

        const notify = () => toast.error(t('default', { ns: namespaces.errors }))
        notify()
      }
    }
  }

  const deleteProject = async (projectId:string) => {
    try{
      global.addLoading()
      const url = `v2/companies/${projectId}`
      const response = await rest.remove(url)

      if(response?.status === 200){
        notify({ message: t('item removed with succes', { ns: namespaces.errors }), success: true })
      }

      return response?.data
    } catch(error: any){
      const notify = () => toast.error(t('default', { ns: namespaces.errors }))
      notify()
    }finally{
      global.removeLoading()
    }
  }

  return {
    getAllProject,
    createProject,
    updateProject,
    deleteProject
  }
}

export default useProject
