
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { useAuth } from 'hooks/useAuth'
import useGroups from 'repositories/useGroups'
import useNdvi from 'repositories/useNdvi'
import { TGroups, TGetLotes, TFields, TSelectOption } from 'types/types'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import find from 'lodash/find'

import MapPharms from 'components/MapPharms'
import SelectFarm from 'components/SelectFarm'
import MenuNdvi from 'components/MenuNDVI'

import { Container } from './styles'
import { getFarm, getListField } from 'utils/helpers'

const Dashboard = () => {
  const { t } = useTranslation('namespaces')
  const { loading, saveGroupsSelected, groupsSelected, setIdFarmSelected, saveFarmSelected, idFarmSelected, farmSelected, setZoomMap } = useGlobalContext()
  const params:any = useParams()
  const groupsApi = useGroups()
  const { isSignedIn } = useAuth()
  const ndviApi = useNdvi()

  const [groups, setGroups] = useState<TGroups[]>()
  const [modeNdvi, setModeNdvi] = useState<TSelectOption>({ value: 'Cultura', label: t('culture', { ns: namespaces.common }) })
  const [ndviOptions, setNdviOptions] = useState()
  const [fields, setFields] = useState<TFields[]>([])
  const [mode, setMode] = useState<string>('menu')
  const [label, setLabel] = useState<string>(t('all farms', { ns: namespaces.common }))
  const [totalArea, setTotalArea] = useState<number>(0)
  const [totalFarms, setTotalFarms] = useState<number>(0)
  const [totalFields, setTotalFields] = useState<number>(0)

  const hasLoading = useMemo(() => loading >= 1, [loading])

  const arrayIds = useMemo(() => uniq(map(fields, (field:any) => field?.idFarm)), [groups])

  const fieldsAndNdvis = useMemo(
    () =>
      map(fields, (field:any) => ({ ...field, ndvi: find(ndviOptions, ({ id }) => field.id === Number(id)) }))
    , [fields, ndviOptions])

  const seachFields = useCallback(
    async ({ project, type, id = undefined }:TGetLotes) => {

      const response = await groupsApi.getFields({ project, type, id })
      if (!response?.data) return

      const fields = getListField(response?.data.groups, [])

      setFields(fields)
      setTotalArea(response?.data?.total_area)
      setTotalFarms(response?.data?.total_farms)
      setTotalFields(response?.data?.total_fields)
      setGroups(response?.data.groups)

      if(type === 'all'){
        saveGroupsSelected(response?.data.groups)
      }

      if(type === 'farm' && id){
        const farm = getFarm(response.data.groups, id)

        setLabel(farm.name)
        setIdFarmSelected(id)
        saveFarmSelected(response.data.groups)
      }

      setMode('description')
      setZoomMap(true)
      setModeNdvi({ value: '', label: t('monitoring', { ns: namespaces.layout }) })

    }, [params?.project])

  useEffect(() => {
    if(farmSelected && idFarmSelected){
      seachFields({ type: 'farm', project: params.project, id: idFarmSelected })
    }else{
      seachFields({ type: 'all', project: params.project })
    }
  }, [params.project, isSignedIn ])

  useEffect(() => {
    const requestNdvi = async () => {

      if (isEmpty(arrayIds)) return

      const response = await ndviApi.getNdvi(arrayIds)

      if(response)
        setNdviOptions(response as any)

    }
    if (!arrayIds) return

    requestNdvi()
  }, [arrayIds])

  return(
    <Container>
      <SelectFarm
        lisTGroupsFarm={groupsSelected}
        getLotes={seachFields}
        mode={mode}
        setMode={setMode}
        groupOrFarmSelected={groups}
        label={label}
        handleLabel={setLabel}
        display={hasLoading}
        projectSelected={params?.project}
        totalArea={totalArea}
        totalFarms={totalFarms}
        totalFields={totalFields}
      />
      <MenuNdvi
        setModeNdvi={setModeNdvi}
        modeNdvi={modeNdvi}
        display={hasLoading}
        options={[{ value: 'Cultura', label: t('culture', { ns: namespaces.common }) }, { value: 'Vegetação', label: t('vegetation', { ns: namespaces.common }) }, { value: 'hd', label: t('hd image', { ns: namespaces.common }) }]}
      />
      <MapPharms
        fieldsData={fieldsAndNdvis}
        modeNdvi={modeNdvi}
        projectSelected={params?.project}
        style={{
          height: '100%',
        }}
      />
    </Container>
  )
}

export default Dashboard
