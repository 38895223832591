import styled from 'styled-components'

export const Container = styled.div`
 width: 210px;
 /* display: flex;
 justify-content: center;
 align-items: center; */
 padding: 0 10px;
 height: 40px;

 .ant-select {
    span {
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};
    }
  }

  .ant-select-selector {
    border: none !important;
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }

  .ant-select-multiple .ant-select-selection-item {
    border: none;
    background: ${({ theme }) => theme.colors.THEME};
  }
`