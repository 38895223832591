import React, { useRef, useState } from 'react'
import { v1 as uuidv1 } from 'uuid'
import Highcharts from 'highcharts'
import {
  HighchartsChart,
  Chart,
  HighchartsProvider,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineSeries,
  PlotBand,
  Title
} from 'react-jsx-highcharts'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { Wrapper, ChartWrapper, Table, Description, TableTitle } from './styles'

interface GeneralChartProps {
  title: string,
  description: string,
  yMin: number,
  yMax: number,
  xLabel: string,
  yLabel: string,
  idealValues: Array<any>,
  classification: {below: number, total: number, ideal: number, above: number},
  series: Array<any>,
  leastSquares: {trendline: any},
}

const GeneralChart: React.FC<GeneralChartProps> = ({
  title,
  description,
  yMin,
  yMax,
  xLabel,
  yLabel,
  idealValues,
  classification,
  series,
  leastSquares,
}) => {
  const { t } = useTranslation('namespaces')
  const [chartWidth, setChartWidth] = useState<number>()
  const containerRef: any = useRef(0)

  const leastSquaresName = (leastSquares: any) => {
    let y = '<div>y = '
    leastSquares.coefficients.map((c: any, i: any) => {
      const pow = leastSquares.coefficients.length - (i + 1)
      y += `<span>${i > 0 ? '+ ' : ''}${(c || 0).toFixed(2)}${pow > 0 ? 'x' : ''}<sup>${
        pow > 1 ? pow : ''
      }</sup> </span>`
      return y
    })
    y += '</div>'

    const r = `<div>r<sup>2</sup> = ${(leastSquares.r_squared || 0).toFixed(2)}</div>`

    return `<div>${y}${r}</div>`
  }

  const showToolTip = (e: any) => {
    const x = e.target && e.target.x

    Highcharts.charts.forEach(chart => {
      if (chart && chart.series && chart.series) {
        const points: any = []

        chart.series.forEach(serie => {
          const tracking = serie.options && serie.options.enableMouseTracking !== false
          serie.data.forEach((value: any) => {
            if (value.x === x && !isNaN(value.y) && tracking) {
              points.push(value)
            }
          })
        })

        if (points.length > 0) {
          chart.tooltip.refresh(points)
        }
      }
    })
  }

  const hideToolTip = (e: any) => {
    e.target.select(false, false)
    Highcharts.charts.forEach(chart => {
      if (chart) {
        chart.tooltip.hide()
        chart.series.forEach(serie => {
          serie.data.forEach(value => {
            if (value.x === e.target.x) {
              value.select(false, false)
            }
          })
        })
      }
    })
  }

  const plotOptions = {
    series: {
      animation: false,
      label: { connectorAllowed: false },
      point: {
        events: {
          mouseOver: (e: any) => showToolTip(e),
          mouseOut: (e: any) => hideToolTip(e),
        },
      },
    },
  }

  return (
    <Wrapper>
      <ChartWrapper ref={containerRef}>
        <HighchartsProvider Highcharts={Highcharts}>
          <HighchartsChart plotOptions={plotOptions}>
            <Chart width={chartWidth} onRender={() => setChartWidth(containerRef?.current?.clientWidth)} />
            <Title useHTML>{title}</Title>
            <Legend layout='horizontal' align='center' verticalAlign='bottom' useHTML />
            <Tooltip shared />
            <XAxis min={1} gridLineWidth={1} allowDecimals={false}>
              <XAxis.Title>{xLabel}</XAxis.Title>
            </XAxis>
            <YAxis min={yMin || 0} softMax={yMax || undefined}>
              <YAxis.Title>{yLabel}</YAxis.Title>
              {series.map(serie => (
                <LineSeries
                  key={uuidv1()}
                  name={serie.label || yLabel}
                  showInLegend={false}
                  marker={{
                    symbol: 'circle',
                    enabledThreshold: 0,
                    fillColor: serie.color || '#7cb5ec',
                  }}
                  states={{
                    hover: {
                      lineWidthPlus: 0,
                    },
                    inactive: {
                      opacity: 1,
                    },
                  }}
                  opacity={1}
                  lineWidth={0}
                  data={(serie.values || []).map((value: any) => [Number(value.x), Number(value.y)])}
                />
              ))}

              {leastSquares && (
                <LineSeries
                  name={leastSquaresName(leastSquares)}
                  color='#7cb5ec'
                  enableMouseTracking={false}
                  marker={{
                    enabled: false,
                  }}
                  data={leastSquares.trendline}
                />
              )}

              {idealValues && (
                <PlotBand from={idealValues[0]} to={idealValues[1]} color='rgba(220, 220, 220, 0.3)' />
              )}
            </YAxis>
          </HighchartsChart>
        </HighchartsProvider>

      </ChartWrapper>

      <Description dangerouslySetInnerHTML={{ __html: description }} />

      {classification && (
        <>
          <TableTitle>
            {t('classification of data in relation to the ideal', { ns: namespaces.pages.field })}
          </TableTitle>
          <Table>
            <thead>
              <th>
                {t('below', { ns: namespaces.common })}
              </th>
              <th>
                {t('ideal', { ns: namespaces.common })}
              </th>
              <th>
                {t('above', { ns: namespaces.common })}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  {((classification.below / classification.total) * 100.0).toFixed(2)}
                  {'%'}
                </td>
                <td>
                  {((classification.ideal / classification.total) * 100.0).toFixed(2)}
                  {'%'}
                </td>
                <td>
                  {((classification.above / classification.total) * 100.0).toFixed(2)}
                  {'%'}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </Wrapper>
  )
}

export default GeneralChart
