import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { TFormMember } from 'types/member'

export const initialValues: TFormMember = {
  email: '',
  role: 'user'
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'email':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('email', { ns: namespaces.common }) })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}
