import React, { Fragment, ReactNode, useEffect, useMemo, useState } from 'react'
import {
  TablePaginationConfig
} from 'antd/lib/table/interface'
import { SearchOutlined } from '@ant-design/icons'
import { DEFAULT_PAGINATION } from 'constants/pagination'
import { TColumns, TGroups } from 'types/types'
import TextField from 'components/TextField'
import Button from 'components/Button'
import FieldsDisplayed from './FieldsDisplayed'
import { theme } from 'styles'

import { Container, Header, Filter, Table, Title, Content, Wrapper, Line } from './styles'
import { apiBackEnd } from 'services/axios'


type TProps = {
  columns: TColumns[]
  emptyMessage?: string
  selectableRow?: boolean
  setSelectedRows?: React.Dispatch<React.SetStateAction<object[]>>
  loading?: boolean
  getDataFromApi(pagination: TablePaginationConfig, changeTotalPages: (totalPages: number) => void): Promise<object[]> | object[] | [] | any
  create: () => void
  createSecondary?: () => void
  labelBtnCreate: string
  title: string
  labelSearch: string
  makeRequest?: boolean
  search?: string
  setSearch: (search: string) => void
  renderComponentFilter?: (updateTable: (pagination: TablePaginationConfig) => Promise<object[]> | object[] | [] | any) => JSX.Element
  labelSecondaryBtnCreate?: string
  groupFarmData?: TGroups[]
  disabledSearch?: boolean
  hideColumnsFilter?: boolean
  customTable?: ReactNode,
  hasBackground?: boolean
}

const DataTable = (
  { columns,
    selectableRow = false,
    loading,
    setSelectedRows,
    getDataFromApi,
    create,
    createSecondary,
    labelBtnCreate,
    title,
    labelSearch,
    makeRequest,
    search,
    setSearch,
    renderComponentFilter = () => <div />,
    labelSecondaryBtnCreate,
    disabledSearch = false,
    hideColumnsFilter = false,
    customTable,
    hasBackground = true,
    ...rest
  }: TProps) => {

  const [pagination, setPagination] = useState<TablePaginationConfig>(DEFAULT_PAGINATION)
  const [data, setData] = useState<TGroups[] | undefined>([])
  const [filterColumns, setfilterColumns] = useState<TColumns[] | []>([])

  const rowSelection = useMemo(() => {
    if (!selectableRow) return undefined

    return {
      onChange: (keys: React.Key[], rows: object | object[]) => {
        const _rows = Array.isArray(rows) ? rows : [rows]
        if (setSelectedRows) setSelectedRows(_rows)
      }
    }
  }, [selectableRow])

  const changeTotalPages = (totalPages: number) =>
    setPagination(old => ({ ...old, total: totalPages }))

  const getRowTable = async (pagination: TablePaginationConfig) => {
    const response = await getDataFromApi(pagination, changeTotalPages)
    setData(response)
  }

  const onChangeTable = (tablePagination: TablePaginationConfig) => {
    const newPagination = {
      ...tablePagination,
      current: tablePagination.current
    }
    setPagination(newPagination)
    getRowTable(newPagination)
  }

  const handleSearch = (value: string, type: string) => {
    setPagination(DEFAULT_PAGINATION)
    setSearch(value)
    if (type === 'click') {
      setSearch('')
    }
  }

  useEffect(
    () => {
      getRowTable(pagination)
    }, [search, makeRequest, apiBackEnd.defaults.baseURL])

  return (
    // @ts-ignore
    <Container style={{ background: !hasBackground && 'rgba(0,0,0,0)' }}>
      <Header>
        <Title> {title} </Title>
        <Filter>
          <div
            style={{
              position: 'relative',
              left: '-55%',
              marginTop: '-43px'
            }}
          >
            {renderComponentFilter(() => getRowTable(pagination))}
          </div>
          {
            !hideColumnsFilter && <div style={{ marginTop: -11 }}>
              <FieldsDisplayed columns={columns} filterColumns={setfilterColumns} />
            </div>
          }
          <div>
            {!disabledSearch && (
              <TextField
                name='search'
                onChange={({ target, type }) => handleSearch(target.value, type)}
                value={search}
                id='input-search-table'
                placeholder={labelSearch}
                startIcon={<SearchOutlined />}
                iconColor={theme.colors.YELLOW}
              />
            )}
          </div>
          <Wrapper style={{ paddingTop: 0 }}>
            <Button
              id='btn-new-rotation'
              onClick={() => create()}
              type='primary'
              label={labelBtnCreate}
              htmlType='button'
            />
            {
              labelSecondaryBtnCreate && createSecondary &&
              <Button
                id='btn-new-rotation'
                onClick={() => createSecondary()}
                type='primary'
                label={labelSecondaryBtnCreate}
                htmlType='button'
              />
            }
          </Wrapper>
        </Filter>
      </Header>
      <Content>
        {
          customTable ||
          <Fragment>
            <Line />
            <Table
              rowSelection={rowSelection}
              columns={filterColumns}
              dataSource={data}
              size='small'
              pagination={pagination}
              onChange={onChangeTable}
              loading={loading}
              {...rest}
            />
          </Fragment>
        }
      </Content>
    </Container>
  )
}

export default DataTable