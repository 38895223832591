import styled from 'styled-components'

export const ChartsWrapper = styled.div<{ fullscreen: boolean, fieldDrawerOpen: boolean }>`
    height: ${({ fullscreen }) => fullscreen ? 'calc(100vh - 213px)' : 'calc(100vh - 433px)'};
    width: auto;
};
    background-color: ${({ theme }) => theme.colors.THEME};
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px;
    overflow: auto;

    ::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.colors.THEME};
        border-bottom-right-radius: 8px;
    }
    ::-webkit-scrollbar {
        width: 6px;
        height: 12px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.YELLOW};
    }
`

export const PDFViwer = styled.object`
    width: 100%;
    height: 100vh;
`

export const Recommendation = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.WHITE};
    h2, h4 {
        color: ${({ theme }) => theme.colors.WHITE};
    }
`