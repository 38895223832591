import React from 'react'

type TProps = {
  size?: number;
  color?: string;
};

const ArrowRightIcon = ({
  size = 18,
  color = '#F37422',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 18 18'
    >
      <path d='M9 0.984375L7.59375 2.39062L13.1719 8.01562H0.984375V9.98438H13.1719L7.59375 15.6094L9 17.0156L17.0156 9L9 0.984375Z' fill={color} />
    </svg>
  )
}

export default ArrowRightIcon