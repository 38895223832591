import styled from 'styled-components'
import { Layout } from 'antd'
const { Footer, Sider } = Layout

type TDrawerProps = {
  open: boolean;
};

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${({ theme }) => theme.colors.THEME};
`

export const ContainerFooter = styled(Footer)`
  padding: 0px 0px !important;
  font-size: 14px;
  height: 100px;
  background: ${({ theme }) => `${theme.colors.THEME} !important`};
`

export const Drawer = styled.div`
  width: 7px;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.GRAY};
  border-radius: 80px;
  position: absolute;
  top: 40%;
  margin: 0px 6px;
`

export const ContainerSider = styled(Sider)<TDrawerProps>`
  flex: 0 0 417px !important;
  max-width: ${({ open }) => (open ? '441px !important' : '24px  !important')};
  min-width: ${({ open }) => (open ? '441px !important' : '24px  !important')};
  width: ${({ open }) => (open ? '441px !important' : '24px  !important')};
  border-left: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  background-color: ${({ theme }) => theme.colors.THEME};
`
