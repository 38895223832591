import React, { useMemo } from 'react'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { useParams } from 'react-router-dom'

import Loading from 'components/Loading'

import MainLayout from './MainLayout'
import MainLayoutProject from './MainLayoutProject'
import { useAuth } from 'hooks/useAuth'

const WithLayout = ({ children }:any) => {

  const global = useGlobalContext()
  const { userData } = useAuth()
  const { loading } = global
  const params:any = useParams()

  const Layout = useMemo(
    () => {

      if (userData?.role === 'admin'){
        return MainLayout
      }

      if (params.project)return MainLayout


      return MainLayoutProject

    }, [params, userData, loading])

  return (
    <Layout>
      {loading >= 1 && <Loading />}
      {children}
    </Layout>
  )
}

export default WithLayout