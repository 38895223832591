import React, { useState, useEffect, useCallback } from 'react'
import { namespaces } from 'i18n/i18n.constants'
import { Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import arrayMutators from 'final-form-arrays'
import Modal from 'components/Modal'
import Button from 'components/Button'
import TextField from 'components/TextField'

import {
  ContainerBtn,
  FarmName,
  FarmTh,
  PermissionTable,
  TableLabel,
  Radio
} from './styles'
import { TFormMemberPermission, TFormMemberPermissionArray } from 'types/member'
import useMembers from 'repositories/useMembers'
import { useGlobalContext } from 'hooks/useGlobalContext'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  handleSave(permissions:TFormMemberPermissionArray, id?:any):void
  member: any
  projectSelected: any
}

const ModalEditMemberPermission = ({ open, handleClose, id, handleSave, member, projectSelected }: TProps) => {
  const { getMember } = useMembers()
  const { fieldSelected, groupsSelected } = useGlobalContext()
  const { t } = useTranslation('namespaces')

  const [allGroups, setAllGroups] = useState<any[]>()
  const [memberPermissions, setMemberPermissions] = useState<TFormMemberPermission[]>([])
  const [memberEmail, setMemberEmail] = useState<string>('')

  // Edit member permission request
  const onSubmit = (permissions:any[]) => {
    const convertedPermissions: TFormMemberPermissionArray = {
      permissions: Object.keys(permissions)
        .filter((key: string | any) => permissions[key] !== undefined)
        .map((key: any) => ({ ...permissions[key], farm_id: key.substring(2) })),
    }
    handleSave(convertedPermissions, member?.key)
    handleClose()
  }

  // Farm row with member permission
  const renderGroup = (group: any, depth = 0) => (
    <React.Fragment>
      <tr key={group.id}>
        <td colSpan={5} style={{ paddingLeft: 16 * (depth + 1) }}>
          <b>{group.name}</b>
        </td>
      </tr>
      {group.farms
        .map((farm: any) => (
          <tr key={farm.id} style={{ marginLeft: 15 * depth }}>
            <FarmName>{farm.name}</FarmName>
            <td>
              <Radio component='input' type='radio' name={`id${farm.id}.kind`} value='' />
            </td>
            <td>
              <Radio component='input' type='radio' name={`id${farm.id}.kind`} value='read' />
            </td>
            <td>
              <Radio component='input' type='radio' name={`id${farm.id}.kind`} value='write' />
            </td>
            <td>
              <Radio component='input' type='radio' name={`id${farm.id}.kind`} value='manage' />
            </td>
          </tr>
        ))}
      {Object.values(group.groups)
        .map(child => renderGroup(child, depth + 1))}
    </React.Fragment>
  )

  const decorateIntialValues = (values: any) => (values || [])?.reduce((acc: any, i: any) => {
    acc[`id${i.farm_id}`] = { kind: i.kind }
    return acc
  }, {})


  // Get the member permissions
  const handleGetMemberPermission = useCallback(
    async (project:string, idMember:number) => {
      const response = await getMember(project, idMember )
      setMemberPermissions(response.permissions)
    }, [])

  useEffect(() => {
    handleGetMemberPermission(projectSelected, member?.key)
    setMemberEmail(member.email)
  }, [projectSelected])


  // Get all groups of farms an sort in ASC order
  useEffect(() => {
    setAllGroups(groupsSelected)
  }, [groupsSelected, fieldSelected])

  return (
    <Modal
      id={id}
      title={t('update a members permissions', { ns: namespaces.pages.member })}
      isModalVisible={open}
      handleClose={handleClose}
    >
      {memberPermissions && (
        <Form
          onSubmit={onSubmit}
          subscription={{}}
          initialValues={decorateIntialValues(memberPermissions)}
          // @ts-ignore
          mutators={arrayMutators}
          render={({ handleSubmit }) => (
            <form noValidate autoComplete='off' onSubmit={handleSubmit}>
              <TextField
                label={t('email', { ns: namespaces.common })}
                id='input-email'
                placeholder={t('email', { ns: namespaces.common })}
                value={memberEmail}
                disabled
              />
              <TableLabel>{t('type of permissions', { ns: namespaces.pages.member })}</TableLabel>
              <PermissionTable>
                <thead>
                  <FarmTh>{t('farm', { ns: namespaces.pages.member })}</FarmTh>
                  <th>{t('no access', { ns: namespaces.pages.member })}</th>
                  <th>{t('view', { ns: namespaces.pages.member })}</th>
                  <th>{t('edit', { ns: namespaces.pages.member })}</th>
                  <th>{t('manage', { ns: namespaces.pages.member })}</th>
                </thead>
                {allGroups && (
                  <tbody>
                    {Object.values(allGroups)
                      .map(group => renderGroup(group))}
                  </tbody>
                )}
              </PermissionTable>
              <FormSpy subscription={{ submitting: true }}>
                {({ submitting } : any) => (
                  <ContainerBtn>
                    <Button
                      id='button-confirm'
                      type='primary'
                      label={t('update permissions', { ns: namespaces.pages.member })}
                      htmlType='submit'
                      disabled={submitting}
                    />
                  </ContainerBtn>
                )}
              </FormSpy>
            </form>
          )}
        />
      )}
    </Modal>
  )
}

export default ModalEditMemberPermission
