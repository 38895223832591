import React, { useState, useEffect } from 'react'
import { Spin } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'

import { Container, Content } from './styles'

import MonitoringLoading from './Monitoring'

const Loading = () => {
  const [page, setPage] = useState<boolean>(false)
  const [pageName, setPageName] = useState<string>()
  const antIcon = (
    <Loading3QuartersOutlined style={{ fontSize: 52, color: '#f37422' }} spin />
  )

  useEffect(() => {
    const pathName = window.location.pathname

    if (pathName.includes('/monitoring') || pathName.includes('/satellite-monitoring')) {
      setPageName(pathName)
      setPage(true)
    }
  }, [window.location.pathname])

  return (
    <Container>
      <Content>
        {page ? <MonitoringLoading pageName={pageName} /> : <Spin indicator={antIcon} />}
      </Content>
    </Container>
  )
}

export default Loading
