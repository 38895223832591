import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const Img = styled.img<{rotate:number}>`
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  width: auto;
  height: 100%;
  background-size: cover;
`

export const CarouselImage = styled(Swiper)`
  width: auto;
  height: 700px;

  .swiper-slide {
    display:flex;
    justify-content: center;

    img{
      width: 100%;
      height: 88%;
    }
  }
`

export const ConfigCarousel = styled.div`
  width: 60px;
  height: 38px;
  background:${({ theme }) => theme.colors.SLIGHT_BLACK_OPACITY};
  position: absolute;
  z-index: 1;
  bottom: 17.3%;
  right: 12%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Arrow = styled.div<{ disabled?: boolean }>`
 padding: 5px;
  
  svg {
    color: ${({ theme }) => theme.colors.YELLOW};
  }
`

export const Icons = styled.div`
  z-index: 1000;
  bottom: 15%;
  right: -283px;
  position: absolute;
  justify-content: space-around;
  display: flex;
  align-items: center;
  width: 100%;

  div{
    width: 35px;
    height: 38px;
    background: ${({ theme }) => theme.colors.SLIGHT_BLACK_OPACITY};
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      height: 1em;
      width: 1em;
    }
  }
`