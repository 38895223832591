import { namespaces } from 'i18n/i18n.constants'

export const en = {
  [namespaces.layout]: {
    dashboard: 'Dashboard',
    monitoring: 'Monitoring',
    'change image': 'Change image',
    'reset image': 'Remove image',
    'visualization': 'Visualization',
    'current file': 'Current file',
    'cover image': 'Cover image',
    'right image': 'Right image (recommendations)',
    'left image': 'Left image (recommendations)',
    'alert format': '* images accepted in the formats of .JPEG and .PNG',
    'view settings reports': 'View all report settings',
    gis: 'Gis',
    'field report': 'Watcher reports',
    'settings field report': 'Report settings',
    'agro intelligence': 'Agro intelligence',
    'operational management': 'Operational management',
    'my informations': 'My Informations',
    'my profile': 'My Profile',
    exit: 'Exit',
    climate: 'Climate',
    forecast: 'Forecast',
    'weather history': 'Weather history',
    pluvius: 'PluviUs',
    'agro-data': 'Agrodata',
    'land-report': 'Watcher reports',
    'analytical-products': 'Analytical products',
    'admin': 'Admin',
    field: 'Field',
    'project': 'Project',
    products: 'Product',
    type: 'Type',
    'members': 'Members',
    'farms': 'Group of farms',
    crops: 'Crops',
    'platform management': 'Platform management',
    'users': 'Users',
    'satellite-monitoring': 'Satellite Monitoring',
    reports: 'Reports',
    'crop-rotations': 'Growth rotations',
    'field-report': 'Watcher reports'
  },
  [namespaces.common]: {
    'new register': 'Register the information of a new record',
    'transfer group': 'Transfer the selected Group to another project',
    'new group register': 'Register the information for a new farm group',
    'file': 'File',
    'placeholder name': 'Enter a name',
    search: 'Search what you are looking for',
    login: 'Enter',
    password: 'Password',
    cancel: 'Cancel',
    name: 'Name',
    age: 'Age',
    address: 'Address',
    neighborhoods: 'Neighborhoods',
    city: 'City',
    state: 'State',
    area: 'Area',
    'total area': 'Total area',
    'total fields': 'Total fields',
    'quick summary': 'Quick summary',
    status: 'Status',
    email: 'Email',
    save: 'Save',
    'clean': 'Clean',
    'please wait': 'Please wait...',
    loading: 'Loading ...',
    'wait': 'Wait',
    'loading data farm': 'We are loading the farm data...',
    'loading data field': 'We are loading field data...',
    'delete': 'Delete',
    'password is required': 'Password is required',
    'user is required': 'User is required',
    'full name is required': 'Full name is required',
    'phone is required': 'Phone name is required',
    'it is not a valid email': 'It is not a valid email',
    actions: 'Actions',
    'name file': 'File Name',
    'added to': 'Added to',
    type: 'Type',
    edit: 'Edit',
    size: 'Size',
    'are you sure you want to delete the document?': 'Are you sure you want to delete the document?',
    'to view': 'To view',
    close: 'Close',
    filter: 'Filter',
    observation: 'Observation',
    'start date': 'Start Date',
    'end date': 'End Date',
    english: 'English',
    spanish: 'Spanish',
    portuguese: 'Portuguese',
    'all farms': 'All farms',
    send: 'Send',
    'back-to-login': 'Back to login',
    'import a file': 'Import a file',
    'import file': 'Import file',
    'coordinates': 'Coordinates',
    'latitude': 'Latitude',
    'longitude': 'Longitude',
    'group': 'Group',
    'farm': 'Farm',
    'approximate area': 'Approximate area',
    'imported file': 'Imported file',
    'tillage': 'Tillage',
    'processed area': 'Processed area',
    field: 'Field',
    'not informed': 'Not informed',
    year: 'Year',
    'select year': 'select the year',
    sample: 'Sample',
    'below': 'Below',
    'ideal': 'Ideal',
    'above': 'Above',
    new: 'New',
    all: 'All',
    title: 'Title',
    suggestion: 'Suggestion',
    content: 'Content',
    'new something': 'New {{label}}',
    'new something a': 'New {{label}}',
    'remove something': 'Remove {{label}}',
    'attachment': 'Attachment',
    'archive': 'Archive',
    'starts at': 'Starts at',
    'ends at': 'Ends at',
    'created in': 'Created in',
    culture: 'Growth',
    'culture two': 'Growth',
    crop: 'Crop',
    crops: 'Crops',
    'are you sure you want to delete': 'Are you sure you want to delete {{label}}?',
    'to complete type and confirm': 'To complete, type {{label}} and confirm',
    'field is required': 'Field {{label}} is required',
    'select date': 'Select date',
    'no items found': 'No {{label}} found',
    'created at': 'Created at',
    profile: 'Profile',
    'yes': 'Yes',
    'no': 'No',
    'user type': 'User type',
    'field of farm': 'Field',
    'users': 'Users',
    'import a .PNG or .JPG image': 'Import a .PNG or .JPG image',
    'import': 'Import {{type}}',
    image: 'Image',
    'hd image': 'HD image',
    'visual image': 'Visual image',
    'active': 'active',
    'inactive': 'inactive',
    'vegetation': 'Vegetation',
    'point': 'Point {{number}}',
    'general data': 'General data',
    'select one': 'Select one {{anything}}',
    'add photo': 'Add photos',
    'good': 'Good',
    'bad': 'Bad',
    'regular': 'Regular',
    'excellent': 'Excellent',
  },
  [namespaces.pages.login]: {
    welcome: 'Welcome',
    title: 'Sign in to YVY',
    loginBtn: 'login',
    forgotBtn: 'Forgot your password?',
    'remember account': 'Remember me',
    'forgot password': 'Forgot password?'
  },
  [namespaces.pages.forgotPassword]: {
    title: 'Recover password',
    button: 'Send',
    link: 'Back to login',
  },
  [namespaces.pages.resetPassword]: {
    title: 'Reset password',
    'password-confirmation': 'Password Confirmation',
    'password confirmation is required': 'Password confirmation is required',
    'passwords do not match': 'Passwords do not match'
  },
  [namespaces.pages.dashboard]: {
    welcome: 'Welcome',
  },
  [namespaces.pages.field]: {
    'back reports': 'Back to reports',
    'general data': 'General data',
    'observations': 'Observations',
    'information': 'Informação',
    'variety': 'Variety',
    'vegetative_index': 'Vegetative index',
    'vegetation indices': 'Vegetation indices',
    'weather condition': 'Weather condition',
    'nutritional symptoms': 'Nutritional symptoms',
    'threats': 'Threats',
    'quantity': 'Quantity',
    'spacing': 'Spacing',
    'seeds': 'Seeds',
    'germination': 'Germination',
    'population': 'Population',
    'pods/plants': 'Pods/Plants',
    'grains/pods': 'Grains/Pods',
    'grains weight': 'Thousand grain weight',
    'yield': 'Estimated income',
    'deepness': 'Deepness',
    'samples number': 'Samples number',
    'equipment': 'Equipment',
    'high': 'Elevado',
    'middle': 'Medio',
    'low': 'Bajo',
    'pictures': 'Pictures',
    'responsible': 'Responsible',
    'date': 'Date',
    'culture': 'Culture',
    'growth stage': 'Growth stage',
    'farm': 'Farm',
    'field': 'Field',
    'crop': 'Crop',
    'median ndvi': 'Median NDVI',
    'siganematoide': 'Siganematoid',
    'nutricheck': 'Nutriagro',
    'solocheck': 'Solo check',
    'scout': 'Scout',
    'health': 'Health',
    'weed': 'Weed',
    'bug': 'Bug',
    'yield_component': 'Yeld',
    'field dashboard': 'Field dashboard',
    'field monitoring': 'Field monitoring',
    'geo analysis': 'Geo analysis',
    'products': 'Products',
    'new product': 'Novo product',
    'type product': 'Type of the product',
    'field report': 'Watcher reports',
    'create field': 'Create field',
    'create a new field': 'Create a new field',
    'drag and drop description': 'Drag and drop into the field below or click and select a file containing the field schema',
    'supported files are in the format': 'Supported files are in the format',
    'draw the field on the map': 'Draw the field on the map',
    'draw the field on the map using the drawing tool': 'Draw the field on the map using the drawing tool',
    'draw field': 'Draw field',
    'new field': 'New field',
    'center map': 'Center map',
    'general field data': 'General field data',
    'field name': 'Field name',
    'enter the field name': 'Enter the field name',
    'select a group': 'Select a group',
    'select a project': 'Select a project',
    'group farms': 'Group of farms',
    'title new product': 'Register the information of a new analytical product',
    'select a farm': 'Select a farm',
    'edit field': 'Edit field',
    'field successfully updated': 'Field successfully updated',
    'field successfully created': 'Field successfully created',
    'field error area or name field': 'The field cannot remain unnamed, or have an area greater than 3000 ha',
    'create new group': 'Create new group',
    'create new farm': 'Create new farm',
    'group successfully created': 'Group successfully created',
    'farm successfully created': 'Farm successfully created',
    'enter a name for the farm': 'Enter a name for the farm',
    'enter a name for the group': 'Enter a name for the group',
    'central tendency': 'Central tendency',
    'dispersion measures': 'Dispersion measures',
    'sand': 'Sand',
    'clay': 'Clay',
    'silt': 'Silt',
    'classification of data in relation to the ideal': 'Classification of data in relation to the ideal',
    'average textural classification': 'Average textural classification',
    'percentage of textural classification': 'Percentage of textural classification',
    'report download': 'Report download',
    'spreadsheet download': 'Spreadsheet download',
    'analytical summary': 'Analytical summary',
    'analytical report': 'Analytical report',
    'fertianalysis': 'Fertianalysis',
    'general analysis': 'General analysis',
    'analysis by environment': 'Analysis by environment',
    'report': 'Report',
    'environment map': 'Environment map',
    'recommendation': 'Recommendation',
    'product': 'Product',
    'edit recommendation': 'Edit recommendation',
    'delete field': 'Delete field',
    'satellite monitoring': 'Satellite Monitoring',
    'status satellite monitoring': 'Satellite monitoring {{status}}',
    'cultivation condition': 'Cultivation condition'
  },
  [namespaces.pages.monitoring]: {
    'select fields': 'Select fields',
    'waiting for farm selection': 'Waiting for farm selection'
  },
  [namespaces.pages.crop]: {
    'new crop': 'New crop',
    'view all crops': 'View all crops',
    'search for a crop': 'Search for a crop',
  },
  [namespaces.pages.member]: {
    'member': 'Member',
    'members': 'Members',
    'view all members': 'View all members',
    'search for a member': 'Search for a member',
    'resend confirmation': 'Resend confirmation',
    'email successfully sent': 'Email successfully sent',
    'edit member': 'Edit member',
    'update a members permissions': 'Update a member\'s permissions',
    'edit permissions': 'Edit permissions',
    'update permissions': 'Update permissions',
    'type of permissions': 'Type of permissions',
    'farm': 'Farm',
    'no access': 'No access',
    'view': 'View',
    'edit': 'Edit',
    'manage': 'Manage'
  },
  [namespaces.pages.farmGroups]: {
    'register the information of a new farm': 'Register the information of a new farm',
    'edit farm information': 'edit farm information',
    'edit farm group information': 'Edit farm group information',
    farmGroups: 'Farm groups',
    'view all farm groups': 'View all farm groups',
    'find a farm': 'Find a farm',
    group: 'group',
    farm: 'farm',
    at: ' at '
  },
  [namespaces.pages.cropRotationst]: {
    'register the information of a new crop rotation': 'Register the information of a new crop rotations',
    'crop rotationst': 'Growth rotations',
    'new rotation': 'New rotation',
    'view all crop rotations': 'View all growth rotations',
    'search for a rotation': 'Search for a rotation',
    'fields displayed': 'Fields displayed',
    'harvest / year': 'Crop / Year',
    'variety': 'Variety',
    'planting date': 'Planting date',
    'harvest date': 'Harvest date',
    'productivity': 'Productivity',
  },
  [namespaces.pages.users]: {
    'view all users': 'View all users',
    'search for a user': 'Search for a user',
    'user': 'User',
    'edit user': 'Edit user',
    'confirmed': 'Confirmed',
    'register a new user': 'Register a new user'
  },
  [namespaces.pages.reports]: {
    reports: 'Reports',
    report: 'report',
    'view all reports': 'View all reports',
    'search for a reports': 'Search for a reports',
    'new reports': 'New reports',
    'type of report': 'Type of report',
  },
  [namespaces.modals.createRotationst]: {
    'register the information of a new crop rotation': 'Register the information of a new crop rotation',
    'select one': 'Select one {{anything}}',
    'field do not match': 'Field {{label}} do not match',
    'are you sure you want to delete the growth rotation?': 'Are you sure you want to delete the growth rotation?',
  },
  [namespaces.modals.createCrop]: {
    'register the information of a new crop': 'Register the information of a new crop',
    'change the information of a crop': 'Change the information of a crop',
    'select one': 'Select one {{anything}}',
    'field do not match': 'Field {{label}} do not match',
    'are you sure you want to delete the Growth Rotation?': 'Are you sure you want to delete the Growth Rotation?',
    'are you sure you want to delete the crop?': 'Are you sure you want to delete the crop?',
  },
  [namespaces.modals.memberForm]: {
    'register a new members information': 'Register a new member\'s information',
    'change a members information': 'Change a member\'s information',
    'access type': 'Access type',
    'access according to assigned permissions': 'Access according to assigned permissions',
    'full access to all farms': 'Full access to all farms',
    'are you sure you want to delete this member?': 'Are you sure you want to delete this member?',
  },
  [namespaces.modals.editUser]: {
    'edit user': 'Edit user',
    'full name': 'Full name',
    'preferred language': 'Preferred language',
    'change photo': 'Change photo',
    'profile updated successfully': 'Profile updated successfully',
    'enter a new password': 'Enter a new password',
    'click to edit password': 'Click to edit password',
    'password changed successfully': 'Password changed successfully'
  },
  [namespaces.modals.newEnvironmentMap]: {
    'new environment map': 'New environment map',
  },
  [namespaces.errors]: {
    '400': 'Unavailable service.',
    '401': 'Invalid login credentials. Please try again.',
    '500': 'Internal server error.',
    'default': 'An unexpected error has occurred. Please try again later.',
    'no records found': 'No records found',
    'item updated successfully': 'Item updated successfully',
    'item removed with success': 'Item removed with success',
    'item create with success': 'Item create with success',
    'images not yet available': 'Images not yet available',
    'image not available. please try another date': 'Image not available. Please try another date',
    'some lots do not have images available. please have another date.': 'Some lots do not have images available. Please have another date.',
    'error KML': 'Unable to download KML, plot polygon has coordinate intersection'
  },
  [namespaces.pages.analyticalProducts]: {
    'new analytical products': 'New products',
    'view all analytical analytical products': 'View all analytical analytical products',
    'search for a products': 'Search for a products',
    'type product': 'Type product',
    'filter by farm': 'Filter by farm',
    'statistical parameters': 'Statistical Parameters'
  },
  [namespaces.pages.projects]: {
    'view all projects': 'View all projects',
    'search for a project': 'Search for a project',
    'new project': 'New project',
    'edit project': 'Edit project',
    'project': 'Project',
    'creation date': 'Creation date',
    'admin users': 'Admin users',
    'name': 'Name',
    'project transfer': 'Successfully transferred',
    'no projects found': 'No projects found',
    'companies': 'Companies'
  },
  [namespaces.pages.projectController]: {
    'file too heavy, maximum size 1mb': 'File too heavy, maximum size 1mb',
    'view all company information': 'View all company information',
    'general information': 'General information',
    'enter the project name': 'Enter the project name',
    'enter the administrative email': 'Enter the administrative email',
    'cnpj ruc': 'CNPJ/RUC',
    'cnpj or ruc': 'CNPJ or RUC',
    'enter the CNPJ/RUC': 'Enter the CNPJ/RUC',
    'platform preferences': 'Platform preferences',
    'insert logo': 'Insert logo',
    'company profile': 'Company profile',
    'cnpj ruc is already being used by another project': 'CNPJ/RUC is already being used by another project',
  },
  [namespaces.pages.scout]: {
    'phenological stage': 'Phenological stage',
    'stand': 'Stand (plants/m)',
    'this action cannot be undone!': 'This action cannot be undone!',
    'discard changes': 'Discard changes',
    'cancel change?': 'Cancel change?',
    'delete report?': 'Delete report?',
    'delete point?': 'Delete point?'
  },
}