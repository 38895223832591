import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import NewFieldModal from 'modules/NewFieldModal'
import Lot from './Lot'

import { Resume, ResumeRow, ResumeItem, PanelOwner, FarmsCollapse, PanelFarm, PanelHeader, OwnerTitle, SubTitle, NewField } from './styles'
import { Card } from '../styles'

type TProps = {
  groupOrFarmSelected:any
  projectSelected: string
  totalArea: number
  totalFarms: number
  totalFields:number
}

const DescriptionFarm = ({ groupOrFarmSelected, projectSelected, totalArea, totalFarms, totalFields }:TProps) => {

  const { t } = useTranslation('namespaces')

  const [isModalVisible, setIsModalVisible] = useState(false)

  const renderGroups:any = (groups:any, marginLeft:number) => {
    return !isEmpty(groups) && map(groups, ({ area, name, farms, groups }: any, index:number) => {
      return(
        <Fragment key={index}>
          <PanelOwner
            header={
              <PanelHeader>
                <OwnerTitle marginleft={marginLeft}>{name}</OwnerTitle>
                <SubTitle>{t('area', { ns: namespaces.common })}: {area?.toFixed(2)} ha</SubTitle>
              </PanelHeader>
            }
            key={name}
            showArrow={!isEmpty(farms) || !isEmpty(groups)}
          >
            <FarmsCollapse expandIconPosition='right' ghost>
              {map(farms, (farm: any) => (
                <PanelFarm
                  header={<PanelHeader>
                    <SubTitle>{farm.name}</SubTitle>
                    <SubTitle>{t('area', { ns: namespaces.common })}: {farm?.area?.toFixed(2)} ha</SubTitle>
                  </PanelHeader>}
                  key={farm.name}
                >
                  {map(farm.fields, (lot: any) => (
                    <Link to={`/satellite-monitoring/${projectSelected}/field/${lot.id}`} target='_blank' rel='noopener noreferrer' key={lot.id}>
                      <Lot
                        id={lot.id}
                        name={lot.name}
                        area={lot.area}
                        group={name}
                        farm={farm.name}
                        shape={lot.shape}
                        color={lot.growth_color}
                      />
                    </Link>
                  ))}
                </PanelFarm>
              ))}
            </FarmsCollapse>
            { !isEmpty(groups) && map(groups, (item: any) => { return recursiveItem(item, marginLeft + 5)})}
          </PanelOwner>
          {/* {!isEmpty(groups) && renderGroups(groups, marginLeft + 5)} */}
        </Fragment>
      )})
  }
  const recursiveItem = (item: any, marginLeft: number) => {
    return (
      <Fragment>
        <FarmsCollapse expandIconPosition='right' ghost>
          <PanelOwner
            key={''}
            header={
              <PanelHeader>
                <OwnerTitle marginleft={marginLeft}>{item?.name}</OwnerTitle>
                <SubTitle>{t('area', { ns: namespaces.common })}: {item.area.toFixed(2)} ha</SubTitle>
              </PanelHeader>
            }
            showArrow={true}
          >
            <FarmsCollapse expandIconPosition='right' ghost>
              {map(item?.farms, (farm: any) => (
                <PanelFarm
                  header={<PanelHeader>
                    <SubTitle>{farm.name}</SubTitle>
                    <SubTitle>{t('area', { ns: namespaces.common })}: {farm?.area?.toFixed(2)} ha</SubTitle>
                  </PanelHeader>}
                  key={farm.name}
                >
                  {map(farm.fields, (lot: any) => (
                    <Link to={`/satellite-monitoring/${projectSelected}/field/${lot.id}`} target='_blank' rel='noopener noreferrer' key={lot.id}>
                      <Lot
                        id={lot.id}
                        name={lot.name}
                        area={lot.area}
                        group={item?.name}
                        farm={farm.name}
                        shape={lot.shape}
                        color={lot.growth_color}
                      />
                    </Link>
                  ))}
                </PanelFarm>
              ))}
            </FarmsCollapse>
            { !isEmpty(item?.groups) && map(item?.groups, (_item: any) => { return recursiveItem(_item, marginLeft + 5)})}
          </PanelOwner>
        </FarmsCollapse>
      </Fragment>
    )
  }

  return (
    <>
      <Card>
        <Resume>
          {t('quick summary', { ns: namespaces.common })}
          <ResumeRow>
            <ResumeItem>
              <h4>{totalFields}</h4>
              {t('total fields', { ns: namespaces.common })}
            </ResumeItem>
            <ResumeItem>
              <h4>{totalFarms}</h4>
              {t('all farms', { ns: namespaces.common })}
            </ResumeItem>
            <ResumeItem>
              <h4>{totalArea.toFixed(2)}</h4>
              {t('total area', { ns: namespaces.common })} (há)
            </ResumeItem>
          </ResumeRow>
        </Resume>
        <NewField onClick={() => setIsModalVisible(true)}>{t('create field', { ns: namespaces.pages.field })}</NewField>
        <FarmsCollapse expandIconPosition='right' ghost>
          {renderGroups(groupOrFarmSelected, 0)}
        </FarmsCollapse>
      </Card>
      <NewFieldModal
        isModalVisible={isModalVisible}
        handleClose={() => setIsModalVisible(false)}
        projectSelected={projectSelected}
      />
    </>
  )

}

export default DescriptionFarm