import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { Layout } from 'antd'
import useToast from 'hooks/useToast'
import { useParams } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import find from 'lodash/find'
import without from 'lodash/without'
import forEach from 'lodash/forEach'

import useGroups from 'repositories/useGroups'
import useNdvi from 'repositories/useNdvi'
import useImagesNdvi from 'repositories/useImagesNdvi'
import useSearchDatesImages from 'repositories/useSearchDatesImages'

import { TGetLotes, TDateComponent, TSelectOption, TInfoTimeLine } from 'types/types'
import { TContrastResponseFarm } from 'types/ndviContrast'

import { dateRange, formatDateToUnix, formatDateToView, getCurrentDate, getDay, isImg, getFarm } from 'utils/helpers'

import MapPharms from 'components/MapPharms'
import MenuNdvi from 'components/MenuNDVI'
import SelectFarm from 'components/SelectFarm'
import TimeLineField from 'components/TimeLineField'
import ButtonUltraSense from 'components/ButtonUltraSense'

import DescriptionFarm from './DescriptionFarm'
import { Container, ContainerFooter, ContainerSider, Drawer } from './styles'

const Monitoring = () => {
  const { t } = useTranslation('namespaces')

  const params:any = useParams()

  const {
    farmSelected,
    saveFarmSelected,
    setIdFarmSelected,
    groupsSelected,
    setZoomMap,
    loading,
    idFarmSelected
  } = useGlobalContext()

  const groupsApi = useGroups()
  const ndviApi = useNdvi()
  const datesApi = useSearchDatesImages()
  const imageNdviApi = useImagesNdvi()
  const { Content } = Layout
  const { notify } = useToast()

  const [fields, setFields] = useState<any>()
  const [mode, setMode] = useState<string>('menu')
  const [modeNdvi, setModeNdvi] = useState<TSelectOption>({ value: '', label: t('monitoring', { ns: namespaces.layout }) })
  const [label, setLabel] = useState<string>(t('all farms', { ns: namespaces.common }))
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [idFieldSelected, setIdFieldSelected] = useState<Array<number> | []>([])
  const [carrosselInfo, setCarrosselInfo] = useState<TInfoTimeLine>({} as TInfoTimeLine)
  const [dateSelected, setDateSelected] = useState<TDateComponent>({} as TDateComponent)
  const [ndviOptions, setNdviOptions] = useState()
  const [datesCalendar, setDatesCalendar] = useState<any>()
  const [haveMapNdviContrast, setHaveMapNdviContrast] = useState<TContrastResponseFarm>()
  const [typeNdviContrast, setTypeNdviContrast] = useState<string>()
  const [imgValidate, setImgValidate] = useState<any>([])
  const [imageNdvi, setImageNdvi ] = useState<string>()
  const [removeLegendContrast, setRemoveLegendContrast] = useState<boolean>(false)
  const [totalArea, setTotalArea] = useState<number>(0)
  const [totalFarms, setTotalFarms] = useState<number>(0)
  const [totalFields, setTotalFields] = useState<number>(0)

  const OPTIONS_NDVI = [
    { value: 'image', label: t('image', { ns: namespaces.common }), options: [
      { value: 'hd', label: t('hd image', { ns: namespaces.common }) },
      { value: 'truecolor', label: t('visual image', { ns: namespaces.common }) }
    ] },

    { value: 'index', label: t('vegetation indices', { ns: namespaces.pages.field }), options: [
      { value: 'ndvi', label: 'NDVI' },
      { value: 'ndvi_contrast', label: 'NDVI Contraste' },
      { value: 'evi', label: 'EVI' },
    ] },
    { value: 'diagnose', label: 'Diagnose Farmus', options: [
      { value: 'farmus_sense', label: 'Farmus Sense' },
    ] }
  ]

  const idFarm = useMemo(() => idFarmSelected, [idFarmSelected])

  const groupOrFarmSelected = useMemo(
    () => {
      if(!isEmpty(farmSelected)) return farmSelected

      return groupsSelected
    }, [groupsSelected, farmSelected])

  const hasLoading = useMemo(() => loading >= 1, [loading])

  const haveSelectedBase = useMemo(() => modeNdvi?.value === 'base', [modeNdvi])

  const fieldsAndNdvis = useMemo(
    () =>
      map(fields, (field:any) => ({ ...field, ndvi: find(ndviOptions, ({ idfield, dt }) => field.id === Number(idfield) && formatDateToView(dt, true) === formatDateToView(dateSelected.unixFormate, true) ) }))
    , [fields, ndviOptions])

  const fieldsAndLastImage = useMemo(
    () => map(fields,
      (field:any) => {

        const imgsField = find(imageNdvi, ({ field_id, dt }: any) => field.id === field_id && dt === dateSelected.unixFormate) as any

        return { ...field,
          imageNdvi: { ...imgsField?.data,
            contrast: { idfield: field.id, dt: field.dt, ...find(haveMapNdviContrast?.fields, item => item?.id === field.id) }
          }
        }
      })
    , [fields, imageNdvi, dateSelected, haveMapNdviContrast])

  //Valida todas as imagens de todos os lotes
  const validateImg = useCallback(
    async (item:any) => {
      const response = await Promise.all(
        map(item, async (url:string, index:string) => {
          if(index !== '_id'){
            return { [index]: await isImg(url) }
          }
        })
      )
      if(!isEmpty(response)){
        return { field: item.idfield, image: without(response, undefined) }
      }

    }, [])

  const imagensFields:any = useMemo(
    () => dateSelected.unixFormate && map(fieldsAndLastImage, ({ imageNdvi }) => imageNdvi)
    , [fieldsAndLastImage, dateSelected])

  useEffect(
    () => {
      if(!isEmpty(imagensFields)){
        const response = map(imagensFields, async item => {
          return await validateImg(item)
        })

        Promise.all(response).then(values => setImgValidate(values))
      }
    }, [imagensFields])

  const haveErrorImg = useMemo(
    () => {
      if(!isEmpty(imgValidate) && dateSelected.unixFormate && modeNdvi?.value){
        let error = false
        const mode = modeNdvi?.value && modeNdvi?.value.toString().toLowerCase()
        forEach( imgValidate, (item:any) =>
          forEach(item?.image, (i:any) => {
            if(mode && i[mode] === false){
              error = true
            }
          }))

        return error

      }
      return false

    }, [imgValidate, modeNdvi, dateSelected.unixFormate])

  //Mostra o toast caso encontre uma imagem com erro
  useEffect(() => {

    if(haveErrorImg){
      notify({ message: t('some lots do not have images available. please have another date.', { ns: namespaces.errors }), warning: true })
    }

  }, [haveErrorImg])

  useEffect(() => {
    const requestNdvi = async () => {
      if(isEmpty(farmSelected) || !idFarm) return

      const response = await ndviApi.getNdviByFarmId(dateSelected.timeInterval?.to, dateSelected.timeInterval?.from, idFarm)

      if(response) setNdviOptions(response as any)
    }

    if(idFarm && dateSelected.timeInterval?.to && dateSelected.timeInterval?.from){

      requestNdvi()
    }
  }, [dateSelected, farmSelected, idFarm])

  const seachFarm = useCallback(
    async ({ project, type, id }:TGetLotes) => {

      const response = await groupsApi.getFields({ project, type, id })
      if (!response?.data) return

      const farm = getFarm(response.data.groups, id)

      saveFarmSelected(response.data.groups)
      setTotalArea(response?.data?.total_area)
      setTotalFarms(response?.data?.total_farms)
      setTotalFields(response?.data?.total_fields)
      setFields(farm?.fields)
      setLabel(farm?.name)
      setMode('description')
      setModeNdvi({ value: '', label: t('monitoring', { ns: namespaces.layout }) })
      setZoomMap(true)
      setDrawerOpen(true)
      setIdFarmSelected(id)

    }, [params?.project, idFarm])

  const getImgNdviContrast = async(idFarm:number, kind:number) => {

    if(dateSelected.unixFormate){

      const res = await ndviApi.getNdviContrastFarm({ idFarm, date: dateSelected?.unixFormate, kind, satellite: dateSelected?.satellite })

      if(!isEmpty(res)){
        return res
      }
      return {} as TContrastResponseFarm
    }
    return {} as TContrastResponseFarm
  }

  const searchInfosTimeLine = useCallback(
    async (pg:number, dt?:any) => {

      if(!idFarm) return

      const response = await datesApi.getInfosTimeLineFarm(idFarm, pg, dt)
      setCarrosselInfo(response)
    }, [idFarm])

  const searchDatesCalendar = useCallback(
    async (to:number, from:number) => {

      if(!idFarm) return

      const response = await datesApi.getAllDatesByFarm(idFarm, to, from)

      setDatesCalendar(response)
    }, [idFarm])

  useEffect(() => {
    if(idFarm && dateSelected.unixFormate){
      switch (modeNdvi?.value) {
        case 'ndvi_contrast': {

          const url = getImgNdviContrast(idFarm, 0)
          setTypeNdviContrast('contrast')
          setRemoveLegendContrast(true)
          Promise.resolve(url).then(value => {
            setHaveMapNdviContrast(value)
            setRemoveLegendContrast(false)
          })
          break
        }
        case 'farmus_sense': {

          const url = getImgNdviContrast(idFarm, 1)
          setTypeNdviContrast('sense')
          setRemoveLegendContrast(true)
          Promise.resolve(url).then(value => {
            setHaveMapNdviContrast(value)
            setRemoveLegendContrast(false)
          })
          break
        }
        case 'ultra_sense': {

          const url = getImgNdviContrast(idFarm, 2)
          setTypeNdviContrast('sense')
          setRemoveLegendContrast(true)

          Promise.resolve(url).then(value => {
            setHaveMapNdviContrast(value)
            setRemoveLegendContrast(false)
          })
          break
        }
        default:
          setHaveMapNdviContrast({} as TContrastResponseFarm)
          setTypeNdviContrast('')
          setRemoveLegendContrast(true)
      }
    }
  }, [modeNdvi, idFarm, dateSelected])

  // pega as informações para popular carousel
  useEffect(() => {
    if(idFarm){
      searchInfosTimeLine(1)
    }
  }, [idFarm])

  // pega as informações para popular calendario de datas
  useEffect(() => {
    if(idFarm && dateSelected.timeInterval?.from && dateSelected.timeInterval?.to){
      searchDatesCalendar(dateSelected.timeInterval?.to, dateSelected.timeInterval?.from,)
    }
  }, [idFarm])

  useEffect(() => {
    const requestNdviImages = async () => {
      if(!idFarm) return

      const response = await imageNdviApi.getImagesFarm(idFarm, dateSelected.unixFormate, dateSelected.satellite )
      setImageNdvi(response)
    }
    if(dateSelected.unixFormate && idFarm){
      requestNdviImages()
    }
  }, [idFarm, dateSelected])

  useEffect(() => {

    if(idFarm){

      seachFarm({ type: 'farm', project: params.project, id: idFarm })


      const currentDate = getCurrentDate()

      const range = dateRange(currentDate)

      const date = {
        unixFormate: formatDateToUnix(currentDate),
        date: currentDate,
        day: getDay(currentDate),
        timeInterval: {
          to: range.to,
          from: range.from
        },
        satellite: 'Sentinel-2'
      }

      setDateSelected(date)
    }
  }, [])

  return (
    <Container>
      <Layout>
        <Layout>
          <Content>
            <SelectFarm
              lisTGroupsFarm={groupsSelected}
              getLotes={seachFarm}
              mode={mode}
              setMode={setMode}
              groupOrFarmSelected={groupOrFarmSelected}
              label={label}
              handleLabel={setLabel}
              display={hasLoading}
              projectSelected={params?.project}
              disabledGroups
              totalArea={totalArea}
              totalFarms={totalFarms}
              totalFields={totalFields}
            />
            {(modeNdvi?.value === 'farmus_sense' || modeNdvi?.value === 'ultra_sense') &&
            <ButtonUltraSense
              isUltraSense={modeNdvi.value === 'ultra_sense'}
              getImg={(status:boolean) => status ? setModeNdvi({ value: 'farmus_sense', label: 'Farmus Sense' }) : setModeNdvi({ value: 'ultra_sense', label: 'Farmus Sense' })}
              pageMode={drawerOpen}
            /> }
            <MenuNdvi setModeNdvi={setModeNdvi} modeNdvi={modeNdvi} display={hasLoading} options={OPTIONS_NDVI} pageMode={drawerOpen} isMonitoring />

            <MapPharms
              fieldsData={fieldsAndLastImage}
              modeNdvi={modeNdvi}
              dateSelected={dateSelected}
              projectSelected={params?.project}
              getImgNdviContrast={getImgNdviContrast}
              haveMapNdviContrast={haveMapNdviContrast}
              typeNdviContrast={typeNdviContrast}
              removeLegendContrast={removeLegendContrast}
              isMonitoring
              style={{
                height: '100%',
              }}
            />
          </Content>
          {!haveSelectedBase && <ContainerFooter>
            <TimeLineField
              infoTimeLine={carrosselInfo}
              setDate={setDateSelected}
              updateCarousel={searchInfosTimeLine}
              onUpdateCalendar={searchDatesCalendar}
              dateSelected={dateSelected}
              datesCalendar={datesCalendar}
              idFarm={idFarm}
            />
          </ContainerFooter>}
        </Layout>
        {fieldsAndNdvis.length > 0 ?
          <ContainerSider open={drawerOpen}>
            <Drawer
              onClick={() => {
                setDrawerOpen(old => !old)
                setZoomMap(true)
              }}
            />
            <DescriptionFarm
              fields={fieldsAndNdvis}
              selectIdField={setIdFieldSelected}
              fieldSelected={idFieldSelected}
            />
          </ContainerSider>
          : null}
      </Layout>
    </Container>
  ) }

export default Monitoring

