import React from 'react'

type TProps = {
  size?: number;
  color?: string;
};

const DownloadIcon = ({
  size = 21,
  color = '#F37422',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 19 18'
    >
      <path d='M15 12V15H3V12H0.984375V15C0.984375 16.0781 1.92188 17.0156 3 17.0156H15C16.0781 17.0156 17.0156 16.0781 17.0156 15V12H15ZM14.0156 8.01562L12.6094 6.60938L9.98438 9.1875V0.984375H8.01562V9.1875L5.39062 6.60938L3.98438 8.01562L9 12.9844L14.0156 8.01562Z' fill={color} />
    </svg>
  )
}

export default DownloadIcon