import styled from 'styled-components'
import { Form, Select as AntSelect } from 'antd'
import { ImCheckboxUnchecked, ImCheckboxChecked } from 'react-icons/im'

const { Option, OptGroup } = AntSelect

type TItemProps = {
  isGroup?: boolean;
  disabled?: boolean;
  color?: string
  label?:string
};

export const FormItem = styled(Form.Item)<TItemProps>`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  margin: ${({ label }) => label ? '20px 0' : '8px 0'};

  .ant-form-item-label,
  label {
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: 400;
    font-size: 14px;
    text-align: left;

    :before {
      display: none !important;
    }
  }

  label {
    width: 300px;
  }

  .ant-select-selector {
  border: ${({ theme }) =>
    `1px solid${theme.colors.THEME} !important`};
}
`

export const Options = styled(Option)``

export const OptionsGroups = styled(OptGroup)``

export const Item = styled.div<TItemProps>`
  color: ${({ theme, isGroup }) =>
    isGroup ? theme.colors.YELLOW : theme.colors.WHITE};
  margin-left: ${({ isGroup }) => (isGroup ? '0px' : '10px')};
  font-weight: 400;
  font-size: 14px;
  text-align: left;
`

export const CheckedIcon = styled(ImCheckboxChecked)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-bottom: -3px !important; 
  margin-right: 0.5rem;
`

export const UncheckedIcon = styled(ImCheckboxUnchecked)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-bottom: -3px !important; 
  margin-right: 0.5rem;
`

export const Select = styled(AntSelect)<TItemProps>`
  width: 100% !important;
  color: ${({ theme }) => `${theme.colors.WHITE}!important`};
  height: 45px;

  .ant-select-arrow{
      color: ${({ theme }) => `${theme.colors.WHITE} !important`};
    }
  
  .ant-select,
  .ant-select-selector {
    height: 45px !important;
    display: flex;
    color: ${({ theme }) => `${theme.colors.WHITE}!important`};
    align-items: center;
    border-radius: 8px !important;
    border: ${({ theme }) =>
    `1px solid${theme.colors.THEME} !important`};
    background-color: ${({ theme, disabled, color }) => {
    if(disabled)
      return `${theme.colors.MEDIUM_GRAY_OPACITY}!important`

    if(color)
      return `${color}!important`

    return `${theme.colors.THEME}!important`
  }};

`