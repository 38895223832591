import styled from 'styled-components'

export const ChartsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`

export const Chart = styled.div<{fullscreen?: boolean}>`
    width:  ${({ fullscreen }) => fullscreen ? '48%' : '100%'};
`