import React, { useRef, useState } from 'react'
import {
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  ColumnSeries,
  HighchartsChart,
  HighchartsProvider
} from 'react-jsx-highcharts'

import { v1 as uuidv1 } from 'uuid'
import Highcharts from 'highcharts'
import { Wrapper, ChartWrapper, ChartContent, ChartTitle } from './styles'
import StatsTable from './StatsTable'
import { useProductContext } from 'hooks/useProductContext'

interface HistogramProps {
  colors: Array<string>
  name: string
  hist: any
  stats: any
  main_classification: any
}

const Histogram: React.FC<HistogramProps> = ({ colors, name, hist, stats, main_classification }) => {
  const { fullscreen } = useProductContext()
  const [chartWidth, setChartWidth] = useState<number>()
  const containerRef: any = useRef(0)

  const plotOptions = {
    column: {
      grouping: false,
      shadow: false,
      pointWidth: 25
    },
    series: {
      animation: true
    },
  }

  const sum = hist.values.reduce((sum: number, elem: number) => sum + elem, 0)

  return (
    <Wrapper fullscreen={fullscreen}>
      <ChartTitle dangerouslySetInnerHTML={{ __html: name }} />
      <ChartContent ref={containerRef}>
        <ChartWrapper>
          <HighchartsProvider Highcharts={Highcharts}>
            <HighchartsChart plotOptions={plotOptions}>
              <Chart width={chartWidth} onRender={() => setChartWidth(containerRef?.current?.clientWidth)} />
              <Tooltip shared />
              <XAxis
                categories={hist.categories}
                gridLineWidth={1}
              />
              <YAxis allowDecimals={false}>
                <ColumnSeries
                  key={uuidv1()}
                  colorByPoint
                  dataLabels={{
                    enabled: false
                  }}
                  data={(hist.values || []).map((value: any, i: any) => {
                    return {
                      y: value,
                      x: i,
                      percentage: ((value / sum) * 100),
                      color: colors[i],
                    }
                  })}
                  tooltip={
                    {
                      headerFormat: '',
                      pointFormatter() {
                        return `<span style='color:${this.color}'>
                          ● ${this.category}</span>: ${this?.percentage && this?.percentage % 1 === 0 ? this?.percentage : this?.percentage && this?.percentage.toFixed(2)}%`
                      }
                    }
                  }
                />
              </YAxis>
            </HighchartsChart>
          </HighchartsProvider>

        </ChartWrapper>
        <StatsTable
          stats={stats}
          classification={main_classification}
        />
      </ChartContent>
    </Wrapper>
  )
}

export default Histogram
