import React from 'react'

import { Rule } from 'antd/lib/form'
import { Space } from 'antd'

import { FormItem, RadioGroup, FormRadio, Title } from './styles'
import { TOptions } from 'components/SelectField'
import { map } from 'lodash'

type TProps = {
  id: string
  name?: string
  label?: string
  rules?: Rule[]
  disabled?: boolean
  direction: 'horizontal' | 'vertical'
  options: TOptions[]
}

const RadioCustom = ({
  id,
  label,
  name,
  rules,
  disabled = false,
  direction,
  options
}: TProps) => {


  return (
    <FormItem
      name={name}
      rules={rules}
      id={id}
    >
      <RadioGroup>
        <Title>{label} </Title>
        <Space direction={direction}>
          {map(options, ({ label, value }) =>
            <FormRadio value={value} disabled={disabled}>
              <h3> {label} </h3>
            </FormRadio>
          )}
        </Space>
      </RadioGroup>
    </FormItem>
  )
}

export default RadioCustom