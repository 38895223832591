import React, { useCallback, useMemo, useState, useEffect } from 'react'
import {
  TileLayer,
  LayersControl,
  FeatureGroup,
  Polygon,
  useMap,
  LayerGroup,
  ImageOverlay,
} from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { FullscreenControl } from 'react-leaflet-fullscreen'
import hull from 'hull.js'
import { LatLngBounds, LatLngExpression } from 'leaflet'
import { TFields, TDateComponent, TReports, TSelectOption } from 'types/types'
import { TContrastResponse, TContrastResponseFarm } from 'types/ndviContrast'
import { useGlobalContext } from 'hooks/useGlobalContext'

import FieldBoxInfo from 'components/MapPharms/Field/FieldBoxInfo'
import LegendNdviContrast from 'components/LegendNdviContrast'
import { ColorPallet } from 'components/ColorPallet'

import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import get from 'lodash/get'
import find from 'lodash/find'

import { TScout } from 'types/report'

import { compareDate } from 'utils/helpers'

import Field from './Field'
import RenderImagesReport from './AddImagesReports'

import { TooltipCostumer, MapContainer } from './styles'
import 'leaflet/dist/leaflet.css'

type TFieldsProps = {
  fieldsData: TFields[];
  modeNdvi: TSelectOption;
  style?: any;
  scrollWheelZoom?: boolean;
  control?: boolean;
  dateSelected?: TDateComponent;
  isMonitoring?: boolean;
  dragging?: boolean;
  zoomControl?: boolean;
  doubleClickZoom?: boolean
  projectSelected: string,
  getImgNdviContrast?:(idField:number, kind:number, makeRequest:boolean)=> any
  haveMapNdviContrast?: TContrastResponse | TContrastResponseFarm
  typeNdviContrast?:string
  removeLegendContrast?:boolean
  newWindowClick?:boolean,
  reportSelected?: TReports & TScout
};

const LocationMarker = ({ allBounds, isZoom, setIsZoomMap }: any) => {
  const map = useMap()

  if (isEmpty(allBounds) || !isZoom) return null

  map.flyToBounds(allBounds).getCenter()
  map.invalidateSize()
  setIsZoomMap(false)

  return null
}

const MapPharms = ({
  fieldsData = [],
  modeNdvi,
  style,
  scrollWheelZoom = true,
  control = true,
  dateSelected = {
    date: '',
    day: '',
    timeInterval: {
      from: 0,
      to: 0
    },
    unixFormate: 0,
    satellite: 'Sentinel-2'
  },
  isMonitoring = false,
  dragging = true,
  zoomControl = true,
  doubleClickZoom = true,
  projectSelected,
  haveMapNdviContrast,
  typeNdviContrast,
  removeLegendContrast = true,
  newWindowClick = true,
  reportSelected
}: TFieldsProps) => {
  const key = 'AIzaSyAihyxupihwhxoMMRA9A2d3DjVagEix1PY'

  const [bounds, setBounds] = useState<any>()
  const { zoomMap, setZoomMap } = useGlobalContext()
  const [ infoFields, setInfoField ] = useState<TFields | undefined>()
  const [allBounds, setAllBounds] = useState<any>([])
  const [currentMap, setCurrentMap] = useState<any>()

  const convertCoordinate = (coordinate: []) =>
    (coordinate && coordinate.map(point => [point[1], point[0]])) || []

  const filterItem = (image: object, dateSelected:number ) => {
    if (!isEmpty(image)) {
      return find(image, (item: any) => {
        if (
          dateSelected &&
            compareDate(item.dt, dateSelected)
        ) {
          return item
        }
        return item
      }) as any
    }
    return null
  }


  const getImage = useCallback(
    (image: any) => {

      switch (modeNdvi?.value) {

        case 'truecolor': {

          const item = !image?.truecolor && filterItem(image, dateSelected.unixFormate)

          if (!isEmpty(item)){
            return item?.image?.truecolor
          }

          return image?.truecolor

        }

        case 'evi': {

          const item = !image?.evi && filterItem(image, dateSelected?.unixFormate)

          if (!isEmpty(item)) return item?.image?.evi

          return image?.evi

        }

        case 'ndvi': {

          const item = !image?.ndvi && filterItem(image, dateSelected?.unixFormate)

          if (!isEmpty(item)) return item?.image?.ndvi

          return image?.ndvi

        }

        case 'hd': {

          return null
        }

        default: {

          if (!isEmpty(image)) return image?.contrast?.url

          return null
        }
      }
    },
    [modeNdvi, fieldsData, dateSelected?.unixFormate]
  )

  const renderImage:any = useMemo(
    () => {
      if(dateSelected?.unixFormate){
        const arrayImagens = map(fieldsData, (field:TFields, index:number) => {

          if(!isEmpty(field?.shape?.features)){

            const image = getImage(field.imageNdvi)

            let boundsField:any = []

            if (field?.shape?.features[0].geometry.type === 'GeometryCollection') {

              boundsField = allBounds[index]

            }else{
              boundsField = new LatLngBounds(convertCoordinate(get(field.shape.features[0], 'geometry.coordinates[0]', [])) as any)
            }

            if(image && !isEmpty(boundsField)){

              return (
                <ImageOverlay
                  url={image}
                  bounds={boundsField}
                  opacity={1}
                  zIndex={1000}
                  key={field.id}
                />)
            }
            return null
          }
          return null
        })

        return arrayImagens
      }
    }, [fieldsData, modeNdvi, dateSelected?.unixFormate, allBounds, haveMapNdviContrast])

  const farmsDataBounds: any = useMemo(() => {
    setAllBounds([])
    const farms: any = {}
    forEach(fieldsData, (field: any) => {
      if (field.shape && field?.shape?.features) {
        const coordinates = field.shape.features
          .map((feature: any) => {
            if (feature.geometry.type === 'GeometryCollection') {
              return feature.geometry.geometries
                .map((geometry: any) =>
                  convertCoordinate(geometry.coordinates[0])
                )
                .flat()
            }
            return convertCoordinate(feature.geometry.coordinates[0])
          })
          .flat()

        setAllBounds((old:any) => [...old, coordinates])

        if (farms[field.idFarm]) {
          farms[field.idFarm].fields.push(field)
          farms[field.idFarm].bounds =
          farms[field.idFarm].bounds.concat(coordinates)
          farms[field.idFarm].farm = { id: field.idFarm, name: field.farmName }
        } else {
          farms[field.idFarm] = {
            farm: { id: field.idFarm, name: field.farmName },
            fields: [field],
            bounds: coordinates,
          }
        }
      }
    })

    return Object.keys(farms).map(key => farms[key])
  }, [fieldsData])

  const handdleFields = useCallback(
    farm => {
      const boundsTuple = hull(farm.bounds) as LatLngExpression[]

      return (
        <div key={`farm-polygon-${farm.farm.id}`}>
          {farm.fields.map((field: TFields, index:number) => (
            <Field
              key={`field-polygon-${index}`}
              field={field}
              modeNdvi={modeNdvi}
              setInfoField={setInfoField}
              projectSelected={projectSelected}
              newWindowClick={newWindowClick}
            />
          ))}

          {farm.bounds.length > 1 && !isMonitoring && (
            <Polygon
              color='#fff'
              weight={1}
              dashArray='3,4'
              fill={false}
              positions={boundsTuple}
            >
              <TooltipCostumer permanent direction='top'>
                <p>{farm.farm.name}</p>
              </TooltipCostumer>
            </Polygon>
          )}
        </div>
      )
    },
    [farmsDataBounds, modeNdvi, isMonitoring]
  )

  const setResizeMap = (map:any) => {
    setCurrentMap(map)
    const resizeObserver = new ResizeObserver( () => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 300)
    })
    const container = document.getElementById('map-container')
    resizeObserver.observe(container!)
  }

  useEffect(() => {
    if (!isEmpty(farmsDataBounds)) {
      setBounds(farmsDataBounds[0].bounds[0])
    }
  }, [farmsDataBounds])

  return (
    <div
      style={{ ...style, width: '100vm' }}
    >
      <MapContainer
        id='map-container'
        whenCreated={setResizeMap}
        dragging={dragging}
        zoomControl={zoomControl}
        doubleClickZoom={doubleClickZoom}
        attributionControl={false}
        center={bounds ? bounds : { lat: -26.0854, lng: -60.5357 }}
        zoom={5}
        minZoom={5}
        scrollWheelZoom={scrollWheelZoom}
        bounds={bounds}
        style={{
          ...style,
        }}
      >
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          maxZoom={5}
        />

        { isMonitoring && control && (
          <LegendNdviContrast
            map={currentMap}
            min={haveMapNdviContrast?.min}
            max={haveMapNdviContrast?.max}
            remove={removeLegendContrast}
            type={typeNdviContrast}
          />)}

        {isMonitoring && (
          <LayerGroup>{renderImage}</LayerGroup>
        )}

        <LocationMarker allBounds={allBounds} isZoom={zoomMap} setIsZoomMap={setZoomMap} />
        {control ? (

          <LayersControl position='topright'>
            <LayersControl.BaseLayer checked name='Sátelite'>
              <ReactLeafletGoogleLayer apiKey={key} type={'hybrid'} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name='Mapa'>
              <ReactLeafletGoogleLayer apiKey={key} type={'roadmap'} />
            </LayersControl.BaseLayer>
            <FullscreenControl position='bottomright' />
          </LayersControl>
        ) : (
          <>
            <ReactLeafletGoogleLayer apiKey={key} type={'satellite'} />

            <LayersControl.BaseLayer name='Mapa'>
              <ReactLeafletGoogleLayer apiKey={key} type={'roadmap'} />
            </LayersControl.BaseLayer>
          </>
        )}

        <FeatureGroup>
          {map(farmsDataBounds, (farm: any) => handdleFields(farm))}
          {!isEmpty(reportSelected) && dragging && <RenderImagesReport reportSelected={reportSelected} currentMap={currentMap} />}
        </FeatureGroup>
        {
          !isMonitoring && !isEmpty(infoFields) && <FieldBoxInfo field={infoFields} />
        }
        {
          (modeNdvi.value === 'ndvi' || modeNdvi.value === 'evi') && dateSelected?.unixFormate ? <ColorPallet /> : modeNdvi.value === 'Vegetação' && <ColorPallet />
        }
      </MapContainer>
    </div>
  )
}

export default MapPharms

