import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { apiBackEnd, apiGeo, apiKml } from 'services/axios'

import { useGlobalContext } from 'hooks/useGlobalContext'
import { useScoutContext } from 'hooks/useScoutContext'
import useToast from 'hooks/useToast'

import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import useWatcherReports from 'repositories/useWatcherReports'
import useImagesNdvi from 'repositories/useImagesNdvi'
import useNdvi from 'repositories/useNdvi'
import useField from 'repositories/useField'
import useFields from 'repositories/useFields'
import useSearchDatesImages from 'repositories/useSearchDatesImages'

import { TDateComponent, TSelectOption } from 'types/types'
import { TContrastResponse } from 'types/ndviContrast'

import fileDownload from 'js-file-download'

import { RiPencilFill } from 'react-icons/ri'
import { MdFileDownload } from 'react-icons/md'

import{ downloadUrl, formatDateToUnix, formatDateToView, getCurrentDate, isImg, compareDate, dateRange, getLabelImageMonitoring, getDay } from 'utils/helpers'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import find from 'lodash/find'
import without from 'lodash/without'
import forEach from 'lodash/forEach'
import get from 'lodash/get'

import FieldProducts from './MenuContent/FieldProducts'
import FieldReports from './MenuContent/FieldReports'
import Reports from './MenuContent/Reports'
import ReportCarroussel from './MenuContent/FieldReports/FielReportsCaroussel'

import MenuNdvi from 'components/MenuNDVI'
import Drawer from 'components/Drawer'
import TimeLineField from 'components/TimeLineField'
import GrowthRotationst from './MenuContent/GrowthRotationst'
import MapPharms from 'components/MapPharms'
import ButtonUltraSense from 'components/ButtonUltraSense'

import {
  Container,
  FieldDrawer,
  FieldInfo,
  FieldNameArea,
  Title,
  SubTitle,
  DrawerMenu,
  MenuItem,
  DrawerMap,
  DrawerController,
  EditFieldButton,
  Layout,
  DownloadKmlButton
} from './styles'

const Field = () => {
  const { Content } = Layout
  const { t } = useTranslation('namespaces')

  const { setZoomMap, loading } = useGlobalContext()
  const { reportSelected, setReportSelected } = useScoutContext()

  const [fieldDrawerOpen, setFieldDrawerOpen] = useState<boolean>(false)

  const [reportsData, setReportsData] = useState<[]>()
  const [modeNdvi, setModeNdvi] = useState<TSelectOption>({ value: '', label: t('monitoring', { ns: namespaces.layout }) })
  const [carrosselInfo, setCarrosselInfo] = useState<any>()
  const [dateSelected, setDateSelected] = useState<TDateComponent>(
    {} as TDateComponent
  )
  const [fieldSelected, setFieldSelected] = useState<any>()
  const [datesCalendar, setDatesCalendar] = useState<any>()
  const [haveMapNdviContrast, setHaveMapNdviContrast] = useState<TContrastResponse>()
  const [imgValidate, setImgValidate] = useState<any>([])
  const [typeNdviContrast, setTypeNdviContrast] = useState<string>()
  const [removeLegendContrast, setRemoveLegendContrast] = useState<boolean>(false)

  const reportsApi = useWatcherReports()
  const ndviApi = useNdvi()
  const datesApi = useSearchDatesImages()
  const fieldApi = useField()
  const fieldsApi = useFields()
  const { notify } = useToast()

  const [imageNdvi, setImageNdvi] = useState<any>()
  const history = useHistory()
  const location:any = useLocation()
  const params:any = useParams()

  const [monitoringKey, setMonitoringKey] = useState(params?.type)
  const imageNdviApi = useImagesNdvi()

  const OPTIONS_NDVI = [
    { value: 'image', label: t('image', { ns: namespaces.common }), options: [
      { value: 'hd', label: t('hd image', { ns: namespaces.common }) },
      { value: 'truecolor', label: t('visual image', { ns: namespaces.common }) }
    ] },

    { value: 'index', label: t('vegetation indices', { ns: namespaces.pages.field }), options: [
      { value: 'ndvi', label: 'NDVI' },
      { value: 'ndvi_contrast', label: 'NDVI Contraste' },
      { value: 'evi', label: 'EVI' },
    ] },
    { value: 'diagnose', label: 'Diagnose Farmus', options: [
      { value: 'farmus_sense', label: 'Farmus Sense' },
    ] }
  ]

  const hasLoading = useMemo(() => loading >= 1, [loading])

  const drawerController = () => {
    setFieldDrawerOpen(old => !old)
    setZoomMap(true)
  }

  const fieldMap = useMemo(() => ([
    // {
    //   name: t('field dashboard', { ns: namespaces.pages.field }),
    //   key: 'field dashboard',
    // },
    {
      name: t('satellite monitoring', { ns: namespaces.pages.field }),
      key: 'satellite-monitoring',
      path: `/satellite-monitoring/${params.project}/field/${params.id}`
    },
    {
      name: t('products', { ns: namespaces.pages.field }),
      key: 'analytical-products',
      path: `/analytical-products/${params.project}/field/${params.id}`
    },
    {
      name: t('reports', { ns: namespaces.pages.reports }),
      key: 'reports',
      path: `/reports/${params.project}/field/${params.id}`
    },
    {
      name: t('field report', { ns: namespaces.pages.field }),
      key: 'field-report',
      path: `/field-report/${params.project}/field/${params.id}`
    },
    {
      name: t('crop rotationst', { ns: namespaces.pages.cropRotationst }),
      key: 'crop-rotations',
      path: `/crop-rotations/${params.project}/field/${params.id}`
    }
  ]), [t, params])

  const typeSatellite = useMemo(() => {

    return find(carrosselInfo?.data, ({ dt }) => dt === reportSelected?.monitoring_date)

  }, [carrosselInfo, reportSelected])

  const fieldsDataAndLastImage = useMemo(
    () => {

      const arrayNdvis = find(imageNdvi, ({ data }:any) => data )

      return [{ ...fieldSelected,
        imageNdvi: { ...arrayNdvis?.data, contrast: haveMapNdviContrast }
      }]
    }, [fieldSelected, imageNdvi, dateSelected, haveMapNdviContrast])

  const haveSelectedBase = useMemo(() => modeNdvi.value === 'hd', [modeNdvi])

  //Valida todas as imagens de todos os lotes
  const validateImg = useCallback(
    async (item:any) => {
      const response = await Promise.all(
        map(item?.image, async (url:string, index:string) => {
          if(index !== '_id'){
            return await { [index]: await isImg(url) }
          }
        })
      )
      if(!isEmpty(response)){
        return without(response, undefined)
      }

    }, [])

  const imagensFields:any = useMemo(() => dateSelected.unixFormate && map(fieldsDataAndLastImage, ({ imageNdvi }) => {
    return find(imageNdvi, (item: any) => dateSelected && compareDate(item.dt, dateSelected.unixFormate))
  })
  , [fieldsDataAndLastImage, dateSelected])

  const editField = () => {
    history.push(`/dashboard/${params?.project}/controller`, { field: fieldSelected, edit: true })
  }

  const downloadKml = useCallback(
    async() => {

      const idFarm = get(fieldSelected, 'farm.id')

      const nameGroup = fieldSelected?.group?.name ? fieldSelected?.group?.name.toLowerCase().replace(/[^\w\s]/gi, '').replace(/ /g, '') : ''
      const farmName = fieldSelected?.farm?.name ? fieldSelected?.farm?.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[//"+-]/g, '').toLowerCase().replace(/ /g, '') : ''
      const nameField = fieldSelected?.name ? fieldSelected?.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/ /g, '') : ''

      const response = await fieldApi.handleKmlFile(idFarm, farmName, params?.id )

      if(response)
        fileDownload(response, `${nameGroup}_${farmName}_${nameField}.kml`)

    }, [params?.id, fieldSelected, apiKml.defaults.baseURL])

  const handleShapeFile = useCallback(
    async(date:number) => {
      const res = await fieldApi.handleShapeFile(date, params?.id)

      if(!isEmpty(res?.url)){
        downloadUrl(res.url)
      }
    }, [params?.id])

  const getImgNdviContrast = async(idField:number, kind:number, date: number, satellite: string) => {
    if(date){
      const res = await ndviApi.getNdviContrast({ idField, date, kind, satellite })

      if(!isEmpty(res)){
        return res
      }
      return {} as TContrastResponse
    }
    return {} as TContrastResponse
  }

  const getLotesOfFarm = useCallback( async () => {

    const response = await fieldsApi.getById(params?.id, params?.project )

    if (isEmpty(response?.data)) return

    setFieldSelected(response?.data)
    setModeNdvi({ value: '', label: t('monitoring', { ns: namespaces.layout }) })
    const currentDate = getCurrentDate()

    const range = dateRange(currentDate)

    const date = {
      unixFormate: formatDateToUnix(currentDate),
      date: currentDate,
      day: getDay(currentDate),
      timeInterval: {
        to: range.to,
        from: range.from
      },
      satellite: 'Sentinel-2'
    }

    setDateSelected(date)

  }, [params?.id, params?.project])

  const getAllWatchReports = async () => {
    const response = await reportsApi.getNewWatcherReports(Number(params?.id))
    setReportsData(response)
  }

  const searchInfosTimeLine = useCallback(
    async (pg:number, dt?:any) => {

      const response = await datesApi.getInfosTimeLineField(params?.id, pg, dt)
      setCarrosselInfo(response)
    }, [params])

  const searchDatesCalendar = useCallback(
    async (to:number, from:number) => {

      const response = await datesApi.getAllDatesByField(params.id, to, from)

      setDatesCalendar(response)
    }, [params.id])

  useEffect(
    () => {
      if(!isEmpty(imagensFields)){
        const response = map(imagensFields, async item => {
          return await validateImg(item)
        })

        Promise.all(response).then(values => setImgValidate(values[0]))
      }
    }, [imagensFields])

  //Mostra o toast caso encontre uma imagem com erro
  useEffect(() => {
    if(!isEmpty(imgValidate) && modeNdvi?.value){
      let error = false
      const mode = modeNdvi?.value && modeNdvi?.value.toString().toLowerCase()
      forEach(imgValidate, (item:any) => {
        if(item[mode] === false){
          error = true
        }
      })

      if(error){
        notify({ message: t('image not available. please try another date', { ns: namespaces.errors }), warning: true })
      }
    }
  }, [imgValidate, modeNdvi])

  useEffect(() => {
    if(params?.id && dateSelected.unixFormate !== formatDateToUnix(getCurrentDate())){
      switch (modeNdvi.value) {
        case 'ndvi_contrast': {

          const url = getImgNdviContrast(params?.id, 0, dateSelected.unixFormate, dateSelected.satellite)
          setTypeNdviContrast('contrast')
          setRemoveLegendContrast(true)
          Promise.resolve(url).then(value => {
            setHaveMapNdviContrast(value)
            setRemoveLegendContrast(false)
          })
          break
        }
        case 'farmus_sense': {

          const url = getImgNdviContrast(params?.id, 1, dateSelected.unixFormate, dateSelected.satellite)
          setTypeNdviContrast('sense')
          setRemoveLegendContrast(true)
          Promise.resolve(url).then(value => {
            setHaveMapNdviContrast(value)
            setRemoveLegendContrast(false)
          })
          break
        }
        case 'ultra_sense': {

          const url = getImgNdviContrast(params?.id, 2, dateSelected.unixFormate, dateSelected.satellite)
          setTypeNdviContrast('sense')
          setRemoveLegendContrast(true)
          Promise.resolve(url).then(value => {
            setHaveMapNdviContrast(value)
            setRemoveLegendContrast(false)
          })
          break
        }
        default:
          setHaveMapNdviContrast({} as TContrastResponse)
          setRemoveLegendContrast(true)
          setTypeNdviContrast('')
      }
    }
  }, [modeNdvi, params?.id, dateSelected])

  useEffect(() => {
    if((reportSelected?.monitoring_date || reportSelected?.image_type)){
      const labelImg = getLabelImageMonitoring(reportSelected?.image_type, t)
      setModeNdvi({ value: reportSelected?.image_type, label: labelImg })

    }else{
      const dateCurrent = getCurrentDate()
      const range = dateRange(dateCurrent)

      const newDate = {
        unixFormate: formatDateToUnix(dateCurrent),
        date: formatDateToView(dateCurrent),
        day: getDay(dateCurrent),
        timeInterval: {
          to: range.to,
          from: range.from
        },
        satellite: ''
      }as unknown as TDateComponent

      setModeNdvi({ value: '', label: t('monitoring', { ns: namespaces.layout }) })
      setDateSelected(newDate)
      getAllWatchReports()
      searchInfosTimeLine(1)
      setHaveMapNdviContrast({} as TContrastResponse)
      setRemoveLegendContrast(true)
      setTypeNdviContrast('')
    }
  }, [reportSelected, t])

  useEffect(() => {
    setFieldDrawerOpen(false)

    if(monitoringKey === 'reports' || monitoringKey === 'crop-rotations') {
      setFieldDrawerOpen(true)
    }
  }, [monitoringKey])

  useEffect(() => {

    if(params?.id && apiGeo.defaults.baseURL){
      searchInfosTimeLine(1)
    }

  }, [params, apiGeo.defaults.baseURL, monitoringKey])

  useEffect(() => {
    if(!isEmpty(dateSelected) && (monitoringKey === 'satellite-monitoring' || monitoringKey === 'field-report') && apiGeo.defaults.baseURL){
      searchDatesCalendar(dateSelected?.timeInterval?.to, dateSelected?.timeInterval?.from)
    }

  }, [params, apiGeo.defaults.baseURL, monitoringKey])

  useEffect(() => {
    const requestNdviImages = async () => {
      const response = await imageNdviApi.getImagesNdvi(params?.id, dateSelected.unixFormate, dateSelected.satellite)

      setImageNdvi(response)
    }
    if(!isEmpty(dateSelected) && (monitoringKey === 'satellite-monitoring' || monitoringKey === 'field-report')){
      requestNdviImages()
    }
  }, [ params, dateSelected, apiGeo.defaults.baseURL, monitoringKey])

  useEffect(() => {
    if(monitoringKey === 'field-report'){
      getAllWatchReports()
    }
  }, [params, apiBackEnd.defaults.baseURL, monitoringKey])

  useEffect(() => {
    if(location?.state?.goMonitoringKey){
      setMonitoringKey(location?.state?.goMonitoringKey)
    }
  }, [location])

  useEffect(() => {

    getLotesOfFarm()
    if(params?.type && params?.products && params.project){
      setMonitoringKey(params?.type)
      setFieldDrawerOpen(false)
      setZoomMap(true)
    }
  }, [params, apiBackEnd.defaults.baseURL])

  useEffect(() => {

    if(monitoringKey !== 'report'){ //removidos !hasLoading && !fieldDrawerOpen
      setTimeout(() => {
        setFieldDrawerOpen(true)
        setZoomMap(true)
      }, 2000)
    }

  }, [ monitoringKey]) //removido hasLoading

  useEffect(() => {
    setZoomMap(true)
  }, [fieldDrawerOpen])

  return (
    <Container>
      <Layout>
        {!isEmpty(fieldsDataAndLastImage) ?
          <FieldDrawer visible={fieldDrawerOpen}>
            <DrawerController
              onClick={drawerController}
              visible={fieldDrawerOpen}
            />
            {fieldDrawerOpen && (
              <>
                <DrawerMap
                  scrollWheelZoom={false}
                  control={false}
                  fieldsData={fieldsDataAndLastImage}
                  projectSelected={params?.project}
                  modeNdvi={
                    monitoringKey === 'satellite-monitoring' ? modeNdvi : { value: 'Cultura', label: t('culture', { ns: namespaces.common }) }
                  }
                  doubleClickZoom={false}
                  dragging={false}
                  zoomControl={false}
                  newWindowClick={false}
                  style={{
                    width: '354px',
                    height: '163px',
                  }}
                  isMonitoring
                />
                <DownloadKmlButton
                  onClick={() => downloadKml()}
                  icon={<MdFileDownload />}
                  size='middle'
                />
                <FieldInfo>
                  <FieldNameArea>
                    <Title>{fieldSelected?.name}</Title>
                    <SubTitle>{fieldSelected?.area?.toFixed(2)} ha</SubTitle>
                  </FieldNameArea>
                  <SubTitle>{fieldSelected?.farm?.name}</SubTitle>
                  <SubTitle>{fieldSelected?.group?.name}</SubTitle>
                  <EditFieldButton onClick={() => editField()} htmlType='button'>
                    <RiPencilFill />
                    {t('edit field', { ns: namespaces.pages.field })}
                  </EditFieldButton>
                </FieldInfo>
                <DrawerMenu
                  style={{ width: 256 }}
                  defaultSelectedKeys={['field dashboard']}
                  selectedKeys={[monitoringKey]}
                  mode='inline'
                >
                  {fieldMap.map(item => (
                    <MenuItem
                      key={item.key}
                      onClick={() => {
                        history.push(item.path)
                        setMonitoringKey(item.key)
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </DrawerMenu>
              </>
            )}
          </FieldDrawer>
          : null}
        <Layout>
          <Content>
            { monitoringKey === 'field dashboard' && !isEmpty(fieldsDataAndLastImage) && (
              <MapPharms
                fieldsData={fieldsDataAndLastImage}
                projectSelected={params?.project}
                modeNdvi={{ value: 'Cultura', label: t('culture', { ns: namespaces.common }) }}
                newWindowClick={false}
                style={{
                  height: '100%'
                }}
              />
            )}

            { monitoringKey === 'satellite-monitoring' && !isEmpty(fieldsDataAndLastImage) && (
              <Fragment>
                {(modeNdvi.value === 'farmus_sense' || modeNdvi.value === 'ultra_sense') && <ButtonUltraSense isUltraSense={modeNdvi.value === 'ultra_sense'} getImg={(status:boolean) => status ? setModeNdvi({ value: 'farmus_sense', label: 'Farmus Sense' }) : setModeNdvi({ value: 'ultra_sense', label: 'Farmus Sense' })} pageMode={fieldDrawerOpen} /> }
                <MenuNdvi
                  setModeNdvi={setModeNdvi}
                  modeNdvi={modeNdvi}
                  display={hasLoading}
                  options={OPTIONS_NDVI}
                  pageMode={fieldDrawerOpen}
                  isMonitoring={true}
                />
                <MapPharms
                  fieldsData={fieldsDataAndLastImage}
                  projectSelected={params?.project}
                  modeNdvi={modeNdvi}
                  dateSelected={dateSelected}
                  haveMapNdviContrast={haveMapNdviContrast}
                  typeNdviContrast={typeNdviContrast}
                  removeLegendContrast={removeLegendContrast}
                  isMonitoring
                  newWindowClick={false}
                  style={{
                    height: '100%'
                  }}
                />
              </Fragment>
            )}
            { monitoringKey === 'field-report' && (
              <Fragment>
                <div style={{ position: 'relative' }}>
                  {(modeNdvi.value === 'farmus_sense' || modeNdvi.value === 'ultra_sense') && <ButtonUltraSense isUltraSense={modeNdvi.value === 'ultra_sense'} getImg={(status:boolean) => status ? setModeNdvi({ value: 'farmus_sense', label: 'Farmus Sense' }) : setModeNdvi({ value: 'ultra_sense', label: 'Farmus Sense' })} pageMode={fieldDrawerOpen} /> }
                  <MenuNdvi
                    setModeNdvi={setModeNdvi}
                    modeNdvi={modeNdvi}
                    display={hasLoading}
                    options={OPTIONS_NDVI}
                    pageMode={fieldDrawerOpen}
                    isMonitoring={true}
                  />
                </div>
                <MapPharms
                  fieldsData={fieldsDataAndLastImage}
                  projectSelected={params?.project}
                  modeNdvi={modeNdvi}
                  dateSelected={dateSelected}
                  haveMapNdviContrast={haveMapNdviContrast}
                  typeNdviContrast={typeNdviContrast}
                  removeLegendContrast={removeLegendContrast}
                  reportSelected={reportSelected}
                  isMonitoring
                  newWindowClick={false}
                  style={{
                    height: '100%'
                  }}
                />
              </Fragment>
            )}
            {monitoringKey === 'analytical-products' && (
              <FieldProducts
                listField={fieldsDataAndLastImage}
                productId={params?.products}
                projectSelected={params.project}
                fieldDrawerOpen={fieldDrawerOpen}
              />
            )}
            {monitoringKey === 'reports' && ( <Reports />)}
            {monitoringKey === 'crop-rotations' && ( <GrowthRotationst field={fieldSelected} />)}
          </Content>
          {monitoringKey === 'satellite-monitoring' && !haveSelectedBase && (
            <TimeLineField
              infoTimeLine={carrosselInfo}
              setDate={setDateSelected}
              dateSelected={dateSelected}
              handleShapeFile={handleShapeFile}
              datesCalendar={datesCalendar}
              fieldSelected={fieldSelected}
              monitoringField={true}
              updateCarousel={searchInfosTimeLine}
              onUpdateCalendar={searchDatesCalendar}
              dowloadFile
            />
          )}
          { monitoringKey === 'field-report' && !haveSelectedBase && (
            <TimeLineField
              infoTimeLine={carrosselInfo}
              setDate={setDateSelected}
              dateSelected={dateSelected}
              fieldDrawerOpen={fieldDrawerOpen}
              datesCalendar={datesCalendar}
              fieldSelected={fieldSelected}
              monitoringField={true}
              updateCarousel={searchInfosTimeLine}
              onUpdateCalendar={searchDatesCalendar}
            />
          )}
          { monitoringKey === 'field-report' && !isEmpty(reportSelected) && <ReportCarroussel infoTimeLineReports={reportsData} reportSelected={reportSelected} setReportSelected={setReportSelected} />}
        </Layout>
        { monitoringKey === 'field-report' && (
          <Drawer>
            <FieldReports
              reportsData={reportsData}
              setReportSelected={setReportSelected}
              reportSelected={reportSelected}
              projectSelected={params.project}
              monitoringDate={dateSelected.unixFormate}
              imageType={modeNdvi}
              searchInfosTimeLine={searchInfosTimeLine}
              searchDatesCalendar={searchDatesCalendar}
              setDateSelected={setDateSelected}
              typeSatellite={typeSatellite}
            />
          </Drawer>) }
      </Layout>
    </Container>
  )
}

export default Field
