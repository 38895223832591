import React, { useState, useMemo, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { DialogContext } from 'dialog/context'

import { CloseOutlined } from '@ant-design/icons'
import { AiOutlineRedo } from 'react-icons/ai'

import { useScoutContext } from 'hooks/useScoutContext'

import DeleteIcon from 'components/Icons/DeleteIcon'
import SelectField from 'components/SelectField'
import TextArea from 'components/TextArea'
import TextField from 'components/TextField'
import UploadButton from 'components/UploadButton'
import ModalCarouselImage from 'components/ModalCarouselImage'

import ModalDeleteReport from '../ModalDeleteReport'

import { SwiperSlide } from 'swiper/react'

import { TScoutPointImage } from 'types/report'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import { theme } from 'styles'

import {
  PointContainer,
  PointHeader,
  PointContent,
  CarouselImage,
  Img,
  PointCircle,
  ContentInfo,
  Text,
  ButtonImage,
  Icons
} from './styles'
interface IPoint{
  field: any
  borderColor?: any
  imagens?: any
  varietyOptions?: any
  phenologicalStageOptions?:any
  totalPoints?:boolean
  formPointValues?:(fields:any)=>void
  removeItem?:(key:number)=>void
  removeImage?:(key:number, idImg:number |string)=>void
}

const FormPoint = ({
  field,
  borderColor,
  imagens,
  formPointValues = () => {},
  varietyOptions,
  phenologicalStageOptions,
  totalPoints,
  removeItem = () => {},
  removeImage = () => {}
}:IPoint) => {

  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const { handleRotateImage } = useScoutContext()

  const [imagesCurrent, setImagesCurrent] = useState<TScoutPointImage[]>([])

  const labelTitle = useMemo(() => t('point', { ns: namespaces.common, number: field.name + 1 }), [t, field])

  const conditionsOptions = useMemo(
    () => [
      { label: t('good', { ns: namespaces.common }), value: 'good' },
      { label: t('bad', { ns: namespaces.common }), value: 'bad' },
      { label: t('regular', { ns: namespaces.common }), value: 'regular' },
      { label: t('excellent', { ns: namespaces.common }), value: 'excellent' }
    ], [t])

  const viewDeletePointModal = useCallback(
    () => {
      createDialog({
        id: 'modal-delete-point',
        open: false,
        Component: ModalDeleteReport,
        props: {
          title: t('delete point?', { ns: namespaces.pages.scout }),
          message: t('this action cannot be undone!', { ns: namespaces.pages.scout }),
          handleConfirm: () => {
            removeItem(field.name)
            // formPointValues()
          }
        }
      })
    }, [field])

  const viewModalImage = useCallback(
    (index:any) => {
      createDialog({
        id: 'modal-image',
        open: false,
        Component: ModalCarouselImage,
        props: {
          imagens: imagesCurrent,
          currentIndex: index,
          keyForm: field.name,
          isView: false
        }
      })
    }, [imagesCurrent, field])

  const handleDeleteImage = (idImg:number | string) => {
    removeImage(field.name, idImg)
  }

  useEffect(() => {
    setImagesCurrent(imagens)
  }, [field, imagens])

  return (
    <>
      <PointContainer colorBorder={borderColor}>
        <PointHeader onClick={() => !totalPoints && viewDeletePointModal()}>
          <PointCircle color={borderColor} />
          <strong>{ labelTitle }</strong>
          {!totalPoints && <DeleteIcon />}
        </PointHeader>
        <PointContent>
          <ContentInfo>
            {t('variety', { ns: namespaces.pages.field })}
            <SelectField
              name={[field.name, 'growth_variety_id']}
              fieldKey={[field.name, 'growth_variety_id']}
              //   rules={validation('growth_variety_id', t)}
              handleChange={formPointValues}
              id={'select-variety'}
              placeholder={t('select one', { ns: namespaces.common, anything: t('variety', { ns: namespaces.pages.field }) })}
              options={varietyOptions}
              color={theme.colors.WHITE_OPACITY }
            />
          </ContentInfo>
          <ContentInfo>
            {t('phenological stage', { ns: namespaces.pages.scout })}

            <SelectField
              name={[field.name, 'growth_stage_id']}
              fieldKey={[field.name, 'growth_stage_id']}
              //   rules={validation('growth_stage_id', t)}
              handleChange={formPointValues}
              id='select-phenological-stage'
              placeholder={t('select one', { ns: namespaces.common, anything: t('phenological stage', { ns: namespaces.pages.scout }) })}
              options={phenologicalStageOptions}
              color={theme.colors.WHITE_OPACITY }
            />
          </ContentInfo>
          <ContentInfo>
            {t('stand', { ns: namespaces.pages.scout })}

            <TextField
              name={[ field.name, 'stand']}
              fieldKey={[field.name, 'stand']}
              type='number'
              //   rules={validation('stand', t)}
              onChange={formPointValues}
              id='select-stand'
              placeholder={t('select one', { ns: namespaces.common, anything: t('stand', { ns: namespaces.pages.scout }) })}
              color={theme.colors.WHITE_OPACITY }
            />
          </ContentInfo>
          <ContentInfo>
            {t('cultivation condition', { ns: namespaces.pages.field })}
            <SelectField
              name={[ field.name, 'growing_conditions']}
              fieldKey={[field.name, 'growing_conditions']}
              // rules={validation('growing_conditions', t)}
              handleChange={formPointValues}
              id='select-conditions'
              placeholder={t('select one', { ns: namespaces.common, anything: t('cultivation condition', { ns: namespaces.pages.field }) })}
              options={conditionsOptions}
              color={theme.colors.WHITE_OPACITY }
            />
          </ContentInfo>
          <ContentInfo>
            {t('observation', { ns: namespaces.common })}
            <TextArea
              name={[ field.name, 'observation']}
              fieldKey={[field.name, 'observation']}
              //   rules={validation('observation', t)}
              onChange={formPointValues}
              id='observation'
            />
          </ContentInfo>
          <Text>Fotos</Text>
        </PointContent>

        {!isEmpty(imagesCurrent) && (
          <CarouselImage
            slidesPerView={3}
            dir='ltr'
            className='mySwiper'
          >
            {map(imagesCurrent, (item:TScoutPointImage, index:number) => {
              const url = item?.originFileObj && window.URL.createObjectURL(item?.originFileObj)

              return !item._destroy && (
                <SwiperSlide key={index}>
                  <Img
                    src={item?.image_url || url}
                    rotate={item.rotation}
                    onClick={() => viewModalImage(index)}
                  />
                  <Icons>
                    <div onClick={() => handleDeleteImage(item.id || item.uid)}>
                      <CloseOutlined style={{ color: theme.colors.WHITE }} />
                    </div>
                    <div onClick={() => handleRotateImage(item.rotation, item.id || item.uid, field.name)}>
                      <AiOutlineRedo style={{ color: theme.colors.WHITE }} />
                    </div>
                  </Icons>
                </SwiperSlide>
              )})}
          </CarouselImage>
        )}
        <ButtonImage>
          <UploadButton
            name={[field.name, 'report_scout_point_attachments']}
            fieldKey={[field.name, 'report_scout_point_attachments']}
            disable={false}
            type='default'
            labelButton='add photo'
            accept='image/png, image/jpeg'
            maxFile={20}
            onchangeCreateUrl={formPointValues}
            isMultipleFile
            isImage
            disablePreview
          />
        </ButtonImage>
      </PointContainer>
      <TextField
        name='id'
        type='hidden'
        id='id'
      />
      <TextField
        name='latitude'
        type='hidden'
        id='latitude'
      />
      <TextField
        name='longitude'
        type='hidden'
        id='longitude'
      />
    </>
  )
}

export default FormPoint