import React from 'react'

type TProps = {
  size?: number;
  colorOne?: string;
  colorTwo?: string;
};

const Users = ({
  size = 35,
  colorOne = '#F37422',
  colorTwo = '#f5f6f6',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 49.05 49.05'
    >
      <g>
        <g id='Camada_x0020_1_2_'>
          <g id='_2991031584_1_'>
            <path fill={colorOne} d='M25.2,27.7v3.2H9.6v-3.2C9.6,20.5,25.2,20.5,25.2,27.7z' />
            <path fill={colorTwo} d='M33.6,27.7v3.2h-6v-3.4c0-2.4-1.4-4.2-3.4-5C28.6,21.9,33.6,23.7,33.6,27.7z' />
            <circle fill={colorOne} cx='17.4' cy='15.7' r='4.4' />
            <circle fill={colorTwo} cx='27' cy='15.7' r='4.4' />
          </g>
          <circle fill='none' cx='21.6' cy='21.7' r='21.6' />
        </g>
      </g>
    </svg>
  )
}

export default Users