import React, { useMemo, useContext, useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { DialogContext } from 'dialog/context'
import { Form } from 'antd'

import { TReports, TSelectOption } from 'types/types'
import { TScout } from 'types/report'

import useGrowths from 'repositories/useGrowths'
import useScout from 'repositories/useScout'

import { useScoutContext } from 'hooks/useScoutContext'

import { setColor } from 'utils/helpers'

import ModalDeleteReport from '../ModalDeleteReport'
import FormPoint from './FormPoint'

import DeleteIcon from 'components/Icons/DeleteIcon'
import SelectField from 'components/SelectField'
import Button from 'components/Button'
import TextField from 'components/TextField'

import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import map from 'lodash/map'
import get from 'lodash/get'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import size from 'lodash/size'

import { theme } from 'styles'

import {
  initialValues,
  validation,
} from './validations'

import {
  Container,
  HeaderTitle,
  ContentInfo,
  ContainerBtn,
  Content
} from './styles'
import debounce from 'lodash/debounce'
import orderBy from 'lodash/orderBy'

type TProps = {
  scoutSelected: TScout | undefined
  goBack:()=>void
  deleteScout:()=>void
  setReportSelected:React.Dispatch<React.SetStateAction<TReports & TScout | undefined>>
  cropOptions: any
  varietyOptions: any
  growthsOptions: any
  getVariety:(id:any)=>void
  monitoringDate: number
  imageType: TSelectOption
}

const EditScout = ({ scoutSelected, goBack, deleteScout, setReportSelected, cropOptions, varietyOptions, growthsOptions, getVariety, monitoringDate, imageType }:TProps) => {

  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const { setToEdit, setRefForm, points, setPoints } = useScoutContext()
  const growthsApi = useGrowths()
  const apiScout = useScout()
  const [form] = Form.useForm<any>()
  const localLanguage = localStorage.getItem('agfarmusLanguage')

  const [phenologicalStage, setPhenologicalStage] = useState<any>()

  const [idsFromRemove, setIdsFromRemove] = useState<any>()

  const formValues = useMemo(() => {
    if(!isEmpty(scoutSelected)){
      setRefForm(form)
      setPoints(scoutSelected?.report_scout_points)
      return {
        farm: scoutSelected?.farm_name,
        field: scoutSelected?.field_name,
        crop: scoutSelected?.crop_id,
        culture: scoutSelected?.growth_id,
        report_scout_points_attributes: scoutSelected?.report_scout_points,
        ...scoutSelected
      }
    }
    return initialValues
  }, [scoutSelected, monitoringDate, imageType ])

  const phenologicalStageOptions = useMemo(
    () => map(phenologicalStage, ({ translations, id }) => {
      const language:any = find(translations, item => item.locale === localLanguage)
      return { label: language?.name, value: id }
    }), [phenologicalStage])

  const getIdIten = useCallback(
    (key:number) => {
      const valuesForm = form.getFieldsValue()
      if(!isEmpty(valuesForm)){
        return get(valuesForm.report_scout_points[key], 'id', [])
      }
    }, [points])

  const getImagenIten = useCallback(
    (key:number) => {
      const valuesForm = form.getFieldsValue()
      if(!isEmpty(valuesForm)){
        return get(valuesForm.report_scout_points[key], 'report_scout_point_attachments', [])
      }
    }, [points])

  const getColorIten = useCallback(
    (key:number) => {
      const valuesForm = form.getFieldsValue()
      if(!isEmpty(valuesForm)){
        return setColor(get(valuesForm.report_scout_points[key], 'growing_conditions', []))
      }
    }, [points])

  const searchGrowthStages = async(idGrowth:number | undefined) => {
    if(idGrowth){
      const response = await growthsApi.getGrowthStages(idGrowth)
      setPhenologicalStage(response)
      getVariety(idGrowth)
    }
  }

  const clearVarietyAndStage = () => {
    const fields = form.getFieldsValue()
    const { report_scout_points } = fields
    forEach(points, (item:any, index:any) => {
      Object.assign(report_scout_points[index], { growth_stage_id: undefined, growth_variety_id: undefined })
    })
    form.setFieldsValue({ report_scout_points })
  }

  const debouncedSave = debounce((valuesForm:any) => setReportSelected({ ...valuesForm, id: scoutSelected?.id, oldComponent: true }), 1000)

  const handleFormPoint = useCallback(
    () => {
      const valuesForm = form.getFieldsValue()

      setPoints(orderBy(valuesForm.report_scout_points, ['id'], ['asc']))
      debouncedSave(valuesForm)
    }, [scoutSelected ])

  const removeImage = (key:number, idImg:number | string) => {
    const fields = form.getFieldsValue()
    const { report_scout_points } = fields

    if( typeof idImg !== 'string'){
      Object.assign(report_scout_points[key], { report_scout_point_attachments: [...filter(report_scout_points[key].report_scout_point_attachments, (item:any) => item?.id !== idImg ), { id: idImg, _destroy: true } ] })
    }else{
      Object.assign(report_scout_points[key], { report_scout_point_attachments: filter(report_scout_points[key].report_scout_point_attachments, (item:any) => item?.uid !== idImg) })
    }

    form.setFieldsValue({ report_scout_points })
    setPoints(report_scout_points)
    handleFormPoint()
  }

  const onFinish = async(values:any) => {
    if(scoutSelected?.id){

      const payload = {
        crop: values.crop || '',
        culture: values.culture || '',
        farm: scoutSelected.farm_id,
        field: scoutSelected.field_id,
        image_type: values.image_type || '',
        monitoring_date: values.monitoring_date || '',
        report_scout_points_attributes: [...map(values.report_scout_points, item => ({
          ...item,
          growth_variety_id: item.growth_variety_id || '',
          growth_stage_id: item.growth_stage_id || '',
          stand: item.stand || '',
          report_scout_point_attachments_attributes: map(item.report_scout_point_attachments, (img:any) => ({
            id: img.id,
            image: img.originFileObj || img.image_url,
            new: !isEmpty(img.originFileObj),
            ...img
          }))
        })), ...map(idsFromRemove, id => ({ id, _destroy: true }))]
      }

      await apiScout.updateScout(payload, scoutSelected?.id, goBack)
    }
  }

  const handleDeleteReport = useCallback(
    async() => {
      await apiScout.deleteScout(scoutSelected?.id, setReportSelected)
      deleteScout()
      setToEdit(false)
    }, [scoutSelected])

  const handleCulture = (idCrop:number) => {
    clearVarietyAndStage()

    const newCultura = find(cropOptions, (item:any) => item.value === idCrop)

    searchGrowthStages(newCultura?.growthId)
    form.setFieldsValue({
      culture: newCultura?.growthId,
    })
  }

  const viewDeleteReportModal = () => {
    createDialog({
      id: 'modal-delete-report',
      open: false,
      Component: ModalDeleteReport,
      props: {
        title: t('delete report?', { ns: namespaces.pages.scout }),
        message: t('this action cannot be undone!', { ns: namespaces.pages.scout }),
        handleConfirm: handleDeleteReport
      }
    })
  }

  const cancelScoutEditModal = () => {
    createDialog({
      id: 'modal-cancel-edit-scout',
      open: false,
      Component: ModalDeleteReport,
      props: {
        title: t('discard changes', { ns: namespaces.pages.scout }),
        message: t('this action cannot be undone!', { ns: namespaces.pages.scout }),
        handleConfirm: goBack,
        colorButtonConfirm: 'primary',
        colorButtonCancel: 'link',
        labelButtonConfirm: 'yes',
        labelButtonCancel: 'no'
      }
    })
  }

  useEffect(() => {
    if(scoutSelected?.growth_id){
      searchGrowthStages(scoutSelected?.growth_id)
      getVariety(scoutSelected?.growth_id)
    }
  }, [scoutSelected])

  useEffect(() => {
    form.setFieldsValue({
      monitoring_date: monitoringDate,
      image_type: imageType.value
    })
  }, [monitoringDate, imageType])

  return (
    <Form
      initialValues={formValues}
      labelCol={{ span: 6 }}
      name='form-scout'
      form={form}
      autoComplete='off'
      onFinish={ onFinish}
    >
      <Container>
        <HeaderTitle>
          <div>
            {t('general data', { ns: namespaces.common })}
          </div>
          <div onClick={() => viewDeleteReportModal()}>
            <DeleteIcon />
          </div>
        </HeaderTitle>
        <Content>
          <ContentInfo>
            {t('farm', { ns: namespaces.pages.field })}
            <SelectField
              name='farm'
              rules={validation('farm', t)}
              id='select-farm'
              placeholder={t('select one', { ns: namespaces.common, anything: t('farm', { ns: namespaces.common }) })}
              options={[]}
              color={theme.colors.WHITE_OPACITY }
              disabled
            />
          </ContentInfo>
          <ContentInfo>
            {t('field', { ns: namespaces.pages.field })}
            <SelectField
              name='field'
              rules={validation('farm', t)}
              id='select-field'
              placeholder={t('select one', { ns: namespaces.common, anything: t('field', { ns: namespaces.common }) })}
              options={[]}
              color={theme.colors.WHITE_OPACITY }
              disabled
            />
          </ContentInfo>
          <ContentInfo>
            {t('crop', { ns: namespaces.common })}
            <SelectField
              name='crop'
              rules={validation('crop', t)}
              id='select-crop'
              placeholder={t('select one', { ns: namespaces.common, anything: t('crop', { ns: namespaces.common }) })}
              options={cropOptions}
              color={theme.colors.WHITE_OPACITY }
              handleChange={e => handleCulture(e)}
            />
          </ContentInfo>
          <ContentInfo>
            {t('culture', { ns: namespaces.common })}
            <SelectField
              name='culture'
              rules={validation('culture', t)}
              id='select-culture'
              placeholder={t('select one', { ns: namespaces.common, anything: t('culture', { ns: namespaces.common }) })}
              options={growthsOptions}
              color={theme.colors.WHITE_OPACITY }
              disabled
            />
          </ContentInfo>
          <Form.List name='report_scout_points'>
            {(fields: any, { remove }: any) => {
              return map(fields, (field:any) => {
                const imagens = getImagenIten(field.name)
                const color = getColorIten(field.name)
                const id = getIdIten(field.name)

                return(
                  <FormPoint
                    key={field.name}
                    field={field}
                    borderColor={color}
                    varietyOptions={varietyOptions}
                    phenologicalStageOptions={phenologicalStageOptions}
                    imagens={imagens}
                    formPointValues={handleFormPoint}
                    removeItem={(key:number) => {
                      remove(key)
                      setIdsFromRemove((old: any) => {
                        if(!isEmpty(old)){
                          return [...old, id]
                        }
                        return [id]
                      })
                    }}
                    removeImage={removeImage}
                    totalPoints={size(fields) <= 1}
                  />)
              })
            }}
          </Form.List>
        </Content>
      </Container>
      <ContainerBtn>
        <Button
          id='button-cancel'
          type={ 'default'}
          label={ t('cancel', { ns: namespaces.common })}
          onClick={() => cancelScoutEditModal()}
        />
        <Button
          id='button-confirm'
          type='primary'
          label={t('save', { ns: namespaces.common })}
          htmlType='submit'
        />
      </ContainerBtn>
      <TextField
        name='image_type'
        type='hidden'
        id='image_type'
      />
      <TextField
        name='monitoring_date'
        type='hidden'
        id='monitoring_date'
      />
      <TextField
        name='id'
        type='hidden'
        id='id'
      />
    </Form>
  )
}

export default EditScout