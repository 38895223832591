import React from 'react'

import { Container, PrimaryButton } from './styles'

type TProps ={
  type?: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text'
  icon?: React.ReactNode
  label?: string
  id: string,
  onClick?: () => void
  shape?: 'default' | 'circle' | 'round' | undefined,
  disabled?:boolean
  loading?: boolean
  htmlType?: 'submit' | 'button' | 'reset' | undefined
  color?: string
}

const Button = ({
  type = 'default',
  icon,
  label,
  id,
  onClick,
  shape = 'default',
  disabled = false,
  loading = false,
  htmlType = 'button',
  color
}:TProps) => {

  return (
    <Container>
      <PrimaryButton
        size='middle'
        type={type}
        icon={icon}
        id={id}
        onClick={onClick}
        htmlType={htmlType}
        shape={shape}
        disabled={disabled}
        loading={loading}
        color={color}
      >{label}
      </PrimaryButton>
    </Container>
  )
}
export default Button