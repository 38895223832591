import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .highcharts-background {
        fill: none;
    }
    .highcharts-axis-labels, .highcharts-xaxis-labels, .highcharts-legend-item  {
        text {
            fill: ${({ theme }) => theme.colors.WHITE} !important;
        }
    }
    .highcharts-axis-title {
      fill: ${({ theme }) => theme.colors.WHITE} !important;
    }
    .highcharts-title, span.highcharts-title{
        color: ${({ theme }) => theme.colors.WHITE} !important;
        fill: ${({ theme }) => theme.colors.WHITE} !important;
    }
`

export const Description = styled.h4`
    color: ${({ theme }) => theme.colors.WHITE};
    font-size: 12px;
    margin-left: 3rem;
`
