import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { MapContainer, TileLayer, FeatureGroup, LayersControl, useMapEvents, ZoomControl, useMap } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { FullscreenControl } from 'react-leaflet-fullscreen'
import { getUniqueArray } from 'utils/helpers'
import { MapDrawer, MapControl } from './styles'
import spritesheet2xV2 from 'assets/leaflet/spritesheet-2x-v2.png'
import spritesheetV2 from 'assets/leaflet/spritesheet-v2.png'
import spritesheetSVGV2 from 'assets/leaflet/spritesheet-v2.svg'
import { useFieldContext } from 'hooks/useFieldContext'
import { area, polygon } from '@turf/turf'
import { isEmpty } from 'lodash'

const CenterMap = ({ lat, lng, callback }: any) => {
  const map = useMap()

  if (lat && lng) {
    map.setView([lat, lng])
    map.invalidateSize()
    callback(false)
  }

  return null
}

const FieldMap = () => {
  const [fieldMounted, setFieldMounted] = useState()

  const key = 'AIzaSyDEG4lyorD61vnJoAHG0FkQERZ-McElZyg'

  const {
    polygons,
    mapFields,
    setMapFields,
    mapCenter,
    mapKey,
    lat,
    lng,
    setUpdateCenter,
    updateCenter,
    setFieldTotalArea,
    fieldTotalArea
  } = useFieldContext()

  function HandleEvents() {
    const map = useMapEvents({
      load() {
        map.locate()
      },
      locationfound(e) {
        map.flyTo(e.latlng, map.getZoom())
      }
    })

    return <></>
  }

  const getFieldTotalArea = useCallback(
    (mapFields: any) => {
      let featuresTotalArea: number = 0

      if(mapFields?.length > 0 && fieldTotalArea === 0){
        mapFields?.forEach((mapField: any) => {
          if(mapField?.field){
            featuresTotalArea = featuresTotalArea + Number(area(polygon(mapField?.field?.geometry?.coordinates)) * 0.0001)
          }
        })

        setFieldTotalArea(Number(featuresTotalArea.toFixed(2)))
      }
    }, [fieldTotalArea, mapFields])

  // Create or edit field request
  // MAP DRAWER CONTROLLERS
  // On Create field map controller
  const onCreateField = (e: any) => {
    const { layer } = e
    const { _leaflet_id } = layer

    setFieldTotalArea(0)

    setMapFields((mapFields: any) => {
      if(!isEmpty(mapFields)){
        getFieldTotalArea(mapFields)
      }

      setFieldTotalArea(Number(area(polygon(layer.toGeoJSON()?.geometry?.coordinates)) * 0.0001))

      return [
        ...mapFields,
        { id: _leaflet_id, field: layer.toGeoJSON() }
      ]
    })


  }

  // On Edit field map controller
  const onEditField = (e: any) => {
    const {
      layers: { _layers }
    } = e

    const fieldValue: any = Object.values(_layers)[0]

    setMapFields((mapFields: any) =>
      mapFields.map((l: any) => {
        if(l.id === Number(Object.keys(_layers)[0])){
          setFieldTotalArea(Number(area(polygon(fieldValue.toGeoJSON()?.geometry?.coordinates)) * 0.0001))
          return { ...l, field: fieldValue.toGeoJSON() }
        }

        return l
      }))
  }

  // On Delete field map controller
  const onDeleteField = (e: any) => {
    const {
      layers: { _layers },
    } = e

    setFieldTotalArea(0)

    Object.values(_layers).map(({ _leaflet_id }: any) => {
      setMapFields((mapFields: any) => mapFields.filter((l: any) => l.id !== _leaflet_id))
    })
  }

  // Converting polygons mounted in map to mapfields GeoJSON
  const onMountedField = useCallback((e: any) => {
    if(polygons) {
      const { _map: { _layers } } = e
      setFieldMounted(_layers)
    }
  }, [polygons])


  const center = useMemo(
    () => {
      if(updateCenter){
        return <CenterMap lat={lat} lng={lng} callback={setUpdateCenter} />
      }

      return ''

    }, [updateCenter, lng, lat])

  useEffect(() => {
    if(fieldMounted && polygons && polygons?.length > 0){
      const fieldMountedArray = Object.values(fieldMounted)

      fieldMountedArray.forEach((each: any) => {
        const layerField = each?._layers
        const uniqFieldArray = mapFields || []

        if(layerField){
          const layerFieldValueArray: Array<any> = Object?.values(layerField)
          uniqFieldArray.push({ id: Number(Object.keys(layerField)[0]), field: layerFieldValueArray[0]?.toGeoJSON() })
        }
        if(uniqFieldArray?.length > 0) {
          setMapFields(getUniqueArray(uniqFieldArray, 'id'))
          getFieldTotalArea(getUniqueArray(uniqFieldArray, 'id'))
        }
      })
    }
  }, [fieldMounted, polygons])

  const customIcons = [spritesheet2xV2, spritesheetV2, spritesheetSVGV2]

  return (
    <MapDrawer key={mapKey} customIcons={customIcons}>
      <MapContainer
        center={mapCenter}
        attributionControl
        zoom={13}
        scrollWheelZoom
        zoomControl={false}
        style={{ position: 'relative', top: 0, width: '100%', height: '100%' }}
      >
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {center}
        <FeatureGroup>
          <LayersControl position='topright'>
            <LayersControl.BaseLayer checked name='Sátelite'>
              <ReactLeafletGoogleLayer apiKey={key} type={'hybrid'} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name='Mapa'>
              <ReactLeafletGoogleLayer apiKey={key} type={'roadmap'} />
            </LayersControl.BaseLayer>
          </LayersControl>
          <FullscreenControl position='bottomright' />
          <MapControl
            position='bottomright'
            draw={{
              rectangle: false,
              circle: false,
              circlemarker: false,
              marker: false,
              polyline: false,
              polygon: {
                allowIntersection: false,
                showArea: true,
                showLength: true
              }
            }}
            onCreated={(e: any) => onCreateField(e)}
            onEdited={(e: any) => onEditField(e)}
            onDeleted={(e: any) => onDeleteField(e)}
            onMounted={(e: any) => onMountedField(e)}
          />
          <ZoomControl position='bottomright' />
          {polygons}
        </FeatureGroup>
        <HandleEvents />
      </MapContainer>
    </MapDrawer>
  )
}

export default FieldMap