import { TablePaginationConfig } from 'antd/lib/table/interface'

export const DEFAULT_PAGINATION: TablePaginationConfig = {
  defaultCurrent: 1,
  current: 1,
  total: 1,
  pageSize: 10,
  disabled: false
}

