import styled from 'styled-components'
import { Collapse, Button } from 'antd'
const { Panel } = Collapse

type TSubTiltle = {
  marginleft?: number
}

export const Resume = styled.div`
    padding: 14px 16px;
    color: ${({ theme }) => theme.colors.WHITE};
    font-size: 14px;
    border-bottom: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    border-top: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
`
export const ResumeRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    `

export const ResumeItem = styled.div`
    display: flex;
    flex-direction: column;
    h4 {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.WHITE};
        margin: 0;
        padding: 0;
    }
`

export const FarmsCollapse = styled(Collapse)`
.ant-collapse-header {
    border-top: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    border-bottom: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    svg {
        color: ${({ theme }) => theme.colors.WHITE};
    }
}
.ant-collapse-content-box{
    padding: 0 !important;
}
`

export const PanelOwner = styled(Panel)`


`

export const PanelFarm = styled(Panel)`
.ant-collapse-header {
    border-top: none;
}
h5 {
    padding-left: 10px;
}
`

export const PanelHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 97%;
`

export const OwnerTitle = styled.h4<TSubTiltle>`
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #f37422;
    margin-left:  ${({ marginleft }) => `${marginleft}px`};
}
`

export const SubTitle = styled.h5`
 padding: 0;
    margin: 0;
    font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.WHITE};
  
`

export const NewField = styled(Button)`
    margin: 14px 16px;
    cursor: pointer;
    height: 38px;
    width: calc(100% - 32px);
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.WHITE};
    background-color:${({ theme }) => theme.colors.BLUEGREEN};
    
    :hover {
        background-color:${({ theme }) => theme.colors.MOSS_GREEN};
        color: ${({ theme }) => theme.colors.WHITE};
    }
`