import React, { memo, useContext } from 'react'
import { useProductContext } from 'hooks/useProductContext'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import isEmpty from 'lodash/isEmpty'

import { isJSONStrig, isValidURL } from 'utils/helpers'
import AnalyticalSummary from './ChartsTypes/AnalyticalSummary'
import EnvironmentAnalysis from './ChartsTypes/EnvironmentAnalysis'
import GeneralAnalysis from './ChartsTypes/GeneralAnalysis'
import ModalNewRecommendation from '../ProductAnalysisController/Modals/ModalNewRecommendation'


import { DialogContext } from 'dialog/context'

import { ChartsWrapper, PDFViwer, Recommendation } from './styles'
import Button from 'components/Button'

type TProps ={
  productAnalysis: any
  projectSelected: string
  fieldDrawerOpen: boolean
}

const Charts = ({ productAnalysis, projectSelected, fieldDrawerOpen }:TProps) => {
  const { chartSelected, fullscreen, setChartSelected } = useProductContext()
  const { general, environment, analytical_summary } = productAnalysis
  const { createDialog } = useContext(DialogContext)
  const { t } = useTranslation('namespaces')

  if(isEmpty(productAnalysis) || isEmpty(analytical_summary?.sheppard_diagram) || isEmpty(analytical_summary?.histogram)){
    return null
  }

  const { sheppard_diagram: { series, classification, elements, stats }, histogram: { charts } } = analytical_summary

  const handleModalRecommendation = () => {
    createDialog({
      id: 'modal-new-recommendation',
      open: false,
      Component: ModalNewRecommendation,
      props: {
        productSelected: projectSelected,
        recommendation: {
          title: JSON.parse(chartSelected.menu).title,
          suggestion: JSON.parse(chartSelected.menu).suggestion,
          content: JSON.parse(chartSelected.menu).content,
          id: JSON.parse(chartSelected.menu).id
        },
        callback: () => setChartSelected({ menu: 'analytical summary', submenu: 'all' })
      }
    })
  }

  return (
    <ChartsWrapper fullscreen={fullscreen} fieldDrawerOpen={fieldDrawerOpen}>
      {isValidURL(chartSelected.menu) ? (
        <PDFViwer data={chartSelected.menu} />
      ) : (
        <>
          {chartSelected.menu === 'analytical summary' && (
            <AnalyticalSummary
              series={series}
              classification={classification}
              elements={elements}
              stats={stats}
              charts={chartSelected.submenu === 'all' ? charts : [charts[Number(chartSelected.submenu)]] }
            />
          )}
          {chartSelected.menu === 'general analysis' && (
            <GeneralAnalysis
              general={chartSelected.submenu === 'all' ? general : [general[Number(chartSelected.submenu)]]}
            />
          )}
          {chartSelected.menu === 'environment analysis' && (
            <EnvironmentAnalysis
              environment={chartSelected.submenu === 'all' ? environment : [environment[Number(chartSelected.submenu)]]}
            />
          )}
          {isJSONStrig(chartSelected.menu) && (
            <Recommendation>
              <Button
                id='btn-edit-recommendation'
                label={t('edit recommendation', { ns: namespaces.pages.field }) }
                type='primary'
                onClick={() => handleModalRecommendation()}
              />
              <h2>{JSON.parse(chartSelected.menu).title}</h2>
              <h4>{JSON.parse(chartSelected.menu).suggestion}</h4>
              <div dangerouslySetInnerHTML={{ __html: JSON.parse(chartSelected.menu).content }} />
            </Recommendation>
          )}
        </>
      )}

    </ChartsWrapper>)
}

export default memo(Charts)