import styled from 'styled-components'

/* const animationDuration = 10
const animationSetupDuration = animationDuration / 7 */
export const Container = styled.div`
display: flex;
flex-direction: column;
margin-top: -10%;
@keyframes slide {
  0% {
    transform : translate(0, 0);
  }
  2% {
    transform : translate(33px, 0);
  }

  12.5% {
    transform : translate(33px, 0);
  }
  15.5% {
    transform : translate(66px, 0);
  }

  25% {
    transform : translate(66px, 0);
  }
  27% {
    transform : translate(66px, 33px);
  }

  37.5% {
    transform : translate(66px, 33px);
  }
  39.5% {
    transform : translate(33px, 33px);
  }

  50% {
    transform : translate(33px, 33px);
  }
  52% {
    transform : translate(33px, 66px);
  }

  62.5% {
    transform : translate(33px, 66px);
  }
  64.5% {
    transform : translate(0, 66px);
  }

  75% {
    transform : translate(0, 66px);
  }
  77% {
    transform : translate(0, 33px);
  }

  87.5% {
    transform : translate(0, 33px);
  }
  89.5% {
    transform : translate(0, 0);
  }

  100% {
    transform : translate(0, 0);
  }
}

svg#loading {
  display : block;
  margin  : auto;
  width   : 20rem;
  height  : auto;
  //transform : rotate(180deg);
  
  .rect {
    animation : slide 10s ease infinite;
  }
  #rect1 {
    animation-delay : 0s;
  }
  #rect2 {
    animation-delay : -1.42s;
  }
  #rect3 {
    animation-delay : -2.48s;
  }
  #rect4 {
    animation-delay : -4.26s;
  }
  #rect5 {
    animation-delay : -5.68s ;
  }
  #rect6 {
    animation-delay : -7.1s;
  }
  #rect7 {
    animation-delay : -8.52s;
  }
}

div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: #f37422;
 
}

`