import React, { useState, useMemo, useContext } from 'react'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { DialogContext } from 'dialog/context'
import Modal from 'components/Modal'
import Button from 'components/Button'
import TextField from 'components/TextField'
import UploadButton from 'components/UploadButton'

import ModalDeleteUser from '../ModalDeleteUser'
import {
  initialValues,
  validation,
} from './validations'
import {
  FormBox,
  ContainerBtn,
  StyleInput,
  Container,
  ContentInputs,
  ContentImg,
  Avatar,
  Title
} from './styles'
import { TFormMember } from 'types/member'

import RadioCustom from 'components/RadioCustom'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  handleSave(values:TFormMember, id?:any):void
  user: any
  deleteUser(id:any):void
}

const ModalCreateUser = ({ open, handleClose, id, handleSave, user, deleteUser }: TProps) => {
  const [form] = Form.useForm<TFormMember>()
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)

  const [currentImage, setCurrentImage] = useState<any>()

  const modeEdit = useMemo(() => !isEmpty(user), [user])

  const formValues = useMemo(() => {
    if(!isEmpty(user)){
      if(!isEmpty(user?.image_file)){
        setCurrentImage(user?.image_file)
        return { ...user, image_file: [{ ...user?.image_file, name: user?.image_file?.filename }] }
      }
      return { ...user, image_file: [] }
    }
    return initialValues
  }, [user])

  const onFinish = (values:TFormMember) => {

    if(modeEdit){
      handleSave(values, user?.key)
    }else{
      handleSave(values)
    }
    handleClose()
  }

  const closeModal = () => {
    handleClose()
  }

  const handleDelete = () => {
    createDialog({
      id: 'modal-delete-user',
      open: true,
      Component: ModalDeleteUser,
      props: {
        title: t('delete', { ns: namespaces.common }),
        message: t('are you sure you want to delete this member?', { ns: namespaces.modals.memberForm }),
        handleSave: () => {
          deleteUser(user?.key)
          handleClose()
        }
      }
    })
  }

  const roleOptions = [
    { label: 'Regular', value: 'user' },
    { label: 'Admin', value: 'admin' },
  ]

  const languageOptions = useMemo( () => ([
    { label: t('portuguese', { ns: namespaces.common }), value: 'pt-BR' },
    { label: t('english', { ns: namespaces.common }), value: 'en' },
    { label: t('spanish', { ns: namespaces.common }), value: 'es' },
  ]), [t])

  return (
    <Modal
      id={id}
      title={modeEdit ? t('edit user', { ns: namespaces.pages.users }) : t('register a new user', { ns: namespaces.pages.users })}
      isModalVisible={open}
      handleClose={closeModal}
    >
      <FormBox>
        <Form
          initialValues={formValues}
          labelCol={{ span: 6 }}
          name='form-rotationst'
          form={form}
          autoComplete='off'
          onFinish={ onFinish}
        >
          <Container>
            <ContentInputs>
              <StyleInput>
                <TextField
                  name='name'
                  label={t('name', { ns: namespaces.common })}
                  id='input-name'
                  placeholder={t('name', { ns: namespaces.common })}
                  rules={validation('name', t)}
                />
              </StyleInput>
              <StyleInput>
                <TextField
                  name='email'
                  label={t('email', { ns: namespaces.common })}
                  id='input-email'
                  placeholder={t('email', { ns: namespaces.common })}
                  rules={validation('email', t)}
                  disabled={modeEdit}
                />
              </StyleInput>
              <StyleInput>
                <RadioCustom
                  id='radio-role'
                  name='role'
                  rules={validation('role', t)}
                  label={t('access type', { ns: namespaces.modals.memberForm })}
                  options={roleOptions}
                  direction='horizontal'
                />
              </StyleInput>
              <StyleInput>
                <RadioCustom
                  id='radio-locale'
                  name='locale'
                  rules={validation('locale', t)}
                  label={t('preferred language', { ns: namespaces.modals.editUser })}
                  options={languageOptions}
                  direction='horizontal'
                />
              </StyleInput>
            </ContentInputs>
            <ContentImg>
              <Title>{t('import a .PNG or .JPG image', { ns: namespaces.common })}</Title>
              <Avatar src={currentImage?.url } />

              <UploadButton
                type='default'
                name='image_file'
                accept='image/*'
                onchangeCreateUrl={setCurrentImage}
                disable={modeEdit}
                labelButton='image'
              />
            </ContentImg>
          </Container>

          <ContainerBtn>
            <Button
              id='button-cancel-or-delete'
              type={modeEdit ? 'dashed' : 'default'}
              label={ modeEdit ? t('delete', { ns: namespaces.common }) : t('cancel', { ns: namespaces.common })}
              onClick={() => modeEdit ? handleDelete() : closeModal()}
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalCreateUser
