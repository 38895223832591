import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { TFormUser } from 'types/users'

export const initialValues: TFormUser = {
  name: '',
  email: '',
  role: 'user',
  locale: 'pt-BR',
  image_file: []
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'name':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('name', { ns: namespaces.common }) })
      }] as Rule[]

    case 'email':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('email', { ns: namespaces.common }) })
      }] as Rule[]

    case 'language':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('language', { ns: namespaces.common }) })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}
