import React, {
  useContext,
  useCallback,
} from 'react'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button'
import ErrorIcon from 'components/Icons/ErrorIcon'

import { DialogContext } from 'dialog/context'

import { ModalConfirmStyle, Container, Content, ContainerBtn, CustomAlert } from '../styles'

  type TProps = {
    handleClose: ()=> void
    handleConfirmation?: () => void
    isModalVisible: boolean
    id: string
    title?: string
    message?:string
    newLayout?: boolean
    colorButtonConfirm?: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text'
    colorButtonCancel?: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text',
    labelButtonConfirm?:string
    labelButtonCancel?:string
  }

const ModalConfirm = ({
  handleClose,
  handleConfirmation,
  isModalVisible,
  id,
  title,
  message,
  newLayout = false,
  colorButtonConfirm = 'primary',
  colorButtonCancel = 'default',
  labelButtonConfirm = 'delete',
  labelButtonCancel = 'cancel'
}:TProps) => {

  const { removeDialog } = useContext(DialogContext)
  const { t } = useTranslation('namespaces')

  const close = useCallback(
    () => {
      handleClose()
    },
    [id, handleClose, removeDialog]
  )

  return (
    <ModalConfirmStyle title={title} visible={isModalVisible} footer={false} onOk={handleConfirmation} onCancel={close}>
      <Container>
        <Content>
          {newLayout ? (
            <CustomAlert>
              <ErrorIcon />
              {message}
            </CustomAlert>
          ) : (
            message
          )}
        </Content>
        <ContainerBtn>
          <Button
            id='button-cancel'
            type={colorButtonCancel}
            label={ t(`${labelButtonCancel}`, { ns: namespaces.common })}
            onClick={close}
            htmlType='reset'
          />
          <Button
            id='button-confirm'
            type={colorButtonConfirm}
            label={t(`${labelButtonConfirm}`, { ns: namespaces.common })}
            onClick={handleConfirmation}
            htmlType='submit'
          />
        </ContainerBtn>
      </Container>
    </ModalConfirmStyle>
  )
}

export default ModalConfirm