import React, { memo, useEffect, useState } from 'react'
import i18n from '../../i18n'
import BrFlag from 'assets/flags/br-flag.svg'
import PaFlag from 'assets/flags/pa-flag.png'
import UsaFlag from 'assets/flags/usa-flag.svg'
import { Menu, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { DropdownSelect, Flag } from './styles'

export type TProps ={
  setFormKey: Function
}

const SelectLanguage = ({ setFormKey }: TProps) => {
  const options = [
    { value: 'pt-BR', label: BrFlag },
    { value: 'es', label: PaFlag },
    { value: 'en', label: UsaFlag },
  ]

  const [language, setLanguage] = useState(options[0])

  const menu = (
    <Menu>
      {options.map((item, idx) =>
        <Menu.Item key={idx} onClick={() => handleChangeLanguage(item)}>  <Flag src={item.label} /></Menu.Item>
      )}
    </Menu>
  )

  const handleChangeLanguage = (lang: any) => {
    localStorage.setItem('agfarmusLanguage', lang.value)
    i18n.changeLanguage(lang.value)
    setLanguage(lang)
    setFormKey(lang.value)
  }

  const localLanguage = localStorage.getItem('agfarmusLanguage')

  useEffect(() => {
    if (localLanguage !== null) {
      options.filter(item => {if(item.value === localLanguage ) setLanguage(item)})
    } else {
      setLanguage(options[0])
    }
  }, [])

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <DropdownSelect className='ant-dropdown-link' onClick={e => e.preventDefault()}>
        <Flag src={language.label} /> <DownOutlined color='#fff' />
      </DropdownSelect>
    </Dropdown>
  )
}

export default memo(SelectLanguage)
