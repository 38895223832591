import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { EyeFilled } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'
import { useHistory, useParams } from 'react-router-dom'

import map from 'lodash/map'

import useReport from 'repositories/useReport'

import { DialogContext } from 'dialog/context'
import { TFormReport } from 'types/report'
import { formatDateToView, removeTimeFromDateTime } from 'utils/helpers'

import { SoloCheck, FertilizationIcon, CompressionIcon, Signematoide, RainIcon } from 'components/Icons'
import DataTable from 'components/DataTable'

import ModalCreateReport from './ModalCreateReport'

import { Container, IconTable } from './styles'



const Reports = () => {
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const reportApi = useReport()

  const localLanguage = localStorage.getItem('agfarmusLanguage')
  const history = useHistory()
  const params:any = useParams()

  const [makeRequest, setMakeRequest] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const filterData = (growthRotationst:any) => {
    return map(growthRotationst, item => ({
      report_type: item?.report_type?.name || ' - ',
      created_at: item.created_at,
      file: item.file?.filename || ' - ',
      farm: item?.farm?.name || '',
      field: item?.field?.name || '',
      key: item.id,
      urlFile: item?.file?.url || null,
      report_type_id: item?.report_type?.id,
      project_id: item?.project?.id || null,
      field_id: item?.field.id
    }))
  }

  const getReports = async (pagination:TablePaginationConfig, changeTotalPages:any) => {
    const resReport = await reportApi.getAllReportByIdField({
      page: pagination.current,
      size: pagination.pageSize,
      q: search,
      project: params.project,
      field_id: params.id
    })

    changeTotalPages(resReport?.meta?.total_count)
    setMakeRequest(false)
    return filterData(resReport.reports)
  }

  const createReport = useCallback(
    async (report:TFormReport) => {
      const payload = {
        ...report,
        project: params.project,
        field_id: params.id
      }

      await reportApi.createReportByField(payload)

      setMakeRequest(true)
    }, [])

  const createReportsModal = useCallback(
    () => {
      createDialog({
        id: 'modal-create-report',
        open: false,
        Component: ModalCreateReport,
        props: {
          handleSave: createReport,
          projectSelected: params.project,
          fieldId: params.id
        }
      })
    }, [])


  const viewReportsModal = useCallback(
    (row:any) => {

      history.push(`/monitoring/${params.project}/report/preview-file`, { data: row })

    }, [])

  const columns = [
    {
      title: t('type of report', { ns: namespaces.pages.reports }),
      dataIndex: 'report_type',
      key: 'report_type',
      render: (text:string, ) => {
        switch (text) {
          case 'Solo':
            return <IconTable><SoloCheck /> {text} </IconTable>

          case 'Fertilizantes':
            return <IconTable><FertilizationIcon /> {text} </IconTable>

          case 'Compactação':
            return <IconTable><CompressionIcon /> {text} </IconTable>

          case 'Nematóide':
            return <IconTable><Signematoide /> {text} </IconTable>

          case 'Água':
            return <IconTable><RainIcon /> {text} </IconTable>

          default:
            return <IconTable> {text } </IconTable>
        }
      }
    },
    {
      title: t('created in', { ns: namespaces.common }),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text)
      }
    },
    {
      title: t('file', { ns: namespaces.common }),
      dataIndex: 'file',
      key: 'file',
    },
    {
      title: t('farm', { ns: namespaces.common }),
      dataIndex: 'farm',
      key: 'farm',
    },
    {
      title: t('field of farm', { ns: namespaces.common }),
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: t('actions', { ns: namespaces.common }),
      dataIndex: 'actions',
      key: 'actions',
      render: (text:string, record:any) => {
        return <EyeFilled onClick={() => viewReportsModal(record)} />
      }
    },
  ]

  return (
    <Container>
      <DataTable
        getDataFromApi={getReports}
        columns={columns}
        create={createReportsModal}
        labelBtnCreate={ t('new reports', { ns: namespaces.pages.reports })}
        title={t('view all reports', { ns: namespaces.pages.reports })}
        labelSearch={t('search for a reports', { ns: namespaces.pages.reports })}
        makeRequest={makeRequest}
        search={search}
        setSearch={setSearch}
        disabledSearch
      />
    </Container>
  )
}

export default Reports
