import styled from 'styled-components'
import { Button, Menu, Layout as AntLayout } from 'antd'
import MapPharms from 'components/MapPharms'
import { IoIosArrowDropright } from 'react-icons/io'
const { Footer, Sider } = AntLayout

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.THEME};
`

export const FieldDrawer = styled(Sider)<{visible: boolean}>`
  flex: 0 0 417px !important;
  max-width: ${({ visible }) => (visible ? '354px !important' : '24px  !important')};
  min-width: ${({ visible }) => (visible ? '354px !important' : '24px  !important')};
  width: ${({ visible }) => (visible ? '354px !important' : '24px  !important')};
  background: ${({ theme }) => theme.colors.THEME};
  position: relative;
  z-index: 999;
  height: 100%;
`

export const FieldInfo = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
`

export const FieldNameArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.WHITE};
  margin: 0;
`

export const SubTitle = styled.h4`
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.WHITE};
  margin: 0;
`

export const DrawerMenu = styled(Menu)`
     width: 354px !important;
     background: ${({ theme }) => theme.colors.THEME};
     color: ${({ theme }) => theme.colors.YELLOW};
    .ant-menu-item{
        margin: 0;
    }
 .ant-menu-item-active , .ant-menu-item-selected {
    background-color: rgba(10, 166, 150, 0.1) !important;
     color: #f37422 !important;

 }
 li > .ant-menu-item,
  .ant-menu-item-selected {
    background: ${({ theme }) => `${theme.colors.MOSS_GREEN} !important`};

    ::before {
      display: block;
      float: left;
      height: 100%;
      border-left: ${({ theme }) => `3px solid${theme.colors.BLUEGREEN}`};
      content: "";
      margin-left: -24px;
      padding-left: 24px;
    }

    ::after {
      display: none;
    }
  }
    border-right: none;
`


export const MenuItem = styled(Menu.Item)`
  margin: 0 !important;
  background-color: transparent !important;
`

export const DrawerMap = styled(MapPharms)`
 position: relative !important;
 width: 354px !important;
 height: 163px !important;
.leaflet-top, .leaflet-bottom {
    display: none !important;
    visibility: hidden !important;
}
.leaflet-control-attribution {
  visibility: hidden !important;
}

`

export const DrawerController = styled(IoIosArrowDropright)<{visible: boolean}>`
  font-size: 30px;
    color: ${({ theme }) => theme.colors.YELLOW};
    border-radius: 80px;
    position: absolute;
    top: 66.5px;
    right: -15px;
    z-index: 999999;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.THEME};
     transform: ${({ visible }) => visible ? 'rotate(180deg)' : 'rotate(360deg)'} ;
`

export const EditFieldButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.THEME};
  color: ${({ theme }) => theme.colors.WHITE} ;
  border: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  border-radius: 4px;
  margin-bottom: 6px;
  display: flex;
  align-items:center;
  justify-content: center;
  gap: 8px;

  svg {
    color: ${({ theme }) => theme.colors.YELLOW};
  }

  :hover{
    background-color: ${({ theme }) => theme.colors.BLUEGREEN};
    color: ${({ theme }) => theme.colors.WHITE};
    border-color:  ${({ theme }) => theme.colors.BLUEGREEN};
  }
`
export const ContainerFooter = styled(Footer)`
  position: inherit;
  padding: 0px 0px !important;
  font-size: 14px;
  background: ${({ theme }) => `${theme.colors.THEME} !important`};
`

export const Layout = styled(AntLayout)`
  max-height: calc(100vh - 83px);
`

export const DownloadKmlButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.THEME} !important;
  color: ${({ theme }) => theme.colors.YELLOW} !important;
  margin-left: 4px;
  border: none;
  color: transparent;
  position: absolute;
  top: 14%;
  left: 88%;
  z-index: 1000;

`