import React, { useRef, useState } from 'react'
import { Avatar, Form } from 'antd'
import SelectField from 'components/SelectField'
import TextField from 'components/TextField'
import { validation } from './validation'
import { useTranslation } from 'react-i18next'
import UserImage from 'assets/user.png'
import Modal from 'components/Modal'
import { namespaces } from 'i18n/i18n.constants'
import { Wrapper, EditAvatar, SaveBtn, PencilIcon } from './styles'
import Tooltip from 'components/Tooltip'
import { TUserData } from 'types/types'

export type TUser ={
  fullname: string
  locale: string
  password: string
}

export type TUserModal = {
  open: boolean;
  handleClose: () => void;
  id: string;
  userData?: TUserData;
  editUser: Function;
  editUserPassword: Function;
}

const EditUserModal = ({ open, handleClose, id, userData, editUser, editUserPassword }:TUserModal) => {
  const { t } = useTranslation('namespaces')
  const [form] = Form.useForm<TUser>()
  const hiddenFileInput: any = useRef(null)
  const [userAvatar, setUserAvatar] = useState<any>(userData?.image?.url || UserImage)

  const [editPassword, setEditPassword] = useState(true)

  const language = [
    {
      label: t('portuguese', { ns: namespaces.common }),
      value: 'pt-BR'
    },
    {
      label: t('english', { ns: namespaces.common }),
      value: 'en'
    },
    {
      label: t('spanish', { ns: namespaces.common }),
      value: 'es'
    }
  ]

  const initialValues: TUser = {
    fullname: userData?.name || '',
    locale: userData?.locale || '',
    password: ''
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const onFinish = (values: TUser) => {
    editUser(values, userAvatar)
    if(values.password) editUserPassword(values.password)
  }

  return (
    <Modal
      title={t('edit user', { ns: namespaces.modals.editUser })}
      isModalVisible={open}
      handleClose={handleClose}
      id={id}
    >
      <Wrapper>
        <Form
          name='basic'
          initialValues={initialValues}
          onFinish={onFinish}
          form={form}
          autoComplete='off'
        >
          <EditAvatar onClick={handleClick}>
            <Avatar size={100} src={userAvatar.length > 0 ? userAvatar : URL.createObjectURL(userAvatar)} />
            {t('change photo', { ns: namespaces.modals.editUser })}
          </EditAvatar>
          <input
            type='file'
            id='file'
            style={{ display: 'none' }}
            accept='image/png, image/jpeg'
            ref={hiddenFileInput}
            onChange={(event: any) => setUserAvatar(event.target.files[0])}
          />
          <TextField
            label={t('full name', { ns: namespaces.modals.editUser })}
            type='text'
            name='fullname'
            rules={ validation('fullname', t)}
            id='input-fullname'
          />
          <TextField
            label={t('email', { ns: namespaces.common })}
            name='email'
            defaultValue={userData?.email}
            id='input-email'
            disabled
          />
          <TextField
            name='password'
            label={t('password', { ns: namespaces.common })}
            placeholder={t('enter a new password', { ns: namespaces.modals.editUser })}
            id='input-password'
            endIcon={<Tooltip title={t('click to edit password', { ns: namespaces.modals.editUser })}>
              <PencilIcon onClick={() => setEditPassword(old => !old)} />
            </Tooltip>
            }
            disabled={editPassword}
          />
          <SelectField
            label={t('preferred language', { ns: namespaces.modals.editUser })}
            name='locale'
            options={language}
            defaultValue={userData?.locale}
            id='input-locale'
          />
          <SaveBtn
            htmlType='submit'
            type='primary'
          >
            {t('save', { ns: namespaces.common })}
          </SaveBtn>
        </Form>
      </Wrapper>
    </Modal>

  )
}

export default EditUserModal