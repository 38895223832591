import React, { useCallback } from 'react'
import { v1 as uuidv1 } from 'uuid'
import GeneralChart from './GeneralChart'
import { ChartGroup, ChartGroupTitle, ChartsWrapper, Chart } from './styles'
import { useProductContext } from 'hooks/useProductContext'

interface GeneralAnalysisProps {
  general: any
}
const GeneralAnalysis: React.FC<GeneralAnalysisProps> = ({ general } ) => {
  const { analysisWithCorrelation, fullscreen } = useProductContext()

  const getSeries = useCallback((series: any) => {
    if (analysisWithCorrelation) {
      const low: any = { values: [], color: '#c95058' }
      const middle: any = { values: [], color: '#eeff41' }
      const high: any = { values: [], color: '#73a84b' };

      (series[0].values || []).forEach((value: any) => {
        if (value.potential === 'low') {
          low.values.push(value)
        } else if (value.potential === 'middle') {
          middle.values.push(value)
        } else if (value.potential === 'high'){
          high.values.push(value)
        } else {
          return null
        }
      })
      return [low, middle, high]
    }
    return series
  }, [analysisWithCorrelation])

  return (
    <div>
      {general.map((group: any) => (
        <>
          <ChartGroupTitle>{`${group.index}. `}<div dangerouslySetInnerHTML={{ __html: group.title }} /></ChartGroupTitle>
          <ChartGroup key={`general-chart-group-${uuidv1()}`}>
            <ChartsWrapper>
              {group.charts.map((chart: any) => (
                <Chart fullscreen={fullscreen} key={`general-chart-${uuidv1()}`}>
                  <GeneralChart
                    title={chart.title}
                    description={chart.description}
                    yMin={chart.yMin}
                    yMax={chart.yMax}
                    xLabel={chart.xLabel}
                    yLabel={chart.yLabel}
                    series={getSeries(chart.series)}
                    idealValues={chart.classification && chart.ideal_values}
                    classification={chart.classification}
                    leastSquares={chart.least_squares}
                  />
                </Chart>
              ))}
            </ChartsWrapper>

          </ChartGroup>
        </>
      ))}
    </div>
  )
}

export default GeneralAnalysis
