import styled from 'styled-components'
import { Input } from 'antd'

type TMainProps = {
  main?: boolean;
};

type TContainerProps = {
  display: boolean
}

export const Container = styled.div<TContainerProps>`
  width: auto;
  position: absolute;
  top: 11px;
  overflow-y: hidden;
  z-index: 999;
  transition: 0.3s ease-in-out;
  margin-left: 4%;
  border-radius: 7px;
  max-width: 441px;
  display: ${({ display }) => display ? 'none' : 'block'};
`

export const ButtonListFarm = styled.div`
  width: 441px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: ${({ theme }) => theme.colors.YELLOW};
  background-color: ${({ theme }) => theme.colors.THEME};
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.1);
  height: 56px;
  border-radius: 8px;

  strong {
    width: 250px;
    margin-right: 50px;
  }
`

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.THEME};
`

export const Menu = styled.ul`
  width: 100%;
  font-size: 17px;
  padding: 0px 17px;
`

export const SubMenu = styled.li`
  width: 100%;
  color: ${({ theme }) => theme.colors.YELLOW};
  background: ${({ theme }) => theme.colors.THEME};
  padding: 0 15px;
  list-style-type: none;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
`

export const Item = styled.div<TMainProps>`
  padding: ${({ main }) => (main ? '20px 0px' : '20px 15px')};
  height: 54px;
  color: ${({ theme, main }) =>
    main ? theme.colors.YELLOW : theme.colors.WHITE};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.BLUEGREEN};
  }
`

export const SearchInput = styled(Input)`
  color: ${({ theme }) => theme.colors.YELLOW};
  background: ${({ theme }) => theme.colors.THEME};
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: none;
  border-right: none;
  height: 56px;
  padding-left: 16px;
  font-size: 17px;

  span > svg {
    color: ${({ theme }) => theme.colors.YELLOW};
    font-size: 22px;
  }

  input {
    color: ${({ theme }) => theme.colors.YELLOW};
    background: ${({ theme }) => theme.colors.THEME};
    font-size: 20px;
    margin-left: 15px;
  }
`

export const DropdownContainer = styled.div`
  max-height: 645px;
  width: 441px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.THEME};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: -4px;

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.THEME};
    border-bottom-right-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.YELLOW};
  }
`

export const ButtonDescription = styled.div`
  span > svg {
    font-size: 25px;
  }
`
