import styled from 'styled-components'
import { Button } from 'antd'

export const Container = styled.div`
  width: 100%;
  padding: 10px;
`

export const PrimaryButton = styled(Button)`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, disabled }) => disabled ? `${theme.colors.GRAY} !important` : theme.colors.WHITE};
  border-style: ${({ type }) => type === 'default' ? 'solid' : 'hidden'};
  border-radius: ${({ shape }) => shape === 'default' && '5px'};
  border-color: ${({ color, theme }) => color || theme.colors.MEDIUM_GRAY_OPACITY};
  align-self: right;
  background: ${({ theme, type, color, disabled }) => {
    if(disabled) return `${theme.colors.BLUEGREEN} !important`
    if(color) return color
    switch (type) {
      case 'primary':
        return theme.colors.BLUEGREEN
      case 'dashed':
        return theme.colors.RED
      case 'ghost':
        return theme.colors.WHITE
      default:
        return 'transparent'
    }
  }};

  &:hover,:focus {
    color: ${({ theme, type }) => type === 'ghost' ? theme.colors.GREEN : theme.colors.WHITE};
    /* border-color: ${({ theme }) => theme.colors.WHITE}; */
    background: ${({ theme, type, color }) => {
    if(color) return color
    switch (type) {
      case 'primary':
        return `${theme.colors.THEME_OPACITY} !important`
      case 'dashed':
        return `${theme.colors.RED} !important`
      case 'ghost':
        return `${theme.colors.WHITE} !important`
      default:
        return `${theme.colors.THEME_OPACITY} !important`
    }
  }};
  }

  span{

  }
`
