import React from 'react'
import Histogram from 'components/Histogram'
import SheppardDiagram from 'components/SheppardDiagram'

import { v1 as uuidv1 } from 'uuid'
import { Chart, ChartsWrapper } from './styles'
import { useProductContext } from 'hooks/useProductContext'
import { isEmpty } from 'lodash'

interface AnalyticalSummaryProps {
  series: any
  classification: any
  elements: any
  stats: any
  charts: Array<any>
}

const AnalyticalSummary: React.FC<AnalyticalSummaryProps> = ({ series,
  classification,
  elements,
  stats,
  charts,
}) => {

  const { fullscreen } = useProductContext()

  return (
    <>
      {!isEmpty(series) && <SheppardDiagram series={series} classification={classification} sheppard_elements={elements} stats={stats} fullscreen={fullscreen} />}
      <ChartsWrapper>
        {charts?.map((chart: any) => (
          <Chart fullscreen={fullscreen} key={`general-chart-${uuidv1()}`}>
            <Histogram
              key={uuidv1()}
              colors={chart?.colors}
              name={chart?.name}
              hist={chart?.hist}
              stats={chart?.stats}
              main_classification={chart?.main_classification}
            />
          </Chart>
        ))}
      </ChartsWrapper>
    </>
  )
}

export default AnalyticalSummary