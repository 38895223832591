import React, { useMemo } from 'react'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import TextField from 'components/TextField'
import useReport from 'repositories/useReport'
import Modal from 'components/Modal'
import Button from 'components/Button'

import {
  initialValues,
  validation,
  TFormReport
} from './validations'
import {
  FormBox,
  ContainerBtn,
} from './styles'
import TextArea from 'components/TextArea'
import { isEmpty } from 'lodash'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  productSelected: number
  recommendation: any
  callback:()=>void
  projectSelected:string
}

const ModalNewRecommendation = ({ open, handleClose, id, productSelected, recommendation, projectSelected, callback }: TProps) => {

  const [form] = Form.useForm<TFormReport>()
  const { t } = useTranslation('namespaces')
  const reportApi = useReport()

  const formInitialValues = useMemo(
    () => {
      if(!isEmpty(recommendation)){

        return recommendation

      }
      return initialValues

    }, [recommendation])


  const onFinish = async(values:TFormReport) => {
    const params = {
      title: values?.title,
      suggestion: values?.suggestion,
      content: values?.content,
      project: projectSelected,
      productSelected
    }
    if(!isEmpty(recommendation)){
      await reportApi.updateRecommendation(params, recommendation?.id, handleClose)
    }else{
      await reportApi.newRecommendation(params, handleClose)
    }
    callback()
  }


  return (
    <Modal
      id={id}
      title={t('new something a', { ns: namespaces.common, label: t('recommendation', { ns: namespaces.pages.field }) })}
      isModalVisible={open}
      handleClose={ handleClose}
    >
      <FormBox>
        <Form
          initialValues={formInitialValues}
          labelCol={{ span: 6 }}
          name='form-report'
          form={form}
          autoComplete='off'
          onFinish={ onFinish}
        >
          <TextField
            label={t('title', { ns: namespaces.common })}
            type='text'
            name='title'
            rules={ validation('title', t)}
            id='input-title'
          />
          <TextArea
            label={t('suggestion', { ns: namespaces.common })}
            name='suggestion'
            rules={ validation('suggestion', t)}
            id='input-suggestion'
          />
          <TextArea
            label={t('content', { ns: namespaces.common })}
            name='content'
            rules={ validation('content', t)}
            id='input-content'
          />

          <ContainerBtn>
            <Button
              id='button-cancel'
              type='default'
              label={t('cancel', { ns: namespaces.common })}
              onClick={ handleClose }
              htmlType='reset'
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalNewRecommendation
