import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useProductContext } from 'hooks/useProductContext'
import useProduct from './useProduct'
import isEmpty from 'lodash/isEmpty'

type TParamsGetReports = {
  page?: number
  size?: number
  q?:string
  project?: string
  field_id?: number
}
const useReport = () => {
  const rest = useRest()
  const { setProductProject } = useProductContext()
  const { getProductProject } = useProduct()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const getAllReportByIdField = async ({ page, size, q, project, field_id }:TParamsGetReports) => {

    const url = `/v2/companies/${project}/reports?field_id=${field_id}&page=${page}&size=${size}&q=${q}`
    const response = await rest.get(url)

    return response?.data
  }

  const getReportTypes = async () => {

    const url = 'v2/report_types'
    const response = await rest.get(url)

    return response?.data
  }

  const createReport = async (params:any, callback:()=>void) => {
    const url = `v2/companies/${params.project}/projects/${params.productSelected}/reports`
    const formDate = new FormData()

    formDate.append('file', params.file?.originFileObj)
    formDate.append('field_id', params.field_id)
    formDate.append('report_type_id', params.report_type_id)

    const response = await rest.post(url, formDate)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.common }), success: true })
      const projectResponse = await getProductProject(params.productSelected, params.project)
      setProductProject(projectResponse)
      callback()
    }
  }

  const createReportByField = async (params:any) => {
    const url = `v2/companies/${params.project}/reports`
    const formDate = new FormData()

    formDate.append('field_id', params.field_id)
    formDate.append('report_type_id', params.report_type_id)

    if(params.project_id){
      formDate.append('project_id', params.project_id)
    }
    formDate.append('file', params.file?.originFileObj)

    const response = await rest.post(url, formDate)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.common }), success: true })
    }
  }

  const createAttachment = async (params:any, callback:()=>void) => {
    const url = `v2/companies/${params.project}/projects/${params.productSelected}/attachments`
    const formDate = new FormData()
    formDate.append('file', params.file?.originFileObj)
    formDate.append('name', params.name)

    const response = await rest.post(url, formDate)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.common }), success: true })
      const projectResponse = await getProductProject(params.productSelected, params.project)
      setProductProject(projectResponse)
      callback()
    }
  }

  const newRecommendation = async (params:any, callback:()=>void) => {
    const url = `v2/companies/${params.project}/projects/${params.productSelected}/recommendations`
    const data = {
      title: params.title,
      suggestion: params.suggestion,
      content: params.content,
    }

    const response = await rest.post(url, data)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.common }), success: true })
      const projectResponse = await getProductProject(params.productSelected, params.project)
      setProductProject(projectResponse)
      callback()
    }
  }

  const updateRecommendation = async (params:any, id:number, callback:()=>void) => {
    const url = `v2/companies/${params.project}/projects/${params.productSelected}/recommendations/${id}`
    const data = {
      title: params.title,
      suggestion: params.suggestion,
      content: params.content,
    }

    const response = await rest.put(url, data)

    if(response?.status === 200){
      notify({ message: t('item updated successfully', { ns: namespaces.errors }), success: true })
      const projectResponse = await getProductProject(params.productSelected, params.project)
      setProductProject(projectResponse)
      callback()
    }
  }

  const updateReportByField = async (params:any, project:any, id:any) => {
    const url = `v2/companies/${project}/reports/${id}`
    const formDate = new FormData()

    formDate.append('field_id', params.field_id)
    formDate.append('report_type_id', params.report_type_id)

    if(params.project_id){
      formDate.append('project_id', params.project_id)
    }
    if(!isEmpty(params.file?.originFileObj)){
      formDate.append('file', params.file?.originFileObj)
    }


    const response = await rest.put(url, formDate)

    if(response?.status === 200){
      notify({ message: t('item updated successfully', { ns: namespaces.common }), success: true })

      return response.data
    }
  }

  const createEnvironmentMap = async (params:any, callback:()=>void) => {
    const url = `v2/companies/${params.project}/fertianalises/${params.actable_id}`

    const formDate = new FormData()
    formDate.append('environment_map', params.file?.originFileObj)

    const response = await rest.put(url, formDate)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.common }), success: true })
      const projectResponse = await getProductProject(params.productSelected, params.project)
      setProductProject(projectResponse)
      callback()
    }
  }

  const removeAttach = async (params:any, callback:()=>void) => {
    const url = `v2/companies/${params.project}/projects/${params.productSelected}/attachments/${params.attachId}`

    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.common }), success: true })
      const projectResponse = await getProductProject(params.productSelected, params.project)
      setProductProject(projectResponse)
      callback()
    }
  }

  const removeReport = async (params:any, callback:()=>void) => {
    const url = `v2/companies/${params.project}/projects/${params.productSelected}/reports/${params.reportId}`

    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.common }), success: true })
      const projectResponse = await getProductProject(params.productSelected, params.project)
      setProductProject(projectResponse)
      callback()
    }
  }

  const removeReportById = async (project:any, reportId:any) => {
    const url = `v2/companies/${project}/reports/${reportId}`

    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.common }), success: true })
    }
  }

  const removeRecommendation = async (params:any, callback:()=>void) => {
    const url = `v2/companies/${params.project}/projects/${params.productSelected}/recommendations/${params.recommendationId}`

    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.common }), success: true })
      const projectResponse = await getProductProject(params.productSelected, params.project)
      setProductProject(projectResponse)
      callback()
    }
  }

  const getReportScoutById = async (id:number) => {
    try {

      const url = `/v3/report_scouts/${id}`
      const response = await rest.get(url)

      return response?.data.report_scout

    } catch (error) {
      notify({ message: t('no records found', { ns: namespaces.errors }), warning: true })
    }
  }


  return {
    getReportTypes,
    createReport,
    createAttachment,
    newRecommendation,
    createEnvironmentMap,
    removeAttach,
    removeReport,
    removeRecommendation,
    updateRecommendation,
    getAllReportByIdField,
    createReportByField,
    removeReportById,
    updateReportByField,
    getReportScoutById
  }
}

export default useReport
