import { apiBackEnd, apiGeo } from 'services/axios'
import { useGlobalContext } from 'hooks/useGlobalContext'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { isEmpty } from 'lodash'
import { TContrastResponse, TContrastResponseFarm, TGetNdviContrastFarm, TGetNdviContrastField } from 'types/ndviContrast'

const useNdvi = () => {
  const global = useGlobalContext()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const getNdvi = async (farm_ids:number[]) => {

    try {
      global.addLoading()

      const response = await apiBackEnd.post('v3/median_ndvi',
        {
          farm_ids,
        },
      )

      return response.data as any

    } catch (error: any) {
      notify(error.response.data)
    }finally{
      global.removeLoading()
    }
  }

  const getNdviByFarmId = async (start:number, end:number, idfarm:number | undefined) => {

    try {
      global.addLoading()

      if(!idfarm) return []

      const response = await apiGeo.post('/polygonhistoric/ndviHistoricByFarm',
        {
          start,
          end,
          idfarm: idfarm.toString(),
        },
      )

      return response.data.data as any

    } catch (error: any) {
      notify(error.response.data)
    }finally{
      global.removeLoading()
    }
  }

  const getNdviContrast = async ({ idField, date, kind, satellite }: TGetNdviContrastField) => {

    try {
      global.addLoading()

      // const url = `/v3/fields/${idField}/ndvi_contrast/${date}?kind=${kind}`
      const url = `v3/contrasts/${idField}/field/${date}?kind=${kind}&satellite=${satellite}`
      const response = await apiBackEnd.get(url)

      if(isEmpty(response.data)){
        notify({ message: t('images not yet available', { ns: namespaces.errors }), warning: true })
      }

      return response.data as TContrastResponse

    } catch (error:any) {

      if(error?.response?.status === 404){
        notify({ message: t('images not yet available', { ns: namespaces.errors }), warning: true })
      }

    }finally{
      global.removeLoading()
    }
  }

  const getNdviContrastFarm = async ({ idFarm, date, kind, satellite }: TGetNdviContrastFarm) => {

    try {
      global.addLoading()

      const url = `/v3/contrasts/${idFarm}/farm/${date}?kind=${kind}&satellite=${satellite}`
      const response = await apiBackEnd.get(url)

      if(isEmpty(response.data)){
        notify({ message: t('images not yet available', { ns: namespaces.errors }), warning: true })
      }

      return response.data as TContrastResponseFarm

    } catch (error:any) {

      if(error?.response?.status === 404){
        notify({ message: t('images not yet available', { ns: namespaces.errors }), warning: true })
      }

    }finally{
      global.removeLoading()
    }
  }

  return {
    getNdvi,
    getNdviContrast,
    getNdviContrastFarm,
    getNdviByFarmId
  }
}

export default useNdvi