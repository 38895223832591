import { apiBackEnd } from 'services/axios'
import { useGlobalContext } from 'hooks/useGlobalContext'
import useToast from 'hooks/useToast'

const useSearchDatesImages = () => {
  const global = useGlobalContext()
  const { notify } = useToast()

  const getInfosTimeLineFarm = async (idfarm: number | undefined, page: number, date?: number) => {
    try {
      global.addLoading()

      if(!idfarm) return []

      let url = `v3/agromonitoring/${idfarm}/farm_date?page=${page}`

      if(date){
        url = `${url}&dt=${date}`
      }

      const response = await apiBackEnd.get(url)

      return response.data
    } catch (error:any) {
      const _error = error as any
      notify({ message: _error.response.data, errors: true })
    }finally{
      global.removeLoading()
    }
  }

  const getInfosTimeLineField = async (idfield: number, page: number, date?: number) => {
    try {
      global.addLoading()


      let url = `v3/agromonitoring/${idfield}/field_date?page=${page}`

      if(date){
        url = `${url}&dt=${date}`
      }

      const response = await apiBackEnd.get(url)

      return response.data
    } catch (error:any) {
      const _error = error as any
      notify({ message: _error.response.data, errors: true })
    }finally{
      global.removeLoading()
    }
  }

  const getAllDatesByFarm = async (idfarm: number | undefined, dateStart: number, dateEnd: number) => {
    try {
      global.addLoading()

      if(!idfarm) return []

      const url = `v3/agromonitoring/${idfarm}/farm_calendar/${dateStart}/${dateEnd}`
      const response = await apiBackEnd.get(url)

      return response.data
    } catch (error:any) {
      const _error = error as any
      notify({ message: _error.response.data, errors: true })
    }finally{
      global.removeLoading()
    }
  }

  const getAllDatesByField = async (id: number, dateStart: number, dateEnd: number) => {
    try {
      global.addLoading()
      const url = `v3/agromonitoring/${id}/field_calendar/${dateStart}/${dateEnd}`
      const response = await apiBackEnd.get(url)

      return response.data
    } catch (error:any) {
      const _error = error as any
      notify({ message: _error.response.data, errors: true })
    }finally{
      global.removeLoading()
    }
  }

  return {
    getInfosTimeLineFarm,
    getInfosTimeLineField,
    getAllDatesByFarm,
    getAllDatesByField,
  }
}

export default useSearchDatesImages