import React from 'react'
import { v1 as uuidv1 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { Wrapper, MiniTable, TableTitle } from './styles'


interface StatsTableProps {
  stats: any,
  classification: any
}

const StatsTable: React.FC<StatsTableProps> = ({ stats, classification }) => {
  const { t } = useTranslation('namespaces')
  const { average, median, standard_deviation, coefficient_variation, variance, amplitude, min, max } = stats

  const tendency = [average, median]
  let dispersion = [standard_deviation, coefficient_variation]
  dispersion = dispersion.concat([variance, amplitude, min, max])

  return(
    <Wrapper>
      <TableTitle>
        {t('central tendency', { ns: namespaces.pages.field })}
      </TableTitle>
      <MiniTable>
        {
          tendency.map(({ label, value }) => (
            <tr key={uuidv1()}>
              <td>{label}</td>
              <td>{value}</td>
            </tr>
          ))
        }
      </MiniTable>
      <TableTitle>
        {t('dispersion measures', { ns: namespaces.pages.field })}
      </TableTitle>
      <MiniTable>
        {
          dispersion.map(({ label, value }) => (
            <tr key={uuidv1()}>
              <td>{label}</td>
              <td>{value}</td>
            </tr>
          ))
        }
      </MiniTable>

      <MiniTable>
        <tr>
          <td>{classification.coefficient_variation.label}</td>
          <td>{classification.coefficient_variation.value}</td>
        </tr>
      </MiniTable>
      {classification.general && (
        <MiniTable>
          <tr style={{ borderColor: 'black' }}>
            <td>{classification.general.label}</td>
            <td>{classification.general.value}</td>
          </tr>
        </MiniTable>)}
    </Wrapper>)
}

export default StatsTable
