import styled from 'styled-components'

export const Wrapper = styled.div<{fullscreen?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width:  100%; 
    padding: 1.5rem 0;
    .highcharts-background {
        fill: none;
    }
    .highcharts-axis-labels, .highcharts-xaxis-labels {
        text {
            fill: ${({ theme }) => theme.colors.WHITE} !important;
        }
    }
`
export const ChartContent = styled.div`
    flex: 1;
    width:  100%; 

`

export const ChartTitle = styled.div`
 font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.WHITE};
`

export const Chart = styled.div`
    flex: 1;
    width:  100%; 
`

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .highcharts-background {
        fill: none;
    }
    .highcharts-axis-labels, .highcharts-xaxis-labels, .highcharts-legend-item  {
        text {
            fill: ${({ theme }) => theme.colors.WHITE} !important;
        }
    }
    .highcharts-axis-title {
      fill: ${({ theme }) => theme.colors.WHITE} !important;
    }
    .highcharts-title, span.highcharts-title{
        color: ${({ theme }) => theme.colors.WHITE} !important;
        fill: ${({ theme }) => theme.colors.WHITE} !important;
    }
`