import React from 'react'
import { theme } from 'styles'
import { Rule } from 'antd/lib/form'
import map from 'lodash/map'

import { FormItem, Select, Options, CheckedIcon, UncheckedIcon } from './styles'

export type TOptions = {
  value: string | number
  label: string
}
import { TSelectOption } from 'types/types'

interface IProps {
  name?: string | any
  fieldKey?:any
  label?: string
  defaultValue?: string[] | number[] | string | number
  value?: any //string[] | number[] | string | number | undefined | null
  placeholder?: string
  disabled?: boolean
  id: string
  options?: TSelectOption[]
  handleChange?(event: any): void
  multiple?: boolean
  allowClear?: boolean
  useFlex?:boolean
  dropdownRender?: any
  rules?: Rule[]
  showSearch?: boolean
  labelInValue?: boolean
  color?:string
}

const SelectField = ({
  name,
  fieldKey,
  label,
  defaultValue,
  value,
  placeholder,
  disabled,
  id,
  handleChange = () => {},
  options = [],
  multiple = false,
  allowClear = false,
  dropdownRender,
  rules,
  showSearch,
  labelInValue = false,
  color
}: IProps) => {

  return (
    <FormItem
      label={label}
      name={name}
      rules={rules}
      fieldKey={fieldKey}
    >
      <Select
        id={id}
        labelInValue={labelInValue}
        mode={multiple ? 'multiple' : undefined}
        defaultValue={ defaultValue}
        showSearch={showSearch}
        onChange={handleChange}
        menuItemSelectedIcon={({ isSelected }) =>
          isSelected && multiple ? <CheckedIcon /> :
            !isSelected && multiple ? <UncheckedIcon /> : null}
        disabled={disabled}
        allowClear={allowClear}
        placeholder={placeholder}
        value={value}
        dropdownRender={dropdownRender}
        dropdownStyle={{ background: theme.colors.THEME }}
        maxTagCount='responsive'
        filterOption={(inputValue, option: any) =>
          option.props.children
            .toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        }
        color={color}
      >
        {map(options, ({ value, label }:TSelectOption, index: number) =>
          <Options
            value={value}
            key={index}
            style={ multiple ? { diplay: 'flex', flexDirection: 'row-reverse' } : {}}
          >
            {label}
          </Options>
        )}
      </Select>
    </FormItem>
  )
}

export default SelectField