import styled from 'styled-components'
import { darken } from 'polished'

type TContainerProps = {
  display?: boolean
  pageMode?: boolean
  isMonitoring?: boolean
}

export const Container = styled.div<TContainerProps>`
  position: absolute;
  z-index: 500;
  align-items: center;
  width: ${({ isMonitoring }) => isMonitoring ? '200px' : '184px'} ;
  min-height: 36px;
  color: ${({ theme }) => theme.colors.YELLOW};
  margin-top: 11px;
  margin-left: ${({ pageMode }) => pageMode ? '61%' : '80%'};
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.THEME};
  display: ${({ display }) => display ? 'none' : 'block'};

  @media screen and (max-width: 1500px) {
    margin-left: ${({ pageMode }) => pageMode ? '61%' : '75%'};
    }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  padding: 10px;
  color: ${({ theme }) => theme.colors.YELLOW};
  border-radius: 7px;

  p{
    margin: 0;
    padding: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.THEME};
  }
  div {
    display: flex;
    align-items: center;
  }
`

export const ContentOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`

export const Options = styled.div<TContainerProps>`
  display: flex;
  width: 100%;
  height: 20px;
  align-items: center;
  padding: 15px;
  color: ${({ theme, isMonitoring }) => isMonitoring ? theme.colors.WHITE : theme.colors.YELLOW};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => darken(0.1, theme.colors.BLUEGREEN)};
  }
`