import React from 'react'

type TProps = {
  size?: number;
  color?: string;
};

const ArrowUpIcon = ({
  size = 18,
  color = '#F37422',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 18 18'
    >
      <path d='M6 0.015625L0 6.01562L1.40625 7.42188L6 2.82812L10.5938 7.42188L12 6.01562L6 0.015625Z' fill={color} />
    </svg>
  )
}

export default ArrowUpIcon