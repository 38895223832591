import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { DialogContext } from 'dialog/context'

import ArrowDownIcon from 'components/Icons/ArrowDownIcon'
import ArrowUpIcon from 'components/Icons/ArrowUpIcon'
import ModalCarouselImage from 'components/ModalCarouselImage'

import { SwiperSlide } from 'swiper/react'

import { TScoutPointImage, TPoint } from 'types/report'

import { setColor } from 'utils/helpers'

import find from 'lodash/find'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import { theme } from 'styles'

import {
  PointContainer,
  PointHeader,
  PointContent,
  CarouselImage,
  Img,
  PointCircle,
  ContentInfo,
} from './styles'
interface IPoint{
  index:number
  pointData: TPoint | any
}

const Point = ({
  index,
  pointData,
}:IPoint) => {

  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)

  const [open, setOpen] = useState<boolean>(true)
  const [varietyCurrent, setVarietyCurrent] = useState<string | number | null>(null)
  const [phenologicalStageCurrent, setPhenologicalStageCurrent] = useState<string | number | null>(null)
  const [conditionsCurrent, setConditionsCurrent] = useState<any>()
  const [observationCurrent, setObservationCurrent] = useState<string | number | null>(null)
  const [standCurrent, setStandCurrent] = useState<string | number | null>(null)
  const [imagesCurrent, setImagesCurrent] = useState<TScoutPointImage[]>([])
  const [ColorPoint, setColorPoint] = useState<string>(theme.colors.YELLOW)

  const labelTitle = useMemo(() => t('point', { ns: namespaces.common, number: index }), [t, index])

  const conditionsOptions = useMemo(
    () => [
      { label: 'Bom', value: 'good' },
      { label: 'Ruim', value: 'bad' },
      { label: 'Regular', value: 'regular' },
      { label: 'Excelente', value: 'excellent' }
    ], [t])

  const setIcon = useMemo(
    () => {
      if(open){
        return <ArrowUpIcon />
      }

      return <ArrowDownIcon />
    }, [open])

  const viewModalImage = useCallback(
    (index:any) => {
      createDialog({
        id: 'modal-image',
        open: false,
        Component: ModalCarouselImage,
        props: {
          imagens: imagesCurrent,
          currentIndex: index,
          isView: true
        }
      })
    }, [imagesCurrent])

  useEffect(() => {
    if(!isEmpty(pointData)){
      const conditions = find(conditionsOptions, ({ value }:any) => value === pointData.growing_conditions )

      setVarietyCurrent(pointData.growth_variety_name)
      setPhenologicalStageCurrent(pointData.growth_stage_name)
      setConditionsCurrent(conditions)
      setColorPoint(setColor(pointData.growing_conditions))
      setObservationCurrent(pointData.observation)
      setStandCurrent(pointData.stand)
      setImagesCurrent(pointData.report_scout_point_attachments)
    }
  }, [pointData])

  return (
    <>
      <PointContainer colorBorder={ColorPoint}>
        <PointHeader onClick={() => setOpen((old:boolean) => !old) }>
          <PointCircle color={ColorPoint} />
          <strong>{ labelTitle }</strong>
          { setIcon }
        </PointHeader>
        {open && (
          <PointContent>
            <ContentInfo>
              {t('variety', { ns: namespaces.pages.field })}
              <span>{varietyCurrent || ' - ' }</span>
            </ContentInfo>
            <ContentInfo>
              {t('phenological stage', { ns: namespaces.pages.scout })}
              <span>{phenologicalStageCurrent || ' - ' }</span>
            </ContentInfo>
            <ContentInfo>
              {t('stand', { ns: namespaces.pages.scout })}
              <span>{standCurrent || ' - ' }</span>
            </ContentInfo>
            <ContentInfo>
              {t('cultivation condition', { ns: namespaces.pages.field })}
              <span>{conditionsCurrent?.label || ' - ' }</span>

            </ContentInfo>
            <ContentInfo>
              {t('observation', { ns: namespaces.common })}
              <span>{observationCurrent || ' - '}</span>
            </ContentInfo>
          </PointContent>
        )}
        {!isEmpty(imagesCurrent) && (
          <CarouselImage
            slidesPerView={3}
            dir='ltr'
            className='mySwiper'
          >
            {map(imagesCurrent, (item:TScoutPointImage, index:number) => (
              <SwiperSlide key={index}>
                <Img src={item?.image_url} rotate={item.rotation} onClick={() => viewModalImage(index)} />
              </SwiperSlide>
            ))}
          </CarouselImage>
        )}
      </PointContainer>
    </>
  )
}

export default Point
