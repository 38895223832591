import React from 'react'

type TProps = {
  size?: number;
  colorOne?: string;
  colorTwo?: string;
};

const HealthIcon = ({
  size = 35,
  colorOne = '#F37422',
  colorTwo = '#f5f6f6',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      version='1.1'

      viewBox='0 0 72.95 72.95'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <circle fill='none' className='fil0' cx='36.48' cy='36.48' r='36.48' />
        <path stroke={colorTwo} fill={colorTwo} className='fil1 str0' d='M36.68 55.06c0.13,-5.27 0.25,-30.25 -0.28,-33.19 -0.54,-2.96 -1.74,-3.6 -2.95,-4.25' />
        <path stroke={colorTwo} fill={colorOne} className='fil2' d='M35.26 16.78c-0.23,-0.48 -0.49,-1.01 -0.8,-1.54 -0.11,0.02 -0.23,0.03 -0.34,0.03 -1.11,0 -2.02,-0.9 -2.02,-2.02 0,-0.19 0.03,-0.37 0.08,-0.55 -0.8,-0.51 -1.7,-0.83 -2.64,-1.01 -1.31,-0.25 -2.66,-0.25 -4.03,-0.25 -0.04,0.07 -0.07,0.15 0,0.76 0.07,0.61 0.25,1.76 0.79,2.78 0.21,0.4 0.46,0.77 0.76,1.13 0.31,-0.35 0.76,-0.56 1.26,-0.56 0.93,0 1.69,0.76 1.69,1.69 0,0.36 -0.11,0.69 -0.31,0.97 0.65,0.32 1.33,0.55 2,0.72 1.03,0.25 2.07,0.37 2.86,0.42 0.79,0.06 1.32,0.06 1.87,0.06 -0.33,-0.77 -0.64,-1.55 -1.15,-2.62l-0.01 -0z' />
        <path stroke={colorTwo} fill={colorOne} className='fil2' d='M39.61 26.26c0.85,0 1.88,-0.03 2.86,-0.24 -0.09,-0.24 -0.13,-0.5 -0.13,-0.77 0,-1.26 1.02,-2.28 2.28,-2.28 0.62,0 1.18,0.25 1.59,0.65 0.07,-0.09 0.14,-0.18 0.21,-0.27 0.76,-1.04 1.32,-2.23 1.89,-3.42 -0.04,-0.06 -0.1,-0.12 -0.65,-0.31 -0.57,-0.19 -1.64,-0.49 -2.77,-0.45 -1.12,0.04 -2.29,0.43 -3.27,1 -0.98,0.57 -1.76,1.29 -2.41,2.1 -0.65,0.8 -1.18,1.65 -1.55,2.32 -0.37,0.67 -0.6,1.15 -0.82,1.62 0.8,0.04 1.62,0.07 2.77,0.07l0 -0.02z' />
        <line stroke={colorTwo} fill='none' strokeWidth={1.34} strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit={22.9256} className='fil1 str1' x1='19.47' y1='55.2' x2='55.32' y2= '55.31' />
        <path stroke={colorTwo} fill={colorOne} className='fil2' d='M35.35 39.03c-0.59,-1.25 -1.4,-2.82 -2.55,-3.93 -0.13,-0.12 -0.26,-0.24 -0.4,-0.35 -0.15,1.04 -1.05,1.84 -2.13,1.84 -1.19,0 -2.16,-0.97 -2.16,-2.16 0,-0.51 0.17,-0.97 0.47,-1.34 -1.49,-0.27 -3.03,-0.27 -4.59,-0.27 -0.05,0.09 -0.09,0.17 0,0.88 0.09,0.71 0.29,2.04 0.92,3.24 0.62,1.19 1.65,2.27 2.77,3.05 0.15,0.1 0.3,0.2 0.45,0.3 0.36,-0.66 1.06,-1.1 1.86,-1.1 1.17,0 2.13,0.95 2.13,2.13 0,0.14 -0.01,0.28 -0.04,0.41 0.89,0.17 1.75,0.25 2.44,0.29 0.92,0.07 1.54,0.07 2.18,0.07 -0.38,-0.9 -0.74,-1.8 -1.33,-3.05l-0.02 -0z' />
        <path stroke={colorTwo} fill={colorOne} className='fil2' d='M39.61 46.68c1.15,0 2.63,-0.06 3.85,-0.52l0 -0c-0.22,-0.34 -0.35,-0.75 -0.35,-1.18 0,-1.19 0.97,-2.16 2.16,-2.16 0.55,0 1.05,0.21 1.43,0.54 0.62,-0.94 1.11,-1.98 1.61,-3.02 -0.04,-0.06 -0.1,-0.12 -0.65,-0.31 -0.57,-0.19 -1.64,-0.49 -2.77,-0.45 -1.12,0.04 -2.29,0.43 -3.27,1 -0.98,0.57 -1.76,1.29 -2.41,2.1 -0.65,0.8 -1.18,1.65 -1.55,2.32 -0.37,0.67 -0.6,1.15 -0.82,1.62 0.8,0.04 1.62,0.07 2.77,0.07l0 -0.02z' />
      </g>
    </svg>
  )
}

export default HealthIcon