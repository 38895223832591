import React from 'react'

import { Container, Content, Pallet } from './styles'

export const ColorPallet = () => {
  const scales = [
    {
      index: 0,
      color: '#FDFCD8',
      _id: '61b8d777341d7ece82a8a186',
    },
    {
      index: 0.1,
      color: '#EBF6B0',
      _id: '61b8d777341d7ece82a8a187',
    },
    {
      index: 0.2,
      color: '#C2E799',
      _id: '61b8d777341d7ece82a8a188',
    },
    {
      index: 0.3,
      color: '#90D182',
      _id: '61b8d777341d7ece82a8a189',
    },
    {
      index: 0.4,
      color: '#5FB96B',
      _id: '61b8d777341d7ece82a8a18a',
    },
    {
      index: 0.5,
      color: '#36783E',
      _id: '61b8d777341d7ece82a8a18b',
    },
    {
      index: 0.6,
      color: '#36773E',
      _id: '61b8d777341d7ece82a8a18c',
    },
    {
      index: 0.7,
      color: '#23512E',
      _id: '61b8d777341d7ece82a8a18d',
    },
    {
      index: 0.8,
      color: '#003200',
      _id: '',
    },
    {
      index: 0.9,
      color: '#003200',
      _id: '',
    },
    {
      index: 1,
      color: '#003200',
      _id: '61b8d777341d7ece82a8a18e',
    },
  ]
  return (
    <Container>
      <Pallet>
        {scales.map((scale, index) => (
          <Content key={index}>
            <div style={{ backgroundColor: scale.color }} />
            <p>{scale.index}</p>
          </Content>
        ))}
      </Pallet>
    </Container>
  )
}
