import styled from 'styled-components'

type TProps = {
  isYellow?: Boolean
}

export const Container = styled.div`
  display: flex;
  max-width: 250px;
  min-height: 184px;
  position: relative;
  z-index: 500;
  padding: 5px;
  margin-left: 40%;
  margin-top: 11px;
  background-color: rgba(9, 45, 61, 0.9);
  border-radius: 5px;

  @media screen and (max-width: 1500px) {
        margin-left: 50%;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    padding: 10px;
    width: 100%;

    div {
        display: flex;
        justify-content: space-between;
        width: 100%;

        div {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: start;
            margin-top: 5px;
            text-align: left;
        }
    }
`

export const FieldName = styled.span`
    font-weight: bold;
    font-size: 16px;
    color: #f37422;

`
export const FieldInfo = styled.span<TProps>`
    font-size: 12px;
    color: ${({ theme, isYellow }) => isYellow ? theme.colors.YELLOW : theme.colors.WHITE}
`