import React, { useEffect, useRef } from 'react'
import { LotContainer, LotImageWrapper, LotInfo, LotHeader, LotTitle, LotSubtitle } from './styles'
import * as d3 from 'd3'
import { isEmpty } from 'lodash'

type TProps = {
  id:number
  name: string
  area?: number
  group: string
  farm: string
  shape?: any
  color:string
}

const Lot = ({ id, name, area, group, farm, shape, color }:TProps) => {
  const svgRef: any = useRef()

  useEffect(() => {
    if(!isEmpty(shape)){
      const svg = d3.select(svgRef.current)

      const width = 62
      const height = 50

      const projection = d3.geoMercator().fitSize([width, height], shape).precision(100)
      const pathGenerator = d3.geoPath().projection(projection)
      svg.selectAll('.lot')
        .data(shape.features)
        .join('path')
        .attr('class', 'lot')
        .transition()
        .attr('fill', color)
        .attr('d', (feature: any) => pathGenerator(feature))
    }
  }, [shape, color])

  return (
    <LotContainer key={id}>
      <LotImageWrapper>
        <svg ref={svgRef} />
      </LotImageWrapper>
      <LotInfo>
        <LotHeader>
          <LotTitle>{name}</LotTitle>
          <LotTitle>{area?.toFixed(2)} ha</LotTitle>
        </LotHeader>
        <LotSubtitle>{group} - {farm}</LotSubtitle>
      </LotInfo>
    </LotContainer>
  )
}

export default Lot