import React, { Fragment } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import map from 'lodash/map'

import { useAuth } from 'hooks/useAuth'

import { Container, MenuItem, Item, ItemGroup } from './styles'

import {
  MonitoringIcon,
  DashboardIcon,
  AgroDataIcon,
} from 'components/Icons'
import { isEmpty } from 'lodash'

type TItems = {
  key: string
  path: string
  icon: JSX.Element | null
  title: string
  root: boolean
  subMenu: TItems[]
}

enum Uri {
  DASHBOARD = '/dashboard',
  MONITORING = '/monitoring',
  AGRO_DATA = '/agroData',
  ANALYTICAL_PRODUCTS= '/analytical-products'
}

type TProps = {
  doCollapsedSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  isCollapsedSidebar: boolean;
};

const Sidebar = ({ doCollapsedSidebar, isCollapsedSidebar = false }: TProps) => {

  const { userData } = useAuth()
  const { t } = useTranslation('namespaces')

  const location:any = useLocation()
  const history = useHistory()
  const params:any = useParams()

  const onClickGoToPage = (path: string) => () => {path && history.push(path)}

  if(!userData?.role){
    return null
  }

  const items:TItems[] = [
    {
      key: '1',
      path: `${Uri.DASHBOARD}/${params.project}`,
      title: t('dashboard', { ns: namespaces.layout }),
      icon: <DashboardIcon />,
      root: true,
      subMenu: []
    },
    {
      key: '2',
      path: `${Uri.MONITORING}/${params.project}`,
      title: t('monitoring', { ns: namespaces.layout }),
      icon: <MonitoringIcon />,
      root: true,
      subMenu: []
    },
    {
      key: '3',
      path: Uri.AGRO_DATA,
      title: t('agro-data', { ns: namespaces.layout }),
      icon: <AgroDataIcon />,
      root: true,
      subMenu: [{
        key: '1',
        path: `${Uri.ANALYTICAL_PRODUCTS}/${params.project}`,
        title: t('analytical-products', { ns: namespaces.layout }),
        icon: null,
        root: false,
        subMenu: []
      }]
    },
  ]

  return (
    <Container
      onMouseEnter={() => doCollapsedSidebar(false)}
      onMouseLeave={() => doCollapsedSidebar(true)}
      iscollapsedsidebar={isCollapsedSidebar}
    >
      <MenuItem
        selectedKeys={location.pathname}
        mode='inline'
        inlineCollapsed={isCollapsedSidebar}
      >
        <ItemGroup key='g1' iscollapsedsidebar={isCollapsedSidebar}>
          {map(items, ({ path, title, icon, root, subMenu }, index) => {
            return (
              <Fragment key={index}>
                {userData?.role && (
                  <>
                    {root && (
                      <Item
                        key={path}
                        icon={icon}
                        onClick={isEmpty(subMenu) ? onClickGoToPage(path) : () => {}}
                      >
                        {title}
                      </Item>
                    )}
                    {subMenu && !isCollapsedSidebar && (
                      <ItemGroup iscollapsedsidebar={isCollapsedSidebar}>
                        {map(subMenu, ({ path, title }) => (
                          <Item
                            key={path}
                            onClick={onClickGoToPage(path)}
                          >
                            {title}
                          </Item>
                        ))}
                      </ItemGroup>
                    )}
                  </>
                )}
              </Fragment>
            )
          })}
        </ItemGroup>
      </MenuItem>
    </Container>
  )
}

export default Sidebar
