import styled from 'styled-components'
import { Menu } from 'antd'

const { SubMenu: AntSubMenu } = Menu

export const Wrapper = styled.div<{fullscreen: boolean}>`
    display: flex;
    align-items:  ${({ fullscreen }) => fullscreen ? 'center' : 'unset'};;
    flex-direction: ${({ fullscreen }) => fullscreen ? 'row' : 'column'};
    gap: ${({ fullscreen }) => fullscreen ? '1rem' : 'unset'};
    padding-right: ${({ fullscreen }) => fullscreen && '1rem'};
`

export const ProductTypeTitle = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    gap: 6px;
    padding: 16px;
    color: ${({ theme }) => theme.colors.YELLOW};
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    
    svg {
        color: ${({ theme }) => theme.colors.YELLOW};
    }
    `

export const ProductButton = styled.a<{fullscreen: boolean}>`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 12px 16px;
    color: ${({ theme }) => theme.colors.WHITE};
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    border-radius: ${({ fullscreen }) => fullscreen && '8px'};
    border-top: ${({ fullscreen, theme }) => !fullscreen && `solid 1px ${theme.colors.MEDIUM_GRAY_OPACITY}`};
    height: ${({ fullscreen }) => fullscreen ? '38px' : 'unset'} !important;
    border: ${({ fullscreen, theme }) => fullscreen && `solid 1px ${theme.colors.MEDIUM_GRAY_OPACITY}`};
    :last-child {
        margin-right: ${({ fullscreen }) => fullscreen && '1rem'};
        padding: 12px;
    }
    svg {
        color: ${({ theme }) => theme.colors.YELLOW};
    }
`

export const SubMenu = styled(AntSubMenu)`


.ant-dropdown-menu-submenu-title {
    color: ${({ theme }) => theme.colors.WHITE} !important;
    :hover {
        background-color: ${({ theme }) => theme.colors.MOSS_GREEN} !important;
    }
}
`

export const MenuItem = styled(Menu.Item)`
    margin: 0 !important;
    background-color: ${({ theme }) => theme.colors.THEME} !important;
    color: ${({ theme }) => theme.colors.WHITE};
    display: flex;
    align-items: center;
    gap: 1rem;
  :hover {
        background-color: ${({ theme }) => theme.colors.MOSS_GREEN} !important;
    }
    svg {
        color: ${({ theme }) => theme.colors.YELLOW};
        margin: 2px 8px -2px 0;
    }
`

export const DropdownWrapper = styled(Menu)`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.THEME};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    padding: 0.25rem;
`
