import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import Login from 'modules/Login'
import ForgotPassword from 'modules/ForgotPassword'
import ResetPassword from 'modules/ResetPassword'
import PrivacyPolicy from 'modules/PrivacyPolicy'

import Dashboard from 'modules/Dashboard'
import Projects from 'modules/Projects'
import ProjectController from 'modules/ProjectController'
import Field from 'modules/Monitoring/Field'
import Monitoring from 'modules/Monitoring'
import FieldController from 'modules/FieldController'
import FarmGroups from 'modules/FarmGroups'
import Crop from 'modules/Crop'
import AnalyticalProducts from 'modules/AnalyticalProducts'
import Members from 'modules/Members'
import FieldsReportsSetting from 'modules/SettingFieldsReport'
import Users from 'modules/Users'
import PreviewFile from 'modules/Monitoring/Field/MenuContent/Reports/PreviewFile'

const Routes = () => {
  return (
    <Switch>

      <Route exact path='/login' component={Login} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route exact path= '/PrivacyPolicy' component={PrivacyPolicy} />

      <Route exact path='/' component={Projects} isPrivate />
      <Route exact path='/dashboard/:project' component={Dashboard} isPrivate />

      <Route exact path='/project-controller' component={ProjectController} isPrivate />

      <Route exact path='/monitoring/:project' component={Monitoring} />

      <Route exact path='/:type/:project/field/:id' component={Field} isPrivate />
      <Route exact path='/:type/:project/field/:id/products/:products' component={Field} />

      <Route exact path='/monitoring/:project/report/preview-file' component={PreviewFile} isPrivate />

      <Route exact path='/settings/report/:project' component={FieldsReportsSetting} isPrivate />

      <Route exact path='/crops/:project' component={Crop} isPrivate />

      <Route exact path='/analytical-products/:project' component={AnalyticalProducts} isPrivate />

      <Route exact path='/members/:project' component={Members} isPrivate />

      <Route exact path='/farms/:project' component={FarmGroups} isPrivate />

      <Route exact path='/users' component={Users} isPrivate />

      <Route exact path='/dashboard/:project/controller' component={FieldController} isPrivate />

    </Switch>
  )
}

export default Routes
