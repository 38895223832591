import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.THEME};
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.DARK_BLUE};
  font-size: 32px;
  font-weight: 400;
`

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.DARK_BLUE};
  font-size: 14px;
  font-weight: 300;
  margin-top: 30px;
`
