import React, { useState, useMemo, useRef } from 'react'
import { DownOutlined, UpOutlined, CaretDownOutlined, CaretUpOutlined, FolderFilled } from '@ant-design/icons'
import { TGroups, TGetLotes } from 'types/types'
// import useOnClickOutside from 'hooks/useOnClickOutside'
import DescriptionFarm from './DescriptionFarm'
import ListFarms from './ListFarms'

import { Container, ButtonListFarm, DropdownContainer, ButtonDescription } from './styles'

type TProps ={
  label?:string
  handleLabel:(name:string)=>void
  lisTGroupsFarm: TGroups[] | [] | undefined
  getLotes({ type, id }:TGetLotes):void
  setMode:(mode:string)=>void
  mode:string
  groupOrFarmSelected: any
  display:boolean
  disabledGroups?: boolean
  projectSelected:string
  totalArea: number
  totalFarms: number
  totalFields:number
}

const SelectFarm = ({
  label,
  handleLabel,
  lisTGroupsFarm,
  getLotes,
  mode,
  setMode,
  groupOrFarmSelected,
  display = true,
  disabledGroups = false,
  projectSelected,
  totalArea,
  totalFarms,
  totalFields
}:TProps) => {

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>

  const [isVisible, setIsVisible] = useState<boolean>(true)

  // useOnClickOutside(ref, () => setIsVisible(false))

  const handleListFarm = () => {
    setMode('menu')
    setIsVisible(old => !old)
  }

  const handleDescription = () => {
    setMode('description')
    setIsVisible(old => !old)
  }

  const componentRender = useMemo(
    () => mode === 'menu' ?
      <DropdownContainer ref={ref}>
        <ListFarms
          groups={lisTGroupsFarm}
          project={projectSelected}
          getLotes={getLotes}
          setLabel={handleLabel}
          disabledGroups={disabledGroups}
        />
      </DropdownContainer>
      :
      <DropdownContainer ref={ref}>
        <DescriptionFarm
          totalArea={totalArea}
          totalFarms={totalFarms}
          totalFields={totalFields}
          groupOrFarmSelected={groupOrFarmSelected}
          projectSelected={projectSelected}
        />
      </DropdownContainer>
    , [mode, groupOrFarmSelected, lisTGroupsFarm, projectSelected] )

  return (
    <Container display={display}>
      <ButtonListFarm>
        <FolderFilled style={{ fontSize: '17px' }} />
        <strong>{label}</strong>
        {isVisible && mode === 'menu' ?
          <UpOutlined onClick={handleListFarm} style={{ fontSize: '13px' }} />
          :
          <DownOutlined onClick={handleListFarm} style={{ fontSize: '13px' }} />}
        <ButtonDescription>{
          isVisible && mode === 'description' ?
            <CaretUpOutlined onClick={handleDescription} />
            :
            <CaretDownOutlined onClick={handleDescription} />}
        </ButtonDescription>
      </ButtonListFarm>
      { isVisible && componentRender }
    </Container>
  )
}
export default SelectFarm