import React, { useState, useEffect } from 'react'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import UploadButton from 'components/UploadButton'

import Button from 'components/Button'
import useSettingsReports from 'repositories/useSettingsReports'

import { initialValues, validation, TFormReport } from './validations'

import {
  Container,
  UploadContainer,
  Content,
  HeaderTitle,
  ContentInformations,
  ImageViewControlers,
  Title,
  Viewer,
  TopContainer,
  ContainerBtn,
} from './styles'
import { useParams } from 'react-router-dom'
import { apiBackEnd } from 'services/axios'

const SettingFieldsReport = () => {
  const [form] = Form.useForm<TFormReport>()
  const [imageCover, setImageCover] = useState<any>()
  const [imageRight, setImageRight] = useState<any>()
  const [imageLeft, setImageLeft] = useState<any>()

  const { t } = useTranslation('namespaces')
  const reportSettingApi = useSettingsReports()
  const { project }:any = useParams()


  const onFinish = async (values: TFormReport) => {
    const params = {
      ...values,
      image_cover: values.image_cover[0],
      image_right: values.image_right[0],
      image_left: values.image_left[0],
      imageCover,
      imageRight,
      imageLeft
    }

    await reportSettingApi.changeImagesReports(params, project)
  }
  const getImages = async () => {
    const response = await reportSettingApi.getImagesReports(project)
    const data = response?.data
    setImageCover(data?.report_cover_image)
    setImageLeft(data?.report_recommendations_left_image)
    setImageRight(data?.report_recommendations_right_image)
  }

  useEffect(() => {
    getImages()
  }, [apiBackEnd.defaults.baseURL])

  return (
    <Container>
      <Form
        initialValues={initialValues}
        name='form-report'
        form={form}
        autoComplete='off'
        onFinish={onFinish}
        style={{ width: '100%' }}
      >
        <TopContainer>
          <Title>{t('view settings reports', { ns: namespaces.layout })}</Title>
          <ContainerBtn>
            <Button
              id='button-cancel'
              type='default'
              label={t('cancel', { ns: namespaces.common })}
              onClick={() => {setImageCover('')
                setImageLeft('')
                setImageRight('')} }
              htmlType='reset'
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </TopContainer>
        <Content>
          <UploadContainer>
            <HeaderTitle>
              <Title>{t('cover image', { ns: namespaces.layout })}</Title>
            </HeaderTitle>
            <ContentInformations>
              <ImageViewControlers>
                <Title>{t('visualization', { ns: namespaces.layout })}</Title>
                <Viewer>
                  <img src={imageCover?.url} alt='' />
                </Viewer>
              </ImageViewControlers>
              <ImageViewControlers>
                <Title>{t('current file', { ns: namespaces.layout })}</Title>
                <UploadButton
                  disable={false}
                  rules={validation('image_cover', t)}
                  name='image_cover'
                  label=''
                  accept='image/png, image/jpeg'
                  type='default'
                  isImage={true}
                  onchangeCreateUrl={setImageCover}
                />
                <p>{t('alert format', { ns: namespaces.layout })}</p>
                <Button
                  id='button-cancel'
                  type='default'
                  label={t('reset image', { ns: namespaces.layout })}
                  onClick={() => {setImageCover('')
                    form.setFieldsValue({ image_cover: [] })} }
                />
              </ImageViewControlers>
            </ContentInformations>
          </UploadContainer>
          <UploadContainer>
            <HeaderTitle>
              <Title>{t('right image', { ns: namespaces.layout })}</Title>
            </HeaderTitle>
            <ContentInformations>
              <ImageViewControlers>
                <Title>{t('visualization', { ns: namespaces.layout })}</Title>
                <Viewer>
                  <img src={imageRight?.url} alt='' />
                </Viewer>
              </ImageViewControlers>
              <ImageViewControlers>
                <Title>{t('current file', { ns: namespaces.layout })}</Title>
                <UploadButton
                  disable={false}
                  rules={validation('image_right', t)}
                  name='image_right'
                  label=''
                  accept='image/png, image/jpeg'
                  type='default'
                  isImage={true}
                  onchangeCreateUrl={setImageRight}
                />
                <p>{t('alert format', { ns: namespaces.layout })}</p>
                <Button
                  id='button-cancel'
                  type='default'
                  label={t('reset image', { ns: namespaces.layout })}
                  onClick={() => {setImageRight('')
                    form.setFieldsValue({ image_right: [] })} }
                />
              </ImageViewControlers>
            </ContentInformations>
          </UploadContainer>
          <UploadContainer>
            <HeaderTitle>
              <Title>{t('left image', { ns: namespaces.layout })}</Title>
            </HeaderTitle>
            <ContentInformations>
              <ImageViewControlers>
                <Title>{t('visualization', { ns: namespaces.layout })}</Title>
                <Viewer>
                  <img src={imageLeft?.url} alt='' />
                </Viewer>
              </ImageViewControlers>
              <ImageViewControlers>
                <Title>{t('current file', { ns: namespaces.layout })}</Title>
                <UploadButton
                  disable={false}
                  rules={validation('image_left', t)}
                  name='image_left'
                  label=''
                  accept='image/png, image/jpeg'
                  type='default'
                  isImage={true}
                  onchangeCreateUrl={setImageLeft}
                />
                <p>{t('alert format', { ns: namespaces.layout })}</p>
                <Button
                  id='button-cancel'
                  type='default'
                  label={t('reset image', { ns: namespaces.layout })}
                  onClick={() => {setImageLeft('')
                    form.setFieldsValue({ image_left: [] })} }
                />
              </ImageViewControlers>
            </ContentInformations>
          </UploadContainer>
        </Content>
      </Form>
    </Container>
  )
}

export default SettingFieldsReport
