import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

export const initialValues: any = {
  farm: {
    label: '', value: null
  },
  field: {
    label: '', value: null
  },
  crop: undefined,
  culture: undefined,
  image_type: undefined,
  monitoring_date: undefined,
  report_scout_points: []
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'farm':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('farm', { ns: namespaces.common }) })
      }] as Rule[]

    case 'field':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('field', { ns: namespaces.common }) })
      }] as Rule[]

    case 'crop':
      return [{
        required: false,
        message: t('field is required', { ns: namespaces.common, label: t('crop', { ns: namespaces.common }) })
      }] as Rule[]

    case 'culture':
      return [{
        required: false,
        message: t('field is required', { ns: namespaces.common, label: t('culture', { ns: namespaces.common }) })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}
