import React from 'react'

type TProps = {
  size?: number;
  color?: string;
  rest?: any
};

const FilterIcon = ({
  size = 18,
  color = '#F37422',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      version='1.1'
      viewBox='0 0 18 18'
    >
      <path d='M3.98438 3H14.0156L9 9.28125L3.98438 3ZM1.26562 2.625C3.28125 5.20312 6.98438 9.98438 6.98438 9.98438V15.9844C6.98438 16.5469 7.45312 17.0156 8.01562 17.0156H9.98438C10.5469 17.0156 11.0156 16.5469 11.0156 15.9844V9.98438C11.0156 9.98438 14.7188 5.20312 16.7344 2.625C17.25 1.96875 16.7812 0.984375 15.9375 0.984375H2.0625C1.21875 0.984375 0.75 1.96875 1.26562 2.625Z' fill={color} />
    </svg>
  )
}

export default FilterIcon