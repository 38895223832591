import React, { useMemo, useContext } from 'react'
import { MdDeleteForever } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { DialogContext } from 'dialog/context'
import { isJSONStrig, isValidURL } from 'utils/helpers'
import { MenuCollapse, MenuPanel, MenuWrapper, MenuTitle, MenuItem } from './styles'
import { useProductContext } from 'hooks/useProductContext'
import ModalRemoveReport from '../Modals/ModalRemoveReport'
import DraggabLeTab from './DraggabLeTab'
import ModalRemoveRecommendation from '../Modals/ModalRemoveRecommendation'
import ModalRemoveAttach from '../Modals/ModalRemoveAttach'
import isEmpty from 'lodash/isEmpty'

type TProps={
  productAnalysis: any
  projectSelected: string
}

const MenuController = ({ productAnalysis, projectSelected }:TProps) => {
  const {
    chartSelected,
    setChartSelected,
    productProject,
    fullscreen,
  } = useProductContext()
  const { createDialog } = useContext(DialogContext)

  const { general, environment, analytical_summary } = productAnalysis
  const { attachments, recommendations, reports, specific: { environment_map }, id: product_id } = productProject

  const { t } = useTranslation('namespaces')

  const menuBase: any = [
    {
      collapseTitle: `${t('analytical summary', { ns: namespaces.pages.field })}`,
      collapseKey: 'analytical summary',
      submenuItems: [
        {
          label: `${t('all', { ns: namespaces.common })}`,
          value: 'all'
        },
      ]
    },
    {
      collapseTitle: `${t('general analysis', { ns: namespaces.pages.field })}`,
      collapseKey: 'general analysis',
      submenuItems: [
        {
          label: `${t('all', { ns: namespaces.common })}`,
          value: 'all'
        }
      ]
    },
    {
      collapseTitle: `${t('analysis by environment', { ns: namespaces.pages.field })}`,
      collapseKey: 'environment analysis',
      submenuItems: [
        {
          label: `${t('all', { ns: namespaces.common })}`,
          value: 'all'
        }
      ]
    },
  ]

  const productAnalysisControllerMenu = useMemo(() => {

    // Add analitical summary charts in the menu
    if(!isEmpty(analytical_summary?.histogram.charts)){
      analytical_summary.histogram.charts.forEach((analyticalItem: any, index: number) => (
        menuBase[0].submenuItems.push(
          {
            label: analyticalItem?.name,
            value: (index).toString(),
          })
      ))
    }

    // Add general analysis charts in the menu
    if (general){
      general.forEach((generalItem: any, index: number) => (
        menuBase[1].submenuItems.push(
          {
            label: generalItem?.title,
            value: (index).toString(),
          })
      ))
    }

    // Add environment analysis charts in the menu
    if (environment) {
      environment.forEach((environmentItem: any, index: number) => (
        menuBase[2].submenuItems.push(
          {
            label: environmentItem?.title,
            value: (index).toString(),
          })
      ))

      // Add environment map in the menu
      if(environment_map){
        menuBase.push(
          {
            collapseTitle: t('environment map', { ns: namespaces.pages.field }),
            collapseKey: environment_map.url,
          })
      }

      // Add recommendations in the menu
      if(recommendations){
        recommendations.forEach((recommendation: any) => (
          menuBase.push(
            {
              collapseTitle: recommendation.title,
              collapseKey: JSON.stringify({
                content: recommendation.content,
                title: recommendation.title,
                suggestion: recommendation.suggestion,
                id: recommendation.id
              }),
              recommendationId: recommendation.id
            })
        ))
      }

      // Add reports in the menu
      if(reports){
        reports.forEach((reportItem: any) => (
          menuBase.push(
            {
              collapseTitle: reportItem.file.filename,
              collapseKey: reportItem.file.url,
              reportId: reportItem.id,
              reportName: reportItem.file.filename,
            })
        ))
      }

      // Add attachments in the menu
      if(attachments) {
        attachments.forEach((attachmentItem: any) => (
          menuBase.push(
            {
              collapseTitle: attachmentItem.name,
              collapseKey: attachmentItem.file.url,
              attachId: attachmentItem.id,
              attachName: attachmentItem.name
            })
        ))
      }
    }

    return menuBase
  }, [productProject])


  const handleRemoveAttach = (attachId: number, attachName: string) => {
    createDialog({
      id: 'modal-remove-report',
      open: false,
      Component: ModalRemoveAttach,
      props: {
        productSelected: product_id,
        fieldId: productProject?.field?.id,
        attachId,
        attachName,
        projectSelected
      }
    })
  }
  const handleRemoveReport = (reportId: number, reportName: string) => {
    createDialog({
      id: 'modal-remove-report',
      open: false,
      Component: ModalRemoveReport,
      props: {
        productSelected: product_id,
        fieldId: productProject?.field?.id,
        reportId,
        reportName,
        projectSelected
      }
    })
  }

  const handleRemoveRecommendation = (recommendationId: number, recommendationName: string) => {
    createDialog({
      id: 'modal-remove-recommendation',
      open: false,
      Component: ModalRemoveRecommendation,
      props: {
        productSelected: product_id,
        fieldId: productProject?.field?.id,
        recommendationId,
        recommendationName,
        projectSelected
      }
    })
  }

  return (
    <>
      {fullscreen ? (
        <DraggabLeTab productAnalysisControllerMenu={productAnalysisControllerMenu} projectSelected={projectSelected} />
      ) : (
        <MenuCollapse accordion ghost onChange={e => setChartSelected({ menu: (e || 'analytical summary'), submenu: 'all' })}>
          {productAnalysisControllerMenu?.map((collapse: any) => (
            <MenuPanel
              header={
                <MenuTitle>
                  {(isValidURL(collapse.collapseKey) && collapse.attachId) &&
                    <MdDeleteForever onClick={() => handleRemoveAttach(collapse.attachId, collapse.attachName)} />
                  }
                  {(isJSONStrig(collapse.collapseKey) && collapse.recommendationId) &&
                    <MdDeleteForever onClick={() => handleRemoveRecommendation(collapse.recommendationId, collapse.collapseTitle)} />
                  }
                  {(isValidURL(collapse.collapseKey) && collapse.reportId) &&
                    <>
                      <MdDeleteForever onClick={() => handleRemoveReport(collapse.reportId, collapse.reportName)} />
                      {t('report', { ns: namespaces.pages.field })} {' - '}
                    </>
                  }
                  {collapse.collapseTitle}
                </MenuTitle>}
              key={collapse.collapseKey}
            >
              <MenuWrapper selectedKeys={[chartSelected.submenu]} onSelect={e => setChartSelected({ ...chartSelected, submenu: (e.key || 'all') })}>
                {collapse?.submenuItems?.map((link: any) => (
                  <MenuItem key={link.value}>
                    <div dangerouslySetInnerHTML={{ __html: link.label }} />
                  </MenuItem>
                ))}
              </MenuWrapper>
            </MenuPanel>
          ))}
        </MenuCollapse>
      )}
    </>
  )
}

export default MenuController