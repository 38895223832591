import styled from 'styled-components'
import { DatePicker } from 'antd'

export const Content = styled.div`
  width: 35px;
  height: 100%;
  align-items: center;
  display: flex;
  padding: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};

  
`
export const Date = styled(DatePicker)``