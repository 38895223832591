import React, { useMemo, useRef, useState } from 'react'
import Highcharts from 'highcharts'
import {
  HighchartsChart,
  Chart,
  HighchartsProvider,
  XAxis,
  YAxis,
  Title,
  Tooltip,
  Legend,
  ColumnSeries,
  PlotBand,
} from 'react-jsx-highcharts'
import { v1 as uuidv1 } from 'uuid'
import { Wrapper, ChartWrapper, Description } from './styles'

type EnvironmentChartProps = {
  title: string,
  description: string,
  series: Array<any>,
  idealValues: Array<any>,
  yMin: number,
  yMax: number,
  xLabel: string,
  yLabel: string,
  colors: any,
  ungrouped: boolean,
};

const EnvironmentChart: React.FC<EnvironmentChartProps> = ({
  title,
  description,
  series,
  idealValues,
  yMin,
  yMax,
  xLabel,
  yLabel,
  colors,
  ungrouped
}) => {
  const [chartWidth, setChartWidth] = useState<number>()
  const containerRef: any = useRef(0)

  const showToolTip = (e: any) => {
    const x = e.target && e.target.x

    Highcharts.charts.forEach(chart => {
      if (chart && chart.series && chart.series) {
        chart.tooltip.hide()

        const points: Array<any> = []

        chart.series.forEach(serie => {
          serie.data.forEach(value => {
            if (value.x === x && !Number.isNaN(value.y)) {
              points.push(value)
            }
          })
        })

        if (points.length > 0) {
          chart.tooltip.refresh(points)
        }
      }
    })
  }

  const hideToolTip = () => {
    Highcharts.charts.forEach(chart => {
      if (chart) {
        chart.tooltip.hide()
      }
    })
  }

  const categories = useMemo(() => {
    const categoriesArray: Array<any> = []
    series.forEach(serie => {
      serie.values.forEach((value: any) => {
        if (value.x && !categoriesArray.includes(value.x))
          categoriesArray.push(value.x)
      })
    })
    return categoriesArray
  }, [])

  const plotOptions = {
    column: { grouping: !ungrouped, shadow: false },
    series: {
      animation: false,
      point: {
        events: {
          mouseOver: (e: any) => showToolTip(e),
          mouseOut: () => hideToolTip(),
        },
      },
    },
  }

  return (
    <Wrapper>
      <ChartWrapper ref={containerRef}>
        <HighchartsProvider Highcharts={Highcharts}>
          <HighchartsChart plotOptions={plotOptions}>
            <Chart width={chartWidth} onRender={() => setChartWidth(containerRef?.current?.clientWidth)} />

            <Title>{title}</Title>
            {series && series.length > 1 && <Legend />}
            <Tooltip shared />

            <XAxis categories={categories} gridLineWidth={1} crosshair>
              {xLabel && (
                <XAxis.Title>{xLabel}</XAxis.Title>
              )}
            </XAxis>

            <YAxis softMin={yMin || 0} softMax={yMax || undefined}>
              <YAxis.Title>{yLabel}</YAxis.Title>

              {series && series?.map((serie, i) => (
                <ColumnSeries
                  key={uuidv1()}
                  name={serie.label || yLabel}
                  colorByPoint
                  dataLabels={{ enabled: true }}
                  data={(serie.values || []).map((value: any) => ({
                    y: value.y,
                    x: categories.indexOf(value.x),
                    color: colors[value.x][i],
                  }))}
                />
              ))}

              {idealValues && (
                <PlotBand from={idealValues[0]} to={idealValues[1]} color='rgba(220, 220, 220, 0.3)' />
              )}
            </YAxis>
          </HighchartsChart>
        </HighchartsProvider>
      </ChartWrapper>
      <Description>
        {description}
      </Description>
    </Wrapper>
  )
}

export default EnvironmentChart
