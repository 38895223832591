import styled from 'styled-components'

type TContent = {
  iscollapsedsidebar?: boolean
}

export const Container = styled.div`
  display: flex;
`

export const Main = styled.div`
  background-color: ${({ theme }) => theme.colors.THEME};
  padding-top: 80px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
      margin-top: 40px;
  }
`

export const Content = styled.div<TContent>`
  padding-left: 55px;
  position: ${({ iscollapsedsidebar }) => iscollapsedsidebar ? 'absolute' : 'relative'};
  height: calc(100vh - 80px);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.THEME};
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.THEME};
    border-bottom-right-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.YELLOW};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    padding-left: 82px;
  }
`
