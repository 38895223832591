import React, { useCallback, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { EyeFilled } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'
import { useParams } from 'react-router-dom'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import { useGlobalContext } from 'hooks/useGlobalContext'

import useProduct from 'repositories/useProduct'
import useFields from 'repositories/useFields'
import useGroups from 'repositories/useGroups'

import { DialogContext } from 'dialog/context'

import { formatDateToView, removeTimeFromDateTime } from 'utils/helpers'

import DataTable from 'components/DataTable'
import SelectGroupsByFarms from 'components/SelectGroupsByFarms'
import { SoloCheck } from 'components/Icons'

import ModalCreateProducts from 'modules/Monitoring/Field/MenuContent/FieldProducts/ModalNewProduct'

import { Container, IconTable } from './styles'

const AnalyticalProducts = () => {
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const productApi = useProduct()
  const fieldsApi = useFields()
  const groupsApi = useGroups()
  const { saveFieldSelected } = useGlobalContext()

  const localLanguage = localStorage.getItem('agfarmusLanguage')
  const params:any = useParams()

  const [makeRequest, setMakeRequest] = useState<boolean>(false)
  const [searchProducts, setSearchProducts] = useState<string>('')
  const [groupsByFarmId, setGroupsByFarmId] = useState<number | null>()
  const [typeSelected, setTypeSelected] = useState<string>()
  const [groups, setGroups] = useState<any>()

  const filterData = (products:any) => {
    return map(products, item => ({
      actable_type: item?.actable_type || ' - ',
      farm_id: item.farm?.name || ' - ',
      field_id: item?.field?.name || ' - ',
      fieldId: item?.field?.id || ' - ',
      reference_year: item?.reference_year || ' - ',
      created_at: item?.created_at,
      key: item.id
    }))
  }

  const getProducts = async (pagination?:TablePaginationConfig, changeTotalPages?:any) => {
    const resProducts = await productApi.getProduct({
      q: searchProducts,
      projectSelected: params?.project,
      typeProduct: '',
      page: pagination?.current || 1,
      size: pagination?.pageSize || 10,
      type: typeSelected,
      id: groupsByFarmId
    })

    changeTotalPages(resProducts?.meta?.total_count)
    setMakeRequest(false)

    return filterData(resProducts.projects)
  }

  const createProductsModal = useCallback(
    () => {
      createDialog({
        id: 'modal-create-product',
        open: false,
        Component: ModalCreateProducts,
        props: {
          handleSave: () => {},
          newProduct: true,
          callback: () => setMakeRequest(true),
          projectSelected: params?.project,
          fieldSelected: null
        }
      })
    }, [])

  const handleChangeGroup = useCallback(
    (type:'farm' | 'farm_group' | 'all', id:number | null, updateTable?:any) => {
      setGroupsByFarmId(id)
      setTypeSelected(type)
      updateTable()
      setMakeRequest(true)
    },
    [])

  const FilterComponent = useCallback(
    (updateTable:any) => {
      return (
        <SelectGroupsByFarms
          groups={groups}
          id='select-groups'
          handleChange={(type, id) => handleChangeGroup(type, id, updateTable)}
        />
      )
    }, [groups])

  const getField = useCallback(
    async (product:any) => {
      const response = await fieldsApi.getById(product.fieldId, params?.project)

      if (!isEmpty(response?.data) ){
        saveFieldSelected(response?.data)
        window.open(`/analytical-products/${params?.project}/field/${response?.data.id}/products/${product?.key}`, '_blank')
      }
    }, [params])

  const seachGroups = useCallback(
    async (project:string) => {

      const response = await groupsApi.getFields({ project, type: 'all' })
      if (!response?.data) return

      setGroups(response?.data.groups)

    }, [params?.project])

  useEffect(() => {
    seachGroups(params.project)
  }, [params.project ])

  const columns = [
    {
      title: t('type product', { ns: namespaces.pages.analyticalProducts }),
      dataIndex: 'actable_type',
      key: 'actable_type',
      render: (text:string, ) => {

        return <IconTable>
          <SoloCheck /> {text}
        </IconTable>
      }
    },
    {
      title: t('farm', { ns: namespaces.common }),
      dataIndex: 'farm_id',
      key: 'farm_id',
    },
    {
      title: t('field', { ns: namespaces.pages.field }),
      dataIndex: 'field_id',
      key: 'field_id',
    },
    {
      title: t('harvest / year', { ns: namespaces.pages.cropRotationst }),
      dataIndex: 'reference_year',
      key: 'reference_year',
    },
    {
      title: t('created in', { ns: namespaces.common }),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text)
      }
    },
    {
      title: t('actions', { ns: namespaces.common }),
      dataIndex: 'actions',
      key: 'actions',
      render: (text:string, record:any) => {
        return <EyeFilled onClick={() => getField(record)} />
      }
    },
  ]

  return (
    <Container>
      <DataTable
        getDataFromApi={getProducts}
        columns={columns}
        create={createProductsModal}
        labelBtnCreate={ t('new analytical products', { ns: namespaces.pages.analyticalProducts })}
        title={t('new analytical products', { ns: namespaces.pages.analyticalProducts })}
        labelSearch={t('search for a products', { ns: namespaces.pages.analyticalProducts })}
        makeRequest={makeRequest}
        search={searchProducts}
        setSearch={setSearchProducts}
        renderComponentFilter={FilterComponent}
      />
    </Container>
  )
}

export default AnalyticalProducts
