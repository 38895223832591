import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { TFormUser } from 'types/users'
import isEmpty from 'lodash/isEmpty'

type TPayloadGetUsers = {
  page?: number
  size?: number
  q?:string
  getType?: 'all' | 'pagination'
}

const useUsers = () => {
  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const getAllUsers = async ({ page, size, q, getType }:TPayloadGetUsers) => {
    const url = getType === 'all' ? 'v2/users' : `v2/users?page=${page}&size=${size}&q=${q}`
    const response = await rest.get(url)

    return response?.data
  }

  const createUser = async (params:TFormUser) => {
    const url = 'v2/users'

    const formData = new FormData()

    if(!isEmpty(params?.image_file[0]?.originFileObj)){
      formData.append('image', params?.image_file[0]?.originFileObj)
    }
    formData.append('name', params?.name)
    formData.append('locale', params?.locale)
    formData.append('email', params?.email)
    formData.append('role', params?.role)

    const response = await rest.post(url, formData )

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  const getUser = async (idUser:number) => {
    const url = `v2/users/${idUser}`
    const response = await rest.get(url)

    return response?.data
  }

  const updateUser = async (params:TFormUser, idUser:number) => {
    const url = `v2/users/${idUser}`
    const formData = new FormData()

    if (!isEmpty(params.image_file[0]?.originFileObj)) {
      formData.append('image', params?.image_file[0]?.originFileObj)
    }

    if (isEmpty(params.image_file)) {
      formData.append('remove_image', 'true')
    }


    formData.append('name', params?.name)
    formData.append('locale', params?.locale)
    formData.append('role', params?.role)

    const response = await rest.put(url, formData)

    if(response?.status === 200){
      notify({ message: t('item updated successfully', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  const deleteUser = async (idUser:number) => {
    const url = `v2/users/${idUser}`
    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with succes', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  return {
    getAllUsers,
    createUser,
    getUser,
    updateUser,
    deleteUser
  }
}

export default useUsers
