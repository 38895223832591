import React, { useEffect, useMemo, useState } from 'react'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons'
import { Wrapper, UploadButton, FormItem, DeleteField, PreviewImage } from './styles'
import { Rule } from 'antd/lib/form'
import { toast } from 'react-toastify'

interface ImageUploadProps {
  disable?: boolean
  maxFile?: number
  isMultipleFile?: boolean
  label:string
  name:string
  rules?: Rule[] | undefined
  accept?: string
  type?: 'primary' | 'default' | 'ghost' | 'dashed' | 'link' | 'text' | undefined
  isImage?: Boolean
  onRemove?: Function
  onChangeCallback?: Function
  defaultPreviewUrl?: string
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  disable,
  maxFile = 1,
  isMultipleFile = false,
  rules,
  name,
  label,
  accept = 'image/jpeg, image/png',
  onRemove,
  onChangeCallback,
  defaultPreviewUrl
}) => {
  const { t } = useTranslation('namespaces')
  const [previewUrl, setPreviewUrl] = useState<any>(defaultPreviewUrl)

  const props = useMemo(() => ({
    maxCount: maxFile,
    multiple: isMultipleFile,
    accept,
    onChange( event : any ) {
      const url = window.URL.createObjectURL(event.file)
      const erroNotify = () => {
        toast.error(t('file too heavy, maximum size 1mb', { ns: namespaces.pages.projectController }))
        onRemove && onRemove()
      }
      event.file.size > 1048576 ?
        erroNotify() :
        setPreviewUrl(url)
      onChangeCallback && onChangeCallback()
    },
    beforeUpload() {return false },
  }), [disable, maxFile, isMultipleFile])

  const normFile = (e:any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleResetField = () => {
    setPreviewUrl(null)
    onRemove && onRemove()
  }

  useEffect(() => {
    setPreviewUrl(defaultPreviewUrl)
  }, [defaultPreviewUrl])

  return (
    <Wrapper>


      <FormItem
        label={label}
        name={name}
        rules={rules}
        getValueFromEvent={normFile}
        valuePropName='fileList'
      >
        {previewUrl ? (
          <PreviewImage>
            <DeleteField onClick={handleResetField} />
            <img src={previewUrl} />
          </PreviewImage>
        ) : (
          <UploadButton
            {...props}
            maxCount={1}
            accept={accept}
            type='drag'
          >
            <UploadOutlined /> {t('change image', { ns: namespaces.layout })}
          </UploadButton>
        )}
      </FormItem>
    </Wrapper>
  )
}

export default ImageUpload