import { namespaces } from 'i18n/i18n.constants'

export const es = {
  [namespaces.layout]: {
    dashboard: 'Panel',
    monitoring: 'Supervisión',
    'change image': 'Cambiar imagen',
    'reset image': 'Quita la imagen',
    'visualization': 'Visualización',
    'current file': 'Archivo actual',
    'cover image': 'Imagen de portada',
    'right image': 'Imagen derecha (recomendaciones)',
    'left image': 'imagen izquierda (recomendaciones)',
    'alert format': '* imágenes aceptadas en los formatos de .JPEG e .PNG',
    'view settings reports': 'Ver todas las configuraciones del informe',
    gis: 'Gis',
    'field report': 'Informes de campos',
    'settings field report': 'Configuración de informes',
    'agro intelligence': 'Agrointeligencia',
    'operational management': 'Gestión operativa',
    'my informations': 'Mis informaciones',
    'my profile': 'Mi perfil',
    exit: 'Salir',
    climate: 'Clima',
    forecast: 'Pronóstico',
    'weather history': 'Historia del tiempo',
    pluvius: 'PluviUs',
    'agro-data': 'Agrodata',
    'land-report': 'Informes de campo',
    'analytical-products': 'Productos analíticos',
    'admin': 'Admin',
    field: 'Talhão',
    'project': 'Proyecto',
    products: 'Producto',
    type: 'Tipo',
    'members': 'Miembros',
    'farms': 'Grupo de granjas',
    crops: 'Zafras',
    'platform management': 'Gestión de la plataforma',
    'users': 'Usuarios',
    'satellite-monitoring': 'Monitoreo Satelital',
    reports: 'Informes',
    'crop-rotations': 'Rotaciones de cultura',
    'field-report': 'Informes de campo'
  },
  [namespaces.common]: {
    'new register': 'Registrar la información de un nuevo registro',
    'transfer group': 'Transferir el Grupo seleccionado a otro proyecto',
    'new group register': 'Registre la información para un nuevo grupo de fincas',
    'file': 'Archivo',
    'placeholder name': 'Ingresa un nombre',
    search: 'Busca lo que buscas',
    login: 'Iniciar sesión',
    password: 'Contraseña',
    cancel: 'Cancelar',
    name: 'Nombre',
    address: 'Habla a',
    neighborhoods: 'Barrio',
    city: 'Ciudad',
    state: 'Expresar',
    area: 'Área',
    'total area': 'Área total',
    'total fields': 'Parcelas totales',
    'quick summary': 'Sumario rápido',
    status: 'Estado',
    email: 'Correo electrónico',
    save: 'Salvar',
    'clean': 'Limpio',
    'please wait': 'Espere por favor...',
    loading: 'Carregando ...',
    'wait': 'Espere',
    'loading data farm': 'Estamos cargando los datos de la granja...',
    'loading data field': 'Estamos cargando datos del terreno...',
    'delete': 'Borrar',
    'password is required': 'Se requiere contraseña',
    'user is required': 'El usuario es obligatorio',
    'full name is required': 'Se requiere el nombre completo',
    'phone is required': 'El teléfono es obligatorio',
    'it is not a valid email': 'No es un email valido',
    actions: 'Acciones',
    'name file': 'Nombre del archivo',
    'added to': 'Añadido en',
    type: 'Tipo',
    edit: 'Editar',
    size: 'Tamaño',
    'are you sure you want to delete the document?': '¿Estás seguro de que quieres borrar el documento?',
    'to view': 'Ver',
    close: 'Cerrar',
    filter: 'Filtro',
    observation: 'Observación',
    'start date': 'Fecha de inicio',
    'end date': 'Fecha de finalización',
    english: 'Inglés',
    spanish: 'Español',
    portuguese: 'Portugués',
    'all farms': 'Todas las granjas',
    send: 'Enviar',
    'back-to-login': 'Volver al inicio de sesión',
    'import a file': 'Importar un archivo',
    'import file': 'Importar archivo',
    'coordinates': 'Coordenadas',
    'latitude': 'Latitud',
    'longitude': 'Longitud',
    'group': 'Grupo',
    'farm': 'Granja',
    'approximate area': 'Área aproximada',
    'imported file': 'Archivo importado',
    'processed area': 'Área procesada',
    field: 'Campo',
    'not informed': 'No informado',
    year: 'Año',
    'select year': 'Seleccione el año',
    sample: 'Muestra',
    'below': 'Abajo',
    'ideal': 'Ideal',
    'above': 'Encima',
    new: 'Nuevo',
    all: 'Todos',
    title: 'Título',
    suggestion: 'Sugerencia',
    content: 'Contenido',
    'new something': 'Nuevo {{label}}',
    'new something a': 'Nueva {{label}}',
    'remove something': 'Eliminar {{label}}',
    'attachment': 'Anexo',
    'archive': 'Archivo',
    'starts at': 'Inicia en',
    'ends at': 'Termina en',
    'created in': 'Creado en',
    culture: 'Cultura',
    'culture two': 'Cultivo',
    crop: 'Zafra',
    crops: 'Zafras',
    'are you sure you want to delete': '¿Estás seguro de que quieres eliminar {{label}}?',
    'to complete type and confirm': 'Para completar, escribir {{label}} y confirmar',
    'field is required': 'El campo {{label}} es obligatorio',
    'select date': 'Seleccione fecha',
    'no items found': 'No se encontraron artículos {{label}}',
    'created at': 'Creado en',
    profile: 'Perfil',
    'yes': 'Sí',
    'no': 'No',
    'user type': 'Tipo de usuario',
    'field of farm': 'Talhão',
    'users': 'Usuarios',
    'import a .PNG or .JPG image': 'Importar una imagen .PNG o .JPG',
    'import': 'Importar {{type}}',
    image: 'Imagen',
    'hd image': 'Imagen de alta definición',
    'visual image': 'Imagen visual',
    'active': 'activo',
    'inactive': 'inactivo',
    'vegetation': 'Vegetación',
    'point': 'Punto {{number}}',
    'general data': 'Datos generales',
    'select one': 'Seleccione uno {{anything}}',
    'add photo': 'Agregar fotos',
    'good': 'Bom',
    'bad': 'Grave',
    'regular': 'Regular',
    'excellent': 'Excelente',
  },
  [namespaces.pages.login]: {
    welcome: 'Bienvenido',
    title: 'Acceder a YVY',
    loginBtn: 'Entre en',
    forgotBtn: '¿Ha olvidado su contraseña?',
    'remember account': 'Recordar la cuenta',
    'forgot password': '¿Ha olvidado su contraseña?'
  },
  [namespaces.pages.forgotPassword]: {
    title: 'Recuperar la contraseña',
    button: 'Enviar',
    link: 'Volver al inicio de sesión',
  },
  [namespaces.pages.resetPassword]: {
    title: 'Restablecer la contraseña',
    'password-confirmation': 'Confirmación de contraseña',
    'password confirmation is required': 'La confirmación de la contraseña es obligatoria',
    'passwords do not match': 'Las contraseñas no coinciden'
  },
  [namespaces.pages.dashboard]: {
    welcome: 'Bienvenido',
  },
  [namespaces.pages.field]: {
    'back reports': 'Volver a los informes',
    'general data': 'Datos generales',
    'observations': 'Comentarios',
    'information': 'Información',
    'variety': 'Variedad',
    'vegetative_index': 'Índice vegetativo',
    'vegetation indices': 'índices de vegetación',
    'weather condition': 'Condición climática',
    'nutritional symptoms': 'Síntomas nutricionales',
    'threats': 'Amenazas',
    'quantity': 'La cantidad',
    'spacing': 'Espaciado',
    'seeds': 'Semillas',
    'germination': 'Germinación',
    'population': 'Población',
    'pods/plants': 'Vainas/Plantas',
    'grains/pods': 'Frijoles/Vainas',
    'grains weight': 'Peso de mil granos',
    'yield': 'Ingresos estimados',
    'deepness': 'Profundidad',
    'samples number': 'Número de muestras',
    'equipment': 'Equipo',
    'high': 'High',
    'middle': 'Middle',
    'low': 'Low',
    'responsible': 'Responsable',
    'date': 'Fecha',
    'culture': 'Cultura',
    'growth stage': 'Etapa de crecimiento',
    'farm': 'Granja',
    'field': 'Campo',
    'crop': 'Zafra',
    'median ndvi': 'Mediana NDVI',
    'siganematoide': 'Siganematoide',
    'nutricheck': 'Nutriagro',
    'solocheck': 'Solo check',
    'scout': 'Scout',
    'health': 'Salud',
    'weed': 'Hierba',
    'bug': 'Insecto',
    'yield_component': 'Ingresos',
    'field dashboard': 'Dashboard por lote',
    'field monitoring': 'Monitoreo de lotes',
    'geo analysis': 'Análisis geográfico',
    'products': 'Productos',
    'new product': 'Nuevo producto',
    'type product': 'Tipo de producto',
    'field report': 'Informes de campo',
    'create field': 'Crear campo',
    'create a new field': 'Crear un nuevo campo',
    'drag and drop description': 'Arrastre y suelte en el campo de abajo o haga clic y seleccione un archivo que contenga el esquema del campo',
    'supported files are in the format': 'Los archivos admitidos están en el formato',
    'draw the field on the map': 'Dibujar el campo en el mapa',
    'draw the field on the map using the drawing tool': 'Dibuje el campo en el mapa usando la herramienta de dibujo',
    'draw field': 'Dibujar campo',
    'new field': 'Nuevo campo',
    'center map': 'Centralizar mapa',
    'general field data': 'Datos generales del campo',
    'field name': 'Nombre del campo',
    'enter the field name': 'Ingrese el nombre del campo',
    'select a group': 'Seleccione un grupo',
    'select a project': 'Seleccione un proyecto',
    'group farms': 'Grupo de granjas',
    'title new product': 'Registrar la información de un nuevo producto analítico',
    'select a farm': 'Seleccione una granja',
    'edit field': 'Editar campo',
    'field successfully updated': 'Terreno actualizado con éxito',
    'field successfully created': 'Terreno creado con éxito',
    'field error area or name field': 'El campo no puede quedar sin nombre, ni tener una superficie superior a 3000 ha',
    'create new group': 'Crear nuevo grupo',
    'create new farm': 'Crear nueva granja',
    'group successfully created': 'Grupo creado con éxito',
    'farm successfully created': 'Granja creada con éxito',
    'enter a name for the farm': 'Ingrese un nombre para la granja',
    'enter a name for the group': 'Introduce un nombre para el grupo',
    'central tendency': 'Tendencia Central',
    'dispersion measures': 'Medidas de dispersion',
    'sand': 'Arena',
    'clay': 'Arcilla',
    'silt': 'Limo',
    'classification of data in relation to the ideal': 'Clasificación de los datos en relación con el ideal',
    'average textural classification': 'Classificación promédia textural',
    'percentage of textural classification': 'Porcentage de classificación textural',
    'report download': 'Descarga de informe',
    'spreadsheet download': 'Descarga de hoja de cálculo',
    'analytical summary': 'Resumen analítico',
    'analytical report': 'Reporte analítico',
    'fertianalysis': 'Fertianalisis',
    'general analysis': 'Análisis general',
    'analysis by environment': 'Análisis por entorno',
    'report': 'Reporte',
    'environment map': 'Mapa del entorno',
    'recommendation': 'Recomendación',
    'product': 'Producto',
    'edit recommendation': 'Editar recomendación',
    'delete field': 'Borrar campo',
    'satellite monitoring': 'Monitoreo Satelital',
    'status satellite monitoring': 'Monitoreo satelital {{status}}',
    'cultivation condition': 'Condición de cultivo'
  },
  [namespaces.pages.monitoring]: {
    'select fields': 'Seleccionar campos',
    'waiting for farm selection': 'Esperando selección de finca'
  },
  [namespaces.pages.crop]: {
    'new crop': 'Nueva zafra',
    'view all crops': 'Ver todas las zafras',
    'search for a crop': 'Buscar por los zafras',
  },
  [namespaces.pages.member]: {
    'member': 'Miembro',
    'members': 'Miembros',
    'view all members': 'Ver todos los miembros',
    'search for a member': 'Buscar un miembro',
    'resend confirmation': 'Reenviar confirmación',
    'email successfully sent': 'Email enviado con éxito',
    'edit member': 'Editar miembro',
    'update a members permissions': 'Actualizar los permisos de un miembro',
    'edit permissions': 'Editar permisos',
    'update permissions': 'Actualizar permisos',
    'type of permissions': 'Tipo de permisos',
    'farm': 'Granja',
    'no access': 'Sin acceso',
    'view': 'Ver',
    'edit': 'Editar',
    'manage': 'Administrar'
  },
  [namespaces.pages.farmGroups]: {
    'register the information of a new farm': 'Registrar la información de una nueva granja',
    'edit farm information': 'Editar información de la granja',
    'edit farm group information': 'Editar información del grupo de granjas',
    farmGroups: 'Grupos de granja',
    'view all farm groups': 'Ver todos los grupos de granjas',
    'find a farm': 'Encontrar una granja',
    group: 'grupo',
    farm: 'granja',
    at: ' en '
  },
  [namespaces.pages.cropRotationst]: {
    'crop rotationst': 'Rotaciones de cultura',
    'new rotation': 'Nueva rotación',
    'view all crop rotations': 'Ver todas las rotaciones de cultura',
    'search for a rotation': 'Buscar por las rotaciones',
    'fields displayed': 'Campos mostrados',
    'harvest / year': 'Zafra / Año',
    'variety': 'Variedad',
    'planting date': 'Fecha de plantación',
    'harvest date': 'Fecha de cosecha',
    'productivity': 'Productividad',
  },
  [namespaces.pages.users]: {
    'view all users': 'Ver todos los usuarios',
    'search for a user': 'Buscar un usuario',
    'user': 'Usuario',
    'edit user': 'Editar usuario',
    'confirmed': 'Confirmado',
    'register a new user': 'Registrar un nuevo usuario'
  },
  [namespaces.pages.reports]: {
    reports: 'Informes',
    report: 'informe',
    'view all reports': 'Visualizar todos los informes',
    'search for a reports': 'Buscar un informe',
    'new reports': 'Nuevo reporte',
    'type of report': 'Tipo de informe',
  },
  [namespaces.modals.createRotationst]: {
    'register the information of a new crop rotation': 'Nueva Rotación de cultura',
    'select one': 'Seleccione uno {{anything}}',
    'field do not match': 'El campo {{label}} no coincide',
    'are you sure you want to delete the Growth Rotation?': '¿Está seguro de que desea eliminar la rotación de crecimiento?',
  },
  [namespaces.modals.createCrop]: {
    'register the information of a new crop': 'Nueva zafra',
    'change the information of a crop': 'Cambiar la información de un cultivo',
    'select one': 'Seleccione uno {{anything}}',
    'field is required': 'El campo {{label}} es obligatorio',
    'are you sure you want to delete the crop?': '¿Está seguro de que desea eliminar el recorte?',
  },
  [namespaces.modals.memberForm]: {
    'register a new members information': 'Registrar la información de un nuevo miembro',
    'change a members information': 'Cambiar la información de un miembro',
    'access type': 'Tipo de acceso',
    'access according to assigned permissions': 'Acceso según permisos asignados',
    'full access to all farms': 'Acceso completo a todas las granjas',
    'are you sure you want to delete this member?': '¿Estás seguro de que quieres eliminar a este miembro?',
  },
  [namespaces.modals.editUser]: {
    'edit user': 'Editar usuario',
    'full name': 'Nombre completo',
    'preferred language': 'Idioma preferido',
    'change photo': 'Cambiar foto',
    'profile updated successfully': 'Perfil actualizado con éxito',
    'enter a new password': 'Introduzca una nueva contraseña',
    'click to edit password': 'Haga clic para editar la contraseña',
    'password changed successfully': 'Contraseña alterada con éxito'
  },
  [namespaces.modals.newEnvironmentMap]: {
    'new environment map': 'Nuevo mapa del entorno',
  },
  [namespaces.errors]: {
    '400': 'Servicio no disponible.',
    '401': 'Credenciales de acceso invalidos. Inténtalo de nuevo.',
    '500': 'Error interno del servidor.',
    'default': 'Se ha producido un error inesperado. Por favor, inténtelo más tarde.',
    'no records found': 'No se encontraron registros',
    'item updated successfully': 'Artículo actualizado con éxito',
    'item removed with success': 'Artículo eliminado con éxito',
    'item create with success': 'Artículo creado con éxito',
    'images not yet available': 'Imágenes aún no disponibles',
    'image not available. please try another date': 'Imagen no disponible. Intente con otra fecha',
    'some lots do not have images available. please have another date.': 'Alguns lotes não possuem imágenes disponíveis. Por favor tente outra data.',
    'error KML': 'No se puede descargar KML, el polígono de trazado tiene una intersección de coordenadas'
  },
  [namespaces.pages.analyticalProducts]: {
    'new analytical products': 'Nuevo producto',
    'view all analytical analytical products': 'Ver todos los productos analíticos',
    'search for a products': 'Buscar un producto',
    'type product': 'Tipo do producto',
    'filter by farm': 'Filtrar por finca',
    'statistical parameters': 'Parámetros estadísticos'
  },
  [namespaces.pages.projects]: {
    'view all projects': 'Ver todos los proyectos',
    'search for a project': 'Buscar un proyecto',
    'new project': 'Nuevo proyecto',
    'edit project': 'Editar proyecto',
    'project': 'Proyecto',
    'creation date': 'Fecha de creación',
    'admin users': 'Usuarios admin',
    'name': 'Nombre',
    'project transfer': 'Transferido con éxito',
    'no projects found': 'No se encontraron proyectos',
    'companies': 'Empresas'
  },
  [namespaces.pages.projectController]: {
    'file too heavy, maximum size 1mb': 'Archivo demasiado pesado, tamaño máximo 1mb',
    'view all company information': 'Ver toda la información de la empresa',
    'general information': 'Informaciones generales',
    'enter the project name': 'Ingrese el nombre del proyecto',
    'enter the administrative email': 'Ingrese el correo administrativo',
    'cnpj ruc': 'CNPJ/RUC',
    'cnpj or ruc': 'CNPJ o RUC',
    'enter the CNPJ/RUC': 'Introducir el CNPJ/RUC',
    'platform preferences': 'Peferencias de plataforma',
    'insert logo': 'Insertar logotipo',
    'company profile': 'Perfil de la compañía',
    'cnpj ruc is already being used by another project': 'CNPJ/RUC ya está siendo utilizado por otro proyecto',
  },
  [namespaces.pages.scout]: {
    'phenological stage': 'Etapa fenológica',
    'stand': 'Stand (plantas/m)',
    'this action cannot be undone!': '¡Esta acción no se puede deshacer!',
    'discard changes': 'descartar los cambios',
    'cancel change?': '¿Cancelar el cambio?',
    'delete report?': '¿Eliminar informe?',
    'delete point?': '¿Eliminar punto?'
  },
}