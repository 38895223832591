import styled from 'styled-components'
import { Field } from 'react-final-form'

export const ContainerBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  margin-top: 20px;
  margin-right: 0;
  div {
    max-width: 220px;
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.YELLOW};
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin-top: 2rem;
`
export const TableLabel = styled.h4`
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    padding: 2rem 0 0.5rem 0;
`
export const PermissionTable = styled.table`
  width: 100%;
  max-height: 450px !important;
  border-top: 1px solid #ffffff20;
  border-bottom: 1px solid #ffffff20;
  padding: 0;
  margin-bottom: 1rem;
  
  /* Table style */
    td, th {
        padding: 0.5rem;
        font-size: 11px;
    }
    tr:nth-child(odd) td {
        background-color: ${({ theme }) => theme.colors.THEME};
    }

    tr:nth-child(even) td {
        background-color: ${({ theme }) => theme.colors.LIGHT_THEME};
    }
    thead {
    tr,
    th {
      background-color: ${({ theme }) =>
    `${theme.colors.LIGHT_THEME} !important`};
      border: none;
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};

      th::before {
        display: none;
      }
    }
  }

  /* Radio input style */
  input[type='radio'] {
    margin: 0 auto !important;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: "";
    width: 0.40em;
    height: 0.40em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${({ theme }) => theme.colors.BLUEGREEN};
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
  input[type='radio']:checked::before {
    border: 0.15em solid ${({ theme }) => theme.colors.BLUEGREEN} !important;
    transform: scale(1);
  }

  input[type='radio']:focus {
    outline: max(2px, 0.15em) solid ${({ theme }) => theme.colors.BLUEGREEN};;
    outline-offset: max(2px, 0.15em);
  }
`

export const FarmTh = styled.th`
  text-align: left;
  padding-left: 1rem !important;
`

export const FarmName = styled.td`
  padding-left: 3rem !important;
`
export const Radio = styled(Field)`
  cursor: pointer;
`
