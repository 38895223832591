import Moment from 'moment'
import { namespaces } from 'i18n/i18n.constants'
import { TFunction } from 'react-i18next'
import { TSelectOption } from 'types/types'
import 'moment/locale/pt-br'
import 'moment/locale/es'

import map from 'lodash/map'
import { theme } from 'styles'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import first from 'lodash/first'

export const toFixedNoRounding = (value:number, fixed:number) => {
  const v = value.toString().split('.')
  if (fixed <= 0) return v[0]
  let f = v[1] || ''
  if (f.length > fixed) return `${v[0]}.${f.substr(0, fixed)}`
  while (f.length < fixed) f += '0'
  return `${v[0]}.${f}`
}

export const formatDateTimeToView = (date?: string) => {
  if(!date) return ''
  return Moment(date).format('DD/MM/YYYY HH:mm')
}

export const formatDateToServer = (date: string | any) => (
  Moment(date).toISOString()
)

export const removeTimeFromDateTime = (date: string) => (
  date.substring(0, 10)
)

export const removeTime = (date: any) => {
  return new Date(date).toDateString()
}

export const formatDecimalNumber = (number: string | number) => {
  const formatter = new Intl.NumberFormat(
    'pt',
    {
      style: 'decimal',
      maximumFractionDigits: 2
    }
  )

  return formatter.format(Number(number))
}

export const getYear = (date: string) => {
  const fullYear = new Date(date)

  return fullYear.getFullYear()
}

export const formatDecimalNumberToPercentage = (number: string |number) => (
  `${formatDecimalNumber(number)} %`
)

export const formatCurrency = (number: string | number) => {
  const formatter = new Intl.NumberFormat(
    'pt',
    {
      style: 'currency',
      currency: 'BRL'
    }
  )

  return formatter.format(Number(number))
}

export const formatDateToView = (date: string | number | null, isUnix?:boolean, hours?:boolean) => {
  if (!date) return ''

  if(isUnix){
    return Moment.unix(Number(date)).format('DD/MM/YYYY')
  }

  if(hours){
    return Moment(date).format('DD/MM/YYYY HH:mm')
  }

  return Moment(date).format('DD/MM/YYYY')
}

export const getHours = (date: string) => {
  return Moment(date).format('LT')
}

export const formatDateToViewTranslated = (date: number, t:TFunction<'namespaces'> ) => {

  if (!date) return t('not informed', { ns: namespaces.common })

  const formatData = Moment.unix(date).format('ll')

  let month = ''

  const localLanguage = localStorage.getItem('agfarmusLanguage')
  switch(localLanguage){
    case 'en':
      month = Moment.unix(date).locale('en').format('MMMM').slice(0, 3)
      break
    case 'es':
      month = Moment.unix(date).locale('es').format('MMMM').slice(0, 3)
      break
    default:
      month = Moment.unix(date).locale('pt-br').format('MMMM').slice(0, 3)
      break
  }

  return `
    <p>,${formatData.slice(0, 2)}</p>
    <p>${month?.charAt(0).toUpperCase() + month?.slice(1)}</p> 
    <p>${formatData.slice(-4)}</p>`
}

export const formatFullDateToView = (date: string) => {
  if(!date) return ''
  const localLanguage = localStorage.getItem('agfarmusLanguage')
  switch(localLanguage){
    case 'en':
      return Moment(date).locale('en').format('MMMM Do YYYY')
    case 'es':
      return Moment(date).locale('es').format('LL')
    default:
      return Moment(date).locale('pt-br').format('LL')
  }
}

export const formatDateToUnix = (date: string) => {
  if (!date) return 0
  return Moment(date).unix()
}

export const addDaysInDate = (days: string, currentDate: string) => (
  Moment(currentDate).add(days, 'days').format('YYYY-MM-DD')
)

export const removeDaysInDate = (days: string, currentDate: string) => (
  Moment(currentDate).subtract(days, 'days').format('YYYY-MM-DD')
)

export const dateRange = (currentDate: any) => {
  const firstdate = Moment(currentDate).startOf('month').unix()
  const lastdate = Moment(currentDate).endOf('month').format('YYYY-MM-DD')

  return { to: firstdate, from: formatDateToUnix(lastdate) }
}

export const compareDate = (dateOne: number, dateTwo: number) => {

  return Moment(dateOne).isSame(dateTwo, 'day')
}

export const getDay = (date: string) => (
  Moment(date).format('DD')
)

export const getMonth = (date?: string) => {
  if(!date){
    return Moment().format('MM')
  }
  return Moment(date).format('MM')
}

export const getCurrentDate = () => ( Moment().format('YYYY-MM-DD') )

export const getDiffDaysBetweenDates = (start: string, end: string) => {
  const startDate = Moment(start, 'YYYY-MM-DD')
  const endDate = Moment(end, 'YYYY-MM-DD')
  return Moment.duration(endDate.diff(startDate)).asDays()
}

export const formatDateInputDate = (date: string | null) => {
  if(!date) return ''

  return Moment(date)
}

export const fileToBase64 = async (file: Blob) => {
  const base64 = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const [, data] = String(base64).split(',')

  return data
}

export const arrayBufferToBase64 = ( buffer: ArrayBuffer ) => {
  let binary = ''
  const bytes = new Uint8Array( buffer )
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] )
  }
  const image = window.btoa( binary )

  return image
}


export const downloadBase64File = async (
  fileName: string,
  base64string: string,
  fileFormat = 'application/pdf'
) => {
  await new Promise<void>((resolve, reject) => {
    try {
      const linkSource = `data:${fileFormat};base64,${base64string}`

      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export const formatCurrencyToServer = (number: string) => {
  if (!number) return '0'

  if (number.indexOf(','))
    return number.replace(/\./g, '').replace(',', '.')

  return number
}

export const getApiErroMessage = (code: number, t:TFunction<'namespaces', undefined>) => {

  switch (code) {
    case 400: return t('400', { ns: namespaces.errors })
    case 401: return t('401', { ns: namespaces.errors })
    case 500: return t('500', { ns: namespaces.errors })
    default: return t('default', { ns: namespaces.errors })
  }
}

export const getYesNoFromBoolean = (value: boolean) => (
  value ? 'Sim' : 'Não'
)

export const removeMaskGuides = (value: string) => (
  value
    .replace(/_/g, '')
    .replace(/-/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/ /g, '')
    .replace(/[^\d]+/g, '')
)

export const formatCpfCnpj = (number?: string) => {
  if (!number) return ''

  const [regext, replacement] = number.length === 11
    ? [/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4']
    : [/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5']

  return number.replace(regext, replacement)
}

export const downloadUrl = (url: string, name?: any): void => {
  const link = document.createElement('a')
  link.download = name
  link.href = url
  link.target = '_BLANK'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const sleep = (ms: number = 0) => new Promise( resolve => setTimeout(resolve, ms))

export const sortArray = (list: TSelectOption[]) => {
  if(!list.length) return []

  const ordenedArray = list.sort((old, after) => {
    if(old.label > after.label) return 1
    if(old.label < after.label) return -1

    return 0
  })

  return ordenedArray
}

export const formatFileSize = (bytes:number) => {
  if(bytes === 0) return '0 Bytes'
  const k = 1000
  const dm = 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


export const getUniqueArray = (arr: Array<any>, key: string) => {
  if(arr?.length > 0) {
    // @ts-ignore
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }
  return []

}

export const isValidURL = (string: string) => {
  const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)
  return (res !== null)
}

export const isJSONStrig = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}


export const parseAncestorColumn = (value:string) => {
  if (value) {
    const ids = value.toString().split('/')
    return map(ids, (i:string) => parseInt(i, 10))
  }
  return []
}

export const getListField = (groups:any, old:any) => {
  const fieldsArray:any = reduce(groups, (list:any, { groups, farms }:any) => {

    if(!isEmpty(old)){
      list.push(...old)
    }

    map(farms, ({ fields, id, name }: any) => {
      list.push(...map(fields, (field:any) => ({ ...field, idFarm: id, farmName: name })))
    })

    if(!isEmpty(groups)){
      return getListField(groups, list)
    }

    return list
  }, [])

  return fieldsArray
}

export const getFarm = (groups:any, id:any) => {

  const farm = first(map(groups, (group:any) => find(group.farms, (farm:any) => farm.id === id )))

  return farm
}

export const isImg = async (url:string) => {

  const response = new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function(){
      resolve(true)
    }
    img.onerror = function(){
      reject(false)
    }
    img.src = url
  })

  return await Promise.resolve(response).then(() => true).catch(() => false )
}

export const setColor = (conditions:string):string => {
  switch (conditions) {
    case 'good':
      return theme.colors.GREEN

    case 'excellent':
      return theme.colors.BLUE

    case 'bad':
      return theme.colors.SLIGHT_RED

    default:
      return theme.colors.YELLOW
  }
}

export const getLabelImageMonitoring = (type:string, t:TFunction<'namespaces'> ):string => {
  switch (type) {
    case 'hd':
      return t('hd image', { ns: namespaces.common })

    case 'farmus_sense':
      return 'Farmus Sense'

    case 'ndvi_contrast':
      return 'NDVI Contraste'

    case 'ultra_sense':
      return 'Farmus Sense'

    case 'ndvi':
      return 'NDVI'

    case 'truecolor':
      return t('visual image', { ns: namespaces.common })

    case 'evi':
      return 'EVI'

    default:
      return t('monitoring', { ns: namespaces.layout })
  }
}