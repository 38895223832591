import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import useGroups from './useGroups'
import { useGlobalContext } from 'hooks/useGlobalContext'

const useFarms = () => {
  const rest = useRest()
  const { getAllGroupsProject } = useGroups()
  const { saveGroupsSelected } = useGlobalContext()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')


  const createNewFarm = async (project: any, params: object) => {
    const url = `/v2/companies/${project}/farms`
    const response = await rest.post(url, params)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.common }), success: true })
    }
  }

  const editFarm = async (project: any, farmId:number, params: object) => {
    const url = `/v2/companies/${project}/farms/${farmId}`
    const response = await rest.put(url, params)

    if(response?.status === 200){
      notify({ message: t('item updated successfully', { ns: namespaces.errors }), success: true })
    }
  }

  const removeFarm = async (project: any, farmId:number) => {
    const url = `/v2/companies/${project}/farms/${farmId}`
    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.errors }), success: true })
      const response = await getAllGroupsProject(project)
      saveGroupsSelected(response?.data?.records)
    }
  }

  return {
    createNewFarm,
    editFarm,
    removeFarm
  }
}

export default useFarms
