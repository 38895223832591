import styled from 'styled-components'

import { Modal } from 'antd'

export const Card = styled(Modal)`
  width: auto;
  height: auto;
  z-index: 1000;

  .ant-modal-content {
    background-color: ${({ theme }) => `${theme.colors.THEME}!important`};
    border-radius: 4px !important;
    border: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
    width: 800px;
  }

  .ant-modal-header {
    background-color: ${({ theme }) => `${theme.colors.THEME}!important`};
    border: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
    border-radius: 4px 4px 0 0 ;
  }
    .ant-modal-footer {
    border-color:  ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  }
  .ant-modal-title, .ant-modal-close-x{
    color: ${({ theme }) => theme.colors.YELLOW};
  }
`
export const ModalConfirmStyle = styled(Modal)`
  width: auto;
  height: auto;
  z-index: 10001;
  color: ${({ theme }) => theme.colors.WHITE};

 .ant-modal-content {
    background-color: ${({ theme }) => `${theme.colors.THEME}!important`};
    border-radius: 4px !important;
    border: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  }

  .ant-modal-body {
    padding: 24px 30px;
}

  .ant-modal-header {
    background-color: ${({ theme }) => `${theme.colors.THEME}!important`};
    border: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
    border-radius: 4px 4px 0 0 ;
  }
    .ant-modal-footer {
    border-color:  ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  }
  .ant-modal-title, .ant-modal-close-x{
    color: ${({ theme }) => theme.colors.YELLOW};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }
`

export const Container = styled.div``

export const Content = styled.div``

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  margin-top: 20px;
`

export const CustomAlert = styled.div`
  width: 100%;
  height: 50px;
  color: ${({ theme }) => theme.colors.WHITE};
  background: ${({ theme }) => theme.colors.SLIGHT_RED};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  padding: 26px 10px;

  svg{
    margin-right: 10px;
  }

`