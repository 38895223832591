import React from 'react'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'

import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { Container, CheckBoxGroupList } from './styles'


type TProps = {
  setFilterList: React.Dispatch<React.SetStateAction<CheckboxValueType[]>>
  filterList: CheckboxValueType[]
}

const FilterList = ({ setFilterList, filterList }: TProps ) => {
  const { t } = useTranslation('namespaces')

  const plainOptions = [
    { label: t('all', { ns: namespaces.common }), value: 'all' },
    { label: 'Scout', value: 'scout' },
    { label: t('health', { ns: namespaces.pages.field }), value: 'disease' },
    { label: t('bug', { ns: namespaces.pages.field }), value: 'bug' },
    { label: t('weed', { ns: namespaces.pages.field }), value: 'weed' },
    { label: t('solocheck', { ns: namespaces.pages.field }), value: 'solocheck' },
    { label: t('nutricheck', { ns: namespaces.pages.field }), value: 'nutricheck' },
    { label: t('siganematoide', { ns: namespaces.pages.field }), value: 'siganematoide' },
    { label: t('yield_component', { ns: namespaces.pages.field }), value: 'yield_component' }
  ]

  const onChange = (list: any[]) => {
    setFilterList(list)
  }

  return (
    <Container>
      <CheckBoxGroupList
        options={plainOptions}
        value={filterList}
        onChange={onChange}
      />
    </Container>
  )
}

export default FilterList
