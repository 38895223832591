import React, { Fragment, useContext, useCallback } from 'react'
import { Line, SubMenu, Table, Title, Wrapper, ActionsWrapper } from './styles'
import { MdModeEditOutline } from 'react-icons/md'
import { BiTransfer } from 'react-icons/bi'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { TColumns, TGroups } from 'types/types'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { DialogContext } from 'dialog/context'
import ModalGroupForm from '../ModalGroupForm'
import { useParams } from 'react-router-dom'
import ModalRemoveGroup from '../ModalRemoveGroup'

interface Props {
  columns: TColumns[]
  groupFarmData?: TGroups[]
  btnBitTransfer: () => void
}


const FarmTable: React.FC<Props> = ({ columns, groupFarmData, btnBitTransfer }) => {
  const { createDialog } = useContext(DialogContext)
  const { project }:any = useParams()

  const { saveOneGroupSelected } = useGlobalContext()

  const handleClickTransfer = useCallback((item: TGroups) => {
    saveOneGroupSelected(item)
    btnBitTransfer()
  }, [])

  const editGroupFarms = (groupId: number, groupName: string, parentId?: number) => {
    createDialog({
      id: 'modal-edit-group-farms',
      open: false,
      Component: ModalGroupForm,
      props: {
        projectSelected: project,
        groupToEdit: {
          id: groupId,
          name: groupName,
          parent_id: parentId
        },
        removeGroupCallback: removeGroupFarms
      }
    })
  }

  const removeGroupFarms = (groupId: number, groupName: string, callback: Function) => {
    createDialog({
      id: 'modal-remove-group-farms',
      open: false,
      Component: ModalRemoveGroup,
      props: {
        projectSelected: project,
        groupToRemove: {
          id: groupId,
          name: groupName,
        },
        callback
      }
    })
  }

  const addingGroupIdToFarmsArray = (array: any, id: number) => {
    return array.map((item: any) => ({ groupId: id, ...item }))
  }

  const renderSubgroups = (children: any) => {

    return !isEmpty(children) &&
      map(children, (item: TGroups, index) => (
        <SubMenu key={index}>
          <Wrapper>
            <Title> {item.name} </Title>
            <ActionsWrapper>
              <MdModeEditOutline onClick={() => editGroupFarms(item?.id, item?.name, item?.parent_id)} />
              <BiTransfer
                size={17}
                onClick={() => handleClickTransfer(item)}
              />
            </ActionsWrapper>
          </Wrapper>
          <Line />

          {
            !isEmpty(item.farms) && (
              <Table
                columns={columns}
                dataSource={addingGroupIdToFarmsArray(item.farms, item.id)}
                size='small'
                pagination={false}
              />
            )
          }

          {item.groups && renderSubgroups(item.groups)}
        </SubMenu>
      ))

  }

  return (
    <>
      {groupFarmData?.map((item, index) =>
        <Fragment key={index}>
          <Wrapper>
            <Title> {item.name} </Title>
            <ActionsWrapper>
              <MdModeEditOutline onClick={() => editGroupFarms(item?.id, item?.name, item?.parent_id)} />
              <BiTransfer
                size={17}
                onClick={() => handleClickTransfer(item)}
              />
            </ActionsWrapper>
          </Wrapper>
          <Line />
          { !isEmpty(item.farms) && (
            <Table
              columns={columns}
              dataSource={addingGroupIdToFarmsArray(item.farms, item.id)}
              size='small'
              pagination={false}
            />
          )}
          { !isEmpty(item.groups) && renderSubgroups(item.groups)

          }
        </Fragment>
      )}
    </>
  )
}

export default FarmTable