import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`

export const Table = styled.table`
    border: 1px solid #ffffff20;
    padding: 0;
    border-spacing: 0 3px;

    td, th {
        padding: 0.5rem;
        text-align: center;
        font-size: 11px;
    }
    tr:nth-child(odd) td {
        background-color: ${({ theme }) => theme.colors.THEME};
    }

    tr:nth-child(even) td {
        background-color: ${({ theme }) => theme.colors.LIGHT_THEME};
    }
    thead {
    tr,
    th {
      background-color: ${({ theme }) =>
    `${theme.colors.LIGHT_THEME} !important`};
      border: none;
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};

      th::before {
        display: none;
      }
    }
  }
`

export const TableTitle = styled.h4`
  color: ${({ theme }) => theme.colors.LIGHT_THEME};
  background-color: ${({ theme }) => theme.colors.YELLOW} ;
  margin: 0;
  text-align: center;
  margin-bottom: -1rem;
  font-size: 13px;
  font-weight: bold;
  padding: 0.25rem 0;

`

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .highcharts-background {
        fill: none;
    }
    .highcharts-axis-labels, .highcharts-xaxis-labels {
        text {
            fill: ${({ theme }) => theme.colors.WHITE} !important;
          }
        }
        .highcharts-axis-title {
      fill: ${({ theme }) => theme.colors.WHITE} !important;

    }
    span.highcharts-title{
        color: ${({ theme }) => theme.colors.WHITE} !important;
  fill: ${({ theme }) => theme.colors.WHITE} !important;
    }
`

export const Description = styled.h4`
    color: ${({ theme }) => theme.colors.WHITE};
    font-size: 12px;
    margin-left: 3rem;
`
