import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'components/Modal'
import { Form } from 'antd'
import useGroups from 'repositories/useGroups'
import { useGlobalContext } from 'hooks/useGlobalContext'
import Button from 'components/Button'
import SelectField from 'components/SelectField'
import TextField from 'components/TextField'
import { FormBox, ContainerBtn } from './styles'
import { TGroups } from 'types/types'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

import map from 'lodash/map'

type TProps = {
  open: boolean;
  handleClose: () => void;
  id: string;
  projectSelected:string
  groupToEdit?: TGroups
  removeGroupCallback?: Function
  farm?:boolean
}

import {
  validation,
  TFormNewGroup
} from './validations'
import { isEmpty } from 'lodash'
import useFarms from 'repositories/useFarms'

const ModalGroupForm = ({ open, handleClose, id, projectSelected, groupToEdit, removeGroupCallback, farm = false }: TProps) => {
  const global = useGlobalContext()
  const groupsApi = useGroups()
  const farmApi = useFarms()
  const [optionsGroups, setOptionsGroups] = useState<TGroups[] | []>()
  const [form] = Form.useForm<TFormNewGroup>()
  const { t } = useTranslation('namespaces')

  const [valuesInput, setValuesInput] = useState<any>()

  const initialValues:TFormNewGroup = useMemo(
    () => ({
      name: groupToEdit?.name || '',
      parent: groupToEdit?.parent_id || null
    }), [groupToEdit, id])

  const getAllGroups = async() => {
    const response = await groupsApi.getFields({ project: projectSelected, type: 'all' })
    getAllGroupsSelect()
    global.saveGroupsSelected(response?.data.groups)
  }

  const getAllGroupsSelect = async() => {
    const response = await groupsApi.getGroups(projectSelected)
    setOptionsGroups(response?.data)
  }

  const optionsValue = useMemo(() =>
    map(optionsGroups, (option: any) => ({ 'label': option.name, 'value': option.id })), [optionsGroups, groupToEdit])


  const onFinish = async(values: TFormNewGroup) => {
    if(isEmpty(values.name)){
      return
    }

    if(farm){
      const params = {
        name: values.name,
        group_id: valuesInput
      }
      if(groupToEdit) await farmApi.editFarm(projectSelected, groupToEdit.id, params)
    } else {
      const params = {
        name: values.name,
        parent:
          optionsValue?.find(option => option.value === valuesInput),
        parent_id: valuesInput
      }
      groupToEdit ?
        await groupsApi.editGroup(projectSelected, groupToEdit.id, params)
        :
        await groupsApi.createNewGroup(projectSelected, params)
    }
    getAllGroups()
    handleClose()
  }

  const removeCall = () => {
    getAllGroups()
    handleClose()
  }

  const onReset = () => {
    getAllGroups()
    form.resetFields()
  }

  useEffect(() => {
    getAllGroups()
  }, [])
  useEffect(() => {
    getAllGroups()
  }, [open])

  return (
    <Modal
      id={id}
      title={ groupToEdit ?
        t('edit farm group information', { ns: namespaces.pages.farmGroups })
        :
        t('new group register', { ns: namespaces.common })
      }
      isModalVisible={open}
      handleClose={handleClose}
    >
      <FormBox>
        <Form
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          name='form-new-group'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
        >
          <TextField
            id='farm-name'
            rules={validation('name', t)}
            name='name'
            label={t('name', { ns: namespaces.common })}
            placeholder={t('placeholder name', { ns: namespaces.common })}
          />
          <SelectField
            name='parent'
            label={t('group farms', { ns: namespaces.pages.field })}
            id='select-group'
            placeholder={t('select a group', { ns: namespaces.pages.field })}
            options={optionsValue ? optionsValue : []}
            showSearch
            handleChange={e => setValuesInput(e)}
          />
          <ContainerBtn>
            <div>
              <Button
                id='button-cancel'
                type={groupToEdit ? 'dashed' : 'default'}
                label={ groupToEdit ? t('delete', { ns: namespaces.common }) : t('clean', { ns: namespaces.common })}
                onClick={() => {
                  if(groupToEdit && removeGroupCallback) {
                    removeGroupCallback(groupToEdit.id, groupToEdit.name, removeCall)
                  } else{
                    onReset()
                  }
                }}
                htmlType='reset'
              />
              <Button
                id='button-confirm'
                type='primary'
                label={t('save', { ns: namespaces.common })}
                htmlType='submit'
              />
            </div>
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalGroupForm