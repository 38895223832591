import React, { createContext, useContext, useState, useEffect } from 'react'
import {
  storageProjectSelected,
  storageFieldSelected,
  storageFarmSelected,
  storageGroupsSelected,
  storageReportsSelected,
  storageOneGroupSelected
} from 'services/axios'
import { TProject, TFields, TGroups, TReports, TReportsDataImages } from 'types/types'

type TGlobalContext = {
  projectSelected?: TProject | null
  loading:number | 0
  fieldSelected?: TFields | null
  farmSelected?: any
  groupsSelected?: TGroups[] | [] | undefined
  oneGroupSelected?: TGroups
  reportsSelected?: TReports | null
  reportViewDetails?: Boolean
  reportDataImages?: TReportsDataImages | any
  zoomMap?: Boolean
  zoomNumberMap?: number
  idFarmSelected?:number | null | undefined

  saveProjectSelected(projects: TProject): void
  removeProjectSelected(): void
  addLoading(): void
  removeLoading(): void
  saveFieldSelected(field: TFields): void
  saveFarmSelected(field: TGroups[]): void
  saveGroupsSelected(groups: any): void
  saveOneGroupSelected(groups: TGroups): void
  saveReportsDataImages(report: TReportsDataImages) : void
  setZoomMap(value: Boolean) : void
  setZoomNumberMap(value: number) : void
  setIdFarmSelected:React.Dispatch<React.SetStateAction<number | null | undefined>>

}

const GlobalContext = createContext<TGlobalContext>({} as TGlobalContext)

type TProps = {
  children: React.ReactNode
}

const GlobalContextProvider = ({ children }: TProps) => {

  const [projectSelected, setProjectSelected] = useState<TProject | null>()
  const [loading, setLoading] = useState<number>(0)
  const [fieldSelected, setFieldSelected] = useState<TFields | null>()
  const [farmSelected, setFarmSelected] = useState<any>([])
  const [groupsSelected, setGroupsSelected] = useState<TGroups[] | []>([])
  const [oneGroupSelected, setOneGroupSelected] = useState<TGroups>()
  const [reportsSelected, setReportsSelected] = useState<TReports | null>()
  const [reportViewDetails, setReportViewDetails] = useState<Boolean>(false)
  const [reportDataImages, setReportsDataImages] = useState<TReportsDataImages | []>([])
  const [zoomMap, setZoomMap] = useState<Boolean>(true)
  const [zoomNumberMap, setZoomNumberMap] = useState<number>(0)
  const [idFarmSelected, setIdFarmSelected] = useState<number | null | undefined>()


  const removeProjectSelected = () => {
    localStorage.removeItem(storageProjectSelected)
    localStorage.removeItem(storageFarmSelected)
    localStorage.removeItem(storageFieldSelected)
    localStorage.removeItem(storageGroupsSelected)
    localStorage.removeItem(storageReportsSelected)

    setProjectSelected(null)
    setFieldSelected(null)
    setFarmSelected([])
    setGroupsSelected([])
    setReportsSelected(null)
    setReportViewDetails(false)
    setReportsDataImages([])
    setIdFarmSelected(undefined)
  }

  const addLoading = () => {
    setLoading(old => ++old)
  }

  const removeLoading = () => {
    setLoading(old => --old)
  }

  const saveFieldSelected = (data: TFields) => {
    localStorage.setItem(storageFieldSelected, JSON.stringify(data))
    setFieldSelected(data)
  }

  const saveFarmSelected = (farm: any) => {
    localStorage.setItem(storageFarmSelected, JSON.stringify(farm))
    setFarmSelected(farm)
  }

  const saveGroupsSelected = (groups: TGroups[]) => {
    localStorage.setItem(storageGroupsSelected, JSON.stringify(groups))
    setGroupsSelected(groups)
  }

  const saveOneGroupSelected = (group: TGroups) => {
    localStorage.setItem(storageOneGroupSelected, JSON.stringify(group))
    setOneGroupSelected(group)
  }

  const saveProjectSelected = (data: TProject) => {
    localStorage.setItem(storageProjectSelected, JSON.stringify(data))
    setProjectSelected(data)
  }

  const saveReportsDataImages = (data: TReportsDataImages) => {
    setReportsDataImages(data)
  }

  useEffect(() => {
    const haveProject = localStorage.getItem(storageProjectSelected)
    if(haveProject){
      setProjectSelected(JSON.parse(haveProject))
    }

    return () => {
      setProjectSelected(undefined)
    }
  }, [])

  useEffect(() => {
    const haveGroups = localStorage.getItem(storageGroupsSelected)
    if(haveGroups){
      setGroupsSelected(JSON.parse(haveGroups))
    }

    return () => {
      setGroupsSelected([])
    }
  }, [])

  useEffect(() => {
    const haveGroups = localStorage.getItem(storageOneGroupSelected)
    if(haveGroups){
      setOneGroupSelected(JSON.parse(haveGroups))
    }

    return () => {
      setOneGroupSelected(undefined)
    }
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        projectSelected,
        loading,
        fieldSelected,
        farmSelected,
        idFarmSelected,
        groupsSelected,
        oneGroupSelected,
        reportsSelected,
        reportViewDetails,
        reportDataImages,
        zoomMap,
        zoomNumberMap,
        saveProjectSelected,
        removeProjectSelected,
        addLoading,
        removeLoading,
        saveFieldSelected,
        saveFarmSelected,
        saveGroupsSelected,
        saveOneGroupSelected,
        saveReportsDataImages,
        setZoomMap,
        setZoomNumberMap,
        setIdFarmSelected
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

const useGlobalContext = (): TGlobalContext => {
  const context = useContext(GlobalContext)

  return context
}

export { useGlobalContext, GlobalContextProvider }
