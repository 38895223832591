import React, { useEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'
import { Container } from './styles'
// @ts-ignore
import toGeoJSON from '@mapbox/togeojson'
import DrawerForm from './DrawerForm'
import { Polygon } from 'react-leaflet'
import FieldMap from './FieldMap'
import { useFieldContext } from 'hooks/useFieldContext'
import isEmpty from 'lodash/isEmpty'
import { useLocation, useParams } from 'react-router-dom'
import size from 'lodash/size'

const FieldController = () => {

  const location:any = useLocation()
  const params:any = useParams()

  // Local state
  const {
    setFormType,
    setPolygons,
    setMapFields,
    setFieldName,
    setFieldTotalArea,
    setImportedFileName,
    setLat,
    setLng,
    setMapCenter,
    setGroupField,
    setFarmField,
    setMapKey,
    filekml,
    handleResetFieldContext,
    setFilekml
  } = useFieldContext()


  // Edit field mode
  const handleGetFieldToEdit = (fieldSelected:any) => {

    const fieldSelectedToEdit: any = fieldSelected

    const polygonsArray: any = []


    fieldSelectedToEdit?.shape?.features.forEach((feature: any, index:number) => {
      if (feature.geometry.type === 'Polygon' && size(fieldSelectedToEdit?.shape?.features) === 1) {
        polygonsArray.push(renderPolygon(feature.geometry, feature.id))
      } else if (feature.geometry.type === 'GeometryCollection') {
        feature.geometry.geometries.forEach((geometry: any) => {
          polygonsArray.push(renderPolygon(geometry, feature.id))
        })
      }else if(feature.type === 'Feature' && index === 0){
        polygonsArray.push(renderPolygon(feature.geometry, feature.id))
      }
    })

    setFormType('edit')
    setFieldTotalArea(fieldSelectedToEdit?.area)
    setFieldName(fieldSelectedToEdit?.name)
    setPolygons(polygonsArray)
    setMapKey(uuidv1())
    setGroupField(fieldSelectedToEdit.group.id)
    setFarmField(fieldSelectedToEdit.farm.id)
  }

  // Import local file, transform the file in GeoJSON and populate the polygons
  const handleUploadMapFile = (event: any) => {
    setMapFields([])
    setLat()
    setLng()
    setFieldTotalArea(0)
    setFilekml(undefined)
    const shapeFile = event.file.originFileObj
    const reader = new FileReader()

    reader.onload = () => {
      const { result }: any = reader
      const xml = new DOMParser().parseFromString(result, 'text/xml')
      const shape = toGeoJSON.kml(xml)
      transformFileInGeoJSON(shape)
      setFilekml(JSON.stringify({ shape, name: shapeFile.name }))
    }
    setImportedFileName(shapeFile.name)
    reader.readAsText(shapeFile)
  }

  // Using file imported in new field in map drawer
  const renderPolygon = (geometry: any, id: string) => {

    const positions = geometry?.coordinates.map((cord:any) => {
      return cord.map((c: any) => [c[1], c[0]])
    })

    if (!positions) return null

    setMapCenter(positions[0][0])
    setLat(positions[0][0][0])
    setLng(positions[0][0][1])

    return <Polygon key={id} positions={positions} color='#5da05d' />
  }

  // Transform a file in GeoJSON and populate the polygons
  const transformFileInGeoJSON = (shape: any) => {
    const polygonsArray: any = []

    shape.features.forEach((feature: any) => {
      if (feature.geometry.type === 'Polygon') {
        polygonsArray.push(renderPolygon(feature.geometry, feature.id))
      } else if (feature.geometry.type === 'GeometryCollection') {
        feature.geometry.geometries.forEach((geometry: any) => {
          polygonsArray.push(renderPolygon(geometry, feature.id))
        })
      }
    })

    setPolygons(polygonsArray)
    setMapKey(uuidv1())
  }

  useEffect(() => {
    if(filekml){
      const fieldDataInherited = JSON.parse(filekml)
      setImportedFileName(fieldDataInherited.name)
      transformFileInGeoJSON(fieldDataInherited.shape)
    }
  }, [filekml])

  useEffect(() => {
    if(!isEmpty(location?.state?.field) && location?.state?.edit ){
      handleGetFieldToEdit(location?.state?.field)
      setFormType('edit')
    }else{
      handleResetFieldContext()
    }
  }, [location])

  return (
    <Container>
      <FieldMap />
      <DrawerForm
        handleUploadMapFile={handleUploadMapFile}
        fieldSelected={location?.state?.field}
        projectSelected={params?.project}
      />
    </Container>
  )
}

export default FieldController