import React from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { Container } from './styles'

type TProps = {
  pageName: string | undefined
}

const MonitoringLoading = ({ pageName }: TProps) => {
  const { t } = useTranslation()
  return (
    <Container>
      <svg id='loading' viewBox='0 0 100 80'>
        <g transform='translate(50 0) scale(0.707 0.707) rotate(45 0 0)'>
          <rect className='rect' id='rect1' x='0' y='0' width='30' height='30' rx='2' ry='2' fill='#f37422' />
          <rect className='rect' id='rect2' x='0' y='0' width='30' height='30' rx='2' ry='2' fill='#f37422' />
          <rect className='rect' id='rect3' x='0' y='0' width='30' height='30' rx='2' ry='2' fill='#f37422' />
          <rect className='rect' id='rect4' x='0' y='0' width='30' height='30' rx='2' ry='2' fill='#f37422' />
          <rect className='rect' id='rect5' x='0' y='0' width='30' height='30' rx='2' ry='2' fill='#f37422' />
          <rect className='rect' id='rect6' x='0' y='0' width='30' height='30' rx='2' ry='2' fill='#f37422' />
          <rect className='rect' id='rect7' x='0' y='0' width='30' height='30' rx='2' ry='2' fill='#f37422' />
        </g>
      </svg>
      <div>
        <strong>{t('wait', { ns: namespaces.common })}</strong>
        {pageName?.includes('/monitoring') ? t('loading data farm', { ns: namespaces.common }) : t('loading data field', { ns: namespaces.common })}
      </div>

    </Container>
  )
}

export default MonitoringLoading