import styled from 'styled-components'
import { Input, Form } from 'antd'

const { TextArea } = Input

export const TextAreaInput = styled(TextArea)`
  width: 100%;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  background-color: ${({ theme }) => `${theme.colors.THEME} !important`};
  color: ${({ theme }) => `${theme.colors.WHITE} !important`};

  input.ant-input {
    background-color: ${({ theme }) => `${theme.colors.THEME} !important`};
    color: ${({ theme }) => theme.colors.WHITE};
  }
  .ant-input-prefix {
    color: ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    margin-right: 0.5rem;
  }
`


export const FormItem = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-bottom: 0;

  .ant-form-item-label,
  label {
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: 400;
    font-size: 14px;
    text-align: left;

    :before {
      display: none !important;
    }
  }
`
