import styled from 'styled-components'
import { Form } from 'antd'

export const FormItem = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  margin-bottom: 20px;

  .ant-form-item-label,
  label {
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: 400;
    font-size: 14px;
    text-align: left;

    :before {
      display: none !important;
    }
  }

  .ant-upload{
    width: 100%;

    span > div {
      padding: 0;
    }
  }

  .ant-upload-list{
    color: ${({ theme }) => theme.colors.WHITE};
    

    svg{
      color: ${({ theme }) => theme.colors.YELLOW};
    }

    .ant-upload-list-item-info{
      background: ${({ theme }) => `${theme.colors.THEME} !important`};
    }

    .ant-upload-span:hover{
      background: ${({ theme }) => `${theme.colors.THEME} !important`};
    }
  }
`