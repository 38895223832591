import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { TFormCrop } from 'types/crops'

type TPayloadGetCrops = {
  page?: number
  size?: number
  q?:string
  project?: string
  getType?: 'all' | 'pagination'
}

const useCrops = () => {
  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const getAllCrops = async ({ page, size, q, project, getType }:TPayloadGetCrops) => {

    const url = getType === 'all' ? `v2/companies/${project}/crops/all` : `v2/companies/${project}/crops?page=${page}&size=${size}&q=${q}`
    const response = await rest.get(url)

    return response?.data
  }

  const createCrop = async (params:TFormCrop, project:string) => {

    const url = `v2/companies/${project}/crops`
    const response = await rest.post(url, params)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  const updateCrop = async (params:TFormCrop, project:string, idCrop:number) => {

    const url = `v2/companies/${project}/crops/${idCrop}`
    const response = await rest.put(url, params)

    if(response?.status === 200){
      notify({ message: t('item updated successfully', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  const deleteCrop = async (idCrop:number, project:string) => {

    const url = `v2/companies/${project}/crops/${idCrop}`
    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with succes', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  return {
    getAllCrops,
    createCrop,
    updateCrop,
    deleteCrop
  }
}

export default useCrops
