import styled from 'styled-components'

type TpropsTableWrapper = {
  fullscreen: any
}

export const Wrapper = styled.div`
margin-bottom: 1rem;
width: 100%;
display: flex;
justify-content: space-around;
align-items: center;
    flex-wrap: wrap;

 .highcharts-background {
     fill: none
 }
 text {
    fill: ${({ theme }) => theme.colors.WHITE} !important;
    text-shadow:
      -0.75px -0.75px 0 #000,  
      0.75px -0.75px 0 #000,
      -0.75px 0.75px 0 #000,
      0.75px 0.75px 0 #000;
 }
`

export const TableWrapper = styled.div<TpropsTableWrapper>`
    display: flex;
    flex-direction: column;
    width: ${({ fullscreen }) => !fullscreen ? '100%' : 'auto'};
`

export const SubTitle = styled.h6`
    font-size: 14px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.WHITE};
`

export const Table = styled.table`
    border: 1px solid #ffffff20;
    padding: 0;
    border-spacing: 0 3px;

    td, th {
        padding: 0.5rem;
        text-align: center;
        font-size: 11px;
    }
    tr:nth-child(odd) td {
        background-color: ${({ theme }) => theme.colors.THEME};
    }

    tr:nth-child(even) td {
        background-color: ${({ theme }) => theme.colors.LIGHT_THEME};
    }
    thead {
    tr,
    th {
      background-color: ${({ theme }) =>
    `${theme.colors.LIGHT_THEME} !important`};
      border: none;
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};

      th::before {
        display: none;
      }
    }
  }
`