import styled from 'styled-components'

export const DropdownLink = styled.a`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.colors.WHITE} !important;
    
    a:hover, a:active, a:focus {
        color: ${({ theme }) => theme.colors.WHITE};
    }
`

export const Wrapper = styled.div`
    padding: 23px 24px 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    background-color: rgba(33, 67, 95, 0.95);
    width: 415px;
    border-radius: 4px;
`

export const Flag = styled.img`
    width: 20px;
    height: 14px;
`

export const UserName = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    text-align: left;
    color: #fff;
    margin: 3px 8px 0 3px;
`

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    margin: 16px 0;
    background-color: ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
`


export const UserContent = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`

export const UserInfo = styled.div`
    flex: 1;
`

export const Role = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    color: #f37422;
    text-transform: capitalize;
`

export const Edit = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    :hover {
        color: #ffffff;
    }
`

export const UserEmail = styled.h3`
    font-size: 14px;
    color: #fff;
`

export const MenuLink = styled.div`
    display: flex;
    flex-direction: column;
    background-color: transparent;
    gap: 1.25rem;
`

export const Link = styled.a`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 16px;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: left;
    color: #f37422;
    svg {
        margin-bottom: 3px;
    }
    :hover {
        color: #ffffff;
    }
`

export const Logout = styled.a`
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    font-size: 15px;
    line-height: 0.87;
    color: #f37422;
     :hover {
        color: #ffffff;
    }
`

export const DropAvatar = styled.img`
    height: 80px;
    width: 80px;
    border-radius: 80px;
    object-fit: cover;
`