import React, { useMemo, useState } from 'react'
import { useAuth } from 'hooks/useAuth'
import { useParams } from 'react-router-dom'

import AdminSidebar from './AdminSidebar'
import Header from './Header'
import Sidebar from './Sidebar'

import { Container, Main, Content } from './styles'

const MainLayout = ({ children }:any) => {

  const { userData } = useAuth()
  const params:any = useParams()

  const [collapsedSidebar, setCollapsedSidebar] = useState<boolean>(true)

  const haveProjectSelected = useMemo(() => Boolean(params?.project), [params])

  return (
    <Container>
      <Header />
      <Main>
        {!haveProjectSelected && userData?.role === 'admin' ? (
          <AdminSidebar
            isCollapsedSidebar={collapsedSidebar}
            doCollapsedSidebar={setCollapsedSidebar}
          />
        ) : (
          <Sidebar
            isCollapsedSidebar={collapsedSidebar}
            doCollapsedSidebar={setCollapsedSidebar}
          />
        )}
        <Content iscollapsedsidebar={collapsedSidebar} id='main'>
          {children}
        </Content>
      </Main>
    </Container>
  )
}

export default MainLayout
