import React, {
  useContext,
  useCallback,
  useMemo
} from 'react'

import { DialogContext } from 'dialog/context'

import { Card } from './styles'

type TProps = {
  handleClose: ()=> void
  handleConfirmation?: () => void
  isModalVisible: boolean,
  children: React.ReactChild
  id: string,
  hasButtons?:boolean
  title?: string
}

const Modal = ({ handleClose, handleConfirmation, isModalVisible, children, id, hasButtons = false, title }:TProps) => {

  const { removeDialog } = useContext(DialogContext)

  const close = useCallback(
    () => {
      handleClose()
    },
    [id, handleClose, removeDialog]
  )

  const footer = useMemo(() => hasButtons ? hasButtons : null, [hasButtons])

  return (
    <Card title={title} visible={isModalVisible} footer={footer} onOk={handleConfirmation} onCancel={close}>
      {children}
    </Card>
  )
}

export default Modal