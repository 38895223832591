import styled from 'styled-components'
import { Table as Tb } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
`
export const SubMenu = styled.div`
  margin-left: 40px;
`
export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.YELLOW};
    font-size: 17px;
  }
`

export const Title = styled.div`
 display: flex;
 justify-content: start;
 font-size: 18px;
 font-weight: bold;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.72;
 letter-spacing: normal;
 color: ${({ theme }) => `${theme.colors.YELLOW} !important`};
 margin-right: 10px;
`
export const Line = styled.div`
  width: '100%';
  height: 1px;
  background-color: ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  margin-top: 10px;
`

export const Table = styled(Tb)`
  padding: 0;
  margin-left: 20px;
  border-spacing: 0 3px;
  thead {
    tr,
    th {
      background-color: ${({ theme }) => `${theme.colors.AZUL_YVY_WRAPPER_BACKGROUND} !important`};
      border: none; 
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};
      height: 50px;
      
      th::before {
        display: none;
      }
    }
  }

  tr td{
    height: 50px; 
  }

  tr:nth-child(odd) td {
    background-color: ${({ theme }) => theme.colors.AZUL_YVY_WRAPPER_BACKGROUND};
  }

  tr:nth-child(even) td {
    background-color: ${({ theme }) => theme.colors.AZUL_SEC_YVY};
  }

  .ant-table-cell-row-hover {
    background-color: ${({ theme }) => `${theme.colors.THEME} !important`};
  }

  .ant-table-tbody > tr > td {
    border-bottom: ${({ theme }) =>
    `1px solid  ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  }

  

  .ant-table-thead > tr > th {
    border-bottom: ${({ theme }) =>
    `1px solid  ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  }

  .ant-pagination-item{
    background-color: ${({ theme }) => theme.colors.LIGHT_THEME};
    border: ${({ theme }) =>
    `1px solid  ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
    border-radius: 50%;
    width: 38px !important;
    height: 38px !important;
    line-height: 35px !important;

    a{
      width: 38px !important;
    height: 38px !important;
    color: ${({ theme }) => `${theme.colors.WHITE} !important`};
  }
}

.ant-pagination.mini .ant-pagination-item{
  margin-left: 10px;
}

.ant-pagination-prev, .ant-pagination-next{
  line-height: 35px !important;
  svg{
    color: ${({ theme }) => `${theme.colors.WHITE} !important`}};
  }
}

  .ant-pagination-item-ellipsis{
    color: ${({ theme }) => `${theme.colors.WHITE} !important`}};
  }

  .ant-pagination-item-active a{
    background-color: ${({ theme }) => `${theme.colors.BLUEGREEN} !important`};
    border-radius: 50%;
  }

 
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: ${({ theme }) =>
    `${theme.colors.LIGHT_THEME} !important`};
    border:none;
    color: ${({ theme }) => `${theme.colors.WHITE} !important`}};

    .ant-select-arrow{
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};
    }
  }

  .ant-table-empty tbody tr td{
    background-color: ${({ theme }) => `${theme.colors.THEME} !important`};
  }

  .ant-empty-description {
    color: ${({ theme }) => `${theme.colors.YELLOW} !important`};
  }
`
