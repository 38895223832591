import axios from 'axios'

export const storageUserData = 'AGRODIGITAL:userData'
export const storageUserCredentials = 'AGRODIGITAL:userCredentials'
export const storageProjectSelected = 'AGRODIGITAL:projectSelected'
export const storageFieldSelected = 'AGRODIGITAL:fieldSelected'
export const storageFarmSelected = 'AGRODIGITAL:farmSelected'
export const storageGroupsSelected = 'AGRODIGITAL:groupsSelected'
export const storageOneGroupSelected = 'AGRODIGITAL:oneGroupSelected'
export const storageReportsSelected = 'AGRODIGITAL:reportsSelected'
export const storageReporDetails = 'AGRODIGITAL:reportsDetail'
export const storageReportsDetailsImages = 'AGRODIGITAL:reportsDetailImages'

const apiBackEnd = axios.create()

const apiGeo = axios.create()

const apiKml = axios.create()

const busca = async () => {
  try {
    const response = await axios.get('/env/env.json')

    return response.data

  } catch(e) {

    throw 'error'
  }
}

async function getUrl(){
  try {
    return await busca()

  } catch (err) {
    return {
      REACT_APP_SERVER_URL: process.env.REACT_APP_SERVER_URL,
      REACT_APP_SERVER_GEO: process.env.REACT_APP_SERVER_GEO,
      REACT_APP_SERVER_KML: process.env.REACT_APP_SERVER_KML,
    }
  }
}

(async function() {
  if(apiBackEnd.defaults.baseURL && apiGeo.defaults.baseURL){
    return
  }

  const urls = await getUrl()

  apiBackEnd.defaults.baseURL = urls.REACT_APP_SERVER_URL
  apiGeo.defaults.baseURL = urls.REACT_APP_SERVER_GEO
  apiKml.defaults.baseURL = urls.REACT_APP_SERVER_KML

})()

const storageKey = localStorage.getItem(storageUserCredentials)

if (storageKey) {
  apiBackEnd.defaults.headers = JSON.parse(storageKey)
  apiGeo.defaults.headers = JSON.parse(storageKey)
}

export { apiBackEnd, apiGeo, apiKml }