import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ArrowLeftOutlined, DeleteFilled } from '@ant-design/icons'
import { RiPencilFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import useReport from 'repositories/useReport'

import { DialogContext } from 'dialog/context'

import ModalCreateReport from '../ModalCreateReport'
import ModalDeleteReport from '../ModalDeleteReport'

import { Container, ViewPdf, Title, Header, Actions } from './styles'

const PreviewFile = () => {

  const location:any = useLocation()
  const { project }:any = useParams()
  const { t } = useTranslation('namespaces')
  const history = useHistory()
  const reportApi = useReport()
  const { createDialog } = useContext(DialogContext)

  const [fileName, setFileName] = useState<string>('')
  const [urlFile, setUrlFile] = useState<string>('')
  const [id, setId] = useState<number>()
  const [report, setReport] = useState<any>()

  const goBack = () => {
    history.push(`/reports/${project}/field/${report?.field_id}`, { goMonitoringKey: 'reports' })
  }

  const handleEditReport = useCallback(
    async (params:any) => {
      const newReport = await reportApi.updateReportByField({ ...params, field_id: report?.field_id }, project, id )
      setReport((old:any) => ({ ...old,
        field_id: newReport?.field.id,
        file: newReport?.file.filename,
        report_type_id: newReport?.report_type.id,
        urlFile: newReport?.file.url,
      }))

      setFileName(newReport?.file.filename)
      setUrlFile(newReport?.file.url)
    }, [project, id])

  const handleDeleteReport = useCallback(
    async () => {
      await reportApi.removeReportById(project, id)
      goBack()
    }, [id, project])

  const editReportsModal = useCallback(
    () => {
      createDialog({
        id: 'modal-create-report',
        open: false,
        Component: ModalCreateReport,
        props: {
          handleSave: handleEditReport,
          reportData: report,
          projectSelected: project,
          fieldId: report?.field_id
        }
      })
    }, [report])

  const handleDelete = () => {
    createDialog({
      id: 'modal-delete-report',
      open: true,
      Component: ModalDeleteReport,
      props: {
        handleSave: () => {
          handleDeleteReport()
          goBack()
        }
      }
    })
  }

  useEffect(() => {
    if(location?.state?.data){
      setFileName(location?.state?.data.file)
      setUrlFile(location?.state?.data.urlFile)
      setId(location?.state?.data.key)
      setReport(location?.state?.data)
    }
  }, [location])

  return (
    <Container>
      <Header>
        <Title>
          <ArrowLeftOutlined onClick={() => goBack()} />
          {fileName}
        </Title>
        <Actions>
          <div onClick={() => editReportsModal()}>
            <RiPencilFill /> {t('edit', { ns: namespaces.common })}
          </div>
          <div onClick={() => handleDelete()}>
            <DeleteFilled /> {t('delete', { ns: namespaces.common })}
          </div>
        </Actions>
      </Header>
      <ViewPdf data={urlFile} />
    </Container>
  )
}

export default PreviewFile
