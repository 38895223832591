import { apiBackEnd } from 'services/axios'
import useToast from './useToast'
import { useGlobalContext } from 'hooks/useGlobalContext'

const useRest = () => {
  const { notify } = useToast()
  const { addLoading, removeLoading } = useGlobalContext()

  const get = async (
    url: string,
    params: Object = {},
    customHandleError?: (error: any) => any
  ) => {
    try {
      addLoading()
      const _params = {
        params,
      }

      const response = await apiBackEnd.get(url, _params)
      return response

    } catch (error: any) {
      const _error = error as any

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      notify(error.response.data)

    }finally{
      removeLoading()
    }
  }

  const post = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: any) => any
  ) => {
    try {
      addLoading()
      const response = await apiBackEnd.post(url, body)
      return response
    } catch (error: any) {
      const _error = error as any

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      notify(error.response.data)

      return error.response

    }finally{
      removeLoading()
    }
  }

  const put = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: any) => any
  ) => {
    try {
      addLoading()
      const response = await apiBackEnd.put(url, body)
      return response
    } catch (error: any) {
      const _error = error as any

      if (customHandleError) {
        customHandleError(_error)
        return
      }
      notify(error.response.data)

    }finally{
      removeLoading()
    }
  }

  const remove = async (
    url: string,
    customHandleError?: (error: any) => any
  ) => {
    try {
      addLoading()
      const response = await apiBackEnd.delete(url)
      return response
    } catch (error: any) {
      const _error = error as any

      if (customHandleError) {
        customHandleError(_error)
        return
      }
      notify(error.response.data)

    }finally{
      removeLoading()
    }
  }

  return { get, post, put, remove }
}

export default useRest
