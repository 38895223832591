import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { TFormMember, TFormMemberPermissionArray } from 'types/member'

type TPayloadGetMembers = {
  page?: number
  size?: number
  q?:string
  project?: string
  getType?: 'all' | 'pagination'
}

const useMembers = () => {
  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const getAllMembers = async ({ page, size, q, project, getType }:TPayloadGetMembers) => {
    const url = getType === 'all' ? `v2/companies/${project}/members/all` : `v2/companies/${project}/members?page=${page}&size=${size}&q=${q}`
    const response = await rest.get(url)

    return response?.data
  }

  const createMember = async (params:TFormMember, project:string) => {
    const url = `v2/companies/${project}/members`
    const response = await rest.post(url, params)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  const resendConfirmation = async (project:string, memberId: number) => {
    const url = `v2/companies/${project}/members/${memberId}/resend_confirmation`
    const response = await rest.put(url)

    if(response?.status === 200){
      notify({ message: t('email successfully sent', { ns: namespaces.pages.member }), success: true })
    }

    return response?.data
  }

  const getMember = async (project:string, idMember:number) => {
    const url = `v2/companies/${project}/members/${idMember}`
    const response = await rest.get(url)

    return response?.data
  }

  const editMemberPermission = async (permissions:TFormMemberPermissionArray, project:string, idMember:number) => {
    const url = `v2/companies/${project}/members/${idMember}/permissions`
    const response = await rest.put(url, permissions)

    if(response?.status === 200){
      notify({ message: t('item updated successfully', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  const updateMember = async (params:TFormMember, project:string, idMember:number) => {
    const url = `v2/companies/${project}/members/${idMember}`
    const response = await rest.put(url, params)

    if(response?.status === 200){
      notify({ message: t('item updated successfully', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  const deleteMember = async (idMember:number, project:string) => {
    const url = `v2/companies/${project}/members/${idMember}`
    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with succes', { ns: namespaces.errors }), success: true })
    }

    return response?.data
  }

  return {
    getAllMembers,
    createMember,
    resendConfirmation,
    getMember,
    editMemberPermission,
    updateMember,
    deleteMember
  }
}

export default useMembers
