import styled from 'styled-components'

import { Modal } from 'antd'

export const Card = styled(Modal)`
  width: 660px !important;

  .ant-modal-content {
   background-color: transparent !important;
  }

  .ant-modal-close-x {
   background:${({ theme }) => theme.colors.SLIGHT_BLACK_OPACITY};
   width: 30px;
   height: 30px;
   line-height: 33px;

   svg{
    color:${({ theme }) => theme.colors.YELLOW};
   }
  }

  .ant-modal-content{
    border: none !important;
    border-radius: none;
    box-shadow: none;
  }

  .ant-modal-close {
    top: 25px !important;
    right: 32px !important;
  }

`