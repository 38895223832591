import styled from 'styled-components'

export const Container = styled.div`
  right: 15px;
  top: 197px;
  position: absolute;
  z-index: 500;
  width: auto;
  overflow-y: hidden;
`

export const Pallet = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.THEME};
  border-radius: 4px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;

  div {
    width: 12px;
    height: 17px;
    border: 1px solid;
  }
  p {
    margin-left: 2px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 1px;
  }
`
