import React, { createContext, useContext, useState } from 'react'

import map from 'lodash/map'

import { TReports } from 'types/types'
import { TScout } from 'types/report'

type TScoutContext = {
  toEdit:boolean
  setToEdit:React.Dispatch<React.SetStateAction<boolean>>
  setRefForm:any
  handleRotateImage:(rotationCurrent:number, idImg:number | string, key:number) => void
  points:any,
  setPoints:any
  reportSelected:TReports & TScout | undefined
  setReportSelected:React.Dispatch<React.SetStateAction<(TReports & TScout) | undefined>>
}

const ScoutContext = createContext<TScoutContext>({} as TScoutContext)

type TProps = {
  children: React.ReactNode
}

const ScoutContextProvider = ({ children }: TProps) => {

  const [toEdit, setToEdit] = useState<boolean>(false)
  const [refForm, setRefForm] = useState<any>()
  const [points, setPoints] = useState<any>()
  const [reportSelected, setReportSelected] = useState<TReports & TScout | undefined>()

  const handleRotateImage = ( rotationCurrent:number, idImg:number | string, key:number) => {

    let rotation = 0
    if(rotationCurrent === 0){
      rotation = 90
    }

    if(rotationCurrent === 90){
      rotation = 180
    }

    if(rotationCurrent === 180){
      rotation = 270
    }

    const fields = refForm.getFieldsValue()
    const { report_scout_points } = fields

    Object.assign(report_scout_points[key], {
      report_scout_point_attachments:
        map(report_scout_points[key].report_scout_point_attachments, (item:any) => {
          if(item.id === idImg || item.uid === idImg){
            return { ...item, rotation }
          }
          return item
        })
    })

    refForm.setFieldsValue({ report_scout_points })
    setPoints(report_scout_points)
    setReportSelected({ ...refForm.getFieldsValue(), oldComponent: true })
  }

  return (
    <ScoutContext.Provider
      value={{
        toEdit,
        setToEdit,
        setRefForm,
        handleRotateImage,
        points,
        setPoints,
        reportSelected,
        setReportSelected
      }}
    >
      {children}
    </ScoutContext.Provider>
  )
}

const useScoutContext = (): TScoutContext => {
  const context = useContext(ScoutContext)

  return context
}

export { useScoutContext, ScoutContextProvider }
