import React, { createContext, useContext, useState } from 'react'
import { v1 as uuidv1 } from 'uuid'

type TFieldContext = {
  formType: string
  setFormType: Function
  polygons?: Array<any>
  setPolygons: Function
  mapFields?: Array<any>
  setMapFields: Function
  fieldName: string
  setFieldName: Function
  fieldTotalArea: number
  setFieldTotalArea: Function
  importedFileName?: string
  setImportedFileName: Function
  lat?: number
  setLat: Function
  lng?: number
  setLng: Function
  mapCenter: any
  setMapCenter: Function
  groupField?: number
  setGroupField: Function
  farmField?: number
  setFarmField: Function
  farmsOptions?: any
  setFarmsOptions: Function
  mapKey: string
  setMapKey: Function
  handleResetFieldContext: Function
  filekml:any
  setFilekml: Function
  setUpdateCenter:Function
  updateCenter:boolean
}

const FieldContext = createContext<TFieldContext>({} as TFieldContext)

type TProps = {
  children: React.ReactNode
}

const FieldContextProvider = ({ children }: TProps) => {
  const [formType, setFormType] = useState('create')
  const [polygons, setPolygons] = useState<any>([])
  const [mapFields, setMapFields] = useState<any>([])
  const [fieldName, setFieldName] = useState<string>('')
  const [fieldTotalArea, setFieldTotalArea] = useState<number>(0)
  const [importedFileName, setImportedFileName] = useState('')
  const [lat, setLat] = useState(-20.4649)
  const [lng, setLng] = useState(-54.6218)
  const [mapCenter, setMapCenter] = useState({ lat: -20.4649, lng: -54.6218 })
  const [groupField, setGroupField] = useState<any>('')
  const [farmField, setFarmField] = useState<number>()
  const [farmsOptions, setFarmsOptions] = useState<any>([])
  const [mapKey, setMapKey] = useState(uuidv1())
  const [updateCenter, setUpdateCenter] = useState<boolean>(false)

  const [filekml, setFilekml] = useState<any>()

  const handleResetFieldContext = () => {
    setPolygons([])
    setMapFields([])
    setFieldName('')
    setFieldTotalArea(0)
    setImportedFileName('')
    setGroupField(undefined)
    setFarmField(undefined)
    setMapKey(uuidv1())
    setUpdateCenter(true)
    setLat(-20.4649)
    setLng(-54.6218)
    setFormType('create')
    setFilekml(undefined)
  }

  return (
    <FieldContext.Provider
      value={{
        formType,
        setFormType,
        polygons,
        setPolygons,
        mapFields,
        setMapFields,
        fieldName,
        setFieldName,
        fieldTotalArea,
        setFieldTotalArea,
        importedFileName,
        setImportedFileName,
        lat,
        setLat,
        lng,
        setLng,
        mapCenter,
        setMapCenter,
        groupField,
        setGroupField,
        farmField,
        setFarmField,
        farmsOptions,
        setFarmsOptions,
        mapKey,
        setMapKey,
        handleResetFieldContext,
        filekml,
        setFilekml,
        setUpdateCenter,
        updateCenter
      }}
    >
      {children}
    </FieldContext.Provider>
  )
}

const useFieldContext = (): TFieldContext => {
  const context = useContext(FieldContext)

  return context
}

export { useFieldContext, FieldContextProvider }
