import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, FieldName, FieldInfo, Content } from './styles'
import { formatDateToView } from 'utils/helpers'
import { namespaces } from 'i18n/i18n.constants'
import { TFields } from 'types/types'
import { isEmpty } from 'lodash'

type TProps = {
  field: TFields | undefined
}

const FieldBoxInfo = ({ field }: TProps) => {
  const { t } = useTranslation('namespaces')
  if(isEmpty(field)) return null
  return (
    <Container>
      <Content>
        <FieldName>
          {field?.name}
        </FieldName>
        <div>
          <FieldInfo>
            {field?.farmName}
          </FieldInfo>
          <FieldInfo>
            {field?.area ? `${field?.area.toFixed(2)} ha` : 'N/A'}
          </FieldInfo>
        </div>

        <div>
          <div>
            <FieldInfo isYellow>{t('median ndvi', { ns: namespaces.pages.field })}</FieldInfo>
            <FieldInfo>
              {field?.ndvi?.median ? parseFloat(field.ndvi?.median.toString()).toFixed(1) : 'N/A'}
            </FieldInfo>
          </div>

          <div>
            <FieldInfo isYellow>
              {t('crop', { ns: namespaces.pages.field })}
            </FieldInfo>
            <FieldInfo>
              {field?.crop_name ? field?.crop_name : 'N/A'}
            </FieldInfo>
          </div>
        </div>

        <div>
          <div>
            <FieldInfo isYellow>
              {t('culture', { ns: namespaces.pages.field })}
            </FieldInfo>
            <FieldInfo>
              {field?.growth_name ? field?.growth_name : 'N/A'}
            </FieldInfo>
          </div>

          <div>
            <FieldInfo isYellow>
              {t('variety', { ns: namespaces.pages.cropRotationst })}
            </FieldInfo>
            <FieldInfo>
              {field?.growth_variety ? field.growth_variety : 'N/A'}
            </FieldInfo>
          </div>

        </div>

        <div>
          <div>
            <FieldInfo isYellow>
              {t('planting date', { ns: namespaces.pages.cropRotationst })}
            </FieldInfo>
            <FieldInfo>
              {field?.planting_date ? formatDateToView(field?.planting_date) : 'N/A'}
            </FieldInfo>
          </div>

        </div>

      </Content>

    </Container>
  )
}

export default FieldBoxInfo