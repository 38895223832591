import React from 'react'
import Modal from 'components/Modal'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import { MdFileUpload } from 'react-icons/md'
import { FaDrawPolygon } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Wrapper, Title, Text, UploadBtn, UploadBtnInfo, OpenDrawer } from './styles'
// @ts-ignore
import toGeoJSON from '@mapbox/togeojson'
import { useFieldContext } from 'hooks/useFieldContext'

export type TUser ={
  fullname: string
  locale: string
}

export type TUserModal = {
  isModalVisible: boolean
  handleClose: () => void
  projectSelected: string
}

const NewFieldModal = ({ isModalVisible, handleClose, projectSelected }:TUserModal) => {
  const { t } = useTranslation('namespaces')
  const history = useHistory()

  const { setFormType, setFilekml } = useFieldContext()

  const handleOpenNewFieldPage = (event: any) => {
    setFormType('create')
    event?.preventDefault()
    history.push(`/dashboard/${projectSelected}/controller`)

  }

  // Transform the file in GeoJSON and go to new field page
  const handleUploadMapFile = (event: any) => {
    const shapeFile = event.file.originFileObj
    const reader = new FileReader()

    reader.onload = () => {
      const { result }: any = reader
      const xml = new DOMParser().parseFromString(result, 'text/xml')
      const shape = toGeoJSON.kml(xml)
      setFilekml(JSON.stringify({ shape, name: shapeFile.name }))
    }
    reader.readAsText(shapeFile)

    history.push(`/dashboard/${projectSelected}/controller`)
  }

  return (
    <Modal
      title={ t('create a new field', { ns: namespaces.pages.field }) }
      isModalVisible={isModalVisible}
      handleClose={handleClose}
      id='edit-modal-user'
    >
      <Wrapper>
        <Title>{ t('import a file', { ns: namespaces.common }) }</Title>
        <Text>{ t('drag and drop description', { ns: namespaces.pages.field }) }</Text>
        <UploadBtn maxCount={1} accept='.kml' onChange={e => handleUploadMapFile(e)}>
          <MdFileUpload />
          { t('import file', { ns: namespaces.common }) }
        </UploadBtn>
        <UploadBtnInfo>
          <Text>* { t('supported files are in the format', { ns: namespaces.pages.field }) }: </Text>
          <Title>.KML</Title>
        </UploadBtnInfo>
        <Title>{ t('draw the field on the map', { ns: namespaces.pages.field }) }</Title>
        <Text>{ t('draw the field on the map using the drawing tool', { ns: namespaces.pages.field }) }</Text>

        <OpenDrawer onClick={event => handleOpenNewFieldPage(event)}>
          <FaDrawPolygon />
          { t('draw field', { ns: namespaces.pages.field }) }
        </OpenDrawer>
      </Wrapper>
    </Modal>

  )
}

export default NewFieldModal