import React from 'react'

type TProps = {
  size?: number;
  colorOne?: string;
  colorTwo?: string;
};

const Projects = ({
  size = 35,
  colorOne = '#F37422',
  colorTwo = '#f5f6f6',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 49.05 49.05'
    >
      <g>
        <g id='Camada_x0020_1_2_'>
          <g id='_2506779216_1_'>
            <polygon
              fill={colorOne}
              points='14.4,11.8 30.6,11.8 30.6,32.6 32.1,32.6 32.1,33.4 30.6,33.4 14.4,33.4 12.9,33.4 12.9,32.6
				14.4,32.6 			'
            />
            <g>
              <rect x='18.1' y='14.1' fill={colorTwo} width='3.3' height='3.3' />
              <rect x='23.5' y='14.1' fill={colorTwo} width='3.3' height='3.3' />
              <rect x='18.1' y='18.6' fill={colorTwo} width='3.3' height='3.3' />
              <rect x='23.5' y='18.6' fill={colorTwo} width='3.3' height='3.3' />
              <rect x='18.1' y='22.8' fill={colorTwo} width='3.3' height='3.3' />
              <rect x='23.5' y='22.8' fill={colorTwo} width='3.3' height='3.3' />
              <rect x='20.8' y='28' fill={colorTwo} width='3.3' height='4.4' />
            </g>
          </g>
          <circle fill='none' cx='22.5' cy='22.6' r='22.5' />
        </g>
      </g>
    </svg>
  )
}

export default Projects