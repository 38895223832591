import React from 'react'
import { Switch } from 'antd'

import { Container, Label } from './styles'

type TProps ={
  onChange:(e:any)=>void
  value: boolean
  label?: string
  id: string,
  disabled?:boolean
  // color?: string
  size?: 'small' | 'default'
}

const SwitchButton = ({
  onChange,
  value = false,
  label,
  id,
  disabled = false,
  // color,
  size = 'default'
}:TProps) => {

  return (
    <Container>
      <Switch id={id} disabled={disabled} size={size} checked={value} defaultChecked onChange={onChange} />
      {label && <Label>{label}</Label>}
    </Container>
  )
}
export default SwitchButton