import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.THEME_OPACITY};
  height: 100%;
  width: 97%;
  position: absolute;
  z-index: 1050;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;
  left: 50%;
`