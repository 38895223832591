import React from 'react'
import GlobalStyles from './style'
import Routes from './routes'
import Providers from 'providers'

import './i18n'
import './App.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import Schema from 'async-validator'
Schema.warning = function(){}

const App = () => (
  <Providers>
    <Routes />
    <GlobalStyles />
  </Providers>
)

export default App
