import styled from 'styled-components'

import { Form, Radio } from 'antd'

export const FormItem = styled(Form.Item)`

`

export const RadioGroup = styled(Radio.Group)`

`

export const FormRadio = styled(Radio)`

`

export const Title = styled.div`
color: ${({ theme }) => theme.colors.YELLOW};
font-weight: 400;
font-size: 14px;
text-align: left;
margin-top: 2rem;
`