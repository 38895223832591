import styled from 'styled-components'

export const FormBox = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.THEME};
    border-radius: 4px;

    label{
      font-size: 16px;
    }

    span {
      cursor: pointer;
    }

    .ant-select {
    span {
      color: ${({ theme }) => `${theme.colors.WHITE} !important`};
    }
  }

  .ant-select-selector {
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }

  .ant-select-multiple .ant-select-selection-item {
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
    background: ${({ theme }) => theme.colors.THEME};
  }

  .ant-input-affix-wrapper{
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  margin-top: 20px;
`

export const RangeData = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;

 > div:nth-child(1){
  padding-right: 10px; 
 }
`