import React, { useState, useContext, useEffect, useMemo } from 'react'
import { v1 as uuidv1 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { EyeFilled } from '@ant-design/icons'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { formatDateToView, removeTimeFromDateTime } from 'utils/helpers'
import DataTable from 'components/DataTable'
import { Container } from './styles'
import { TGroups } from 'types/types'
import { DialogContext } from 'dialog/context'
import ModalTransferGroup from './ModalTransferGroups'
import ModalGroupForm from './ModalGroupForm'
import ModalFarmForm from './ModalFarmForm'
import { useParams } from 'react-router-dom'
import FarmTable from './FarmTable'
import ModalRemoveFarm from './ModalRemoveFarm'

import map from 'lodash/map'
import { find, forEach, isEmpty } from 'lodash'

const FarmGroups = () => {
  const { t } = useTranslation('namespaces')
  const { groupsSelected } = useGlobalContext()
  const localLanguage = localStorage.getItem('agfarmusLanguage')
  const [searchFarms, setSearchFarms] = useState<string>('')
  const [tableKey, setTableKey] = useState(uuidv1())
  const { createDialog } = useContext(DialogContext)
  const { project }:any = useParams()

  const [groupFiltered, setGroupFiltered] = useState<TGroups[]| undefined>([])

  const formatArrayGroups = useMemo(
    () => {

      const group = find(groupsSelected, (group:TGroups) => group) as TGroups

      if(group?.depth !== undefined ) {
        return groupsSelected
      }

      const recursiveGetGroups = (group: TGroups) => {
        if(!isEmpty(group.groups)){
          forEach(group.groups, (element: TGroups) => {
            recursiveGetGroups(element)
          })
        }
        group.farms?.forEach(filteredFarm => {
          groupsSelected?.forEach(gSelected => {
            gSelected.farms.forEach(selectedFarm => {
              if(filteredFarm.id === selectedFarm.id) {
                filteredFarm.created_at = selectedFarm.created_at
              }
            })
          })
        })
      }
      groupFiltered?.forEach(gFiltered => {
        recursiveGetGroups(gFiltered)
      })
      return groupFiltered
    }, [groupsSelected])

  const columns = [
    {
      title: t('farm', { ns: namespaces.pages.field }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('created at', { ns: namespaces.common }),
      dataIndex: 'created_at',
      key: 'created_at',
      width: '350px',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text, false, true).replace(' ', t('at', { ns: namespaces.pages.farmGroups }))
      }
    },
    {
      title: t('actions', { ns: namespaces.common }),
      dataIndex: 'actions',
      key: 'actions',
      width: '90px',
      render: (item: any, farm: any) => <EyeFilled onClick={() => editFarm(farm.id, farm.name, farm.groupId)} />
    },
  ]

  function handleFilter(filter:string){
    setSearchFarms(filter)
    if (filter === '') {
      setGroupFiltered(formatArrayGroups)
    }else{
      const Groups:TGroups[] = []
      map(groupsSelected, (group:TGroups) => {
        const recursiveGetGroups = (group: TGroups) => {
          if(!isEmpty(group.groups)){
            forEach(group.groups, (element: TGroups) => {
              recursiveGetGroups(element)
            })
          }
          const Farm = group?.farms?.filter(farm => {
            if(farm.name.toLowerCase().includes(filter.toLowerCase())){
              return farm
            }
          })
          if(Farm.length > 0){
            Groups.push({
              farms: Farm,
              id: group.id,
              name: group.name,
              parent_id: group.parent_id,
              groups: group.groups,
              area: group.area,
              depth: group.depth
            })
          }
        }
        recursiveGetGroups(group)
      })
      setGroupFiltered(Groups)
    }
  }

  const createNewGroupFarms = () => {
    createDialog({
      id: 'modal-create-new-group-farms',
      open: false,
      Component: ModalGroupForm,
      props: {
        projectSelected: project,
      }
    })
  }

  const createNewFarm = () => {
    createDialog({
      id: 'modal-create-new-farms',
      open: false,
      Component: ModalFarmForm,
      props: {
        projectSelected: project,
      }
    })
  }

  const editFarm = (id?: number, name?: string, group_id?:number) => {
    createDialog({
      id: 'modal-edit-group-farms-02',
      open: false,
      Component: ModalGroupForm,
      props: {
        projectSelected: project,
        groupToEdit: {
          id,
          name,
          parent_id: group_id,
        },
        farm: true,
        removeGroupCallback: removeFarm
      }
    })
  }

  const removeFarm = (farmId: number, farmName: string, callback: Function) => {
    createDialog({
      id: 'modal-remove-farm',
      open: false,
      Component: ModalRemoveFarm,
      props: {
        projectSelected: project,
        farmToRemove: {
          id: farmId,
          name: farmName,
        },
        callback
      }
    })
  }

  const transferGroups = () => {
    createDialog({
      id: 'modal-create-new-group-farms',
      open: false,
      Component: ModalTransferGroup,
      props: {
        projectSelected: project,
      }
    })
  }


  useEffect(() => {
    setGroupFiltered(formatArrayGroups)
    setTableKey(uuidv1())
  }, [formatArrayGroups, groupsSelected])

  return (
    <Container>
      <DataTable
        key={tableKey}
        hideColumnsFilter
        columns={columns}
        create={() => createNewGroupFarms()}
        createSecondary={() => createNewFarm()}
        labelBtnCreate={t('new something', { ns: namespaces.common, label: t('group', { ns: namespaces.pages.farmGroups }) })}
        labelSecondaryBtnCreate={t('new something a', { ns: namespaces.common, label: t('farm', { ns: namespaces.pages.farmGroups }) })}
        title={t('view all farm groups', { ns: namespaces.pages.farmGroups })}
        labelSearch={t('find a farm', { ns: namespaces.pages.farmGroups })}
        search={searchFarms}
        setSearch={handleFilter}
        getDataFromApi={() => {}}
        customTable={ <FarmTable groupFarmData={groupFiltered} columns={columns} btnBitTransfer={() => transferGroups()} /> }
        hasBackground={false}
      />
    </Container>
  )
}

export default FarmGroups
