import React, { useCallback, useMemo, useState } from 'react'
import { Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import L from 'leaflet'

import pingYellow from 'assets/icons/ping-yellow.png'
import pingRed from 'assets/icons/ping-red.png'
import pingBlue from 'assets/icons/ping-blue.png'
import pingGreen from 'assets/icons/ping-green.png'

import ViewCarouselImage from 'components/ViewCarouselImage'

import { TReports } from 'types/types'
import { TScout } from 'types/report'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { Container } from './styles'

type TRenderImagesReport = {
  reportSelected?: TReports & TScout | undefined
  currentMap: any
}

const RenderImagesReport = ({ reportSelected, currentMap }:TRenderImagesReport ) => {

  const [reload, setReload] = useState<boolean>(false)

  const icon = useCallback((conditions:string) => {
    switch (conditions) {
      case 'bad':
        return pingRed
      case 'excellent':
        return pingBlue
      case 'good':
        return pingGreen
      default:
        return pingYellow
    }
  }, [reportSelected])

  const renderImagesReports = useMemo(() => {
    if (!isEmpty(reportSelected?.report_scout_points)) {
      return (
        <div className='imagen-scout'>
          <MarkerClusterGroup
            disableClusteringAtZoom={1}
          >
            {map(reportSelected?.report_scout_points, ({ latitude, longitude, growing_conditions, report_scout_point_attachments }, index:number) => {
              const imgPoint = icon(growing_conditions)
              const iconMap = L.divIcon({
                html: `<div class="imagen-scout"><img class="imagen-scout-icon" src="${imgPoint}" /> <strong class="leaflet-number">${index + 1}</strong></div>`,
                iconAnchor: [20, 0],
                popupAnchor: [16, 0],
                iconSize: [50, 50],
                className: ''
              })

              return latitude && longitude && (
                <Marker
                  key={index}
                  position={[parseFloat(latitude), parseFloat(longitude)]}
                  icon={iconMap}
                  eventHandlers={
                    {
                      click: () => {
                        currentMap.setView([parseFloat(latitude), parseFloat(longitude)])
                      },
                      mousemove: () => {}
                    }
                  }
                >
                  {!isEmpty(report_scout_point_attachments) && (
                    <Popup onClose={() => setReload(old => !old)}>
                      <ViewCarouselImage imagens={report_scout_point_attachments} isView={reportSelected?.isView} reload={reload} keyForm={index} />
                    </Popup>
                  )}
                </Marker>
              )})}
          </MarkerClusterGroup>
        </div>
      )
    }
    return null
  }, [reportSelected, reload])

  const renderImagesOld = useMemo(() => {
    if (!isEmpty(reportSelected?.attachments)) {
      return (
        <MarkerClusterGroup>
          {map(reportSelected?.attachments, (item: any) => {
            const duckIcon = new L.Icon({
              iconUrl: item.file.url,
              iconRetinaUrl: 'https://i.ya-webdesign.com/images/sample-png-image-download-3.png',
              iconAnchor: new L.Point(20, 0),
              shadowSize: new L.Point(10, 10),
              popupAnchor: new L.Point(16, 0),
              iconSize: new L.Point(50, 50),
              className: item.kind === 'normal' ? 'leaflet-div-icon-green' : 'leaflet-div-icon-red'
            })
            return (
              <Marker
                key={item.id}
                position={[parseFloat(item.latitude), parseFloat(item.longitude)]}
                icon={duckIcon}
                eventHandlers={
                  {
                    click: () => {
                      currentMap.setView(
                        [
                          parseFloat(item.latitude),
                          parseFloat(item.longitude)
                        ],
                      )
                    },
                  }
                }
              >
                <Popup>
                  <img src={item.file.url} alt='' width={200} />
                </Popup>
              </Marker>
            )
          })}
        </MarkerClusterGroup>
      )
    }
    return null
  }, [reportSelected])

  return (
    <Container>
      {
        reportSelected?.oldComponent ? renderImagesReports : renderImagesOld
      }
    </Container>
  )
}

export default RenderImagesReport

