import React from 'react'

type TProps = {
  size?: number;
  colorOne?: string;
  colorTwo?: string;
};

const WeedIcon = ({
  size = 35,
  colorOne = '#F37422',
  colorTwo = '#f5f6f6',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      version='1.1'
      viewBox='0 0 72.95 72.95'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <circle fill='none' className='fil0' cx='36.48' cy='36.48' r='36.48' />
        <path stroke={colorTwo} fill={colorTwo} className='fil1 str0' d='M36.68 55.06c0.13,-5.27 0.25,-30.25 -0.28,-33.19 -0.54,-2.96 -1.74,-3.6 -2.95,-4.25' />
        <path stroke={colorOne} fill={colorTwo} className='fil2' d='M35.26 16.78c-0.51,-1.07 -1.2,-2.42 -2.19,-3.38 -0.98,-0.94 -2.23,-1.46 -3.54,-1.71 -1.31,-0.25 -2.66,-0.25 -4.03,-0.25 -0.04,0.07 -0.07,0.15 0,0.76 0.07,0.61 0.25,1.76 0.79,2.78 0.54,1.03 1.41,1.95 2.38,2.62 0.95,0.67 1.99,1.07 3.02,1.32 1.03,0.25 2.07,0.37 2.86,0.42 0.79,0.06 1.32,0.06 1.87,0.06 -0.33,-0.77 -0.64,-1.55 -1.15,-2.62l-0.01 -0z' />
        <path stroke={colorOne} fill={colorTwo} className='fil2' d='M39.61 26.26c1.15,0 2.63,-0.06 3.85,-0.52 1.22,-0.46 2.19,-1.35 2.96,-2.39 0.76,-1.04 1.32,-2.23 1.89,-3.42 -0.04,-0.06 -0.1,-0.12 -0.65,-0.31 -0.57,-0.19 -1.64,-0.49 -2.77,-0.45 -1.12,0.04 -2.29,0.43 -3.27,1 -0.98,0.57 -1.76,1.29 -2.41,2.1 -0.65,0.8 -1.18,1.65 -1.55,2.32 -0.37,0.67 -0.6,1.15 -0.82,1.62 0.8,0.04 1.62,0.07 2.77,0.07l0 -0.02z' />
        <line stroke={colorTwo} fill='none' className='fil1 str1' x1='19.47' y1='55.2' x2='55.32' y2= '55.31' />
        <path stroke={colorTwo} fill={colorOne} fillRule='nonzero' className='fil3' d='M28.7 53.36c-0.98,-1.43 -2.59,-3.98 -2.92,-5.13 -0.34,-1.15 0.6,-0.89 0.15,-2.33 -0.45,-1.44 -2.29,-4.57 -2.68,-5.96 -0.39,-1.39 0.68,-1.03 0.54,-2.34 -0.14,-1.3 -1.49,-4.27 -1.6,-5.46 -0.11,-1.2 1.01,-0.62 1.17,-2.69 0.16,-2.07 -0.65,-6.79 -0.49,-7.09 0.16,-0.31 1.29,3.8 2.42,5.48 1.13,1.68 2.26,0.94 2.57,1.91 0.31,0.97 -0.21,3.65 0.36,4.85 0.57,1.2 2.23,0.91 2.6,2.38 0.37,1.47 -0.54,4.69 -0.55,6.23 -0.01,1.53 0.9,1.37 1.09,2.74 0.19,1.37 -0.32,4.28 -0.61,6.11 -0.28,1.83 -0.33,2.58 -0.53,2.81 -0.2,0.23 -0.54,-0.07 -1.51,-1.49z' />
        <path stroke={colorTwo} fill={colorOne} fillRule='nonzero' className='fil3' d='M34.61 53.82c0.58,-0.85 1.53,-2.36 1.73,-3.04 0.2,-0.68 -0.36,-0.53 -0.09,-1.38 0.27,-0.85 1.36,-2.71 1.59,-3.53 0.23,-0.82 -0.4,-0.61 -0.32,-1.39 0.08,-0.77 0.88,-2.53 0.95,-3.24 0.07,-0.71 -0.6,-0.37 -0.69,-1.6 -0.09,-1.23 0.39,-4.02 0.29,-4.2 -0.1,-0.18 -0.77,2.25 -1.44,3.24 -0.67,1 -1.34,0.55 -1.52,1.13 -0.18,0.57 0.12,2.16 -0.21,2.87 -0.34,0.71 -1.32,0.54 -1.54,1.41 -0.22,0.87 0.32,2.78 0.33,3.69 0,0.91 -0.53,0.81 -0.65,1.63 -0.11,0.81 0.19,2.54 0.36,3.62 0.17,1.08 0.2,1.53 0.31,1.67 0.12,0.14 0.32,-0.04 0.9,-0.89z' />
        <path stroke={colorTwo} fill={colorOne} fillRule='nonzero' className='fil3' d='M43.34 53.75c0.85,-1.25 2.26,-3.48 2.56,-4.49 0.29,-1 -0.52,-0.78 -0.13,-2.04 0.4,-1.26 2.01,-4 2.35,-5.21 0.34,-1.22 -0.59,-0.9 -0.47,-2.05 0.12,-1.14 1.31,-3.73 1.4,-4.78 0.1,-1.05 -0.88,-0.54 -1.02,-2.36 -0.14,-1.81 0.57,-5.94 0.43,-6.21 -0.14,-0.27 -1.13,3.32 -2.12,4.79 -0.99,1.47 -1.98,0.82 -2.25,1.67 -0.27,0.85 0.18,3.19 -0.32,4.24 -0.5,1.05 -1.95,0.8 -2.28,2.08 -0.33,1.29 0.48,4.11 0.48,5.45 0.01,1.34 -0.79,1.2 -0.96,2.4 -0.17,1.2 0.28,3.74 0.53,5.34 0.25,1.6 0.29,2.26 0.46,2.46 0.17,0.2 0.47,-0.06 1.32,-1.31z' />
        <path stroke={colorTwo} fill={colorOne} fillRule='nonzero' className='fil3' d='M46.07 53.94c0.53,-0.39 1.44,-1.09 1.71,-1.46 0.27,-0.37 -0.1,-0.4 0.25,-0.85 0.35,-0.46 1.41,-1.34 1.72,-1.79 0.32,-0.45 -0.11,-0.46 0.11,-0.91 0.22,-0.45 1.08,-1.34 1.28,-1.75 0.19,-0.41 -0.28,-0.35 -0.07,-1.11 0.21,-0.76 1.1,-2.35 1.09,-2.48 -0.02,-0.13 -0.95,1.19 -1.57,1.65 -0.62,0.46 -0.93,0.05 -1.16,0.35 -0.23,0.31 -0.39,1.33 -0.75,1.69 -0.36,0.36 -0.92,0.04 -1.24,0.52 -0.32,0.48 -0.41,1.75 -0.6,2.3 -0.19,0.55 -0.5,0.38 -0.74,0.84 -0.25,0.47 -0.43,1.57 -0.57,2.27 -0.13,0.69 -0.21,0.97 -0.17,1.07 0.04,0.11 0.2,0.04 0.73,-0.34z' />
      </g>
    </svg>
  )
}

export default WeedIcon