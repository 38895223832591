import { toast } from 'react-toastify'


export type TToastProps = {
  message?: string
  errors?: boolean
  success?: boolean
  warning?: boolean
}

const useToast = () => {
  const notify = ({ message, errors, success }: TToastProps ) => {

    if(success) {
      toast.success(message)

    } else if (errors) {
      toast.error(message)
    }else{
      toast.warn(message)
    }
  }

  return {
    notify
  }
}

export default useToast
