import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'hooks/useAuth'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import { GlobalContextProvider } from 'hooks/useGlobalContext'
import { ConfigProvider } from 'antd'
import { theme } from 'styles'
import { DialogProvider } from 'dialog/context'
import ptBR from 'antd/lib/locale/pt_BR'
import { ProductContextProvider } from 'hooks/useProductContext'
import { FieldContextProvider } from 'hooks/useFieldContext'
import { ScoutContextProvider } from 'hooks/useScoutContext'

type TProps = {
  children: React.ReactNode
}

const Providers = ({ children }: TProps) => (
  <ConfigProvider locale={ptBR}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalContextProvider>
          <ProductContextProvider>
            <FieldContextProvider>
              <ScoutContextProvider>
                <DialogProvider>
                  <ToastContainer
                    position='top-right'
                    autoClose={5000}
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                  />
                  <AuthProvider>
                    {children}
                  </AuthProvider>
                </DialogProvider>
              </ScoutContextProvider>
            </FieldContextProvider>
          </ProductContextProvider>
        </GlobalContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  </ConfigProvider>

)

export default Providers
