import React from 'react'

import ModalConfirm from 'components/Modal/ModalConfirm'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  handleConfirm(id?:any):void
  message:string
  title: string
  colorButtonConfirm?:'link' | 'default' | 'primary' | 'ghost' | 'dashed' | 'text' | undefined
  colorButtonCancel?:'link' | 'default' | 'primary' | 'ghost' | 'dashed' | 'text' | undefined
  labelButtonConfirm?:string
  labelButtonCancel?: string
}

const ModalDeleteReport = ({
  open,
  handleClose,
  id,
  handleConfirm,
  message,
  title,
  colorButtonConfirm = 'dashed',
  colorButtonCancel = 'link',
  labelButtonConfirm = 'delete',
  labelButtonCancel = 'cancel'
}: TProps) => {
  return (
    <ModalConfirm
      id={id}
      title={title}
      isModalVisible={open}
      handleClose={handleClose}
      message={message}
      newLayout
      handleConfirmation={() => {
        handleConfirm()
        handleClose()
      }}
      colorButtonConfirm={colorButtonConfirm}
      colorButtonCancel={colorButtonCancel}
      labelButtonConfirm ={labelButtonConfirm}
      labelButtonCancel = {labelButtonCancel}
    />
  )
}

export default ModalDeleteReport
