import styled from 'styled-components'

export const FormBox = styled.div`
    margin: 10px 0px auto 0px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.THEME};
    border-radius: 4px;

    label{
      font-size: 16px;
    }

    span {
      cursor: pointer;
    }

    > div:nth-child(1){
     margin-button: 10px; 
    }

    .ant-select {
    span {
      color: ${({ theme }) => `${theme.colors.WHITE} !important`};
    }
  }

  .ant-select-selector {
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }

  .ant-select-multiple .ant-select-selection-item {
    border: none;
    background: ${({ theme }) => theme.colors.THEME};
  }

  .ant-input-affix-wrapper{
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }

  label.ant-radio-wrapper {
    display: flex;
    align-items: center !important;
    gap: 0.5rem;
    margin: 0.25rem 0;
    span:last-child{
      h3, p {
        color: ${({ theme }) => theme.colors.WHITE};
        margin: 0;
      }
      h3 {
        font-size: 15px;
      }
      p {
        font-size: 12px;
      }
    }
  }
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  margin-top: 20px;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.YELLOW};
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin-top: 2rem;
`

