import React, { useState } from 'react'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useHistory } from 'react-router-dom'
import { initialValues, validation } from './validations'
import LogoIMG from 'assets/logo-login.png'
import SelectLanguage from '../../components/SelectLanguage'
import {
  Container,
  LoginBox,
  Logo,
  Title,
  PrimaryButton,
  LanguageSwitcher,
  Controller,
  Social,
  TextInput
  // NewAccount,
  // FooterLinks
} from './styles'
import { FaEnvelope } from 'react-icons/fa'
import TextField from 'components/TextField'
import { MdLock } from 'react-icons/md'
import { Checkbox, Form } from 'antd'
import FacebookIcon from 'assets/social-icons/Icon_Facebook.svg'
import InstagramIcon from 'assets/social-icons/Icon_Instagram.svg'
import LinkedinIcon from 'assets/social-icons/Icon_Linkedin.svg'
import TwitterIcon from 'assets/social-icons/Icon_Twitter.svg'
import YoutubeIcon from 'assets/social-icons/Icon_Youtube.svg'
import useLogin from 'repositories/UseLogin'
import isEmpty from 'lodash/isEmpty'

export const socialIcons = [
  { id: 1, src: FacebookIcon, href: '#' },
  { id: 2, src: InstagramIcon, href: 'https://www.instagram.com/yvyagtech/' },
  { id: 3, src: LinkedinIcon, href: 'https://www.linkedin.com/company/yvy-agricultura-digital/' },
  { id: 4, src: YoutubeIcon, href: '#' },
]

export type TLogin ={
  email: string
  password: string
}

const Login = () => {
  const { saveUserData, saveUserCredentials } = useAuth()
  const login = useLogin()
  const history = useHistory()
  const [form] = Form.useForm<TLogin>()
  const { t } = useTranslation('namespaces')

  const onFinish = async (values: TLogin) => {
    const response = await login.requestToken({
      email: values.email,
      password: values.password
    })

    if(isEmpty(response?.data?.data)) return

    saveUserCredentials(response.headers)
    saveUserData(response?.data?.data)
    history.push('/')
  }

  const localLanguage = localStorage.getItem('agfarmusLanguage')
  const [formKey, setFormKey] = useState(localLanguage)

  return (
    <Container>
      <LoginBox>
        <Logo src={LogoIMG} alt='logo' />
        <LanguageSwitcher>
          {t('welcome', { ns: namespaces.pages.login })}
          <SelectLanguage setFormKey={setFormKey} />
        </LanguageSwitcher>
        <Title>{t('title', { ns: namespaces.pages.login })}</Title>
        <Form
          name='basic'
          initialValues={initialValues}
          onFinish={onFinish}
          form={form}
          autoComplete='off'
          key={formKey}
        >
          <TextInput>
            <TextField
              placeholder={t('email', { ns: namespaces.common })}
              type='text'
              name='email'
              startIcon={<FaEnvelope />}
              rules={ validation('email', t)}
              id='input-email'
          
            />
          </TextInput>
          <TextInput>
            <TextField
              placeholder={t('password', { ns: namespaces.common })}
              name='password'
              startIcon={<MdLock />}
              rules={ validation('password', t)}
              id='input-password'
              type='password'
            />
          </TextInput>
          <Controller>
            <Checkbox>{t('remember account', { ns: namespaces.pages.login })}</Checkbox>
            <a href='/forgot-password'>{t('forgot password', { ns: namespaces.pages.login })}</a>
          </Controller>
          <PrimaryButton
            htmlType='submit'
            type='primary'
          >
            {t('loginBtn', { ns: namespaces.pages.login })}
          </PrimaryButton>
        </Form>
        {/* <NewAccount>
          Não tem uma conta?
          <a href=''>Faça o cadastro</a>
        </NewAccount> */}
        <Social>
          
        {
           socialIcons.map(icon => (
            <a href={icon.href} key={icon.id} target='_blank' rel='noreferrer'>
              <img src={icon.src} style={{ width: '31.5px' }} alt='' />
            </a>
          ))
        }
          
        </Social>
        {/* <FooterLinks>
          Eu concordo com os <a href=''> Termos e Condições</a> e com a <a href=''>Política de
Privacidade</a> da AgFarmus
        </FooterLinks> */}
      </LoginBox>
    </Container>
  )
}

export default Login