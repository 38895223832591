import styled from 'styled-components'


export const ChartsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`

export const Chart = styled.div<{fullscreen?: boolean}>`
    width:  ${({ fullscreen }) => fullscreen ? '48%' : '100%'};
`

export const ChartGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    margin: 1rem 0 2.5rem 0;
    :last-child {
        margin: 0;
    }
`

export const ChartGroupTitle = styled.h5`
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: bold;
    font-size: 16px;
    display: flex ;
    align-items: center;
    gap: 0.5rem;
`
