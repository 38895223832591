import React, { useCallback, useEffect, useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Virtual, Keyboard, Mousewheel, Navigation, Pagination, Scrollbar, Parallax, Zoom, Lazy, Controller,
  A11y, History, HashNavigation, Autoplay, EffectFade, EffectCube, EffectFlip, EffectCoverflow, Thumbs
} from 'swiper'

import { useScoutContext } from 'hooks/useScoutContext'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { AiOutlineRedo } from 'react-icons/ai'

import ModalImage from 'components/Modal/ModalImage'

import { TScoutPointImage } from 'types/report'

import map from 'lodash/map'

import { theme } from 'styles'
import { Img, CarouselImage, ConfigCarousel, Arrow, Icons } from './styles'

const ModalCarouselImage = ({ handleClose, id, open, imagens, isView, currentIndex, keyForm }:any) => {

  const { handleRotateImage } = useScoutContext()
  SwiperCore.use([
    Virtual, Keyboard, Mousewheel, Navigation, Pagination, Scrollbar, Parallax, Zoom, Lazy,
    Controller, A11y, History, HashNavigation, Autoplay, EffectFade, EffectCube, EffectFlip, EffectCoverflow, Thumbs
  ])

  const [currentImages, setCurrentImages] = useState<TScoutPointImage[]>([])

  const handleRotate = useCallback(
    ( rotationCurrent:number, idImg:number | string) => {
      let rotation = 0
      if(rotationCurrent === 0){
        rotation = 90
      }

      if(rotationCurrent === 90){
        rotation = 180
      }

      if(rotationCurrent === 180){
        rotation = 270
      }

      const newImgs = map(imagens, (item: TScoutPointImage) => {
        if (item.id === idImg || item.uid === idImg) {
          return { ...item, rotation }
        }
        return item
      }) as unknown as TScoutPointImage[]

      setCurrentImages(newImgs)
    }, [])

  const rotateImage = useCallback(
    (rotation:number, id:number | string) => {

      handleRotateImage(rotation, id, keyForm)
      handleRotate(rotation, id)
    }, [keyForm])

  useEffect(() => {

    setCurrentImages(imagens)

    return () => {
      setCurrentImages([])
    }
  }, [imagens])

  return (
    <ModalImage
      id={id}
      isModalVisible={open}
      handleClose={handleClose}
    >
      <div>
        <CarouselImage
          slidesPerView={1}
          initialSlide={currentIndex}
          dir='ltr' //'rtl' | 'ltr'
          navigation={{
            nextEl: '.slider-next',
            prevEl: '.slider-prev'
          }}
          className='mySwiper'
        >
          {map(currentImages, ({ image_url, id, uid, rotation, originFileObj }:TScoutPointImage) => {
            const url = originFileObj ? window.URL.createObjectURL(originFileObj) : image_url
            return(
              <SwiperSlide key={id}>
                <Img src={url} rotate={rotation} />
                <Icons>
                  <div onClick={() => isView ? handleRotate(rotation, id) : rotateImage(rotation, id || uid)}>
                    <AiOutlineRedo style={{ color: theme.colors.YELLOW }} />
                  </div>
                </Icons>
              </SwiperSlide>
            )})}
        </CarouselImage>
        <ConfigCarousel>
          <Arrow className='slider-prev' onClick={() => setCurrentImages(isView ? imagens : currentImages)}>
            <LeftOutlined />
          </Arrow>
          <Arrow className='slider-next' onClick={() => setCurrentImages(isView ? imagens : currentImages)}>
            <RightOutlined />
          </Arrow>
        </ConfigCarousel>
      </div>
    </ModalImage>
  )
}

export default ModalCarouselImage