import React, { useState, useEffect, useMemo } from 'react'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

import map from 'lodash/map'

import useReport from 'repositories/useReport'
import useProduct from 'repositories/useProduct'

import Modal from 'components/Modal'
import Button from 'components/Button'
import SelectField from 'components/SelectField'
import UploadButton from 'components/UploadButton'

import {
  initialValues,
  validation,
} from './validations'
import {
  FormBox,
  ContainerBtn,
} from './styles'
import isEmpty from 'lodash/isEmpty'


type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  handleSave(values:any):void
  reportData: any
  projectSelected: string
  fieldId: number
}

const ModalCreateReport = ({ open, handleClose, id, handleSave, reportData, projectSelected, fieldId }: TProps) => {

  const [form] = Form.useForm<any>()
  const { t } = useTranslation('namespaces')

  const reportApi = useReport()
  const productApi = useProduct()

  const [type, setType] = useState<any>([])
  const [product, setProduct] = useState<any>([])

  const valuesForm = useMemo(() => {
    if (!isEmpty(reportData)) {
      return {
        ...initialValues,
        project_id: reportData?.project_id,
        report_type_id: reportData?.report_type_id,
      }
    }

    return initialValues
  }, [])

  const onFinish = (values:any) => {
    handleSave({ ...values, file: values.file[0] })
    handleClose()
  }

  const closeModal = () => {
    handleClose()
  }

  const typeOptions = useMemo<any[]>(
    () => map(type, (item:any) => ({ value: item?.id, label: item?.name }))
    , [type])

  const productOptions = useMemo<any[]>(
    () => map(product, (item:any) => ({ value: item?.id, label: `${item?.actable_type} - ${item?.reference_year}` }))
    , [product])

  useEffect(() => {
    const request = async () => {
      const response = await reportApi.getReportTypes()
      const responseProduct = await productApi.getAllProductsByFieldIdReports(projectSelected, fieldId)

      setType(response)
      setProduct(responseProduct)
    }
    request()
  }, [projectSelected, fieldId])

  return (
    <Modal
      id={id}
      title={t('new reports', { ns: namespaces.pages.reports })}
      isModalVisible={open}
      handleClose={closeModal}
    >
      <FormBox>
        <Form
          initialValues={valuesForm}
          labelCol={{ span: 6 }}
          name='form-rotationst'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
        >
          <SelectField
            name='project_id'
            rules={validation('project_id', t)}
            label={t('product', { ns: namespaces.pages.field })}
            id='select-product'
            placeholder={t('select one', { ns: namespaces.modals.createRotationst, anything: t('product', { ns: namespaces.pages.field }) })}
            options={productOptions}
            showSearch
          />
          <SelectField
            name='report_type_id'
            rules={validation('report_type_id', t)}
            label={t('type', { ns: namespaces.common })}
            id='select-report_type'
            placeholder={t('select one', { ns: namespaces.modals.createCrop, anything: t('type', { ns: namespaces.common }) })}
            options={typeOptions}
            showSearch
          />

          <UploadButton
            label={t('file', { ns: namespaces.common })}
            name='file'
            rules={validation('file', t, false)}
            disable={false}
          />

          <ContainerBtn>
            <Button
              id='button-cancel'
              type='default'
              label={t('cancel', { ns: namespaces.common })}
              onClick={closeModal}
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalCreateReport
