import useRest from 'hooks/useRest'
import { TGrowth } from 'types/growths'

const useGrowths = () => {
  const rest = useRest()

  const getAllGrowths = async (idGrowths:number) => {

    const url = `/v2/growths/${idGrowths}/growth_varieties/all`
    const response = await rest.get(url)

    return response?.data as unknown as TGrowth[]
  }

  const getAll = async () => {

    const url = '/v2/growths/all'
    const response = await rest.get(url)

    return response?.data as unknown as TGrowth[]
  }

  const getGrowthStages = async (id:number) => {

    const url = `v2/growths/${id}/growth_stages`
    const response = await rest.get(url)

    return response?.data?.growth_stages
  }

  return {
    getAllGrowths,
    getAll,
    getGrowthStages
  }
}

export default useGrowths
