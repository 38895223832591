import React, {
  useContext,
  useCallback
} from 'react'

import { Card } from './styles'

import { DialogContext } from 'dialog/context'

type TProps = {
  handleClose: ()=> void
  isModalVisible: boolean,
  children: React.ReactChild
  id: string,
}

const ModalImage = ({ handleClose, isModalVisible, children, id }:TProps) => {

  const { removeDialog } = useContext(DialogContext)

  const close = useCallback(
    () => {
      handleClose()
    },
    [id, handleClose, removeDialog]
  )

  return (
    <Card visible={isModalVisible} onCancel={close} footer={false} width={1000}>
      {children}
    </Card>
  )
}

export default ModalImage