import React, { useMemo, Suspense } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import AuthLayout from 'layouts'
import Loading from 'components/Loading'

type TProps = {
  children: React.ReactNode
}

const DefaultLayout = ({ children }: TProps) => {
  return (<>{children}</>)
}

type TPrivateRouteProps = Omit<RouteProps, 'component'> & {
  component: React.ElementType
  isPrivate?: boolean
}

const Wrapper = (props: TPrivateRouteProps) => {
  const { component: Component, isPrivate = false, ...remainingProps } = props

  const { isSignedIn, hasToken } = useAuth()

  const Layout = useMemo(
    () => {
      if(isSignedIn){
        return AuthLayout
      }

      return DefaultLayout
    }, [isSignedIn]
  )

  if (!isSignedIn && !hasToken() && isPrivate) return <Redirect to='/login' />

  return (
    <Route
      {...remainingProps}
      render={routeProps => (
        <Layout>
          <Suspense fallback={<Loading />}>
            <Component {...routeProps} />
          </Suspense>
        </Layout>
      )}
    />
  )
}

export default Wrapper
