import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;

  .ant-switch{
    background-color: ${({ theme }) => theme.colors.GRAY};
  }

  .ant-switch-checked{
    background-color: ${({ theme }) => theme.colors.YELLOW};
  }

  .ant-switch-handle::before {
    background-color:  ${({ theme }) => theme.colors.THEME};
}
`

export const Label = styled.div`

color: ${({ theme }) => theme.colors.WHITE};
padding-left: 15px;

`
