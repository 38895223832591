import React from 'react'

import { Line } from './styles'

type TProps = {
  type:'vertical' | 'horizontal' | undefined
  height?: string
  orientation?: 'left' | 'right' | 'center'
  orientationMargin?: string | number
}

const Divider = ({ type, height, orientation = 'center' }:TProps) => {
  return (
    <Line type={type} height={height} orientation={orientation} />
  )
}

export default Divider
