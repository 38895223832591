import React, { useMemo } from 'react'
import Divider from 'components/Divider'
import { useHistory, useLocation } from 'react-router-dom'
import { useGlobalContext } from 'hooks/useGlobalContext'

import map from 'lodash/map'

import {
  Container,
  Logo,
  Content,
  // Notification,
  // CustomIconBell,
  Text,
  NavigationMenu,
  NavigationItem
} from './styles'
import UserDropdown from './UserDropdown'
import { isEqual, remove } from 'lodash'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

// import InputSearch from './InputSearch'

// const IconBell = <CustomIconBell />

const Header = () => {

  const { t } = useTranslation('namespaces')
  const history = useHistory()
  const global = useGlobalContext()
  const location:any = useLocation()

  const directsToProject = () => {
    global.removeProjectSelected()
    history.push('/')
  }

  const breadcrumb = useMemo(
    () => {
      if(location?.pathname){
        const list = location.pathname.split('/')
        const newlist = remove(list, s => !isEqual(s, ''))
        return newlist.slice(0, 1)
      }

      return []

    }, [location])

  return (
    <Container>
      <Content>
        <Logo onClick={directsToProject} />
        <Text>
          <Divider type='vertical' />
          <NavigationMenu>
            {map(breadcrumb, (item:string, index: React.Key | null | undefined) => (
              <NavigationItem key={index}>
                {t(item, { ns: namespaces.layout })}
              </NavigationItem>
            ))}
          </NavigationMenu>
        </Text>
      </Content>
      <Content>
        {/* <InputSearch />
        <Divider type='vertical' />
        <Notification icon={IconBell} /> */}
        {/* <Divider type='vertical' /> */}
        <UserDropdown />
      </Content>
    </Container>
  )
}

export default Header