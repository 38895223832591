import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { Avatar, Dropdown } from 'antd'
import { FaAngleDown,
  FaUserFriends,
  FaFolder,
  FaRegCalendar,
  // FaBuilding,
  FaCog
} from 'react-icons/fa'
import { RiPencilFill,
  RiDashboardFill
} from 'react-icons/ri'
// import { MdDeviceHub } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom'

import { DropdownLink, Wrapper, Flag, UserName, Divider, UserContent,
  UserInfo,
  Role,
  Edit,
  UserEmail,
  MenuLink,
  Link,
  Logout,
  DropAvatar
} from './styles'
import UserImage from 'assets/user.png'
import BrFlag from 'assets/flags/br-flag.svg'
import PaFlag from 'assets/flags/pa-flag.png'
import UsaFlag from 'assets/flags/usa-flag.svg'
import EditUserModal from 'modules/EditUserModal'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { DialogContext } from 'dialog/context'
import useAuthRequest from 'repositories/useAuthRequest'

const UserDropdown = () => {
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const { userData, removeUserData } = useAuth()
  const { editUser, editUserPassword } = useAuthRequest()
  const global = useGlobalContext()
  const history = useHistory()
  const params:any = useParams()

  const logout = () => {
    removeUserData()
    history.push('/login')
  }

  const userMenu = useMemo(() => {
    return [
      {
        title: t('members', { ns: namespaces.pages.member }),
        link: `/members/${params.project}`,
        icon: <FaUserFriends />
      },
      {
        title: t('farmGroups', { ns: namespaces.pages.farmGroups }),
        link: `/farms/${params.project}`,
        icon: <FaFolder />
      },
      {
        title: t('crops', { ns: namespaces.common }),
        link: `/crops/${params.project}`,
        icon: <FaRegCalendar />
      },
      // {
      //   title: 'Perfil da empresa',
      //   link: '',
      //   icon: <FaBuilding />
      // },
      {
        title: t('settings field report', { ns: namespaces.layout }),
        link: `/settings/report/${params.project}`,
        icon: <RiDashboardFill />
      },
      // {
      //   title: 'Integrações',
      //   link: '',
      //   icon: <MdDeviceHub />
      // }
    ]}, [params])

  const directsToProject = () => {
    global.removeProjectSelected()
    history.push('/')
  }

  const handleEditUser = useCallback(
    () => {
      createDialog({
        id: 'modal-edit-user',
        open: false,
        Component: EditUserModal,
        props: {
          userData,
          editUser,
          editUserPassword
        }
      })
    }, [ userData, editUser, editUserPassword])

  const items = (
    <Wrapper>
      <UserContent>
        <DropAvatar src={userData?.image?.url || UserImage} alt='user image' />
        <UserInfo>
          <Role>
            {userData?.role}
            <Edit onClick={handleEditUser}>
              <RiPencilFill /> {t('edit', { ns: namespaces.common })}
            </Edit>
          </Role>
          <UserName>
            <Flag src={userData?.locale === 'es' ? PaFlag : userData?.locale === 'en' ? UsaFlag : BrFlag} />
            {userData?.name}
          </UserName>
          <UserEmail>
            {userData?.email}
          </UserEmail>
        </UserInfo>
      </UserContent>
      {params.project && (
        <>
          <Divider />
          <MenuLink>
            {userMenu.map((item, idex) => (
              <Link key={idex} onClick={() => history.push(item.link)}>{item.icon} {item.title}</Link>
            ))}
          </MenuLink>
          {userData?.role === 'admin' && (
            <>
              <Divider />
              <Link onClick={directsToProject}><FaCog />{t('platform management', { ns: namespaces.layout })}</Link>
            </>
          )}
        </>
      )}
      <Divider />
      <Logout onClick={() => logout()}>{t('exit', { ns: namespaces.layout })}</Logout>
    </Wrapper>
  )

  return (
    <>
      <Dropdown
        overlay={items}
        trigger={['click']}
        placement='bottomRight'
      >
        <DropdownLink>
          <UserName>
            <Flag src={userData?.locale === 'es' ? PaFlag : userData?.locale === 'en' ? UsaFlag : BrFlag} />
            {userData?.name}
          </UserName>
          <FaAngleDown />
          <Avatar src={userData?.image?.url || UserImage} />
        </DropdownLink>
      </Dropdown>
    </>
  )
}

export default UserDropdown
