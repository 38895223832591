import styled from 'styled-components'
import { Form } from 'antd'

export const Container = styled.div`
  margin: 5px 0;
  width: 100%;

  .ant-picker {
    background: ${({ theme }) => `${theme.colors.THEME} !important`};
    border: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};

  }

  .ant-picker-input > input{
    color: ${({ theme }) => `${theme.colors.WHITE} !important`};
  }

  .ant-picker-range-separator {
    svg {
      color: ${({ theme }) => `${theme.colors.WHITE} !important`};
    }
  }

  .ant-picker-suffix{
    color: ${({ theme }) => `${theme.colors.WHITE} !important`};
  }
  
`

export const FormItem = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 50%;
  margin-bottom: 0;

  .ant-form-item-label{
    width: 100%;

    label {
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: 400;
    font-size: 14px;
    text-align: left;

    :before {
      display: none !important;
    }
  }
  }
  
`
