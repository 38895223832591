import React, { useEffect, useState, useContext, useCallback } from 'react'
import { SwiperSlide } from 'swiper/react'

import { useScoutContext } from 'hooks/useScoutContext'
import { DialogContext } from 'dialog/context'

import ModalCarouselImage from 'components/ModalCarouselImage'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { AiOutlineRedo } from 'react-icons/ai'

import { TScoutPointImage } from 'types/report'

import map from 'lodash/map'

import { theme } from 'styles'
import { Container, Img, CarouselImage, ConfigCarousel, Arrow, Icons } from './styles'

const ViewCarouselImage = ({ imagens, isView, reload, keyForm }:any) => {

  const { handleRotateImage } = useScoutContext()
  const { createDialog } = useContext(DialogContext)

  const [currentImages, setCurrentImages] = useState<TScoutPointImage[]>([])

  const handleRotate = ( rotationCurrent:number, idImg:number | string) => {
    let rotation = 0
    if(rotationCurrent === 0){
      rotation = 90
    }

    if(rotationCurrent === 90){
      rotation = 180
    }

    if(rotationCurrent === 180){
      rotation = 270
    }

    const newImgs = map(currentImages, (item: TScoutPointImage) => {
      if (item.id === idImg || item.uid === idImg) {
        return { ...item, rotation }
      }
      return item
    }) as unknown as TScoutPointImage[]

    setCurrentImages(newImgs)
  }

  const rotateImage = (rotation:number, id:number | string) => {
    handleRotate(rotation, id)
    handleRotateImage(rotation, id, keyForm)
  }

  const viewModalImage = useCallback(
    (index:any) => {
      createDialog({
        id: 'modal-image',
        open: false,
        Component: ModalCarouselImage,
        props: {
          imagens: currentImages,
          currentIndex: index,
          keyForm,
          isView
        }
      })
    }, [currentImages, imagens, isView, keyForm])


  useEffect(() => {
    setCurrentImages(imagens)

    return () => {
      setCurrentImages([])
    }
  }, [imagens, reload])

  return (
    <Container>
      <CarouselImage
        slidesPerView={1}
        dir='ltr'
        navigation={{
          nextEl: '.slider-next-image',
          prevEl: '.slider-prev-image'
        }}
        className='mySwiper'
      >
        {map(currentImages, ({ image_url, id, uid, rotation, originFileObj }:TScoutPointImage, index:number) => {
          const url = originFileObj ? window.URL.createObjectURL(originFileObj) : image_url
          return(
            <SwiperSlide key={id}>
              <Img src={url} rotate={rotation} onClick={() => viewModalImage(index)} />
              <Icons>
                <div onClick={() => isView ? handleRotate(rotation, id || uid) : rotateImage(rotation, id || uid)}>
                  <AiOutlineRedo style={{ color: theme.colors.YELLOW }} />
                </div>
              </Icons>
            </SwiperSlide>
          )})}
      </CarouselImage>
      <ConfigCarousel>
        <Arrow className='slider-prev-image' onClick={() => setCurrentImages(imagens)}>
          <LeftOutlined />
        </Arrow>
        <Arrow className='slider-next-image' onClick={() => setCurrentImages(imagens)}>
          <RightOutlined />
        </Arrow>
      </ConfigCarousel>
    </Container>
  )
}

export default ViewCarouselImage