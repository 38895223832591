import styled from 'styled-components'

export const Table = styled.table`
    border: 1px solid #ffffff20;
    padding: 0;
    border-spacing: 0 3px;

    td, th {
        padding: 0.5rem;
        text-align: center;
        font-size: 11px;
    }
    tr:nth-child(odd) td {
        background-color: ${({ theme }) => theme.colors.THEME};
    }

    tr:nth-child(even) td {
        background-color: ${({ theme }) => theme.colors.LIGHT_THEME};
    }
    thead {
    tr,
    th {
      background-color: ${({ theme }) =>
    `${theme.colors.LIGHT_THEME} !important`};
      border: none;
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};

      th::before {
        display: none;
      }
    }
  }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 10px;
`

export const Title = styled.h6`
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    margin: 0;
`
