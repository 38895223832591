import React, { useState, Fragment, useMemo, useCallback, useRef } from 'react'
import { SearchOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import useOnClickOutside from 'hooks/useOnClickOutside'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import reduce from 'lodash/reduce'

import { TGroups } from 'types/types'

import { Card, Menu, SubMenu, Item, SearchInput, DropdownContainer, ButtonListFarm, Container } from './styles'

type TProps ={
  groups: TGroups[] | undefined
  id:string
  handleChange:(type:'farm' | 'farm_group' | 'all', id:number | null)=>void
}

const SelectGroupsByFarms = ({ id, groups, handleChange }:TProps) => {

  const { t } = useTranslation('namespaces')
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>

  const [search, setSearch] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [label, setLabel] = useState<string>(t('all farms', { ns: namespaces.common }))

  useOnClickOutside(ref, () => setOpen(false))

  const formatArrayGroups = useMemo(
    () => {
      return groups
    }, [groups])

  const filterGroups = useCallback(
    (groups:any) => {
      if (!search && search.length < 3) return formatArrayGroups

      return reduce(groups, (filtered:any, group:any) => {
        const farms = filter(group.farms, item => item.name && item.name.toUpperCase().includes(search.toUpperCase()))
        const children = filterGroups(group.children)

        if (
          farms.length > 0 ||
            children.length > 0 ||
            (group.name && group.name.toUpperCase().includes(search.toUpperCase()))
        ) {
          filtered.push({
            id: group.id,
            name: group.name,
            isGroup: true,
            farms,
            children,
          })
        }
        return filtered
      }, [])
    },
    [formatArrayGroups, search],
  )

  const handleGet = (type:'farm' | 'farm_group' | 'all', id:number | null, name:string) => {
    handleChange(type, id)
    setLabel(name)
    setOpen(false)
  }

  const optionsGroups = useMemo(() => filterGroups(formatArrayGroups), [formatArrayGroups, search])

  const renderSubgroups = (children: any) => {
    return !isEmpty(children) && (
      <SubMenu>
        {map(children, (item, index) => (
          <Fragment key={index}>
            <Item
              main
              onClick={() => handleGet('farm_group', item.id, item.name) }
            >{item.name}</Item>
            <Menu>
              <SubMenu>
                {map(item.farms, ({ name, id }, index) => (
                  <Item
                    key={index}
                    onClick={() => handleGet('farm', id, name)}
                  >{name}</Item>
                ))}
              </SubMenu>
            </Menu>
            {item.groups && renderSubgroups(item.groups)}
          </Fragment>
        ))}
      </SubMenu>
    )
  }

  return (
    <Container id={id} ref={ref}>
      <ButtonListFarm onClick={() => setOpen(old => !old)}>
        <strong>{label}</strong>
        {open ?
          <UpOutlined style={{ fontSize: '13px' }} />
          :
          <DownOutlined style={{ fontSize: '13px' }} />}
      </ButtonListFarm>
      {open && (
        <DropdownContainer>
          <Card>
            <SearchInput
              id='input-search-farm'
              name='searchFarm'
              value={search}
              onChange={(e:any) => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
            />
            <Menu>
              {map(optionsGroups, ({ name, id, groups, farms }, index) => (
                <Fragment key={index}>
                  <Item main onClick={() => handleGet('farm_group', id, name)}>
                    {name}
                  </Item>
                  { !isEmpty(farms) && (
                    map(farms, ({ name, id }, index) => (
                      <Fragment key={index}>
                        <Item onClick={() => handleGet('farm', id, name)}>
                          {name}
                        </Item>
                      </Fragment>
                    ))
                  )}
                  { !isEmpty(groups) && renderSubgroups(groups)}
                </Fragment>
              ))}
              <Item main onClick={() => handleGet('all', null, t('all farms', { ns: namespaces.common })) }>
                {t('all farms', { ns: namespaces.common })}
              </Item>
            </Menu>
          </Card>
        </DropdownContainer>
      )}
    </Container>
  )
}

export default SelectGroupsByFarms