import React, { createContext, useReducer, useMemo } from 'react'
import {
  dialogReducer,
  DialogActions,
  Types,
  TDialogs
} from './reducer'

import map from 'lodash/map'

type TDialog = {
  id:string;
  open:boolean
  Component: React.FunctionComponent | JSX.Element | any
  props:object
};

type TInitialState = {
  dialogs: TDialogs[];
};

const initialState = {
  dialogs: []
}

const DialogContext = createContext<{
  state: TInitialState;
  createDialog: (dialog:TDialog )=>void;
  removeDialog: (id:string )=>void;
  resetDialogs: () => void
}>({
      state: initialState,
      createDialog: () => {},
      removeDialog: () => {},
      resetDialogs: () => {}
    })

const mainReducer = (
  { dialogs }: TInitialState,
  action: DialogActions
) => ({
  dialogs: dialogReducer(dialogs, action),
})

const DialogProvider: React.FC = ({ children }) => {
  const [dialogs, dispatch] = useReducer(mainReducer, initialState)

  const createDialog = (dialog:TDialog ) => dispatch({ type: Types.Create, payload: dialog })

  const removeDialog = (id:string ) => {
    dispatch({ type: Types.Close, payload: { id } })
    setTimeout(() => {
      dispatch({ type: Types.Remove, payload: { id } })
    }, 300)
  }

  const resetDialogs = () => dispatch({ type: Types.Reset, payload: {} })

  const state = dialogs

  const renderedDialogs = useMemo(
    () => {
      return map(dialogs.dialogs, ({
        id,
        open,
        Component,
        props: dialogProps = {}
      }:TDialog) => {

        return (
          <Component
            key={ id }
            id={ id }
            handleClose={ () => removeDialog(id) }
            open={ open }
            { ...dialogProps }
          />
        )
      })
    },
    [dialogs]
  )

  return (
    <DialogContext.Provider value={{ state, createDialog, removeDialog, resetDialogs }}>
      {children}
      {renderedDialogs}
    </DialogContext.Provider>
  )
}

export { DialogProvider, DialogContext }