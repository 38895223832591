import React, { useMemo, useState, useCallback, Fragment, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { DialogContext } from 'dialog/context'

import { useScoutContext } from 'hooks/useScoutContext'

import SoloCheck from 'components/Icons/SoloCheck'
import NutriAgro from 'components/Icons/NutriAgro'
import Signematoide from 'components/Icons/Signematoide'
import YeldComponent from 'components/Icons/YeldComponent'
import Survey from 'components/Icons/Survey'
import BugIcon from 'components/Icons/BugIcon'
import HealthIcon from 'components/Icons/HealthIcon'
import WeedIcon from 'components/Icons/WeedIcon'
import ViewScout from './Scout/ViewScout'

import ViewFieldReport from './ViewFieldReports'
import ModalDeleteReport from './Scout/ModalDeleteReport'

import { forEach, map, groupBy, reduce, filter, find, isEmpty } from 'lodash'
import { formatFullDateToView, getHours } from 'utils/helpers'

import { TReports, TSelectOption } from 'types/types'
import { TScout } from 'types/report'

import FilterIcon from 'components/Icons/FilterIcon'
import ClockIcon from 'components/Icons/ClockIcon'
import UserIcon from 'components/Icons/UserIcon'

import FilterList from './FilterList'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'

import {
  Content,
  Icon,
  HeaderMenuList,
  ListContainer,
  List,
  Item
} from './styles'

type TDataReports = {
  reportsData: [] | undefined
  setReportSelected:React.Dispatch<React.SetStateAction<TReports & TScout | undefined>>
  reportSelected: TReports & TScout | undefined
  projectSelected: string
  monitoringDate: number
  imageType: TSelectOption
  searchInfosTimeLine: Function
  searchDatesCalendar: Function
  setDateSelected: Function
  typeSatellite: Object
}

const FieldReports = ({ reportsData, setReportSelected, reportSelected, projectSelected, monitoringDate, imageType, searchInfosTimeLine, searchDatesCalendar, setDateSelected, typeSatellite }: TDataReports) => {

  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const { toEdit, setToEdit } = useScoutContext()

  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [filterList, setFilterList] = useState<CheckboxValueType[]>(['all'])
  const [reportId, setReportId] = useState<number | undefined>()
  const [type, setType] = useState<string>()

  const renderIcon = (kind: string) => {
    switch (kind) {
      case 'nutricheck':
        return <NutriAgro />

      case 'siganematoide':
        return <Signematoide />

      case 'solocheck':
        return <SoloCheck />

      case 'yield_component':
        return <YeldComponent />
      case 'scout':
        return <Survey />

      case 'disease':
        return <HealthIcon />

      case 'weed':
        return <WeedIcon />
      case 'bug':
        return <BugIcon />

      default:
        break
    }
  }

  const renderLabel = (kind: string) => {
    switch (kind) {
      case 'nutricheck':
        return t('nutricheck', { ns: namespaces.pages.field })
      case 'siganematoide':
        return t('siganematoide', { ns: namespaces.pages.field })
      case 'solocheck':
        return t('solocheck', { ns: namespaces.pages.field })
      case 'yield_component':
        return t('yield_component', { ns: namespaces.pages.field })
      case 'scout':
        return t('scout', { ns: namespaces.pages.field })
      case 'disease':
        return t('health', { ns: namespaces.pages.field })
      case 'weed':
        return t('weed', { ns: namespaces.pages.field })
      case 'bug':
        return t('bug', { ns: namespaces.pages.field })
      default:
        return ' - '
    }
  }

  const datesFiltered = useMemo( () => {
    const dates: any = []
    forEach(reportsData, (item: any) => {
      if(!dates?.includes(formatFullDateToView(item.created_at)))
        dates.push({ date: formatFullDateToView(item.created_at), ...item })
    })

    return groupBy(dates, i => i.date )
  }, [reportsData])

  const filterItens = useCallback(
    (items:any) => {
      if (filterList.includes('all')) return datesFiltered

      const itemsFiltered = reduce(items, (filtered:any, report:any) => {
        const listRes = filter(report, item => find(filterList, option => option === item.kind ))

        if (listRes.length > 0 ) {
          filtered.push(...listRes)
        }
        return filtered
      }, [])

      return groupBy(itemsFiltered, i => i.date )

    },
    [datesFiltered, filterList],
  )

  const options = useMemo(() => filterItens(datesFiltered), [datesFiltered, filterList])

  const renderInfoReports = useMemo(
    () =>
      <ListContainer>
        {map(options, (item: any, index:number) =>
          <Fragment key={index}>
            <Item colorYellow>
              {index}
            </Item>

            { map(item, (report:any, keyItem:number) =>
              <List
                key={keyItem}
                onClick={() => {
                  setReportId(report?.id)
                  setType(report?.kind)
                }}
              >
                {renderIcon(report?.kind)}
                <div>
                  <Item colorYellow>
                    { renderLabel(report?.kind) }
                  </Item>
                  <Item colorYellow>
                    <ClockIcon />
                    <span>{getHours(report?.created_at)}</span>
                  </Item>
                  <Item colorYellow>
                    <UserIcon />
                    <span>{report?.user_name}</span>
                  </Item>
                </div>
              </List>
            )}
          </Fragment>
        )}
      </ListContainer>
    , [options])

  const clearSelecteItem = useCallback(
    () => {
      setReportSelected(undefined)
      setReportId(undefined)
    }, [reportId])

  const handleModalCancelEdit = () => {
    createDialog({
      id: 'modal-cancel-edit-scout',
      open: false,
      Component: ModalDeleteReport,
      props: {
        title: t('discard changes', { ns: namespaces.pages.scout }),
        message: t('this action cannot be undone!', { ns: namespaces.pages.scout }),
        handleConfirm: () => {
          setReportId(reportSelected?.id)
          setType(reportSelected?.kind)
          setToEdit(false)
        },
        colorButtonConfirm: 'primary',
        colorButtonCancel: 'link',
        labelButtonConfirm: 'yes',
        labelButtonCancel: 'no'
      }
    })
  }

  const renderComponent = useMemo(
    () => {
      if(type === 'scout'){
        return (
          <ViewScout
            setReportSelected={setReportSelected}
            reportSelectedId={reportId}
            goBack={clearSelecteItem}
            projectSelected={projectSelected}
            monitoringDate={monitoringDate}
            imageType={imageType}
            searchInfosTimeLine={searchInfosTimeLine}
            searchDatesCalendar={searchDatesCalendar}
            setDateSelected={setDateSelected}
            typeSatellite={typeSatellite}
          />)
      }

      return <ViewFieldReport projectSelected={projectSelected} reportSelectedId={reportId} setReportSelected={setReportSelected} goBack={clearSelecteItem} />
    }, [projectSelected, reportId, type, monitoringDate, imageType])

  useEffect(() => {
    if(!isEmpty(reportSelected) && reportSelected?.id !== reportId ){
      if(toEdit){
        handleModalCancelEdit()
      }else{
        setReportId(reportSelected?.id)
        setType(reportSelected?.kind)
      }
    }
  }, [reportSelected])

  useEffect(() => {
    return () => {
      setReportSelected(undefined)
      setToEdit(false)
    }
  }, [])

  return !reportId ? (
    <>
      <HeaderMenuList>
        { t('field report', { ns: namespaces.pages.field }) }
        <Icon onClick={() => setShowFilter(!showFilter)}>
          <FilterIcon />
        </Icon>
      </HeaderMenuList>
      <Content>
        {showFilter && <FilterList setFilterList={setFilterList} filterList={filterList} />}
        <div>
          {renderInfoReports}
        </div>
      </Content>
    </>
  ) : (
    renderComponent
  )
}

export default FieldReports