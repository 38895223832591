// @flow
export const polygons = [
  {
    type: 'sand',
    color: '#D2D709',
    data: [
      [100, 0],
      [95, 10],
      [85, 0]
    ]
  },
  {
    type: 'loamy_sand',
    color: '#D8C76D',
    data: [
      [95, 10],
      [92.5, 15],
      [70, 0],
      [85, 0]
    ]
  },
  {
    type: 'sand_loam',
    color: '#624D3C',
    data: [
      [70, 0],
      [92.5, 15],
      [90, 20],
      [62, 20],
      [55.5, 7.5],
      [46, 7.5],
      [50, 0]
    ]
  },
  {
    type: 'sand_clay_loam',
    color: '#7F511F',
    data: [
      [90, 20],
      [82.5, 35],
      [62.5, 35],
      [58.5, 27.5],
      [62, 20]
    ]
  },
  {
    type: 'sand_clay',
    color: '#C9BAA5',
    data: [
      [82.5, 35],
      [72.5, 55],
      [62.5, 35]
    ]
  },
  {
    type: 'clay_loam',
    color: '#988E8C',
    data: [
      [65, 40],
      [40, 40],
      [33.5, 27],
      [58.5, 27]
    ]
  },
  {
    type: 'loam',
    color: '#836855',
    data: [
      [58.5, 27],
      [36.25, 27],
      [46, 7.5],
      [55.5, 7.5],
      [62, 20]
    ]
  },
  {
    type: 'silt',
    color: '#663E2E',
    data: [
      [20, 0],
      [13.5, 12.5],
      [6.2, 12.5],
      [0, 0]
    ]
  },
  {
    type: 'silt_loam',
    color: '#724C46',
    data: [
      [50, 0],
      [20, 0],
      [13.5, 12.5],
      [6.2, 12.5],
      [13.75, 27],
      [36.25, 27]
    ]
  },
  {
    type: 'clay',
    color: '#E3D7C9',
    data: [
      [72.5, 55],
      [65, 40],
      [40, 40],
      [30, 60],
      [50, 100]
    ]
  },
  {
    type: 'silty_clay_loam',
    color: '#7F5F36',
    data: [
      [33.5, 27],
      [40, 40],
      [20, 40],
      [13.75, 27]
    ]
  },
  {
    type: 'silty_clay',
    color: '#C2A384',
    data: [
      [40, 40],
      [30, 60],
      [20, 40]
    ]
  }
]

export const lines = [
  [[95, 0], [47.5, 95]],
  [[90, 0], [45, 90]],
  [[85, 0], [42.5, 85]],
  [[80, 0], [40, 80]],
  [[75, 0], [37.5, 75]],
  [[70, 0], [35, 70]],
  [[65, 0], [32.5, 65]],
  [[60, 0], [30, 60]],
  [[55, 0], [27.5, 55]],
  [[50, 0], [25, 50]],
  [[45, 0], [22.5, 45]],
  [[40, 0], [20, 40]],
  [[35, 0], [17.5, 35]],
  [[30, 0], [15, 30]],
  [[25, 0], [12.5, 25]],
  [[20, 0], [10, 20]],
  [[15, 0], [7.5, 15]],
  [[10, 0], [5, 10]],
  [[5, 0], [2.5, 5]],
  [[5, 0], [52.5, 95]],
  [[10, 0], [55, 90]],
  [[15, 0], [57.5, 85]],
  [[20, 0], [60, 80]],
  [[25, 0], [62.5, 75]],
  [[30, 0], [65, 70]],
  [[35, 0], [67.5, 65]],
  [[40, 0], [70, 60]],
  [[45, 0], [72.5, 55]],
  [[50, 0], [75, 50]],
  [[55, 0], [77.5, 45]],
  [[60, 0], [80, 40]],
  [[65, 0], [82.5, 35]],
  [[70, 0], [85, 30]],
  [[75, 0], [87.5, 25]],
  [[80, 0], [90, 20]],
  [[85, 0], [92.5, 15]],
  [[90, 0], [95, 10]],
  [[95, 0], [97.5, 5]],
  [[2.5, 5], [97.5, 5]],
  [[5, 10], [95, 10]],
  [[7.5, 15], [92.5, 15]],
  [[10, 20], [90, 20]],
  [[12.5, 25], [87.5, 25]],
  [[15, 30], [85, 30]],
  [[17.5, 35], [82.5, 35]],
  [[20, 40], [80, 40]],
  [[22.5, 45], [77.5, 45]],
  [[25, 50], [75, 50]],
  [[27.5, 55], [72.5, 55]],
  [[30, 60], [70, 60]],
  [[32.5, 65], [67.5, 65]],
  [[35, 70], [65, 70]],
  [[37.5, 75], [62.5, 75]],
  [[40, 80], [60, 80]],
  [[42.5, 85], [57.5, 85]],
  [[45, 90], [55, 90]],
  [[47.5, 95], [52.5, 95]]
]

export const t_ = 'entities.fertianalise.sections.analytical_summary'

export const labels = (classification: any, sheppard_elements: any) => {
  return [
    [10, 1, 90],
    [20, 6, 80],
    [30, 11, 70],
    [40, 16, 60],
    [50, 21, 50],
    [60, 26, 40],
    [70, 31, 30],
    [80, 36, 20],
    [90, 41, 10],
    [100, 44.5, 0],
    [10, 56.5, 10],
    [20, 61.5, 20],
    [30, 66.5, 30],
    [40, 71.5, 40],
    [50, 76.5, 50],
    [60, 81.5, 60],
    [70, 86.5, 70],
    [80, 91.5, 80],
    [90, 96.5, 90],
    [100, 101, 100],
    [10, 89, 103],
    [20, 79, 103],
    [30, 69, 103],
    [40, 59, 103],
    [50, 49, 103],
    [60, 39, 103],
    [70, 29, 103],
    [80, 19, 103],
    [90, 9, 103],
    [100, -1, 103],
    [classification['clay'], 47, 35, { weight: 'bold', size: 11 }],
    [classification['sandy_clay'], 25, 55, { weight: 'bold', size: 11 }],
    [classification['sandy_clay_loam'], 20, 70, { weight: 'bold', size: 11, color: 'white' }],
    [classification['sandy_loam'], 22, 90, { weight: 'bold', size: 11, color: 'white' }],
    [classification['sand'], 9.5, 93, { weight: 'bold', size: 11 }],
    [classification['loamy'], 13, 97, { weight: 'bold', size: 11 }],
    [classification['sand'], 3, 96, { weight: 'bold', size: 11 }],
    [classification['clay_loam'], 44, 66, { weight: 'bold', size: 11 }],
    [classification['loam'], 46, 82.5, { weight: 'bold', size: 11, color: 'white' }],
    [classification['silty_clay_loam'], 66, 65, { weight: 'bold', size: 11, color: 'white' }],
    [classification['silt_loam'], 63, 88, { weight: 'bold', size: 11, color: 'white' }],
    [classification['silt'], 88, 94, { weight: 'bold', size: 11, color: 'white' }],
    [classification['silty_clay'], 67, 50, { weight: 'bold', size: 11 }],
    [`${sheppard_elements['clay']}%`, 18, 50, { weight: 'bold', size: 16, rotation: -50 }],
    [`${sheppard_elements['silt']}%`, 77, 40, { weight: 'bold', size: 16, rotation: 50 }],
    [`${sheppard_elements['sand']}%`, 45, 107, { weight: 'bold', size: 16 }]
  ]
}