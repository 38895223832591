import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'components/Modal'
import { Form } from 'antd'
import useFarms from 'repositories/useFarms'
import useGroups from 'repositories/useGroups'
import { useGlobalContext } from 'hooks/useGlobalContext'
import Button from 'components/Button'
import SelectField from 'components/SelectField'
import TextField from 'components/TextField'
import { FormBox, ContainerBtn } from './styles'
import { TGroups } from 'types/types'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import { isEmpty, map } from 'lodash'
import { validation, TFormNewFarm } from './validations'

type TProps = {
  open: boolean;
  handleClose: () => void;
  id: string;
  projectSelected:string
  farmToEdit?: {
    id: number,
    name?: string
    group_id?: number
  }
  removeFarmCallback?: Function
}


const ModalFarmForm = ({ open, handleClose, id, projectSelected, farmToEdit, removeFarmCallback }: TProps) => {
  const { saveGroupsSelected } = useGlobalContext()
  const { createNewFarm, editFarm } = useFarms()
  const groupsApi = useGroups()
  const [optionsGroups, setOptionsGroups] = useState<TGroups[] | []>()
  const [form] = Form.useForm<TFormNewFarm>()
  const { t } = useTranslation('namespaces')

  const initialValues: TFormNewFarm = {
    name: farmToEdit?.name || '',
    group_id: farmToEdit?.group_id || null,
  }

  const getAllGroups = async() => {
    const response = await groupsApi.getFields({ project: projectSelected, type: 'all' })
    getAllGroupsSelect()
    saveGroupsSelected(response?.data.groups)
  }

  const getAllGroupsSelect = async() => {
    const response = await groupsApi.getGroups(projectSelected)
    setOptionsGroups(response?.data)
  }

  const optionsValue = useMemo(() =>
    map(optionsGroups, (option: any) => ({ 'label': option.name, 'value': option.id })), [optionsGroups])

  useEffect(() => {
    if(projectSelected){
      getAllGroups()
    }
  }, [projectSelected])
  useEffect(() => {
    getAllGroups()
  }, [open])

  const onFinish = async(values: TFormNewFarm) => {
    if(isEmpty(values.name)){
      return
    }

    farmToEdit ?
      await editFarm(projectSelected, farmToEdit?.id, { ...values })
      :
      await createNewFarm(projectSelected, { ...values })
    getAllGroups()
    handleClose()
  }

  const onReset = () => {
    form.resetFields()
  }

  return (
    <Modal
      id={id}
      title={t(farmToEdit ? 'edit farm information' : 'register the information of a new farm', { ns: namespaces.pages.farmGroups })}
      isModalVisible={open}
      handleClose={handleClose}
    >
      <FormBox>
        <Form
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          name='form-new-farm'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
        >
          <TextField
            id='farm-name'
            rules={validation('name', t)}
            name='name'
            label={t('name', { ns: namespaces.common })}
            placeholder={t('placeholder name', { ns: namespaces.common })}
          />
          <SelectField
            name='group_id'
            rules={validation('group_id', t)}
            label={t('group farms', { ns: namespaces.pages.field })}
            id='select-group_id'
            placeholder={t('select a group', { ns: namespaces.pages.field })}
            options={optionsValue ? optionsValue : []}
            showSearch
          />
          <ContainerBtn>
            <div>
              <Button
                id='button-cancel'
                type={farmToEdit ? 'dashed' : 'default'}
                label={ farmToEdit ? t('delete', { ns: namespaces.common }) : t('clean', { ns: namespaces.common })}
                onClick={() => farmToEdit && removeFarmCallback ? removeFarmCallback(farmToEdit.id, farmToEdit.name, handleClose) : onReset()}
                htmlType='reset'
              />
              <Button
                id='button-confirm'
                type='primary'
                label={t('save', { ns: namespaces.common })}
                htmlType='submit'
              />
            </div>
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalFarmForm