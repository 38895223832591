import styled from 'styled-components'
import Background from 'assets/backgrounds/yvy-background.png'
import { Button } from 'antd'

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-image: url(${Background});
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover; 
`

export const LoginBox = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 576px;
    min-height: 80vh;
    padding: 40px 34px 40px 34px;
    border-radius: 12px;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: #131E2A;
    .ant-form{
        display: flex;
        flex-direction: column;
        max-width: 508px
    }
`

export const TextInput = styled.div`
    margin: 10px 0;
`

export const Title = styled.h1`
    font-family: Montserrat;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 2rem;
`

export const Label = styled.h2`
    font-size: 18px;
    font-weight: 400;
    @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
        color: ${({ theme }) => theme.colors.WHITE};
    }
`

export const PrimaryButton = styled(Button)`
    cursor: pointer;
    height: 51px;
    padding: 13.5px 227.5px 15.5px 227.5px;
    border-radius: 4px;
    border: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.39;
    color: #fff;
    text-transform: uppercase;
    background-color: #F37422;
    
    :hover {
        background-color: #273A5B;
    }
`

export const Logo = styled.img`
    width: 280.554px;
    height: 87.273px;
`
export const Controller = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem 1rem 1rem;
    margin: 1rem 0;
    font-size: 16px;
    line-height: 1.5;
    .ant-checkbox-wrapper {
        color: #fff;
    }
    a {
        color: #f37422;
    }
`

export const LanguageSwitcher = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin: 3rem 0;
`

export const NewAccount = styled.div`
    margin: 0 8px 0 0;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    padding-bottom: 2rem;
    a {
        color: #f37422;
        cursor: pointer;
    }
`

export const Social = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24.5px;
    padding-top: 3rem;
`

export const FooterLinks = styled.div`
 margin: 25px 7px 0 7px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  a {
    text-decoration: underline;
    color: #f37422;
  }
`