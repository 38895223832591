import styled from 'styled-components'
import { Select } from 'antd'

export const FlagSelect = styled(Select)`
  padding: 4px 4.6px 4px 4.5px;
  border-radius: 4px;
  border: solid 1px #bcbccb;
  background-color: rgba(9, 45, 61, 0.9);
  height: 32px;
`

export const DropdownSelect = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 59px;
  height: 32px;
  padding: 4px 4.6px 4px 4.5px;
  border-radius: 4px;
  border: solid 1px #bcbccb;
  background-color: rgba(9, 45, 61, 0.9);
  cursor: pointer;
  svg {
    color: #fff
  }

`

export const Flag = styled.img`
   width: 34px;
  height: 24px;
  margin: 0 4.5px 0 0;

}
`