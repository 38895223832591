import React, { useContext } from 'react'
import { BsArrowLeftShort } from 'react-icons/bs'
import { BiPlus } from 'react-icons/bi'
import { MdFileDownload, MdDeleteForever } from 'react-icons/md'
import { IoEllipsisVertical } from 'react-icons/io5'
import { Dropdown } from 'antd'
import { useProductContext } from 'hooks/useProductContext'
import useProduct from 'repositories/useProduct'
import { DialogContext } from 'dialog/context'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useHistory } from 'react-router-dom'

import ModalCreateReport from '../Modals/ModalCreateReport'
import ModalNewEnvironmentMap from '../Modals/ModalNewEnvironmentMap'
import ModalNewAttach from '../Modals/ModalNewAttach'
import ModalNewRecommendation from '../Modals/ModalNewRecommendation'
import ModalRemoveProduct from '../Modals/ModalRemoveProduct'

import { Wrapper, ProductTypeTitle, ProductButton, SubMenu, MenuItem, DropdownWrapper } from './styles'

declare global {
  interface Window {
    location: Location
  }
}

type TProps = {
  projectSelected:string
  productSelected:number
  setProductSelected:React.Dispatch<any>
}

const ActionsController = ({ projectSelected, productSelected, setProductSelected }:TProps) => {
  const {
    productProject,
    fullscreen,
    setFullscreen
  } = useProductContext()
  const { source, actable_id } = productProject
  const { createDialog } = useContext(DialogContext)
  const { t } = useTranslation('namespaces')
  const { handleReportDownload } = useProduct()
  const history = useHistory()

  const handleModalReport = () => {
    createDialog({
      id: 'modal-create-report',
      open: false,
      Component: ModalCreateReport,
      props: {
        productSelected,
        fieldId: productProject?.field?.id,
        projectSelected
      }
    })
  }

  const handleModalAttach = () => {
    createDialog({
      id: 'modal-new-attach',
      open: false,
      Component: ModalNewAttach,
      props: {
        productSelected,
        fieldId: productProject?.field?.id,
        projectSelected
      }
    })
  }

  const handleModalRecommendation = () => {
    createDialog({
      id: 'modal-new-recommendation',
      open: false,
      Component: ModalNewRecommendation,
      props: {
        productSelected,
        fieldId: productProject?.field?.id,
        projectSelected
      }
    })
  }

  const handleModalNewEnvironmentMap = () => {
    createDialog({
      id: 'modal-create-environmentMap',
      open: false,
      Component: ModalNewEnvironmentMap,
      props: {
        actable_id,
        productSelected,
        projectSelected
      }
    })
  }

  const handleModalRemoveProduct = () => {
    createDialog({
      id: 'modal-remove-product',
      open: false,
      Component: ModalRemoveProduct,
      props: {
        productSelected,
        projectSelected
      }
    })
  }

  const downloadDropdown = (
    <DropdownWrapper mode='vertical'>
      <SubMenu key='sub_download_analytical_report' title={t('analytical summary', { ns: namespaces.pages.field })}>
        <MenuItem key='download_analytical_report' onClick={(e: any) => handleReportDownload(productSelected, e.key, 'pt-BR', projectSelected)}>
          {t('portuguese', { ns: namespaces.common })}
        </MenuItem>
        <MenuItem key='download_analytical_report' onClick={(e: any) => handleReportDownload(productSelected, e.key, 'es', projectSelected)}>
          {t('spanish', { ns: namespaces.common })}
        </MenuItem>
        <MenuItem key='download_analytical_report' onClick={(e: any) => handleReportDownload(productSelected, e.key, 'en', projectSelected)}>
          {t('english', { ns: namespaces.common })}
        </MenuItem>
      </SubMenu>
      <SubMenu key='sub_download_fertianalise' title= {t('analytical report', { ns: namespaces.pages.field })}>
        <MenuItem key='download_fertianalise' onClick={(e: any) => handleReportDownload(productSelected, e.key, 'pt-BR', projectSelected)}>
          {t('portuguese', { ns: namespaces.common })}
        </MenuItem>
        <MenuItem key='download_fertianalise' onClick={(e: any) => handleReportDownload(productSelected, e.key, 'es', projectSelected)}>
          {t('spanish', { ns: namespaces.common })}
        </MenuItem>
        <MenuItem key='download_fertianalise' onClick={(e: any) => handleReportDownload(productSelected, e.key, 'en', projectSelected)}>
          {t('english', { ns: namespaces.common })}
        </MenuItem>
      </SubMenu>
      <SubMenu key='sub_download_pdf' title={t('fertianalysis', { ns: namespaces.pages.field })}>
        <MenuItem key='download_pdf' onClick={(e: any) => handleReportDownload(productSelected, e.key, 'pt-BR', projectSelected)}>
          {t('portuguese', { ns: namespaces.common })}
        </MenuItem>
        <MenuItem key='download_pdf' onClick={(e: any) => handleReportDownload(productSelected, e.key, 'es', projectSelected)}>
          {t('spanish', { ns: namespaces.common })}
        </MenuItem>
        <MenuItem key='download_pdf' onClick={(e: any) => handleReportDownload(productSelected, e.key, 'en', projectSelected)}>
          {t('english', { ns: namespaces.common })}
        </MenuItem>
      </SubMenu>
    </DropdownWrapper>
  )

  const createDropdown = (
    <DropdownWrapper>
      <MenuItem key='new report' onClick={handleModalReport}>
        {t('new something', { ns: namespaces.common, label: t('report', { ns: namespaces.pages.field }) })}
      </MenuItem>
      <MenuItem key='new environment map' onClick={handleModalNewEnvironmentMap}>
        {t('new something', { ns: namespaces.common, label: t('environment map', { ns: namespaces.pages.field }) })}
      </MenuItem>
      <MenuItem key='new attachment' onClick={handleModalAttach}>
        {t('new something', { ns: namespaces.common, label: t('attachment', { ns: namespaces.common }) })}
      </MenuItem>
      <MenuItem key='new recommendation' onClick={handleModalRecommendation}>
        {t('new something a', { ns: namespaces.common, label: t('recommendation', { ns: namespaces.pages.field }) })}
      </MenuItem>
    </DropdownWrapper>
  )

  const menuOptionsDropdown = (
    <DropdownWrapper>
      <MenuItem key='delete-product' onClick={handleModalRemoveProduct}>
        <MdDeleteForever />
        {t('remove something', { ns: namespaces.common, label: t('product', { ns: namespaces.pages.field }) })}
      </MenuItem>
    </DropdownWrapper>
  )

  return (
    <Wrapper fullscreen={fullscreen}>
      <ProductTypeTitle
        onClick={() => {
          setProductSelected(0)
          setFullscreen(false)
          history.push(`/analytical-products/${projectSelected}/field/${productProject?.field?.id}`)
        }}
      >
        <BsArrowLeftShort />
             Ferti
      </ProductTypeTitle>
      <Dropdown overlay={createDropdown} trigger={['click']}>
        <ProductButton fullscreen={fullscreen}>
          <BiPlus />
          {t('new', { ns: namespaces.common })}
        </ProductButton>
      </Dropdown>
      <Dropdown overlay={downloadDropdown} trigger={['click']}>
        <ProductButton fullscreen={fullscreen} onClick={e => e.preventDefault()}>
          <MdFileDownload />
          {t('report download', { ns: namespaces.pages.field })}
        </ProductButton>
      </Dropdown>
      {source && source?.url && (
        <ProductButton fullscreen={fullscreen} href={source?.url} target='_blank'>
          <MdFileDownload />
          {t('spreadsheet download', { ns: namespaces.pages.field })}
        </ProductButton>
      )}
      {fullscreen && (
        <Dropdown overlay={menuOptionsDropdown} trigger={['click']}>
          <ProductButton fullscreen={fullscreen} onClick={e => e.preventDefault()}>
            <IoEllipsisVertical />
          </ProductButton>
        </Dropdown>
      )}
    </Wrapper>
  )
}

export default ActionsController