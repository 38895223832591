import React from 'react'
import { Rule } from 'antd/lib/form'

import { FormItem, TextAreaInput } from './styles'

type TProps = {
  id: string
  name?: string | any
  fieldKey?:any
  label?: string
  placeholder?: string
  rules?: Rule[]
  disabled?: boolean
  value?: any
  defaultValue?: string
  onChange?: (e: any) => void
  onPressEnter?: (e: any) => void
}

const TextArea = ({
  label,
  name,
  fieldKey,
  placeholder,
  rules,
  disabled = false,
  value,
  defaultValue,
  id,
  onChange,
  onPressEnter,
  ...rest
}: TProps) => {

  return (
    <FormItem
      label={label}
      name={name}
      rules={rules}
      fieldKey={fieldKey}
    >
      <TextAreaInput
        onChange={onChange}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        disabled ={disabled}
        value={value}
        defaultValue={defaultValue}
        rows={4}
        id={id}
        {...rest}
      />
    </FormItem>
  )

}


export default TextArea