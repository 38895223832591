import React, { useState, useMemo, useEffect } from 'react'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import useReport from 'repositories/useReport'

import Modal from 'components/Modal'
import Button from 'components/Button'
import SelectField from 'components/SelectField'
import UploadButton from 'components/UploadButton'

import {
  initialValues,
  validation,
  TFormReport
} from './validations'
import {
  FormBox,
  ContainerBtn,
} from './styles'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  productSelected: number
  fieldId: number
  projectSelected: string
}

const ModalCreateReport = ({ open, handleClose, id, productSelected, fieldId, projectSelected }: TProps) => {

  const [form] = Form.useForm<TFormReport>()
  const { t } = useTranslation('namespaces')
  const reportApi = useReport()

  const [type, setType] = useState<any>([])

  const onFinish = async(values:TFormReport) => {

    if(isEmpty(values.file[0])){
      return
    }

    const params = {
      field_id: fieldId,
      report_type_id: values?.type,
      project: projectSelected,
      file: values.file[0],
      productSelected
    }
    await reportApi.createReport(params, handleClose)
  }

  const typeOptions = useMemo<any[]>(
    () => map(type, (item:any) => ({ value: item?.id, label: item?.name }))
    , [type])

  useEffect(() => {
    const request = async () => {
      const response = await reportApi.getReportTypes()
      setType(response)
    }
    request()

    return () => {
      setType(undefined)
    }
  }, [])

  return (
    <Modal
      id={id}
      title={t('new register', { ns: namespaces.common, label: t('report', { ns: namespaces.pages.field }) })}
      isModalVisible={open}
      handleClose={handleClose}
    >
      <FormBox>
        <Form
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          name='form-report'
          form={form}
          autoComplete='off'
          onFinish={ onFinish}
        >
          <SelectField
            name='type'
            rules={validation('type', t)}
            label={t('type', { ns: namespaces.common })}
            id='select-type'
            placeholder={t('select one', { ns: namespaces.modals.createCrop, anything: t('type', { ns: namespaces.common }) })}
            options={typeOptions}
            showSearch
          />
          <UploadButton
            name='file'
            label={t('file', { ns: namespaces.common })}
            rules={validation('file', t)}
            disable={false}
          />
          <ContainerBtn>
            <Button
              id='button-cancel'
              type='default'
              label={t('cancel', { ns: namespaces.common })}
              onClick={ handleClose }
              htmlType='reset'
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalCreateReport
