import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
  }

  html, body {
    font-family: 'Montserrat' !important;
    -webkit-text-size-adjust: 100%;
  }

  .ant-picker-dropdown{
    margin-top: -135px !important;
    border-radius: 15px;
    width: 304px;
    height: auto;
  }

  .ant-picker-date-panel{
    width: 100%;
    height: 100%;
  }

  .ant-picker-date-panel .ant-picker-body {
    padding: 0;
    height: 100%;
}

.ant-picker-date-panel .ant-picker-content {
    width: 100%;
    height: 288px;
}

.ant-picker-panel-container {
  background: #082733 !important;
  border-radius: 20px;
}

.ant-picker-panel {
  border: none !important;
  padding: 20px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #F37422 !important;
  color: #262600 !important;
}

.ant-picker-cell-inner:hover{
  border: 1px solid #F37422;
  background: transparent !important;
}

.ant-picker-header{
  border: none !important;
}

.ant-picker-header-super-next-btn, .ant-picker-header-super-prev-btn{
  display: none!important;
}

.ant-picker-calendar {
  background: transparent !important;
}

.ant-picker-content, table,  thead, tbody, tr, 
 th {
    color: #D9F9F6 !important;
}

.ant-picker-content th, .ant-picker-content td {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.ant-picker-header-view{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 150%;
}

 .ant-picker-cell:not(.ant-picker-cell-in-view) > div {
  color: #146484 !important;
}

.ant-picker-cell, .ant-picker-cell-in-view > div {
  color: #fff !important;
}

.ant-picker-header{
  /* border:1px solid rgba(235, 235, 242, 0.2); */
 
  button {
    color: #f37422;
  }
}

.ant-picker-footer{
  display: none;
}

  .ant-layout {
    background: '#092d3d';
  }

  .leaflet-pane {
    position: relative;
  }

  .leaflet-container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .leaflet-fade-anim .leaflet-tile {
    will-change: unset;
  }

  .leaflet-google-mutant {
    visibility: hidden !important;
  }

  .leaflet-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 2px;
    color: #f37422;
    border: solid 0.5px rgba(9, 45, 61, 0.9);
    background-color: rgba(9, 45, 61, 0.9);

    &:hover {
      background-color: rgba(4, 26, 36, 0.9);
      color:#f37422;
    }
  }

  .leaflet-bar {
    color: #f37422;
    border: none;
    background-color: rgba(9, 45, 61, 0.9);
    a, svg {
      color: #f37422 !important;
      border: solid 0.5px rgba(9, 45, 61, 0.9);
      background-color: rgba(9, 45, 61, 0.9) !important;

      &:hover {
        color: #f37422;
        border: solid 0.5px rgba(9, 45, 61, 0.9);
        background-color: rgba(4, 26, 36, 0.9);
      }
    }
  }
 
  body {
    margin: 0;
    padding: 0;

    iframe {
      display: none;
    }
  }

// style global select
.ant-select-item{
  color: #fff !important;
  background-color: rgba(9, 45, 61, 0.9) !important;

  :hover{
    color: #1890ff;
    background-color: rgba(9, 45, 61, 0.9) !important;
  }
}

.ant-empty-description{
  color:  #f37422;
}

.imagen-scout{
  margin-top: -40px !important;
  strong{
    margin-top: -40px !important;
  } 
}

.imagen-scout-icon{
  height: 40px !important;
  width:35px !important;
}

.leaflet-popup{
  bottom: 31px !important;
  left: -152px !important;
}

.leaflet-div-icon-green {
  background: #fff;
  border: 3px solid green;
  border-radius: 50%;
}

.leaflet-div-icon-red {
  background: #fff;
  border: 3px solid red;
  border-radius: 50%;
}

.leaflet-number {
  position: absolute;
  color: #fff;
  left: 29%;
  top: 15%;
}

.info {
  padding: 0;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.legend {
  text-align: left;
  line-height: 18px;
  background:linear-gradient(#339933,#f37422,#8c0f04);
  height: 150px;
  max-height: 150px;

  div {
    padding: 5px;
    justify-content: center;
    display: flex;
  }
}

.sense{
  background:linear-gradient(#362561,#339933,#f37422,#8c0f04) !important;
}

.ant-image-preview-operations{
    display: none !important;
  }

.rotation90{
   img{
      transform:  rotate(90deg) !important;
      z-index: 1080;
    }
 }

 .rotation180{
   img{
      transform:  rotate(180deg) !important;
      z-index: 1080;
    }
 }

 .rotation270{
  img{
      transform:  rotate(270deg) !important;
    }
 }

 .rotation0{
 img{
      transform:  rotate(0deg) !important;
    }
 }


`

