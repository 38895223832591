import { apiBackEnd } from 'services/axios'
import useToast from 'hooks/useToast'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

const useNewProduct = () => {
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const createNewProjects = async({ productType, year, file, farm_id, field_id, group_id }: any, projectSelected: string) => {
    const formData = new FormData()

    formData.append('reference_year', year?.toString() )
    formData.append('farm_id', farm_id?.toString())
    formData.append('field_id', field_id?.toString())

    if(group_id){
      formData.append('group_id', group_id?.toString())
    }


    formData.append('data_source_attributes[file]', file?.originFileObj)

    const url = `/v2/companies/${projectSelected}/${productType}`

    const response = await apiBackEnd.post(url, formData)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.errors }), success: true })
    }else {
      notify({ message: t('500', { ns: namespaces.errors }), success: false })
    }
    return response
  }

  return { createNewProjects }
}

export default useNewProduct