import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {

  switch (key) {

    case 'fullname':

      return [{
        required: true,
        message: t('full name is required', { ns: namespaces.common })
      },
      ] as Rule[]

    case 'password':

      return [{
        required: false
      },
      ] as Rule[]

    default:
      [] as Rule[]
  }
}