import { Button } from 'antd'
import styled, { css } from 'styled-components'

type TIconsProps = {
  textaAlign?: 'left' | 'center' | 'right' | 'space-between' | 'space-around';
};

type TContentProps = {
  isActive: boolean;
  isReportContent?: boolean;
};

type TInfoReports = {
  isReport?: boolean;
};

export const Container = styled.div`
  max-height: 170px;
  height: auto;
  background: ${({ theme }) => theme.colors.THEME};

  .ant-carousel .slick-list {
    max-height: 100px;
    max-width: 97.5%;
    color: #fff;
    border-right: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
    border-left: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  }
  .slick-slide {
    max-width: 160px;
  }

  .ant-carousel {
    .slick-next {
      &::before {
        content: "";
      }
    }
    .slick-prev {
      &::before {
        content: "";
      }
    }
  }

  .ant-carousel .slick-prev {
    /* left: -72px; */
  }

  .ant-carousel .slick-next {
    right: 6px;
  }
`
export const Arrow = styled.div<{ disabled?: boolean }>`
  color: black !important;
  font-size: 15px !important;
  line-height: 1.5715 !important;
  width: 63px !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  cursor: ${({ disabled }) => disabled ? 'unset' : 'pointer'};
  height: 80px;
  svg {
    color: ${({ theme }) => theme.colors.YELLOW};
    margin: 0 auto;
  }
`

export const Content = styled.div<TContentProps>`
  color: #fff;
  border-right: ${({ theme, isReportContent }) =>
    !isReportContent
      ? `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`
      : 'none'};
  padding: 4px 10px;
  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.MEDIUM_GRAY_OPACITY : 'trasparent'};

  :hover {
    background: ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    cursor: pointer;
  }
`
export const TextDate = styled.p<TInfoReports>`
  color: ${({ theme }) => theme.colors.YELLOW};
  font-size: ${({ isReport }) => (isReport ? '12px' : '18px')};
  display: flex;
  justify-content: center;
  flex-direction: row-reverse !important;
  gap: ${({ isReport }) => (isReport ? '6px' : '8px')};
  p {
    margin: 0;
  }
`

export const Icons = styled.div<TIconsProps>`
  display: flex;
  justify-content: ${({ textaAlign = 'space-between' }) => textaAlign};
  flex-direction: row-reverse;
  align-items: center;
  font-size: 12px;
  margin-bottom: 5px;

  ${({ textaAlign }) =>
    textaAlign !== 'space-between' &&
    css`
      span {
        padding-right: 10px;
      }
    `};
`

export const Message = styled.p`
  color: ${({ theme }) => theme.colors.YELLOW};
  margin-top: 40px;
  margin-left: 30px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-self: center;
`

export const DownloadShapeButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.THEME} !important;
  color: ${({ theme }) => theme.colors.WHITE} !important;
  margin: 0;
  margin-left: 4px;
  border: none;
  color: transparent;
`

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
`