import styled from 'styled-components'
import { Button } from 'antd'

type TItemProps = {
  width?: string;
  colorYellow?: boolean;
};


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  border: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  overflow: auto;
  height: 100%;
`

export const ListContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 3;
  flex-direction: column;
  padding: 20px;
  && {
    border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  }
  :last-child {
      border-bottom: none
  }
`

export const BackButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.THEME};
  color: ${({ theme }) => theme.colors.WHITE} ;
  border: solid 1px rgba(188, 188, 203, 0.2);
  border-radius: 4px;
  margin-bottom: 6px;
  display: flex;
  align-items:center;
  justify-content: center;
  gap: 8px;
  svg {
    color: ${({ theme }) => theme.colors.YELLOW};
  }
  :hover{
    background-color: ${({ theme }) => theme.colors.BLUEGREEN};
    color: ${({ theme }) => theme.colors.WHITE};
    border-color:  ${({ theme }) => theme.colors.BLUEGREEN};
  }
`

export const Title = styled.span`
  width: 187px;
  height: 22px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`

export const List = styled.div`
  color: ${({ theme }) => theme.colors.YELLOW};
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  padding: 5px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  width: 100%;
`
export const Item = styled.div<TItemProps>`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  font-size: 16px;
  word-wrap: break-word;
  color: ${({ theme, colorYellow }) =>
    colorYellow ? theme.colors.YELLOW : theme.colors.WHITE};
  span {
    font-size: 12px;
    padding: 2px;
    color: ${({ theme }) => theme.colors.WHITE}
  }
  img {
      margin: 12px 0 16px 0;
   }
  div {
    display: flex;
    flex-direction: column;
    border: solid 1px rgba(188, 188, 203, 0.2);
  }
`