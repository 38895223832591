import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

type TPayload = {
  email: string
  password: string
}

const useLogin = () => {

  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const url = 'auth/sign_in' as string

  const requestToken = async (data: TPayload) => {

    const response = await rest.post(url, { ...data, provider: 'agfarmus' })

    if(response.status === 401){
      notify({ message: t('401', { ns: namespaces.errors }), errors: true })
    }

    return response as any

  }

  return {
    requestToken,
  }
}

export default useLogin
