import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { TFormProject } from 'types/projects'

export const initialValues: TFormProject = {
  name: '',
  email: '',
  code: '',
  logo: [],
  remove_logo: false,
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'name':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('name', { ns: namespaces.common }) })
      }] as Rule[]

    case 'email':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('email', { ns: namespaces.common }) })
      }] as Rule[]

    case 'code':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('cnpj or ruc', { ns: namespaces.pages.projectController }) })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}
