import { apiBackEnd } from 'services/axios'
import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

const useSettingsReport = () => {
  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const changeImagesReports = async({ image_cover, image_right, image_left, imageCover, imageRight, imageLeft }: any, projectSelected: string) => {
    const formData = new FormData()

    if(imageCover && image_cover?.originFileObj.name !== imageCover.filename){
      if(image_cover?.originFileObj)
        formData.append( 'report_cover_image', image_cover?.originFileObj )
    }else {
      formData.append( 'remove_report_cover_image', 'true' )
    }

    if(imageLeft && image_left?.originFileObj.name !== imageLeft.filename) {
      if(image_left?.originFileObj)
        formData.append('report_recommendations_left_image', image_left?.originFileObj)
    }else {
      formData.append('remove_report_recommendations_left_image', 'true')
    }

    if(imageRight && image_right?.originFileObj.name !== imageRight.filename) {
      if(image_right?.originFileObj)
        formData.append('report_recommendations_right_image', image_right?.originFileObj)
    }else {
      formData.append('remove_report_recommendations_right_image', 'true')
    }

    const url = `/v2/companies/${projectSelected}`

    const response = await apiBackEnd.put(url, formData)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.errors }), success: true })
    }else {
      notify({ message: t('500', { ns: namespaces.errors }), success: false })
    }
    return response
  }

  const getImagesReports = async(projectSelected: string) => {
    const url = `/v2/companies/${projectSelected}`

    const response = await rest.get(url)

    return response
  }

  return { changeImagesReports, getImagesReports }
}

export default useSettingsReport