import React from 'react'

type TProps = {
  size?: number;
  color?: string;
};

const EditIcon = ({
  size = 18,
  color = '#F37422',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 19 18'
    >
      <path d='M11.0625 6L12 6.9375L2.90625 15.9844H2.01562V15.0938L11.0625 6ZM14.6719 0C14.3906 0 14.1562 0.09375 13.9688 0.28125L12.1406 2.10938L15.8906 5.85938L17.7188 4.03125C18.0938 3.65625 18.0938 3 17.7188 2.625L15.375 0.28125C15.1875 0.09375 14.9062 0 14.6719 0ZM11.0625 3.1875L0 14.25V18H3.75L14.8125 6.9375L11.0625 3.1875Z' fill={color} />
    </svg>
  )
}

export default EditIcon