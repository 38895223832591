import styled from 'styled-components'

import { Collapse } from 'antd'
const { Panel } = Collapse

export const Container = styled.div`
    display: flex;
    width: 100%;
`

export const MapContainer = styled.div`
    flex: 1;
    height: 91.5vh;
    display: flex;
    flex-direction: column;
`

export const ProductsCollapse = styled(Collapse)`
    .ant-collapse-header {
        border-top: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
        border-bottom: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
        color: ${({ theme }) => theme.colors.YELLOW} !important;
        svg {
            color: ${({ theme }) => theme.colors.YELLOW};
        }
    }
    .ant-collapse-content-box{
        padding: 0 !important;
    }
`

export const ProductPanel = styled(Panel)``

export const Footer = styled.div`
    display: flex;
    background-color: #000;
    height: 300px;
    flex: 1;
    width: 100%;
`

export const ProductList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
`

export const Product = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;

    h4 {
        color: ${({ theme }) => theme.colors.YELLOW};
        margin: 0;
        padding-right: 8px;
    }
    P {
        color: ${({ theme }) => theme.colors.WHITE};
        margin: 0;
    }
`

export const ProductTypeTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 16px;
    color: ${({ theme }) => theme.colors.YELLOW};
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    
    svg {
        color: ${({ theme }) => theme.colors.YELLOW};
    }
    `

export const ProductButton = styled.a`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 12px 16px;
    color: ${({ theme }) => theme.colors.WHITE};
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    border-top: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    svg {
        color: ${({ theme }) => theme.colors.YELLOW};
    }
`

export const ProductWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

