import { useEffect, useMemo } from 'react'
import L from 'leaflet'
import reverse from 'lodash/reverse'
import forEach from 'lodash/forEach'
import { toFixedNoRounding } from 'utils/helpers'

type Tprops = {
  map: any,
  min?: string
  max?: string
  remove: boolean
  type?:string
}

const LegendNdviContrast = ({ map, min = '0', max = '0', remove, type = '' }:Tprops) => {
  const Leaflet = L as unknown as any
  const legend = Leaflet.control({ position: 'topright' })

  const geravalue = () => {
    const coefMaxMin = parseFloat(max) - parseFloat(min)

    const one = parseFloat(min) + (0.25 * coefMaxMin)
    const two = parseFloat(min) + (0.5 * coefMaxMin)
    const three = parseFloat(min) + (0.75 * coefMaxMin)

    return [
      parseFloat(min),
      one,
      two,
      three,
      parseFloat(max)
    ]
  }

  const div = useMemo(() => Leaflet.DomUtil.create('div', `legend ndvi ${type}`), [type])

  useEffect(() => {

    if (map && max !== '0' && type ){
      const rangePalete = geravalue()

      legend.onAdd = () => {

        const labels: any[] = []

        forEach(rangePalete, (item:number) => {
          labels.push(
            `<div style="width: 35px;
           margin-left: 22px;
           background: rgba(9, 45, 61, 0.9);
           height: 40px;
           ">
           <p style="margin:0">${toFixedNoRounding(item, 3)}<p/>
           </div> `
          )
        })

        div.innerHTML = reverse(labels).join('')
        return div
      }

      legend.addTo(map)
    }

    if(remove || !type){
      const node = document.body.querySelector('.ndvi')

      if (node?.parentNode) {
        node.parentNode.removeChild(node)
      }
    }

  }, [map, remove, type])

  return null

}

export default LegendNdviContrast