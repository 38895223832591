import React from 'react'

type TProps = {
  size?: number;
  colorOne?: string;
  colorTwo?: string;
};

const NutriAgro = ({
  size = 35,
  colorOne = '#F37422',
  colorTwo = '#f5f6f6',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 49.05 49.05'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <circle fill='none' className='fil0' cx='24.52' cy='24.52' r='24.52' />
        <path fill={colorTwo} className='fil1' d='M27.43 12.89c-1.48,1.72 -2.9,3.45 -4.02,5.29l-0.18 -0.11c-0.74,-0.45 -1.62,-0.86 -2.58,-1.24 1.51,-2.55 3.51,-4.84 5.52,-7.14l0.09 -0.1c0.59,-0.6 1.56,-0.61 2.16,-0.02 2.91,2.86 5.82,5.72 7.31,8.41 0.81,1.46 1.2,2.85 1.29,4.21 0.09,1.33 -0.12,2.61 -0.51,3.87 -0.37,1.21 -0.89,2.38 -1.47,3.51 -0.56,1.08 -1.22,2.17 -1.87,3.24 -1.27,2.07 -2.51,3.99 -3.74,5.89l-0.05 0.08 -0 -0 -0.07 0.1c-0.52,0.66 -1.48,0.78 -2.14,0.26 -0.67,-0.52 -1.34,-1.05 -2,-1.58 0.7,0.11 1.41,0.22 2.12,0.32 0.04,-0.81 0.09,-1.62 0.13,-2.43l0.31 0.25c0.95,-1.48 1.9,-2.97 2.83,-4.49 0.6,-0.98 1.21,-2 1.76,-3.06 0.53,-1.01 0.97,-2.01 1.27,-2.98 0.28,-0.92 0.44,-1.85 0.38,-2.79 -0.06,-0.92 -0.33,-1.88 -0.9,-2.91 -1.1,-1.98 -3.28,-4.25 -5.63,-6.57z' />
        <path fill={colorOne} className='fil2' d='M14.29 16.45c-0.46,2.36 -0.86,4.7 -0.88,6.93 -0.01,1.36 0.11,2.71 0.39,3.98 0.25,1.16 0.63,2.24 1.14,3.17 0.51,0.92 1.15,1.71 1.91,2.37 0.8,0.69 1.74,1.26 2.81,1.7 1.1,0.46 2.33,0.79 3.63,1.05 0.81,0.16 1.67,0.31 2.56,0.44l0.09 -1.78c0.06,-1.19 0.11,-2.37 0.15,-3.52 0.02,-0.52 0.03,-0.81 0.03,-1.33l2.96 -3.54c0.03,0.41 0.06,0.82 0.07,1.24 0.05,1.24 0.02,2.5 -0.02,3.74 -0.04,1.25 -0.09,2.43 -0.15,3.57 -0.06,1.25 -0.12,2.34 -0.18,3.42 -0,0.06 -0.01,0.13 -0.02,0.19 -0.12,0.84 -0.89,1.42 -1.73,1.3 -1.46,-0.21 -2.91,-0.43 -4.39,-0.73 -1.48,-0.3 -2.9,-0.68 -4.19,-1.22 -1.36,-0.56 -2.58,-1.3 -3.63,-2.22 -1.04,-0.9 -1.91,-1.97 -2.59,-3.21 -0.66,-1.2 -1.14,-2.56 -1.45,-4 -0.32,-1.47 -0.46,-3.05 -0.45,-4.65 0.03,-3.01 0.64,-6.02 1.24,-9.02 0.01,-0.06 0.02,-0.12 0.04,-0.17 0.21,-0.82 1.05,-1.3 1.87,-1.09 1.93,0.51 3.86,1.02 5.66,1.6 1.84,0.6 3.52,1.27 4.86,2.08 1.19,0.72 2.1,1.55 2.8,2.46l-2 2.48c-0.05,-0.08 -0.1,-0.16 -0.15,-0.24 -0.51,-0.77 -1.22,-1.47 -2.23,-2.08 -1.1,-0.67 -2.56,-1.25 -4.21,-1.78 -1.22,-0.4 -2.56,-0.77 -3.94,-1.14z' />
        <polyline fill='none' stroke={colorTwo} strokeWidth={3.26} strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit={22.9256} className='fil3 str0' points='19.13,24.13 22.82,29.14 29.72,20.35 ' />
      </g>
    </svg>
  )
}

export default NutriAgro