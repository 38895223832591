import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { TFormRotations } from 'types/rotations'

import { formatDateToServer } from 'utils/helpers'

type TPayloadGetAllGrowthRotations = {
  page?: number
  size?: number | string
  search?: string
  project?:string
  fields?: number
}

const useGrowthRotations = () => {
  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')

  const getAllGrowthRotations = async (params:TPayloadGetAllGrowthRotations) => {

    const url = `/v2/companies/${params.project}/fields/${params.fields}/field_crops?page=${params.page}&size=${params.size}&q=${params.search}`
    const response = await rest.get(url)

    return response?.data
  }

  const createGrowthRotations = async (params:TFormRotations, project:string, idField:number) => {
    const payload = {
      crop_id: params.harvest,
      end_at: formatDateToServer(params.harvestDate),
      growth_variety_id: params.variety,
      productivity: params.productivity,
      start_at: formatDateToServer(params.plantingDate),
    }
    const url = `/v2/companies/${project}/fields/${idField}/field_crops`
    const response = await rest.post(url, payload )

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.errors }), success: true })
    }

    return response
  }

  const updateGrowthRotations = async (params:TFormRotations, project:string, idField:number) => {
    const payload = {
      crop_id: params.harvest,
      end_at: formatDateToServer(params.harvestDate),
      growth_variety_id: params.variety,
      productivity: params.productivity,
      start_at: formatDateToServer(params.plantingDate),
    }
    const url = `/v2/companies/${project}/fields/${idField}/field_crops/${params.key}`
    const response = await rest.put(url, payload )

    if(response?.status === 200){
      notify({ message: t('item updated successfully', { ns: namespaces.errors }), success: true })
    }

    return response
  }

  const deleteGrowthRotations = async (id:string, projetc:string, field:number) => {

    const url = `/v2/companies/${projetc}/fields/${field}/field_crops/${id}`
    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.common }), success: true })
    }

    return response?.data
  }

  return {
    getAllGrowthRotations,
    createGrowthRotations,
    updateGrowthRotations,
    deleteGrowthRotations
  }
}

export default useGrowthRotations