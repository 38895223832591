import styled from 'styled-components'
import { Upload } from 'antd'

export const Wrapper = styled.div`
    .ant-form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
    }
`

export const Title = styled.h3`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.YELLOW};
    margin: 0;
`

export const Text = styled.p`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.WHITE};
`

export const UploadBtn = styled(Upload)`
    cursor: pointer;
    padding: 0.75rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.WHITE} !important;
    }
    border: dashed 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    
    svg {
        margin: 0 1.5rem -2px 0;
        color: ${({ theme }) => theme.colors.YELLOW};
    }
`

export const UploadBtnInfo = styled.span`
    display: flex;
    gap: 0.75rem;
    margin: 0.75rem 0 1.5rem 0;
    p, h3 {
        font-size: 80% !important;
    }
    border-bottom: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};

`

export const OpenDrawer = styled.a`
    cursor: pointer;
    padding: 0.70rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.WHITE};
    border: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};

    svg {
        color: ${({ theme }) => theme.colors.YELLOW};
    }
`
