import styled from 'styled-components'
import Background from 'assets/backgrounds/yvy-background.png'
import { Button } from 'antd'

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-image: url(${Background});
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover; 
`

export const LoginBox = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 576px;
    min-height: 80vh;
    padding: 40px 34px 40px 34px;
    border-radius: 12px;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: rgba(9, 45, 61, 0.8);
    .ant-form{
        display: flex;
        flex-direction: column;
    }
`

export const Title = styled.h1`
    font-family: Montserrat;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.colors.WHITE};
    margin-bottom: 1rem;
    padding: 2rem 0;
`

export const PrimaryButton = styled(Button)`
    cursor: pointer;
    height: 51px;
    padding: 13.5px 227.5px 15.5px 227.5px;
    border-radius: 4px;
    border: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.39;
    color: ${({ theme }) => theme.colors.WHITE};
    text-transform: uppercase;
    background-color: #F37422;
    
    :hover {
        background-color: #214062;
    }
`

export const Logo = styled.img`
    width: 280.554px;
    height: 87.273px;
`

export const Controller = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3rem;
    font-size: 16px;
    line-height: 1.5;
    .ant-checkbox-wrapper {
        color: ${({ theme }) => theme.colors.WHITE};
    }
    a {
        color: #f37422;
    }
`

export const StyleInput = styled.div`
 margin: 15px 0;
`