import React, { useMemo } from 'react'
import { v1 as uuidv1 } from 'uuid'
import { Table, Wrapper, Title } from './styles'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
interface StatsProps {
  stats: any,
}

const Stats: React.FC<StatsProps> = ({ stats }) => {
  const { content, headers, keys } = stats
  const { t } = useTranslation('namespaces')

  const statsColumn = useMemo(() => {
    const statsColumnArray: any = []

    headers?.forEach((header: string, idx: number) =>
      statsColumnArray[idx] = { ...statsColumnArray[idx], title: header }
    )
    keys?.forEach((key: string, idx: number) =>
      statsColumnArray[idx] = { ...statsColumnArray[idx],
        key,
      })

    statsColumnArray.splice(0, 0, {
      title: '',
      key: 'empty' }
    )
    return statsColumnArray
  }, [])

  return (
    <Wrapper>
      <Title>{t('statistical parameters', { ns: namespaces.pages.analyticalProducts })}</Title>
      <Table>
        <thead>
          {statsColumn.map((thead: any) => <th key={thead.key}>{thead.title}</th>)}
        </thead>
        {content?.length > 0 && (

          <tbody>
            {content[0].values?.map((value: Array<string>) => (
              <tr key={uuidv1()}>
                {value?.map((cell: string) => (
                  <td key={uuidv1()}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    </Wrapper>
  )
}

export default Stats