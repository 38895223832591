import styled from 'styled-components'
import LogoImage from 'assets/yvy-logo-laranja.png'

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: auto;
`
export const Header = styled.div`
margin-top: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 80px;
  background: ${({ theme }) => theme.colors.THEME};
  padding: 0 20px;

  @media screen and (max-width: 800px) {
    display:none;

  }
`
export const Text = styled.text`
color: #fff;
font-size: 18px;
font-weight: bold;
`
export const Body = styled.div`
background: white;
width: 40%;
margin: auto;
padding-top: 30px;
padding-bottom: 60px;
@media screen and (max-width: 800px) {
    padding-top: 15px;
    width: 75%;
  }
`
export const Logo = styled.div`
  cursor: pointer;
  background: url(${LogoImage}) no-repeat;
  background-size: contain;
  width: 200px;
  height: 35px;
  text-indent: -9999px;
  overflow: hidden;
`