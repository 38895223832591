import React, { ReactElement, useMemo } from 'react'
import { Upload } from 'antd'
import { UploadOutlined, CameraOutlined } from '@ant-design/icons'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import { Rule } from 'antd/lib/form'

import Button from 'components/Button'

import { FormItem } from './styles'
import { theme } from 'styles'
import { map } from 'lodash'

type TProps ={
  disable: boolean
  maxFile?: number
  isMultipleFile?: boolean
  label?:string
  name:string | any
  fieldKey?:any
  rules?: Rule[] | undefined
  accept?: string
  type?: 'primary' | 'default' | 'ghost' | 'dashed' | 'link' | 'text' | undefined
  isImage?: Boolean
  onchangeCreateUrl?: (e: any) => void
  defaultFile?: any,
  labelButton?: 'image'| 'file' | 'add photo'
  disablePreview?: boolean
}

const UploadButton = ({
  disable,
  maxFile = 1,
  isMultipleFile = false,
  rules,
  name,
  fieldKey,
  label,
  accept = 'application/pdf',
  type = 'primary',
  isImage = false,
  onchangeCreateUrl = () => {},
  defaultFile,
  labelButton = 'file',
  disablePreview = false
}:TProps) => {

  const { t } = useTranslation('namespaces')

  const props = useMemo(() => ({
    maxCount: maxFile,
    multiple: isMultipleFile,
    accept,
    defaultFileList: defaultFile,
    onChange( info : any ) {
      if(isMultipleFile){
        const getUrlsTemp = map(info?.fileList, item => {
          if(!item.id){
            const url = window.URL.createObjectURL(item.originFileObj)
            return { ...item, image_url: url, rotation: 0 }
          }
          return item
        })
        onchangeCreateUrl(getUrlsTemp)
      }else{
        const url = window.URL.createObjectURL(info.file)
        onchangeCreateUrl({ name: info.file.filename, url })
      }
    },
    beforeUpload() {return false },
    onRemove(){
      onchangeCreateUrl({ name: '', url: defaultFile })
    },
    itemRender: (originNode: ReactElement) => {

      if(disablePreview) return ''

      return originNode
    }
  }), [disable, maxFile, isMultipleFile, disablePreview])

  const labelBtn = useMemo(
    () => {
      switch (labelButton) {
        case 'file':
          return t('import', { ns: namespaces.common, type: t(`${labelButton}`, { ns: namespaces.common }) })
        case 'image':
          return t('import', { ns: namespaces.common, type: t(`${labelButton}`, { ns: namespaces.common }) })
        case 'add photo':
          return t('add photo', { ns: namespaces.common })
        default:
          return t('change image', { ns: namespaces.layout })
      }
    }, [labelButton, t])

  const normFile = (e:any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return(
    <FormItem
      label={label}
      name={name}
      rules={rules}
      getValueFromEvent={normFile}
      valuePropName='fileList'
      fieldKey={fieldKey}
    >
      <Upload {...props}>
        <Button
          label={labelBtn }
          id='upload-file'
          type={type}
          icon={ isImage ? <CameraOutlined style={{ fontSize: 20 }} /> : <UploadOutlined style={{ color: theme.colors.YELLOW }} />}
          htmlType='button'
        />
      </Upload>
    </FormItem>
  )

}

export default UploadButton