import styled from 'styled-components'
import { Avatar, Breadcrumb } from 'antd'
import { BellOutlined } from '@ant-design/icons'

import LogoImage from 'assets/yvy-logo-laranja.png'
export const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 80px;
  background: ${({ theme }) => theme.colors.THEME};
  padding: 0 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    display: flex;
    flex-direction: column;

    div:first-child {
      margin-bottom: 10px;
    }
  }
`

export const Logo = styled.div`
  cursor: pointer;
  background: url(${LogoImage}) no-repeat;
  background-size: contain;
  width: 200px;
  height: 35px;
  text-indent: -9999px;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: auto;
`

export const Notification = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.THEME};
`

export const CustomIconBell = styled(BellOutlined)`
  color: ${({ theme }) => theme.colors.WHITE};
`

export const Text = styled.div`
  display: flex;
  align-items: center;
`
export const NavigationMenu = styled(Breadcrumb)`
   span:last-child{
    font-weight: bold;
  }
  .ant-breadcrumb-separator {
    color: ${({ theme }) => theme.colors.WHITE};
  }
`
export const NavigationItem = styled(Breadcrumb.Item)`
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.WHITE};
  padding-left: 10px;
`
