import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  padding: 1.5rem 50px 1.5rem 50px;
  background-color: ${({ theme }) => theme.colors.AZUL_YVY_WRAPPER_BACKGROUND};
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.THEME};
    border-bottom-right-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.YELLOW};
  }
`
export const Container = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.THEME};
    border: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  border-radius: 4px;
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.THEME};
  color: ${({ theme }) => theme.colors.BLACK};
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  border-radius: 4px;
  cursor: pointer;
  position: relative;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`

export const ProjectLogo = styled.div`
  flex: 0 0 auto;
  margin-right: 16px;
  width: 30%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
  }
`


export const Avatar = styled.div`
  flex: 0 0 auto;
  margin-right: 16px;
  width: 80px;
  height: 80px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 2.25rem;
  font-weight: bolder;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.DARK_GREEN};
`

export const Footer = styled.div`
  width: 100px;
`

export const ProjectLabel = styled.label`
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  h3, span {
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    padding: 0;
  }
  h3 {
    color: ${({ theme }) => theme.colors.YELLOW};
  }
  span {
    color: ${({ theme }) => theme.colors.WHITE};
  }
`

export const Divider = styled.div`
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`

export const HeaderTitle = styled.h2`
  display: flex;
  justify-content: start;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.72;
  letter-spacing: normal;
  color: ${({ theme }) => `${theme.colors.YELLOW} !important`};
  margin-right: 10px;
`

export const Controllers = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const EditProject = styled.a`
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  svg {
    color: ${({ theme }) => theme.colors.YELLOW};
    font-size: 1.5rem;
  }
`
