import styled from 'styled-components'
import { Swiper } from 'swiper/react'

type TPointContainer ={
  colorBorder: string
}

type TPoint = {
  color:string
}

export const ContentInfo = styled.div`
  padding: 8px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  color: ${({ theme }) => theme.colors.YELLOW};
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    color: ${({ theme }) => theme.colors.THEME_TWO_BORDER};
    font-size: 14px;
    line-height: 143%;
    white-space: normal;
  }
`

export const PointContainer = styled.div<TPointContainer>`
  margin-left: 16px;
  border: ${({ colorBorder }) => `1px solid ${colorBorder}`};
  background-color: ${({ theme }) => theme.colors.THEME};
  border-radius: 8px;
  max-width: 268px;
  width: 268px;
  height: auto;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-bottom: 20px;

`
export const PointHeader = styled.div`
  padding: 12px 16px 12px 16px;
  gap: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.YELLOW};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong{
    width: 100%;
  }
`

export const PointCircle = styled.div<TPoint>`
  width: 18px;
  height: 16px;
  background-color: ${({ theme, color }) => color ? color : theme.colors.YELLOW};
  border-radius: 50%;
  margin-right: 8px;
`

export const PointContent = styled.div``

export const CarouselImage = styled(Swiper)`
  width: 240px;
  height: 75px;
  margin: 10px 0 0 10px;
  gap: 0;

  .swiper-slide{
    width: 70px !important;
  }
`

export const Img = styled.img<{rotate:number}>`
 border-radius: 4px;
 width: 60px;
 height: 60px;
 transform: ${({ rotate }) => `rotate(${rotate}deg)`};
`