

import styled from 'styled-components'
import { Collapse, Menu, Tabs } from 'antd'
const { Panel } = Collapse
const { TabPane } = Tabs

export const Container = styled.div`
    display: flex;
    width: 100%;
`


export const MapContainer = styled.div`
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
`

export const MenuCollapse = styled(Collapse)`
    .ant-collapse-header {
        border-top: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
        border-bottom: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
        color: ${({ theme }) => theme.colors.YELLOW} !important;
        svg {
            color: ${({ theme }) => theme.colors.YELLOW};
        }
    }
    .ant-collapse-content-box{
        padding: 0 !important;
    }
`

export const MenuPanel = styled(Panel)``

export const Footer = styled.div`
    display: flex;
    background-color: #000;
    height: 300px;
    flex: 1;
    width: 100%;
`



export const MenuWrapper = styled(Menu)`
     width: 100% !important;
     background: ${({ theme }) => theme.colors.THEME};
     color: ${({ theme }) => theme.colors.WHITE};
    .ant-menu-item{
        margin: 0;
    }
 .ant-menu-item-active , .ant-menu-item-selected {
    background-color: rgba(10, 166, 150, 0.1) !important;
     color:${({ theme }) => theme.colors.WHITE};
 }
 li > .ant-menu-item,
  .ant-menu-item-selected {
    background: ${({ theme }) => `${theme.colors.MOSS_GREEN} !important`};

    ::before {
      display: block;
      float: left;
      height: 100%;
      border-left: ${({ theme }) => `3px solid${theme.colors.BLUEGREEN}`};
      content: "";
      margin-left: -16px;
      padding-left: 16px;
    }

    ::after {
      display: none;
    }
  }
    border-right: none;
`



export const MenuTitle = styled.div`
    display: flex;
    align-items:  center;
    gap: 0.5rem;
    svg {
        color: ${({ theme }) => theme.colors.RED} !important;
    }

`

export const MenuItem = styled(Menu.Item)`
  margin: 0 !important;
  background-color: transparent !important;
    color: ${({ theme }) => theme.colors.WHITE};
`



export const TabsWrapper = styled.div`
        border-top: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
        padding-top: 0.5rem;

        .ant-tabs-nav-wrap {
            height: 66px;
        }

    .ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: 1px solid ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    }
    .ant-tabs-tab:first-child{
        margin-left: 1.5rem !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.colors.YELLOW};
    }
    .ant-tabs {
        color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab{
        padding-bottom: 1rem;
        background-color: ${({ theme }) => theme.colors.THEME} !important;
        border: none;
        margin-bottom: 1px;
    }
    .ant-tabs-tab-active {
         border-bottom: 1px solid  ${({ theme }) => theme.colors.YELLOW} !important ;
    }
    .ant-tabs-tab-remove {
        display: none;
    }
    .ant-tabs-top > .ant-tabs-nav{
        margin: 0;
    }
`

export const Tab = styled(TabPane)``

export const TabTitle = styled.div<{ isAttach?: boolean}>`
    display: flex;
    align-items: center;
    gap: 1rem;
    color: ${({ isAttach, theme }) => isAttach && theme.colors.WHITE}};
    border: ${({ isAttach, theme }) => isAttach && `1px solid ${theme.colors.WHITE}`};
    padding: ${({ isAttach }) => isAttach && '0.25rem 0.5rem'};
    border-radius: 8px;

    svg {
        color: ${({ theme }) => theme.colors.YELLOW};
    }
`