import React from 'react'
import { Form } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import TextField from 'components/TextField'
import useReport from 'repositories/useReport'
import Modal from 'components/Modal'
import Button from 'components/Button'

import {
  initialValues,
  validation,
  TFormReport
} from './validations'
import {
  FormBox,
  ContainerBtn,
  Text,
} from './styles'

type TProps = {
  open: boolean
  handleClose: () => void
  id: string
  productSelected: number
  reportId: number
  reportName?: string
  projectSelected:string
}

const ModalRemoveReport = ({ open, handleClose, id, productSelected, reportId, reportName, projectSelected }: TProps) => {

  const [form] = Form.useForm<TFormReport>()
  const { t } = useTranslation('namespaces')
  const reportApi = useReport()

  const onFinish = async() => {
    const params = {
      project: projectSelected,
      productSelected,
      reportId
    }
    await reportApi.removeReport(params, handleClose)
  }

  return (
    <Modal
      id={id}
      title={t('remove something', { ns: namespaces.common, label: t('report', { ns: namespaces.pages.field }) })}
      isModalVisible={open}
      handleClose={ handleClose}
    >
      <FormBox>
        <Form
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          name='form-report'
          form={form}
          autoComplete='off'
          onFinish={onFinish}
        >
          <Text> {t('are you sure you want to delete', { ns: namespaces.common, label: reportName })}</Text>
          <Text> {t('to complete type and confirm', { ns: namespaces.common, label: t('delete', { ns: namespaces.common }).toUpperCase() })}</Text>
          <TextField
            type='text'
            name='delete'
            rules={ validation('delete', t)}
            id='input-delete'
          />
          <ContainerBtn>
            <Button
              id='button-cancel'
              type='default'
              label={t('cancel', { ns: namespaces.common })}
              onClick={ handleClose }
              htmlType='reset'
            />
            <Button
              id='button-confirm'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              htmlType='submit'
            />
          </ContainerBtn>
        </Form>
      </FormBox>
    </Modal>
  )
}

export default ModalRemoveReport
