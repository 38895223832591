import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { dateRange, formatDateToView } from 'utils/helpers'

import { useScoutContext } from 'hooks/useScoutContext'

import { TDateComponent, TReports, TSelectOption } from 'types/types'

import { TPoint, TScout } from 'types/report'

import useReport from 'repositories/useReport'
import useGrowthRotations from 'repositories/useGrowthRotations'
import useGrowths from 'repositories/useGrowths'
import useScout from 'repositories/useScout'

import Point from '../Point'
import EditScout from '../EditScout'

import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon'
import EditIcon from 'components/Icons/EditIcon'
import DownloadIcon from 'components/Icons/DownloadIcon'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'

import {
  Container,
  HeaderTitle,
  ContentInfo,
  Content,
  Hr
} from './styles'


type TProps = {
  reportSelectedId: number | undefined
  setReportSelected:React.Dispatch<React.SetStateAction<TReports & TScout | undefined>>
  goBack:()=>void
  projectSelected:string
  monitoringDate: number
  imageType: TSelectOption
  searchInfosTimeLine: Function
  searchDatesCalendar: Function
  setDateSelected:Function
  typeSatellite:any
}

const ViewScout = ({ reportSelectedId, setReportSelected, goBack, projectSelected, monitoringDate, imageType, searchInfosTimeLine, searchDatesCalendar, setDateSelected, typeSatellite }:TProps) => {

  const { t } = useTranslation('namespaces')

  const growthsApi = useGrowths()
  const growthRotationsApi = useGrowthRotations()
  const scoutApi = useReport()
  const apiScout = useScout()
  const { toEdit, setToEdit } = useScoutContext()

  const [scout, setScout] = useState<TScout>()
  const [variety, setVariety] = useState<any>([])
  const [crop, setCrop] = useState<any>()

  const varietyOptions = useMemo(
    () => {
      if(!isEmpty(variety)) return map(variety, ({ name, id }) => ({ label: name, value: id }))

      return []
    }, [variety])

  const cropOptions = useMemo(
    () => map(crop, ({ name, crop, growth }) => ({ label: name, value: crop.id, growthId: growth?.id }))
    , [crop])

  const growthsOptions = useMemo(
    () => uniqBy(map(crop, ({ growth }) => ({ label: growth?.name, value: growth?.id })), 'value')
    , [crop])

  const handleUpdateScout = useCallback(
    () => {
      if(reportSelectedId){
        getScout(reportSelectedId)
        setToEdit(false)
      }
    }, [reportSelectedId])

  const getVariety = useCallback(
    async (id:any) => {
      const response = await growthsApi.getAllGrowths(id)
      setVariety(response)
    }, [scout])

  const viewPDFModal = async() => {
    await apiScout.getUrlFilePdf(reportSelectedId)
  }

  const handleComponent = useMemo(
    () =>
      !toEdit ? (
        <>
          <HeaderTitle>
            <div
              onClick={() => {
                goBack()
                setScout(undefined)
                setToEdit(false)
              }}
            >
              <ArrowLeftIcon />
              {t('scout', { ns: namespaces.pages.field })}
            </div>
            <div>
              <div onClick={() => setToEdit(true)}>
                <EditIcon />
              </div>
              <div onClick={() => viewPDFModal()}>
                <DownloadIcon />
              </div>
            </div>
          </HeaderTitle>
          <Content>
            <ContentInfo>
              <div>
                {t('responsible', { ns: namespaces.pages.field })}
                <span>{scout?.user_name || ' - '}</span>
              </div>
            </ContentInfo>
            <ContentInfo>
              <div>
                {t('creation date', { ns: namespaces.pages.projects })}
                <span>{ scout?.created_at_device ? formatDateToView(scout.created_at_device, false) : ' - '}</span>
              </div>
            </ContentInfo>
            <Hr />
            <ContentInfo>
              <div>
                {t('farm', { ns: namespaces.pages.field })}
                <span>{scout?.farm_name}</span>
              </div>
            </ContentInfo>
            <ContentInfo>
              <div>
                {t('field', { ns: namespaces.layout })}
                <span>{scout?.field_name}</span>
              </div>
            </ContentInfo>
            <ContentInfo>
              <div>
                {t('crop', { ns: namespaces.common })}
                <span>{scout?.crop_name || ' - '}</span>
              </div>
            </ContentInfo>
            <ContentInfo>
              <div>
                {t('culture', { ns: namespaces.common })}
                <span>{scout?.growth_name || ' - '}</span>
              </div>
            </ContentInfo>
            {map(scout?.report_scout_points, (item:TPoint, index:number) => (
              <Point
                index={index + 1}
                pointData={item}
              />
            ))}
          </Content>
        </>
      ) : (
        <EditScout
          scoutSelected={scout}
          goBack={handleUpdateScout}
          deleteScout={goBack}
          setReportSelected={setReportSelected}
          cropOptions={cropOptions}
          growthsOptions={growthsOptions}
          varietyOptions={varietyOptions}
          getVariety={getVariety}
          monitoringDate={monitoringDate}
          imageType={imageType}
        />
      )
    , [toEdit, scout, cropOptions, growthsOptions, varietyOptions, monitoringDate, imageType])

  const getAllCrop = async (id:number) => {
    const response = await growthRotationsApi.getAllGrowthRotations({
      page: 1,
      size: '',
      search: '',
      project: projectSelected,
      fields: id
    })
    setCrop(response?.field_crops) // safra e culturas
  }

  const getScout = async (id:number) => {
    const response = await scoutApi.getReportScoutById(id)

    setScout({ ...response, report_scout_points: orderBy(response.report_scout_points, ['id'], ['asc']) })
    setReportSelected({ ...response, report_scout_points: orderBy(response.report_scout_points, ['id'], ['asc']), oldComponent: true, isView: true })
    getAllCrop(response?.field_id)

    const dateArray = new Date(response?.monitoring_date * 1000)
    const range = dateRange(dateArray)
    const dateFormate = formatDateToView(response?.monitoring_date, true)

    const newDate = {
      unixFormate: response?.monitoring_date,
      date: dateFormate,
      day: dateFormate.slice(0, 2),
      timeInterval: {
        to: range.to,
        from: range.from
      },
      satellite: 'Sentinel-2'
    } as unknown as TDateComponent

    setDateSelected(newDate)
    searchInfosTimeLine(1, response?.monitoring_date)
    searchDatesCalendar(range.to, range.from)
  }

  useEffect(() => {
    if(toEdit){
      setReportSelected((old:any) => ({ ...old, isView: false }))
    }
  }, [toEdit])

  useEffect(() => {
    if(reportSelectedId){
      getScout(reportSelectedId)
    }
  }, [reportSelectedId])

  useEffect(() => {
    if(!isEmpty(typeSatellite)){
      setDateSelected((old:any) => ({ ...old, satellite: typeSatellite.type }))
    }
  }, [scout, typeSatellite, t])

  return (
    <Container toEdit={toEdit}>
      {handleComponent}
    </Container>
  )
}

export default ViewScout