import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { namespaces } from 'i18n/i18n.constants'
import { Form, Row, Col } from 'antd'

import {
  Card,
  Wrapper,
  Header,
  HeaderTitle,
  Controllers,
} from './styles'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import TextField from 'components/TextField'
import ImageUpload from 'components/ImageUpload'
import {
  initialValues,
  validation,
} from './validations'
import { TFormProject } from 'types/projects'
import useProject from 'repositories/useProjects'

const ProjectController = () => {
  const { createProject, updateProject } = useProject()

  const [form] = Form.useForm<TFormProject>()
  const location:any = useLocation()

  const history = useHistory()
  const { t } = useTranslation('namespaces')

  const [projectId, setProjectId] = useState('')
  const [previewLogo, setPreviewLogo] = useState('')

  const onFinish = (values:TFormProject) => {
    const { name, email, code, logo } = values
    const payload = new FormData()
    payload.append('name', name)
    payload.append('email', email)
    payload.append('code', code)
    logo && logo?.length > 0 && payload.append('logo', logo[0]?.originFileObj)
    // @ts-ignore: Unreachable code error
    logo?.length === 0 && !previewLogo && payload.append('remove_logo', true)

    projectId ? updateProject(payload, projectId) : createProject(payload)

  }

  useEffect(() => {
    if(location?.state?.project){
      const { id, name, email, code, logo } = location?.state?.project
      form.setFieldsValue({ name, email, code })
      logo && logo.url && setPreviewLogo(logo.url)
      setProjectId(id)
    }
  }, [location.state])

  return(
    <Wrapper>
      <Form
        initialValues={initialValues}
        labelCol={{ span: 6 }}
        name='form-rotationst'
        form={form}
        autoComplete='off'
        onFinish={ onFinish}
      >
        <Header>
          <HeaderTitle>
            {t('view all company information', { ns: namespaces.pages.projectController })}
          </HeaderTitle>
          <Controllers>
            <Button
              id='btn-cancel'
              onClick={() => history.goBack()}
              color='#354453'
              label={ t('cancel', { ns: namespaces.common }) }
              htmlType='button'
            />
            <Button
              id='btn-save-project'
              type='primary'
              label={ t('save', { ns: namespaces.common }) }
              htmlType='submit'
            />
          </Controllers>
        </Header>
        <Row gutter={[16, 24]} justify='start'>
          <Col className='gutter-row' span={12}>
            <Card title={t('general information', { ns: namespaces.pages.projectController })}>
              <TextField
                label={ t('name', { ns: namespaces.common }) }
                placeholder={t('enter the project name', { ns: namespaces.pages.projectController })}
                id='input-name'
                name='name'
                rules={validation('name', t)}
              />
              <TextField
                label={ t('email', { ns: namespaces.common }) }
                placeholder={t('enter the administrative email', { ns: namespaces.pages.projectController })}
                type='email'
                id='input-email'
                name='email'
                rules={validation('email', t)}
              />
              <TextField
                label={t('cnpj ruc', { ns: namespaces.pages.projectController })}
                placeholder={t('enter the CNPJ/RUC', { ns: namespaces.pages.projectController })}
                id='input-code'
                name='code'
                rules={validation('code', t)}
              />
            </Card>
          </Col>
          <Col className='gutter-row' span={12}>
            <Card title='Logo'>
              <ImageUpload
                label={t('insert logo', { ns: namespaces.pages.projectController })}
                name='logo'
                onRemove={() => { form.setFieldsValue({ logo: [] }); setPreviewLogo('')}}
                defaultPreviewUrl={previewLogo}
              />
            </Card>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  )
}

export default ProjectController