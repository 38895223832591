import React from 'react'

type TProps = {
  size?: number;
  colorOne?: string;
  colorTwo?: string;
};

const FertilizationIcon = ({
  size = 35,
  colorOne = '#F37422',
  colorTwo = '#f5f6f6',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 72.98 72.98'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <circle stroke={colorTwo} cx='36.49' cy='36.49' r='36.49' fill='none' />
        <path fill={colorOne} d='M50.32 19.9l-27.06 0c-1.71,5.23 -2.55,10.6 -2.55,16.08 0,5.58 0.87,11.28 2.56,17.07l27.05 0c1.35,-5.53 1.98,-11.05 1.96,-16.57 -0.02,-5.52 -0.7,-11.05 -1.96,-16.58zm-20.61 18.96l0 -4.77 0.94 0 1.96 3.2 0 -3.2 0.89 0 0 4.77 -0.97 0 -1.93 -3.14 0 3.14 -0.89 0zm4.81 0l0 -4.77 1.55 0c0.59,0 0.97,0.02 1.14,0.07 0.27,0.07 0.5,0.23 0.69,0.47 0.18,0.24 0.28,0.55 0.28,0.93 0,0.29 -0.05,0.54 -0.16,0.74 -0.11,0.2 -0.24,0.36 -0.4,0.47 -0.16,0.11 -0.33,0.19 -0.5,0.23 -0.23,0.05 -0.56,0.07 -1,0.07l-0.63 0 0 1.8 -0.97 0zm0.97 -3.97l0 1.36 0.53 0c0.38,0 0.63,-0.03 0.76,-0.08 0.13,-0.05 0.23,-0.13 0.3,-0.24 0.07,-0.11 0.11,-0.23 0.11,-0.37 0,-0.17 -0.05,-0.32 -0.15,-0.43 -0.1,-0.11 -0.23,-0.18 -0.39,-0.21 -0.11,-0.02 -0.34,-0.03 -0.69,-0.03l-0.46 0zm3.49 3.97l0 -4.77 0.97 0 0 2.11 1.95 -2.11 1.3 0 -1.8 1.86 1.89 2.91 -1.25 0 -1.31 -2.24 -0.78 0.8 0 1.44 -0.97 0zm-2.79 -12.86c2.81,0 5.35,1.14 7.2,2.98 1.84,1.84 2.98,4.39 2.98,7.2 0,2.81 -1.14,5.35 -2.98,7.2 -1.84,1.84 -4.39,2.98 -7.2,2.98 -2.81,0 -5.35,-1.14 -7.2,-2.98 -1.84,-1.84 -2.98,-4.39 -2.98,-7.2 0,-2.81 1.14,-5.36 2.98,-7.2 1.84,-1.84 4.39,-2.98 7.2,-2.98zm6.62 3.55c-1.69,-1.69 -4.04,-2.74 -6.62,-2.74 -2.59,0 -4.93,1.05 -6.62,2.74 -1.69,1.69 -2.74,4.04 -2.74,6.62 0,2.59 1.05,4.93 2.74,6.62 1.69,1.69 4.04,2.74 6.62,2.74 2.59,0 4.93,-1.05 6.62,-2.74 1.69,-1.7 2.74,-4.04 2.74,-6.62 0,-2.59 -1.05,-4.93 -2.74,-6.62z' />
        <polygon fill={colorOne} points='50.51,17.91 23.02,17.91 23.28,19.09 50.3,19.09 ' />
        <polygon fill={colorOne} points='23.02,55.07 50.51,55.07 50.3,53.9 23.28,53.9 ' />
        <path fill={colorTwo} d='M42.81 29.55c-1.69,-1.69 -4.04,-2.74 -6.62,-2.74 -2.59,0 -4.93,1.05 -6.62,2.74 -1.69,1.69 -2.74,4.04 -2.74,6.62 0,2.59 1.05,4.93 2.74,6.62 1.69,1.69 4.04,2.74 6.62,2.74 2.59,0 4.93,-1.05 6.62,-2.74 1.69,-1.7 2.74,-4.04 2.74,-6.62 0,-2.59 -1.05,-4.93 -2.74,-6.62zm-13.1 9.31l0 -4.77 0.94 0 1.96 3.2 0 -3.2 0.89 0 0 4.77 -0.97 0 -1.93 -3.14 0 3.14 -0.89 0zm4.81 0l0 -4.77 1.55 0c0.59,0 0.97,0.02 1.14,0.07 0.27,0.07 0.5,0.23 0.69,0.47 0.18,0.24 0.28,0.55 0.28,0.93 0,0.29 -0.05,0.54 -0.16,0.74 -0.11,0.2 -0.24,0.36 -0.4,0.47 -0.16,0.11 -0.33,0.19 -0.5,0.23 -0.23,0.05 -0.56,0.07 -1,0.07l-0.63 0 0 1.8 -0.97 0zm0.97 -3.97l0 1.36 0.53 0c0.38,0 0.63,-0.03 0.76,-0.08 0.13,-0.05 0.23,-0.13 0.3,-0.24 0.07,-0.11 0.11,-0.23 0.11,-0.37 0,-0.17 -0.05,-0.32 -0.15,-0.43 -0.1,-0.11 -0.23,-0.18 -0.39,-0.21 -0.11,-0.02 -0.34,-0.03 -0.69,-0.03l-0.46 0zm3.49 3.97l0 -4.77 0.97 0 0 2.11 1.95 -2.11 1.3 0 -1.8 1.86 1.89 2.91 -1.25 0 -1.31 -2.24 -0.78 0.8 0 1.44 -0.97 0z' />

      </g>
    </svg>
  )
}

export default FertilizationIcon