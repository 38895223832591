import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

export type TFormReport = {
  image_cover: File[] | [] | string
  image_right: File[] | [] | string
  image_left: File[] | [] | string
}

export const initialValues: TFormReport = {
  image_cover: [],
  image_right: [],
  image_left: []
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'image_cover':
      return [{
        required: false,
        message: t('field is required', { ns: namespaces.modals.createRotationst, label: t('file', { ns: namespaces.common }) })
      }] as Rule[]

    case 'image_right':
      return [{
        required: false,
        message: t('field is required', { ns: namespaces.modals.createRotationst, label: t('file', { ns: namespaces.common }) })
      }] as Rule[]

    case 'image_left':
      return [{
        required: false,
        message: t('field is required', { ns: namespaces.modals.createRotationst, label: t('file', { ns: namespaces.common }) })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}
