import React from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { initialValues, validation } from './validations'
import LogoIMG from 'assets/logo-login.png'
import {
  Container,
  LoginBox,
  Logo,
  Title,
  PrimaryButton,
  Controller,
  StyleInput
} from './styles'
import { FaEnvelope } from 'react-icons/fa'
import TextField from 'components/TextField'
import { Form } from 'antd'
import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'

export type TRecover ={
  email: string
}

const ForgotPassword = () => {
  const { post } = useRest()
  const [form] = Form.useForm<TRecover>()
  const { t } = useTranslation('namespaces')
  const { notify } = useToast()

  const path = `${window.location.href.slice(0, -15)}reset-password`

  const onFinish = (values: TRecover) => {
    post('/auth/password', {
      email: values.email,
      redirect_url: path
    })
      .then(({ data }: any) => {
        notify(data)
      })
  }

  return (
    <Container>
      <LoginBox>
        <Logo src={LogoIMG} alt='logo' />
        <Title>{t('title', { ns: namespaces.pages.forgotPassword })}</Title>
        <Form
          name='basic'
          initialValues={initialValues}
          onFinish={onFinish}
          form={form}
          autoComplete='off'
        >
          <StyleInput>
            <TextField
              placeholder={t('email', { ns: namespaces.common })}
              type='text'
              name='email'
              startIcon={<FaEnvelope />}
              rules={ validation('email', t)}
              id='input-email'
            />
          </StyleInput>
          <PrimaryButton
            htmlType='submit'
            type='primary'
          >
            {t('button', { ns: namespaces.pages.forgotPassword })}
          </PrimaryButton>
        </Form>
        <Controller>
          <a href='/'>{t('link', { ns: namespaces.pages.forgotPassword })}</a>
        </Controller>

      </LoginBox>
    </Container>
  )
}

export default ForgotPassword