import { isEmpty, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import useWatcherReports from 'repositories/useWatcherReports'
import { formatDateTimeToView } from 'utils/helpers'
import { TReports } from 'types/types'
import { TScout } from 'types/report'
import ReportsContent from './reportsContent'

import { Container, ListContainer, BackButton, Title, List, Item } from './styles'

type TProps = {
  projectSelected:string
  reportSelectedId: number | undefined
  setReportSelected: React.Dispatch<React.SetStateAction<TReports & TScout | undefined>>
  goBack:()=> void
}

const ViewFieldReport = ({ projectSelected, reportSelectedId, setReportSelected, goBack }:TProps) => {

  const reportsApi = useWatcherReports()
  const [dataRepostsId, setDataReportsId] = useState<TReports>()
  const localLanguage = localStorage.getItem('agfarmusLanguage')
  const { t } = useTranslation('namespaces')

  useEffect(() => {
    const getAttachments = async () => {
      const response = await reportsApi.getWatcherReportsAttachments(projectSelected, reportSelectedId )

      setDataReportsId(response)
      setReportSelected(response)
    }
    if(reportSelectedId){
      getAttachments()
    }
  }, [reportSelectedId])

  if(!reportSelectedId) return null

  return (
    <Container>
      <ListContainer>
        <BackButton onClick={() => goBack()}>
          {t('back reports', { ns: namespaces.pages.field })}
        </BackButton>
      </ListContainer>
      <ListContainer>
        <Title>{t('general data', { ns: namespaces.pages.field })}</Title>
        {
          dataRepostsId?.info && <List>
            <Item colorYellow>
              {t('information', { ns: namespaces.pages.field })}
              <span>{dataRepostsId?.info}</span>
            </Item>
          </List>
        }
        {
          dataRepostsId?.deepness &&
          <List>
            <Item colorYellow>
              {t('deepness', { ns: namespaces.pages.field })}
              <span>{dataRepostsId?.deepness}</span>
            </Item>
          </List>

        }
        {
          dataRepostsId?.samples_number &&
          <List>
            <Item colorYellow>
              {t('samples number', { ns: namespaces.pages.field })}
              <span>{dataRepostsId?.samples_number}</span>
            </Item>
          </List>

        }
        {
          dataRepostsId?.equipment &&
          <List>
            <Item colorYellow>
              {t('equipment', { ns: namespaces.pages.field })}
              <span>{dataRepostsId?.equipment}</span>
            </Item>
          </List>

        }
        {
          dataRepostsId?.growth_variety && <List>
            <Item colorYellow>
              {t('variety', { ns: namespaces.pages.field })}

              <span>{dataRepostsId?.growth_variety.translations.find((e: any) => e.locale === localLanguage).name}</span>
            </Item>
          </List>
        }
        {
          !isEmpty(dataRepostsId?.content) && <ReportsContent content={dataRepostsId?.content} />
        }
        {
          dataRepostsId?.observation && <List>
            <Item colorYellow>
              {t('observations', { ns: namespaces.pages.field })}

              <span>{dataRepostsId?.observation}</span>
            </Item>
          </List>
        }
        <List>
          <Item colorYellow>
            {t('created at', { ns: namespaces.common })}
            <span>{dataRepostsId?.created_at_device ? formatDateTimeToView(dataRepostsId?.created_at_device) : '-'}</span>
          </Item>
        </List>
        {
          dataRepostsId?.vegetative_index && <List>
            <Item colorYellow>
              {t('vegetative_index', { ns: namespaces.pages.field })}
              <span>{dataRepostsId?.vegetative_index}</span>
            </Item>
          </List>
        }
        {
          dataRepostsId?.weather && <List>
            <Item colorYellow>
              {t('weather condition', { ns: namespaces.pages.field })}
              <span>{dataRepostsId?.weather}</span>
            </Item>
          </List>
        }

        {
          dataRepostsId?.nutritional_symptom && <List>
            <Item colorYellow>
              {t('nutritional symptoms', { ns: namespaces.pages.field })}
              <span>{dataRepostsId?.nutritional_symptom.translations.find((e: any) => e.locale === localLanguage)?.name}</span>
            </Item>
          </List>
        }

        {
          !isEmpty(dataRepostsId?.threat_levels) && (
            <List>
              <Item colorYellow>
                {t('threats', { ns: namespaces.pages.field })}
                {
                  dataRepostsId?.threat_levels?.map((item: any) => {
                    return (
                      <div key={item.id}>
                        <span>{item?.threat?.translations.find((e: any) => e.locale === localLanguage)?.name} / {item?.threat?.translations.find((e: any) => e.locale === localLanguage)?.scientific_name}</span>
                        <span>{t('quantity', { ns: namespaces.pages.field })}: {t(item?.level, { ns: namespaces.pages.field })}</span>
                        <img src={item?.threat?.image.url} alt={item?.threat?.image.filename} />
                        <span>{item?.threat?.translations.find((e: any) => e.locale === localLanguage)?.recognition}</span>
                        <span>{item?.threat?.translations.find((e: any) => e.locale === localLanguage)?.symptoms}</span>
                      </div>
                    )
                  })
                }
              </Item>
            </List>
          )
        }


      </ListContainer>
      <ListContainer>
        <Title>{t('pictures', { ns: namespaces.pages.field })}</Title>

        {dataRepostsId?.attachments?.length ? (
          <>
            {map(dataRepostsId.attachments, (item: any, index) =>
              (
                <Item key={index}>
                  <img src={item.file.url} alt={item.file.filename} />
                </Item>
              )
            )}
          </>
        ) : (<></>)}
      </ListContainer>
    </Container>
  )
}

export default ViewFieldReport