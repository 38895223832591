import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.LIGHT_THEME};
`

export const IconTable = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg{
    margin-right: 10px;
  }
`