import React from 'react'

import Header from '../MainLayout/Header'

import { Container, Main, Content } from '../MainLayout/styles'

const MainLayout = ({ children }:any) => {

  return (
    <Container>
      <Header />
      <Main>
        <Content iscollapsedsidebar={true}>
          {children}
        </Content>
      </Main>
    </Container>
  )
}

export default MainLayout