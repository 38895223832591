import React from 'react'
import EnvironmentChart from './EnvironmentChart'
import { v1 as uuidv1 } from 'uuid'
import { Wrapper, ChartGroup, ChartGroupTitle, ChartsWrapper, Chart } from './styles'
import { useProductContext } from 'hooks/useProductContext'

interface EnvironmentAnalysisProps{
  environment: any
}

const EnvironmentAnalysis: React.FC<EnvironmentAnalysisProps> = ({ environment }) => {
  const { fullscreen } = useProductContext()

  const colors: any = {
    Baixo: ['#c95058', '#ef757d'],
    Médio: ['#eeff41', '#f4ff81'],
    Alto: ['#73a84b', '#b1d695'],
    Low: ['#c95058', '#ef757d'],
    Middle: ['#eeff41', '#f4ff81'],
    High: ['#73a84b', '#b1d695'],
    Bajo: ['#c95058', '#ef757d'],
    Moderado: ['#eeff41', '#f4ff81'],
    // Alto: ['#73a84b', '#b1d695'],
  }
  return (
    <Wrapper>
      {environment.map((group: any) => (
        <>
          <ChartGroupTitle>{`${group.index}. ${group.title}`}</ChartGroupTitle>
          <ChartGroup key={`environment-chart-group-${uuidv1()}`}>
            <ChartsWrapper>
              {group.charts.map((chart: any) => (
                <Chart fullscreen={fullscreen} key={`general-chart-${uuidv1()}`}>
                  <EnvironmentChart
                    key={`environment-chart-${uuidv1()}`}
                    title={chart?.title}
                    description={chart?.description}
                    yMin={chart?.yMin}
                    yMax={chart?.yMax}
                    xLabel={chart?.xLabel}
                    yLabel={chart?.yLabel}
                    series={chart?.series}
                    idealValues={chart?.ideal_values}
                    colors={colors}
                    ungrouped={chart?.type === 'ungrouped_bar'}
                  />
                </Chart>
              ))}
            </ChartsWrapper>
          </ChartGroup>
        </>
      ))}
    </Wrapper>
  )
}

export default EnvironmentAnalysis