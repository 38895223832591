import React from 'react'
import { Wrapper } from './styles'
import { useProductContext } from 'hooks/useProductContext'
import ActionsController from './ActionsController'
import Charts from '../Charts'
import MenuController from './MenuController'

type TProps = {
  productAnalysis: any
  projectSelected: string
  productSelected: number
  setProductSelected:React.Dispatch<any>
  fieldDrawerOpen: boolean
}

const ProductAnalysisController = ({
  productAnalysis,
  projectSelected,
  productSelected,
  setProductSelected,
  fieldDrawerOpen
}:TProps) => {
  const { fullscreen } = useProductContext()

  return (
    <Wrapper>
      <ActionsController
        projectSelected={projectSelected}
        productSelected={productSelected}
        setProductSelected={setProductSelected}
      />
      <MenuController
        productAnalysis={productAnalysis}
        projectSelected={projectSelected}
      />
      {fullscreen && (
        <Charts
          productAnalysis={productAnalysis}
          projectSelected={projectSelected}
          fieldDrawerOpen={fieldDrawerOpen}
        />
      )}
    </Wrapper>
  )
}

export default ProductAnalysisController
