import { apiBackEnd } from 'services/axios'
import { useGlobalContext } from 'hooks/useGlobalContext'
import useToast from 'hooks/useToast'

const useImagesNdvi = () => {
  const global = useGlobalContext()
  const { notify } = useToast()

  const getImagesNdvi = async(id:string, date:number, type?: string ) => {
    try {
      global.addLoading()
      let url = `v3/agromonitoring/${id}/field_image/${date}`

      if(type){
        url = `${url}?type=${type}`
      }

      const response = await apiBackEnd.get(url)

      return response.data as any

    } catch (error: any) {
      notify(error.response.data)
    }finally{
      global.removeLoading()
    }
  }

  const getImagesFarm = async(idFarm:number | undefined, date:number, type?: string ) => {
    try {
      global.addLoading()

      if(!idFarm) return []

      const url = `v3/agromonitoring/${idFarm}/farm_image/${date}?type=${type}`

      const response = await apiBackEnd.get(url)

      return response.data as any

    } catch (error: any) {
      notify(error.response.data)
    }finally{
      global.removeLoading()
    }
  }



  return {
    getImagesNdvi,
    getImagesFarm,
  }
}

export default useImagesNdvi