import styled from 'styled-components'
import { Checkbox } from 'antd'

const CheckboxGroup = Checkbox.Group

export const Container = styled.div`
  display: flex;
  width: 208px;
  height: 223px;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-end;
  padding: 10px;

  position: absolute;
  background-color: ${({ theme }) => theme.colors.THEME_THREE}; 
  border-radius: 4px;
  z-index: 1000;
  overflow: auto;

  ::-webkit-scrollbar {
    visibility: hidden;
  }
`

export const CheckBoxGroupList = styled(CheckboxGroup)`
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.THEME_TWO_BORDER};
    font-family: Montserrat;
    font-weight: 500;
    line-height: 143%;

    input + span{
    background: ${({ theme }) => `${theme.colors.THEME_THREE} !important`};
  }

  }

  label {
    padding: 4px;
  }


  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.THEME_THREE};
    border-color: ${({ theme }) => theme.colors.THEME_TWO_BORDER};
  }
`
