import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

export type TFormNewFarm = {
  name: string
  group_id?: number | null
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'name':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('name', { ns: namespaces.common }) })
      }] as Rule[]

    case 'group_id':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('group', { ns: namespaces.common }) })
      }] as Rule[]


    default:
      [] as Rule[]
  }
}
