import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 30px;
  background: ${({ theme }) => theme.colors.THEME};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0px;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.WHITE};
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;

  svg {
    color: ${({ theme }) => theme.colors.YELLOW};
  }
`

export const Actions = styled.div`
  color: ${({ theme }) => theme.colors.WHITE};
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;

  svg {
    color: ${({ theme }) => theme.colors.YELLOW};
  }
`

export const ViewPdf = styled.object`
  width: 100%;
  height: 100vh;

  p {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.WHITE};
  }
`
