import useRest from 'hooks/useRest'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useGlobalContext } from 'hooks/useGlobalContext'

import { TGetLotes } from 'types/types'

const useGroups = () => {
  const rest = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')
  const { saveGroupsSelected } = useGlobalContext()

  const getAllGroupsProject = async (project:any) => {
    const params = {
      sort: 'ancestor_depth asc',
      size: 9007199254740991
    }

    const url = `/v2/companies/${project}/groups`
    const response = await rest.get(url, params)

    return response
  }

  const createNewGroup = async (project: any, params: object) => {
    const url = `/v2/companies/${project}/groups`
    const response = await rest.post(url, params)

    if(response?.status === 200){
      notify({ message: t('item create with success', { ns: namespaces.common }), success: true })
    }
  }

  const editGroup = async (project: any, groupId: number, params: object) => {
    const url = `/v2/companies/${project}/groups/${groupId}`
    const response = await rest.put(url, params)

    if(response?.status === 200){
      notify({ message: t('item updated successfully', { ns: namespaces.errors }), success: true })
    }
  }

  const removeGroup = async (project: any, groupId: number) => {
    const url = `/v2/companies/${project}/groups/${groupId}`
    const response = await rest.remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.errors }), success: true })
      const response = await getAllGroupsProject(project)
      saveGroupsSelected(response?.data?.records)
    }
  }

  const getAllFields = async (project:any, type: string, id:number | null) => {

    if(!project) return

    const url = `/v2/companies/${project}/fields/all`
    let params = { with_shape: true }

    if(type !== 'all'){
      params = { ...params, [`${type}_id`]: id }
    }

    const response = await rest.get(url, params)

    return response
  }

  const getAllFarmsByProject = async (project:any) => {

    if(!project) return

    const url = `/v2/companies/${project}/farms`

    const response = await rest.get(url)

    return response
  }

  const transferFarmGroup = async (project: any, newProject: any, group: any) => {
    if(!project && !group) return
    const url = `/v2/companies/${project}/groups/${group?.id}/transfer`

    const response = await rest.put(url, { ...group, company_id: newProject })

    if(response?.status === 200){
      notify({ message: t('project transfer', { ns: namespaces.pages.projects }), success: true })
    }
  }

  const getFields = async ({ project, type, id = null }:TGetLotes) => {

    let url = `v3/dashboard/${project}`

    if(type === 'group'){
      url = `${url}?group_id=${id}`
    }

    if(type === 'farm'){
      url = `${url}?farm_id=${id}`
    }

    const response = await rest.get(url)

    return response
  }

  const getGroups = async (project :string) => {

    const url = `v3/dashboard/${project}/groups/`

    const response = await rest.get(url)

    return response
  }

  return {
    getAllGroupsProject,
    createNewGroup,
    editGroup,
    removeGroup,
    getAllFields,
    getAllFarmsByProject,
    transferFarmGroup,
    getFields,
    getGroups,
  }
}

export default useGroups
