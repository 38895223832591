import React, { useMemo } from 'react'

import { DatePicker } from 'antd'
import { Rule } from 'antd/lib/form'

import { Container, FormItem } from './styles'
import { Moment } from 'moment'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

type TProps = {
  onChange?:Function | any
  value?: Moment | any
  label: string
  name: string
  id: string
  rules?: Rule[]
  disabled?: boolean
}

const InputDate = ({ onChange, value, label, name, id, rules, disabled = false }:TProps) => {

  const localLanguage = localStorage.getItem('agfarmusLanguage')
  const { t } = useTranslation('namespaces')

  const formatDate = useMemo(
    () => {
      switch (localLanguage) {
        case 'en':
          return 'YYYY-MM-DD'

        default:
          return 'DD/MM/YYYY'
      }
    }, [localLanguage])

  return (<Container>
    <FormItem
      label={label}
      name={name}
      rules={rules}
      labelAlign='left'
      labelCol={{ span: 12 }}
    >
      <DatePicker
        size='large'
        style={{ width: '100%' }}
        id={id}
        onChange={onChange}
        value={value}
        disabled={disabled}
        format={formatDate}
        placeholder={t('select date', { ns: namespaces.common })}
      />
    </FormItem>
  </Container>
  )
}

export default InputDate

