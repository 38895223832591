import styled from 'styled-components'

type TDrawerProps = {
  fullscreen?: boolean
};

export const Wrapper = styled.aside<TDrawerProps>`
  display: flex;
  height: calc(100vh - 80px );
  width: ${({ fullscreen }) => fullscreen ? '100%' : 'auto'};
  background-color: ${({ theme }) => theme.colors.THEME};
  border-left: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  border-top: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  overflow: hidden !important;
`



export const DrawerBar = styled.button<{ hasCallback?: boolean}>`
  cursor: ${({ hasCallback }) => hasCallback ? 'pointer' : 'unset'};
  width: 24px;
  background-color: ${({ theme }) => theme.colors.THEME};
  border: none;
  border-right: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  
  :after{
    content: '';
    display: block;
    width: 4px;
    height: 80px;
    border-radius: 8px;
    background-color: #bcbccb;
    margin: 0 auto;
  }

`

export const DrawerContainer = styled.div<TDrawerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-top: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  background-color: ${({ theme }) => theme.colors.THEME};
  min-width: 300px;
  width: ${({ fullscreen }) => fullscreen ? '100%' : 'auto'};
`

export const DrawerTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.YELLOW};
  border-bottom: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.YELLOW};
    width: 30px;
    height: 30px;
  }
`

export const DrawerContent = styled.div<TDrawerProps>`
  width: ${({ fullscreen }) => fullscreen ? '100%' : 'auto'};
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.THEME};
    border-bottom-right-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.YELLOW};
  }
`
