import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { TColumns } from 'types/types'

import map from 'lodash/map'
import filter from 'lodash/filter'
import find from 'lodash/find'

import SelectField from 'components/SelectField'

import { Container } from './styles'
import { isEmpty } from 'lodash'

type TProps = {
  columns: TColumns[]
  filterColumns:(colunms:TColumns[] | [])=> void
}

const FieldsDisplayed = ({ columns, filterColumns }:TProps) => {
  const { t } = useTranslation('namespaces')

  const [selectedItens, setSelectedItens ] = useState<string[]>()

  const options = useMemo(() => {
    // const removeProp = filter(columns, item => item.key !== 'actions')
    return map(columns, ({ key, title }:TColumns) => ({ value: key, label: title }))
  }, [columns])

  const filterItens = useMemo<TColumns[]>(
    () => {
      if(!isEmpty(columns) && !isEmpty(selectedItens)){
        return filter(columns, (option: TColumns) => find(selectedItens, (item: string) => item === option.key)) as TColumns[]
      }
      return columns
    }, [columns, selectedItens])

  useEffect(
    () => {
      if(!isEmpty(filterItens))
        filterColumns(filterItens)
    }, [filterItens])

  return (
    <Container>
      <SelectField
        options={options}
        id='select-display-fields'
        placeholder={t('fields displayed', { ns: namespaces.pages.cropRotationst })}
        multiple
        name='selectDisplayFields'
        handleChange={setSelectedItens}
      />
    </Container>
  )
}


export default FieldsDisplayed
