import styled from 'styled-components'
import { Swiper } from 'swiper/react'
import { Image } from 'antd'

const { PreviewGroup } = Image

type TPointContainer ={
  colorBorder: string
}

type TPoint = {
  color:string
}

type TContainer = {
  toEdit:boolean
}

export const Container = styled.div<TContainer>`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: ${({ toEdit }) => toEdit ? '84vh' : 'auto'};
`

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.YELLOW};
  background: ${({ theme }) => theme.colors.THEME};
  display: flex;
  align-items:center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  padding-bottom: 12px;
  padding-top: 12px;
  padding: 10px;
  border-bottom: ${({ theme }) => `1px solid  ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  cursor: pointer;
  width: 300px;
  position: absolute;
  z-index:1000;

  div:first-child{
    display: flex;
    justify-content: flex-start;
    align-items:center;
    width: 100%;
  }

  div:last-child{
    display: flex;
    justify-content: flex-end;
  }

  svg{
      margin: 0 5px;
    }
`

export const Content = styled.div`
  margin-top: 20%;
`

export const ContentInfo = styled.div`
  padding: 8px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  color: ${({ theme }) => theme.colors.YELLOW};
  display: flex;
  flex-direction: row;

  span {
    display: flex;
    color: ${({ theme }) => theme.colors.THEME_TWO_BORDER};
    font-size: 14px;
    line-height: 143%;
  }
`

export const PointContainer = styled.div<TPointContainer>`
  margin-left: 16px;
  border: ${({ colorBorder }) => `1px solid ${colorBorder}`};
  background-color: ${({ theme }) => theme.colors.THEME};
  border-radius: 8px;
  max-width: 268px;
  width: 268px;
  height: auto;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-bottom: 16px;

`
export const PointHeader = styled.div`
  padding: 12px 16px 12px 16px;
  gap: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.YELLOW};
  border-bottom: ${({ theme }) => `1px solid  ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong{
    width: 100%;
  }
`

export const PointCircle = styled.div<TPoint>`
  width: 18px;
  height: 16px;
  background-color: ${({ theme, color }) => color ? color : theme.colors.YELLOW};
  border-radius: 50%;
  margin-right: 8px;
`

export const PointContent = styled.div``

export const CarouselImage = styled(Swiper)`
  width: 240px;
  height: 75px;
  margin: 10px 0 0 10px;
  gap: 0;

  .swiper-slide{
    width: 70px !important;
  }

`

export const Preview = styled(PreviewGroup)`
 z-index: 1000;
`

export const Img = styled(Image)`
 border-radius: 4px;
 width: 60px;
 height: 60px;
`

export const Hr = styled.hr`
 margin: 15px 15px;
 height: 1px; 
 border: ${({ theme }) => `1px solid  ${theme.colors.MEDIUM_GRAY_OPACITY}`};
`