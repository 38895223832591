import useRest from 'hooks/useRest'

const useWatcherReports = () => {
  const rest = useRest()

  const getWatcherReports = async (id:number, projectSelected:string) => {
    const url = `/v2/companies/${projectSelected}/watcher_reports?query=&field_id=${id}`

    const response = await rest.get(url)

    return response?.data
  }

  const getNewWatcherReports = async (idField:number) => {
    const url = `/v3/watcher_reports/${idField}`

    const response = await rest.get(url)

    return response?.data
  }

  const getWatcherReportsAttachments = async ( projectSelected:string, id: number | undefined) => {

    const response = await rest.get(`/v2/companies/${projectSelected}/watcher_reports/${id}`)

    return response?.data
  }

  return { getWatcherReports, getWatcherReportsAttachments, getNewWatcherReports }
}

export default useWatcherReports