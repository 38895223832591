import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import { apiBackEnd, apiGeo, storageUserCredentials, storageUserData } from 'services/axios'
import { TUserData } from 'types/types'
import { useTranslation } from 'react-i18next'

type TUserCredentials = {
  token: string
  client: string
  expiry: string
  uid: string
}

type TUseAuth = {
  isSignedIn: boolean
  userData?: TUserData
  userCredentials?: TUserCredentials
  hasToken(): boolean
  saveUserData(user: TUserData): void
  saveUserCredentials(credentials: TUserCredentials): void
  removeUserData(): void
}

const AuthContext = createContext<TUseAuth>({} as TUseAuth)

type TProps = {
  children: React.ReactNode
}

const AuthProvider = ({ children }: TProps) => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [userData, setUserData] = useState<TUserData>()
  const [userCredentials, setUserCredentials] = useState<TUserCredentials>()

  const { i18n } = useTranslation()

  useEffect(() => {
    // User credentials
    const localUserCredentials = localStorage.getItem(storageUserCredentials)
    if (!localUserCredentials) return
    const parsedUserCredentials = JSON.parse(localUserCredentials)
    setUserCredentials(parsedUserCredentials)

    // User data
    const localUserData = localStorage.getItem(storageUserData)
    if (!localUserData) return
    const parsedUserData = JSON.parse(localUserData)
    setUserData(parsedUserData)

    setIsSignedIn(true)

    return () => {
      setUserCredentials(undefined)
      setIsSignedIn(false)
      setUserData(undefined)
    }
  }, [])

  const saveUserData = useCallback((user: TUserData) => {
    localStorage.setItem(storageUserData, JSON.stringify(user))
    if(user.locale) {
      localStorage.setItem('agfarmusLanguage', user.locale)
      i18n.changeLanguage(user.locale)
    }

    setUserData(user)
  }, [])

  const saveUserCredentials = (credentials: TUserCredentials) => {
    localStorage.setItem(storageUserCredentials, JSON.stringify(credentials))

    setIsSignedIn(true)
    setUserCredentials(credentials)
    setToken(credentials)
  }


  const removeUserData = () => {
    localStorage.removeItem(storageUserCredentials)
    localStorage.removeItem(storageUserData)
    setUserData(undefined)
    setIsSignedIn(false)
  }

  const setToken = (credentials: TUserCredentials) => {
    if(credentials) {
      apiBackEnd.defaults.headers = credentials
      apiGeo.defaults.headers = credentials
    }
  }

  const hasToken = () => Boolean(localStorage.getItem(storageUserCredentials))

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        userData,
        userCredentials,
        hasToken,
        saveUserData,
        saveUserCredentials,
        removeUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): TUseAuth => {
  const context = useContext(AuthContext)

  return context
}

export { useAuth, AuthProvider }
