
import useRest from 'hooks/useRest'

const useFields = () => {

  const { get } = useRest()

  const getById = async (id:number, project:string) => {

    const url = `v2/companies/${project}/fields/${id}`
    const response = await get(url)

    return response
  }

  return {
    getById,
  }
}

export default useFields
