import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

export type TForm = {
  delete: string
}

export const initialValues: TForm = {
  delete: '',
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {

    case 'delete':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('delete', { ns: namespaces.common }) })
      },
      () => ({
        validator(_, value){
          if(!value || value.toUpperCase() === t('delete', { ns: namespaces.common }).toUpperCase()){
            return Promise.resolve()
          }
          return Promise.reject(t('field do not match', { ns: namespaces.modals.createRotationst, label: t('delete', { ns: namespaces.common }) }))
        }
      })] as Rule[]

    default:
      [] as Rule[]
  }
}
