import React from 'react'

import filter from 'lodash/filter'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
      type: Key;
    }
    : {
      type: Key;
      payload: M[Key];
    }
};

export enum Types {
  Create = 'CREATE_DIALOG',
  Close = 'CLOSE_DIALOG',
  Remove = 'REMOVE_DIALOG',
  Reset = 'RESET_DIALOGS'
}

export type TDialogs = {
  id:string;
  open:boolean;
  Component: React.FunctionComponent | JSX.Element | undefined
  props:object
}

type TDialogsPayload = {
  [Types.Create] : {
    id:string;
    open:boolean;
    Component: React.FunctionComponent | JSX.Element | undefined
    props:object;
  };
  [Types.Remove]: {
    id: string;
  };
  [Types.Close]: {
    id: string;
  };
  [Types.Reset]: {}
}

export type DialogActions = ActionMap<TDialogsPayload>[keyof ActionMap<TDialogsPayload>];

export const dialogReducer = (dialogs: TDialogs[], action: DialogActions) => {

  switch (action.type) {
    case Types.Create:
      return uniqBy([
        ...dialogs,
        {
          ...action.payload,
          open: true
        }
      ], 'id')

    case Types.Close:
      return map(dialogs, dialog => {
        if (dialog.id === action.payload.id) {
          return { ...dialog, open: false }
        }
        return dialog
      })

    case Types.Remove:
      return filter(dialogs, dialog => dialog.id !== action.payload.id)

    case Types.Reset:
      return []

    default:
      return dialogs
  }
}
