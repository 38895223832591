import React, { Fragment, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import map from 'lodash/map'

import { useAuth } from 'hooks/useAuth'

import { Container, MenuItem, Item, ItemGroup } from './styles'

import {
  Users as UsersIcon,
  Projects as ProjectsIcon,
} from 'components/Icons'

type TItems = {
  key: string
  path: string
  icon: JSX.Element
  title: string
}

enum Uri {
  PROJECTS = '/',
  USERS = '/users',
}

type TProps = {
  doCollapsedSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  isCollapsedSidebar: boolean;
};

const AdminSidebar = ({ doCollapsedSidebar, isCollapsedSidebar = false }: TProps) => {

  const { userData } = useAuth()
  const history = useHistory()
  const location:any = useLocation()
  const params:any = useParams()
  const { t } = useTranslation('namespaces')

  const onClickGoToPage = (path: string) => () => path && history.push(path)

  const haveProjectSelected = useMemo(() => Boolean(params?.project), [params])

  if(!userData?.role){
    return null
  }

  const items:TItems[] = [
    {
      key: '1',
      path: Uri.PROJECTS,
      title: t('companies', { ns: namespaces.pages.projects }),
      icon: <ProjectsIcon />
    },
    {
      key: '2',
      path: Uri.USERS,
      title: t('users', { ns: namespaces.common }),
      icon: <UsersIcon />
    },
  ]

  return (
    <Container
      onMouseEnter={() => doCollapsedSidebar(false)}
      onMouseLeave={() => doCollapsedSidebar(true)}
      iscollapsedsidebar={isCollapsedSidebar}
    >
      <MenuItem
        selectedKeys={location.pathname}
        mode='inline'
        inlineCollapsed={isCollapsedSidebar}
      >
        <ItemGroup key='g1' iscollapsedsidebar={isCollapsedSidebar}>
          {map(items, ({ path, icon, title }, index) => {

            return (
              <Fragment key={index}>
                {userData?.role === 'admin' && !haveProjectSelected && (
                  <Item
                    key={path}
                    icon={icon}
                    onClick={onClickGoToPage(path)}
                  >
                    {title}
                  </Item>
                )}
              </Fragment>
            )
          })}
        </ItemGroup>
      </MenuItem>
    </Container>
  )
}

export default AdminSidebar
