import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { Row, Col } from 'antd'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { LeftOutlined, RightOutlined, RiseOutlined, CloudFilled } from '@ant-design/icons'
import { MdSatellite, MdFileDownload } from 'react-icons/md'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'

import { formatDateToViewTranslated, formatDateToView, formatDateToUnix, dateRange } from 'utils/helpers'
import { TInfoTimeLine, TDataTimeLine, TDateComponent, TFields, TReports } from 'types/types'

import InputDateCustom from 'components/InputDateCustom'

import { Container, Content, Arrow, TextDate, Icons, Message, DownloadShapeButton, SliderWrapper } from './styles'
import { TScout } from 'types/report'
import moment from 'moment'

type TSliderTo = {
  [x: string]: any
  TSliderTo:(index: number) => void
}

type TProps = {
  setDate:(date:TDateComponent) => void
  dateSelected?: TDateComponent,
  isReport?: Boolean,
  infoTimeLine?: TInfoTimeLine
  infoTimeLineReports?: any
  dowloadFile?: boolean
  handleShapeFile?:(date:number) => void
  fieldDrawerOpen?:boolean
  setLimit?: any
  fieldSelected?: TFields
  datesCalendar?: any,
  monitoringField?: boolean,
  idFarm?: number | null | undefined
  dateSelectedReport?:string | undefined
  idReport?:number
  saveReportSelected?:React.Dispatch<React.SetStateAction<TReports & TScout | undefined>>
  updateCarousel:(_: any, dt?:number)=>void
  onUpdateCalendar:(to: number, from:number)=>void
}

const TimeLineField = ({
  setDate,
  dateSelected,
  isReport = false,
  infoTimeLine = {} as TInfoTimeLine,
  datesCalendar,
  infoTimeLineReports,
  handleShapeFile = () => {},
  dowloadFile = false,
  fieldSelected,
  monitoringField = false,
  idFarm,
  dateSelectedReport,
  idReport,
  saveReportSelected = () => {},
  updateCarousel = () => {},
  onUpdateCalendar = () => {}
}:TProps) => {

  const { t } = useTranslation('namespaces')
  SwiperCore.use([Navigation])

  const sliderWrapper: any = useRef(null)

  const { farmSelected, idFarmSelected } = useGlobalContext()
  const [slidesPerView, setSlidesPerView] = useState<number>(6)
  const [swiperRef, setSwiperRef] = useState<TSliderTo>()
  const [ defaultPicker, setDefaultPicker] = useState<any>()
  const [endSlide, setEndSlide] = useState<boolean>(false)

  const indexDateSelected = useMemo(
    () => {
      const index = findIndex(infoTimeLine?.data, (item:any) => item.dt === dateSelected?.unixFormate)

      if(index === -1) return 1

      return index

    }, [dateSelected, infoTimeLine])

  const disabledPrevButton = useMemo(
    () => {
      if(infoTimeLine.prev_page === 0) return true

      return false

    }, [infoTimeLine])

  const prevPage = useMemo(
    () => {
      return infoTimeLine.prev_page || 0
    }, [infoTimeLine])

  const formatInfoReportsDates = useMemo(() => {
    return map(infoTimeLineReports, (item: any) => {return { ...item, date: formatDateToUnix(item.created_at), icon: item.icon }})
  }, [infoTimeLineReports])

  const checkDate = (dt:number, type:string) => {
    if(!dateSelected) return false

    if(isReport) return dt === dateSelected.unixFormate && (type === 'Sentinel-2' || type === 'Landsat 8')

    return dt === dateSelected.unixFormate && type === dateSelected.satellite
  }

  const checkDateReport = (dt:string) => {
    if(!dateSelectedReport) return false
    return formatDateToView(dt) === formatDateToView(dateSelectedReport)
  }

  const handleSetDate = (date:any, type:string) => {
    const dateFormate = formatDateToView(date, true)
    const dateArray = new Date(date * 1000)
    const range = dateRange(dateArray)

    const newDate = {
      unixFormate: date,
      date: dateFormate,
      day: dateFormate.slice(0, 2),
      timeInterval: {
        to: range.to,
        from: range.from
      },
      satellite: type
    } as unknown as TDateComponent

    setDate(newDate)
    onUpdateCalendar(range.to, range.from)
    setDefaultPicker({ dt: moment.unix(date), type })
  }

  const hiddenCarrousselReports = (item: any) => {
    saveReportSelected(item)
  }

  const handleDownloadFile = (date:number, event: any) => {
    if(!isEmpty(fieldSelected)) {
      handleShapeFile(date)
    }
    event?.stopPropagation()
  }

  const paginationNext = () => {
    if( endSlide) {
      updateCarousel(infoTimeLine.next_page)
      swiperRef?.slideTo(1)
    }
  }

  const paginationPrev = () => {
    if( swiperRef?.activeIndex === 0 && prevPage >= 1) {
      updateCarousel(infoTimeLine.prev_page)
      swiperRef.slideTo(14)
    }
  }

  useEffect(() => {
    if(dateSelected?.unixFormate){
      setDefaultPicker({ dt: moment.unix(dateSelected?.unixFormate), type: dateSelected?.satellite })
    }
  }, [])

  useEffect(() => {
    if(!isReport && !monitoringField && idFarm !== idFarmSelected) {
      setDate({} as TDateComponent)
    }
  }, [farmSelected, idFarm, idFarmSelected])

  useLayoutEffect(() => {
    const updateSize = () => {
      const newWidth = Number((sliderWrapper?.current?.offsetWidth / 170).toFixed())
      if (typeof newWidth === 'number' && newWidth > 0) {
        setSlidesPerView(newWidth)
      } else {
        const defaultWidth = Number(((window.innerWidth - 550) / 170).toFixed())
        setSlidesPerView(defaultWidth)
      }
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [sliderWrapper, sliderWrapper?.current?.offsetWidth])

  useEffect(() => {
    if(indexDateSelected >= 1 && swiperRef){
      swiperRef.slideTo(indexDateSelected)
      setDefaultPicker({ dt: dateSelected?.unixFormate && moment.unix(dateSelected?.unixFormate) || '', type: 'Sentinel-2' })
    }
  }, [indexDateSelected, swiperRef])

  return (
    <Container>
      <Row justify='space-between'>
        {!isReport ? (
          <>
            <Col span={1}>
              <InputDateCustom
                onChangeDate={setDate}
                onUpdateCalendar={onUpdateCalendar}
                updateCarousel={updateCarousel}
                dates={datesCalendar}
                defaultPicker={defaultPicker}
                setDefaultPicker={setDefaultPicker}
              />
            </Col>
            <Col span={23}>
              {!isEmpty(infoTimeLine?.data) ? (
                <SliderWrapper ref={sliderWrapper}>
                  <Arrow
                    className='slider-next'
                    onClick={() => paginationNext()}
                  >
                    <LeftOutlined />
                  </Arrow>
                  <Swiper
                    onSwiper={(swiper:any) => setSwiperRef(swiper)}
                    slidesPerView={slidesPerView}
                    initialSlide={indexDateSelected}
                    dir='rtl'
                    navigation={{
                      nextEl: '.slider-next',
                      prevEl: '.slider-prev',
                    }}
                    className='mySwiper'
                    onActiveIndexChange={e => setEndSlide(e.isEnd)}
                  >
                    <SwiperSlide />
                    {map(infoTimeLine?.data, (item:TDataTimeLine, index: number) => (
                      <SwiperSlide key={index}>
                        <Content onClick={() => handleSetDate(item?.dt, item?.type)} isActive={checkDate(item?.dt, item?.type)}>
                          <TextDate dangerouslySetInnerHTML={{ __html: formatDateToViewTranslated(item?.dt, t) }} />
                          <Icons textaAlign='space-around'>
                            <CloudFilled /> {item?.cl}%
                            <MdSatellite /> {item?.dc}%
                          </Icons>
                          <Icons textaAlign={dowloadFile ? 'space-around' : 'center'}>
                            <RiseOutlined /> {item?.type}
                            {dowloadFile && (
                              <DownloadShapeButton
                                onClick={(event: any) => handleDownloadFile(item?.dt, event)}
                                icon={<MdFileDownload />}
                                size='small'
                              />
                            )}
                          </Icons>
                        </Content>
                      </SwiperSlide>
                    ))}
                    <SwiperSlide />
                  </Swiper>
                  <Arrow
                    className='slider-prev'
                    onClick={() => paginationPrev()}
                    disabled={disabledPrevButton}
                  >
                    <RightOutlined />
                  </Arrow>
                </SliderWrapper>
              ) : (
                <Message>{t('no records found', { ns: namespaces.errors })}</Message>
              )}
            </Col>
          </>
        ) : (
          <>
            <Col span={23}>
              {!isEmpty(infoTimeLineReports) ? (
                <SliderWrapper ref={sliderWrapper}>
                  <Arrow
                    className='slider-next-info-timeline-reports'
                    // onClick={() => paginationNext()}
                  >
                    <LeftOutlined />
                  </Arrow>
                  <Swiper
                    slidesPerView={formatInfoReportsDates.length >= 6 ? slidesPerView : formatInfoReportsDates.length}
                    dir='rtl'
                    navigation={{
                      nextEl: '.slider-next-info-timeline-reports',
                      prevEl: '.slider-prev-info-timeline-reports'
                    }}
                    className='swiper-info-timeline-reports'
                  >
                    {map(formatInfoReportsDates, (item, index) => (
                      <SwiperSlide key={index}>
                        <Content isReportContent key={index} onClick={() => hiddenCarrousselReports(item)} isActive={item.id === idReport && checkDateReport(item?.created_at)}>
                          {item.icon}
                          <TextDate isReport dangerouslySetInnerHTML={{ __html: formatDateToViewTranslated(item?.date, t) }} />
                        </Content>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <Arrow
                    className='slider-prev-info-timeline-reports'
                  >
                    <RightOutlined />
                  </Arrow>
                </SliderWrapper>
              ) : (
                <Message>{t('waiting for farm selection', { ns: namespaces.pages.monitoring })}</Message>
              )}
            </Col>
          </>
        )}
      </Row>
    </Container>
  )}

export default TimeLineField
