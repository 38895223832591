import React from 'react'
import { useHistory } from 'react-router-dom'
import { BsArrowLeftShort } from 'react-icons/bs'
import { Wrapper, DrawerBar, DrawerContainer, DrawerTitle, DrawerContent } from './styles'

type TProps = {
  title?: any;
  fullscreen?: boolean
  callback?: Function
  drawerBarCallback?: Function
  children: React.ReactChild
}

const Drawer = ({ children, title, fullscreen = false, callback, drawerBarCallback }:TProps) => {
  const history = useHistory()

  const handleClick = () => {
    callback ? callback() : history.goBack()
  }

  return (
    <Wrapper fullscreen={fullscreen}>
      {drawerBarCallback && <DrawerBar hasCallback={Boolean(drawerBarCallback)} onClick={() => drawerBarCallback && drawerBarCallback()} /> }
      <DrawerContainer fullscreen={fullscreen}>
        {title && (
          <DrawerTitle>
            <BsArrowLeftShort onClick={handleClick} />
            {title}
          </DrawerTitle>
        )}
        <DrawerContent fullscreen={fullscreen}>
          {children}
        </DrawerContent>
      </DrawerContainer>
    </Wrapper>
  )
}

export default Drawer