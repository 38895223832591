import { namespaces } from 'i18n/i18n.constants'

export const pt = {
  [namespaces.layout]: {
    dashboard: 'Dashboard',
    monitoring: 'Monitoramento',
    'change image': 'Alterar imagem',
    'reset image': 'Remover imagem',
    'visualization': 'Visualização',
    'current file': 'Arquivo atual',
    'cover image': 'Imagem de capa',
    'right image': 'Imagem da direita (recomendações)',
    'left image': 'Imagem da esquerda (recomendações)',
    'alert format': '* imagens aceitas nos formatos de .JPEG e .PNG',
    'view settings reports': 'Visualize todas as configurações de relatórios',
    gis: 'Gis',
    'field report': 'Relatórios de terrenos',
    'settings field report': 'Configurações de relatórios',
    'agro intelligence': 'Agro inteligência',
    'operational management': 'Gestão operacional',
    'my informations': 'Minhas Informações',
    'my profile': 'Meu Perfil',
    exit: 'Sair',
    climate: 'Clima',
    forecast: 'Previsão',
    'weather history': 'Histórico climático',
    pluvius: 'PluviUs',
    'agro-data': 'Agrodata',
    'land-report': 'Relatório de campo',
    'analytical-products': 'Produtos analíticos',
    'admin': 'Admin',
    field: 'Talhão',
    'settings': 'Configurações de relatórios',
    'project': 'Projeto',
    products: 'Produto',
    type: 'Tipo',
    'members': 'Membros',
    'farms': 'Grupo de fazendas',
    crops: 'Safras',
    'platform management': 'Gestão da plataforma',
    'users': 'Usuários',
    'satellite-monitoring': 'Monitoramento Satelital',
    reports: 'Laudos',
    'crop-rotations': 'Rotações de cultura',
    'field-report': 'Relatórios de campo',
    'PrivacyPolicy': 'Política de Privacidade'
  },
  [namespaces.common]: {
    'new register': 'Cadastre as informações de um novo registro',
    'transfer group': 'Transfira o Grupo selecionado para outro projeto',
    'new group register': 'Cadastre as informações de um novo grupo de fazendas',
    'file': 'Arquivo',
    'placeholder name': 'Digite um nome',
    search: 'Pesquise o que você procura',
    login: 'Entrar',
    password: 'Senha',
    cancel: 'Cancelar',
    name: 'Nome',
    address: 'Endereço',
    neighborhoods: 'Bairro',
    city: 'Cidade',
    state: 'Estado',
    area: 'Área',
    'total area': 'Área total',
    'total fields': 'Total de talhões',
    'quick summary': 'Resumo rápido',
    status: 'Status',
    email: 'E-mail',
    save: 'Salvar',
    'clean': 'Limpar',
    'please wait': 'Por favor, aguarde...',
    loading: 'Carregando ...',
    'wait': 'Espere',
    'loading data farm': 'Estamos carregando os dados da fazenda...',
    'loading data field': 'Estamos carregando os dados do terreno...',
    'delete': 'Excluir',
    'password is required': 'Senha é obrigatório',
    'user is required': 'Usuário é obrigatório',
    'full name is required': 'Nome completo é obrigatório',
    'phone is required': 'Telefone é obrigatório',
    'it is not a valid email': 'Não é um email válido',
    actions: 'Ações',
    'name file': 'Nome do arquivo',
    'added to': 'Adicionado em',
    type: 'Tipo',
    edit: 'Editar',
    size: 'Tamanho',
    'are you sure you want to delete the document?': 'Tem certeza que deseja excluir o documento?',
    'to view': 'Visualizar',
    close: 'Fechar',
    filter: 'Filtrar',
    observation: 'Observação',
    'start date': 'Data Início',
    'end date': 'Data Final',
    english: 'Inglês',
    spanish: 'Espanhol',
    portuguese: 'Português',
    'all farms': 'Todas as Fazendas',
    send: 'Enviar',
    'back-to-login': 'Voltar ao login',
    'import a file': 'Importar um arquivo',
    'import file': 'Importar arquivo',
    'coordinates': 'Coordenadas',
    'latitude': 'Latitude',
    'longitude': 'Longitude',
    'group': 'Grupo',
    'farm': 'Fazenda',
    'approximate area': 'Área aproximada',
    'imported file': 'Arquivo importado',
    'processed area': 'Área processada',
    field: 'Campo',
    'not informed': 'Não informado',
    year: 'Ano',
    'select year': 'Selecione o ano',
    sample: 'Amostra',
    'below': 'Abaixo',
    'ideal': 'Ideal',
    'above': 'Acima',
    new: 'Novo',
    all: 'Todos',
    title: 'Título',
    suggestion: 'Sugestão',
    content: 'Conteúdo',
    'new something': 'Novo {{label}}',
    'new something a': 'Nova {{label}}',
    'remove something': 'Remover {{label}}',
    'attachment': 'Anexo',
    'archive': 'Arquivo',
    'starts at': 'Inicia em',
    'ends at': 'Termina em',
    'created in': 'Criado em',
    culture: 'Cultura',
    'culture two': 'Cultivo',
    crop: 'Safra',
    crops: 'Safras',
    'are you sure you want to delete': 'Você tem certeza que deseja deletar {{label}}?',
    'to complete type and confirm': 'Para concluir, digite {{label}} e confirme',
    'field is required': 'Campo {{label}} é obrigatório',
    'select date': 'Selecionar data',
    'no items found': 'Nenhum {{label}} encontrado',
    'created at': 'Criado em',
    profile: 'Perfil',
    'yes': 'Sim',
    'no': 'Não',
    'user type': 'Tipo de usuário',
    'field of farm': 'Talhão',
    'users': 'Usuários',
    'import a .PNG or .JPG image': 'Importe uma imagem .PNG ou .JPG',
    'import': 'Importar {{type}}',
    image: 'Imagem',
    'hd image': 'Imagem HD',
    'visual image': 'Imagem visual',
    'active': 'ativo',
    'inactive': 'inativo',
    'vegetation': 'Vegetação',
    'point': 'Ponto {{number}}',
    'general data': 'Dados Gerais',
    'select one': 'Escolha uma {{anything}}',
    'add photo': 'Adicionar fotos',
    'good': 'Bom',
    'bad': 'Ruim',
    'regular': 'Regular',
    'excellent': 'Excelente',
  },
  [namespaces.pages.login]: {
    welcome: 'Bem Vindo',
    title: 'Faça o login na YVY',
    loginBtn: 'Entrar',
    forgotBtn: 'Esqueceu sua senha?',
    'remember account': 'Lembrar conta',
    'forgot password': 'Esqueceu a senha?'
  },
  [namespaces.pages.forgotPassword]: {
    title: 'Recuperar senha',
    button: 'Enviar',
    link: 'Voltar para o login',
  },
  [namespaces.pages.resetPassword]: {
    title: 'Redefinir senha',
    'password-confirmation': 'Confirmação da senha',
    'password confirmation is required': 'A confirmação da senha é obrigatória',
    'passwords do not match': 'As senhas não coincidem'
  },
  [namespaces.pages.dashboard]: {
    welcome: 'Bem Vindo',
  },
  [namespaces.pages.field]: {
    'back reports': 'Voltar para relatórios',
    'general data': 'Dados gerais',
    'observations': 'Observações',
    'information': 'Informação',
    'variety': 'Variedade',
    'vegetative_index': 'Indice vegetativo',
    'vegetation indices': 'Índices de vegetação',
    'weather condition': 'Condição climática',
    'nutritional symptoms': 'Sintomas nutricionais',
    'threats': 'Ameaças',
    'quantity': 'Quantidade',
    'spacing': 'Espaçamento',
    'seeds': 'Sementes',
    'germination': 'Germinação',
    'population': 'População',
    'pods/plants': 'Vagens/Plantas',
    'grains/pods': 'Grãos/Vagens',
    'grains weight': 'Peso mil grãos',
    'yield': 'Rendimento estimado',
    'deepness': 'Profundidade',
    'samples number': 'Número de subamostras',
    'equipment': 'Equipamento',
    'high': 'Alto',
    'middle': 'Médio',
    'low': 'Baixo',
    'pictures': 'Fotos',
    'responsible': 'Responsável',
    'date': 'Data',
    'growth stage': 'Estágio de crescimento',
    'farm': 'Fazenda',
    'field': 'Terreno',
    'crop': 'Safra',
    'median ndvi': 'Média NDVI',
    'siganematoide': 'Siganematoide',
    'nutricheck': 'Nutriagro',
    'solocheck': 'Solo',
    'scout': 'Scout',
    'health': 'Doenças',
    'weed': 'Daninhas',
    'bug': 'Inseto',
    'yield_component': 'Rendimento',
    'field dashboard': 'Dashboard do lote',
    'field monitoring': 'Monitoramento do lote',
    'geo analysis': 'Geo análise',
    'products': 'Produtos',
    'new product': 'Novo produto',
    'type product': 'Tipo do produto',
    'field report': 'Relatórios de campo',
    'create field': 'Criar talhão',
    'create a new field': 'Criar um novo talhão',
    'drag and drop description': 'Arraste e solte no campo abaixo ou clique e selecione um arquivo que contenha o contorno do talhão',
    'supported files are in the format': 'Os arquivos suportados são no formato',
    'draw the field on the map': 'Desenhar o talhão no mapa',
    'draw the field on the map using the drawing tool': 'Desenha o talhão no mapa através da ferramenta de desenho',
    'draw field': 'Desenhar talhão',
    'new field': 'Novo talhão',
    'center map': 'Centralizar mapa',
    'general field data': 'Dados gerais do talhão',
    'field name': 'Nome do talhão',
    'enter the field name': 'Digite o nome do talhão',
    'select a group': 'Selecione um grupo',
    'select a project': 'Selecione um projeto',
    'title new product': 'Cadastre as informações de um novo produto analítico',
    'group farms': 'Grupo de fazendas',
    'select a farm': 'Selecione uma fazenda',
    'edit field': 'Editar talhão',
    'field successfully updated': 'Terreno atualizado com sucesso',
    'field successfully created': 'Terreno criado com sucesso',
    'field error area or name field': 'O terreno não pode ficar sem nome, ou ter uma área superior a 3000 ha',
    'create new group': 'Criar novo grupo',
    'create new farm': 'Criar nova fazenda',
    'group successfully created': 'Grupo criado com sucesso',
    'farm successfully created': 'Fazenda criada com sucesso',
    'enter a name for the farm': 'Insira um nome para a fazenda',
    'enter a name for the group': 'Insira um nome para o grupo',
    'central tendency': 'Tendência Central',
    'dispersion measures': 'Medidas de Dispersão',
    'sand': 'Areia',
    'clay': 'Argila',
    'silt': 'Silte',
    'classification of data in relation to the ideal': 'Classificação dos dados em relação ao ideal',
    'average textural classification': 'Classificação média textural',
    'percentage of textural classification': 'Percentual de classificação da textura',
    'report download': 'Download do relatório',
    'spreadsheet download': 'Download da planilha',
    'analytical summary': 'Resumo analítico',
    'analytical report': 'Relatório analítico',
    'fertianalysis': 'Fertianálise',
    'general analysis': 'Análise geral',
    'analysis by environment': 'Análise por ambiente',
    'report': 'Laudo',
    'environment map': 'Mapa de ambiente',
    'recommendation': 'Recomendação',
    'product': 'Produto',
    'edit recommendation': 'Editar recomendação',
    'delete field': 'Deletar talhão',
    'satellite monitoring': 'Monitoramento Satelital',
    'status satellite monitoring': 'Monitoramento de satélite {{status}}',
    'cultivation condition': 'Condição do cultivo'
  },
  [namespaces.pages.monitoring]: {
    'select fields': 'Selecione talhões',
    'waiting for farm selection': 'Aguardando seleção de fazenda'
  },
  [namespaces.pages.crop]: {
    'new crop': 'Nova Safra',
    'view all crops': 'Visualize todas as safras',
    'search for a crop': 'Buscar uma safra',
  },
  [namespaces.pages.member]: {
    'member': 'Membro',
    'members': 'Membros',
    'view all members': 'Visualize todos os membros',
    'search for a member': 'Buscar por um membro',
    'resend confirmation': 'Reenviar confirmação',
    'email successfully sent': 'E-mail enviado com sucesso',
    'edit member': 'Editar membro',
    'update a members permissions': 'Atualize as permissões de um membro',
    'edit permissions': 'Editar permissões',
    'update permissions': 'Atualizar permissões',
    'type of permissions': 'Tipo de permissões',
    'farm': 'Fazenda',
    'no access': 'Sem acesso',
    'view': 'Visualizar',
    'edit': 'Editar',
    'manage': 'Gerenciar'
  },
  [namespaces.pages.farmGroups]: {
    'register the information of a new farm': 'Cadastre as informações de uma nova fazenda',
    'edit farm information': 'Edite as informações da fazenda',
    'edit farm group information': 'Edite as informações do grupo de fazendas',
    farmGroups: 'Grupos de fazendas',
    'view all farm groups': 'Visualize todos os grupos de fazenda',
    'find a farm': 'Buscar uma fazenda',
    group: 'grupo',
    farm: 'fazenda',
    at: ' às '
  },
  [namespaces.pages.cropRotationst]: {
    'crop rotationst': 'Rotações de cultura',
    'new rotation': 'Nova Rotação',
    'view all crop rotations': 'Visualize todas as rotações de cultura',
    'search for a rotation': 'Buscar uma rotação',
    'fields displayed': 'Campos exibidos',
    'harvest / year': 'Safra / Ano',
    'variety': 'Variedade',
    'planting date': 'Data de plantio',
    'harvest date': 'Data de colheita',
    'productivity': 'Produtividade',
  },
  [namespaces.pages.users]: {
    'view all users': 'Visualize todos os usuários',
    'search for a user': 'Buscar um usuário',
    'user': 'Usuário',
    'edit user': 'Editar usuário',
    'confirmed': 'Confirmado',
    'register a new user': 'Registrar novo usuário'
  },
  [namespaces.pages.reports]: {
    reports: 'Laudos',
    report: 'laudo',
    'view all reports': 'Visualize todos os laudos',
    'search for a reports': 'Buscar um laudo',
    'new reports': 'Novo laudo',
    'type of report': 'Tipo do laudo',
  },
  [namespaces.modals.createRotationst]: {
    'register the information of a new crop rotation': 'Registre as informações de uma nova rotação de cultura',
    'select one': 'Escolha uma {{anything}}',
    'field do not match': 'Campo {{label}} não coincide',
    'are you sure you want to delete the growth rotation?': 'Tem certeza de que deseja excluir a rotação de cultura?',
  },
  [namespaces.modals.createCrop]: {
    'register the information of a new crop': 'Cadastre as informações de uma nova safra',
    'change the information of a crop': 'Altere as informações de uma safra',
    'select one': 'Selecione um {{anything}}',
    'field do not match': 'Campo {{label}} não coincide',
    'are you sure you want to delete the crop?': 'Tem certeza de que deseja excluir a safra?',
  },
  [namespaces.modals.memberForm]: {
    'register a new members information': 'Cadastre as informações de um novo membro',
    'change a members information': 'Altere as informações de um membro',
    'access type': 'Tipo de acesso',
    'access according to assigned permissions': 'Acesso de acordo com as permissões atribuídas',
    'full access to all farms': 'Acesso total a todas as fazendas',
    'are you sure you want to delete this member?': 'Tem certeza que deseja excluir esse membro?',
  },
  [namespaces.modals.editUser]: {
    'edit user': 'Editar usuário',
    'full name': 'Nome completo',
    'preferred language': 'Idioma de preferência',
    'change photo': 'Alterar foto',
    'profile updated successfully': 'Perfil atualizado com sucesso',
    'enter a new password': 'Insira uma nova senha',
    'click to edit password': 'Clique para editar a senha',
    'password changed successfully': 'Senha alterada com sucesso'

  },
  [namespaces.modals.newEnvironmentMap]: {
    'new environment map': 'Novo mapa de ambiente',
  },
  [namespaces.errors]: {
    '400': 'Serviço indisponível.',
    '401': 'Credenciais de login inválidas. Por favor, tente novamente.',
    '500': 'Erro interno do servidor.',
    'default': 'Um erro inesperado aconteceu. Tente novamente mais tarde.',
    'no records found': 'Não foram encontrados registros',
    'item updated successfully': 'Item atualizado com sucesso',
    'item removed with success': 'Item removido com sucesso',
    'item create with success': 'Item criado com sucesso',
    'images not yet available': 'Imagens ainda não disponíveis',
    'image not available. please try another date': 'Imagem não disponível. Por favor tente outra data',
    'some lots do not have images available. please have another date.': 'Alguns lotes não possuem imagens disponíveis. Por favor tente outra data.',
    'error KML': 'Não foi possível realizar o download do KML, polígono do lote possui intersecção de coordenadas'
  },
  [namespaces.pages.analyticalProducts]: {
    'new analytical products': 'Novo produto',
    'view all analytical analytical products': 'Visualize todas os produtos analíticos',
    'search for a products': 'Buscar um produto',
    'type product': 'Tipo do produto',
    'filter by farm': 'Filtrar por fazenda',
    'statistical parameters': 'Parâmetros Estatísticos'
  },
  [namespaces.pages.projects]: {
    'view all projects': 'Visualize todos os projetos',
    'search for a project': 'Buscar um projeto',
    'new project': 'Novo projeto',
    'edit project': 'Editar projeto',
    'project': 'Projeto',
    'creation date': 'Data de criação',
    'admin users': 'Usuários admin',
    'name': 'Nome',
    'project transfer': 'Transferido com sucesso',
    'no projects found': 'Nenhum projeto encontrado',
    'companies': 'Empresas'
  },
  [namespaces.pages.projectController]: {
    'file too heavy, maximum size 1mb': 'Arquivo muito pesado, tamanho máximo de 1mb',
    'view all company information': 'Visualize todas as informações da empresa',
    'general information': 'Informações gerais',
    'enter the project name': 'Insira o nome do projeto',
    'enter the administrative email': 'Insira o e-mail administrativo',
    'cnpj ruc': 'CNPJ/RUC',
    'cnpj or ruc': 'CNPJ ou RUC',
    'enter the CNPJ/RUC': 'Insira o CNPJ/RUC',
    'platform preferences': 'Preferências da plataforma',
    'insert logo': 'Inserir logo',
    'company profile': 'Perfil da empresa',
    'cnpj ruc is already being used by another project': 'CNPJ/RUC já está sendo usado por outro projeto',
  },
  [namespaces.pages.scout]: {
    'phenological stage': 'Estádio fenológico',
    'stand': 'Stand (plantas/m)',
    'this action cannot be undone!': 'Está ação não pode ser desfeita!',
    'discard changes': 'Descartar alterações',
    'cancel change?': 'Cancelar alteração?',
    'delete report?': 'Excluir relatório?',
    'delete point?': 'Excluir ponto?'
  },
}