import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { EyeFilled } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'

import map from 'lodash/map'

import useCrops from 'repositories/useCrops'

import { DialogContext } from 'dialog/context'
import { TFormCrop } from 'types/crops'
import { formatDateToView, removeTimeFromDateTime } from 'utils/helpers'

import DataTable from 'components/DataTable'

import ModalCreateCrop from './ModalCreateCrop'

import { Container } from './styles'
import { useParams } from 'react-router-dom'

const Crop = () => {
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const cropsApi = useCrops()
  const { project }:any = useParams()
  const localLanguage = localStorage.getItem('agfarmusLanguage')

  const [makeRequest, setMakeRequest] = useState<boolean>(true)
  const [searchCrops, setSearchCrops] = useState<string>('')

  const filterData = (crop:any) => {
    return map(crop, item => ({
      name: item?.name || ' - ',
      growth_id: item.growth?.name || ' - ',
      start_at: item?.start_at || ' - ',
      end_at: item?.end_at || ' - ',
      createdIn: item?.created_at || ' - ',
      key: item.id
    }))
  }

  const getCrop = async (pagination:TablePaginationConfig, changeTotalPages:any) => {
    const resCrops = await cropsApi.getAllCrops({
      q: searchCrops,
      project,
      page: pagination.current,
      size: pagination.pageSize,
      getType: 'pagination'
    })

    changeTotalPages(resCrops?.meta?.total_count)
    setMakeRequest(false)
    return filterData(resCrops.crops)
  }

  const createCrop = useCallback(
    async (crop:TFormCrop) => {
      await cropsApi.createCrop(crop, project )
      setMakeRequest(true)
    }, [])

  const handleEditCrop = useCallback(
    async (params:TFormCrop, id:any) => {
      await cropsApi.updateCrop(params, project, id )
      setMakeRequest(true)
    }, [])

  const handleDeleteCrop = useCallback(
    async (id:any) => {
      await cropsApi.deleteCrop(id, project)
      setMakeRequest(true)
    }, [])

  const createCropModal = useCallback(
    () => {
      createDialog({
        id: 'modal-create-crop',
        open: false,
        Component: ModalCreateCrop,
        props: {
          handleSave: createCrop
        }
      })
    }, [])

  const viewCropModal = useCallback(
    (row:TFormCrop) => {
      createDialog({
        id: 'modal-view-crop',
        open: false,
        Component: ModalCreateCrop,
        props: {
          crop: row,
          disabled: true,
          deleteCrop: handleDeleteCrop,
          handleSave: handleEditCrop
        }
      })
    }, [])

  const columns = [
    {
      title: t('name', { ns: namespaces.common }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('culture', { ns: namespaces.common }),
      dataIndex: 'growth_id',
      key: 'growth_id',
    },
    {
      title: t('starts at', { ns: namespaces.common }),
      dataIndex: 'start_at',
      key: 'start_at',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text)
      }
    },
    {
      title: t('ends at', { ns: namespaces.common }),
      dataIndex: 'end_at',
      key: 'end_at',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text)
      }
    },
    {
      title: t('created in', { ns: namespaces.common }),
      dataIndex: 'createdIn',
      key: 'createdIn',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text)
      }
    },
    {
      title: t('actions', { ns: namespaces.common }),
      dataIndex: 'actions',
      key: 'actions',
      render: (text:string, record:TFormCrop) => {
        return <EyeFilled onClick={() => viewCropModal(record)} />
      }
    },
  ]

  return (
    <Container>
      <DataTable
        getDataFromApi={getCrop}
        columns={columns}
        create={createCropModal}
        labelBtnCreate={ t('new crop', { ns: namespaces.pages.crop })}
        title={t('view all crops', { ns: namespaces.pages.crop })}
        labelSearch={t('search for a crop', { ns: namespaces.pages.crop })}
        makeRequest={makeRequest}
        search={searchCrops}
        setSearch={setSearchCrops}
      />
    </Container>
  )
}

export default Crop
