import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { EyeFilled } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'
import map from 'lodash/map'
import { DialogContext } from 'dialog/context'
import { formatDateToView, removeTimeFromDateTime } from 'utils/helpers'
import DataTable from 'components/DataTable'
import Tooltip from 'components/Tooltip'
import { Container, ActionsWrapper, Label } from './styles'
import useUsers from 'repositories/useUsers'
import { TFormUser } from 'types/users'
import ModalCreateUser from './ModalCreateUser'

const Users = () => {
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const { getAllUsers, createUser, updateUser, deleteUser } = useUsers()
  const localLanguage = localStorage.getItem('agfarmusLanguage')

  const [makeRequest, setMakeRequest] = useState<boolean>(false)
  const [searchUsers, setSearchUsers] = useState<string>('')

  const filterData = (user:any) => {
    return map(user, item => ({
      name: item?.name || ' - ',
      email: item?.email || ' - ',
      confirmed_at: item?.confirmed_at ? t('yes', { ns: namespaces.common }) : t('no', { ns: namespaces.common }),
      role: item.role,
      locale: item.locale,
      created_at: item?.created_at || ' - ',
      image_file: item?.image || null,
      key: item.id
    }))
  }

  const getRole = (role: string) => {
    const map: any = {
      user: {
        text: 'Regular',
        color: '#0aa69676'
      },
      admin: {
        text: 'Admin',
        color: '#1890ff'
      },
    }

    const { text, color } = map[role]
    return <Label color={color}>{text}</Label>
  }

  const getUsers = async (pagination:TablePaginationConfig, changeTotalPages:any) => {
    const resUsers = await getAllUsers({
      q: searchUsers,
      page: pagination.current,
      size: pagination.pageSize,
      getType: 'pagination'
    })

    changeTotalPages(resUsers?.meta?.total_count)
    setMakeRequest(false)
    return filterData(resUsers.users)
  }

  const handleCreateUser = useCallback(
    async (user:TFormUser) => {
      await createUser(user )
      setMakeRequest(true)
    }, [])

  const handleEditUser = useCallback(
    async (params:TFormUser, id:any) => {
      await updateUser(params, id )
      setMakeRequest(true)
    }, [])

  const handleDeleteUser = useCallback(
    async (id:any) => {
      await deleteUser(id)
      setMakeRequest(true)
    }, [])

  const createUserModal = useCallback(
    () => {
      createDialog({
        id: 'modal-create-user',
        open: false,
        Component: ModalCreateUser,
        props: {
          handleSave: handleCreateUser
        }
      })
    }, [])

  const viewUserModal = useCallback(
    (row:TFormUser) => {
      createDialog({
        id: 'modal-view-user',
        open: false,
        Component: ModalCreateUser,
        props: {
          user: row,
          disabled: true,
          deleteUser: handleDeleteUser,
          handleSave: handleEditUser
        }
      })
    }, [])

  const columns = [
    {
      title: t('name', { ns: namespaces.common }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('email', { ns: namespaces.common }),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('confirmed', { ns: namespaces.pages.users }),
      dataIndex: 'confirmed_at',
      key: 'confirmed_at',
    },
    {
      title: t('user type', { ns: namespaces.common }),
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => {
        return getRole(role)
      }
    },
    {
      title: t('created at', { ns: namespaces.common }),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text)
      }
    },
    {
      title: t('actions', { ns: namespaces.common }),
      dataIndex: 'actions',
      key: 'actions',
      render: (text:string, record:any) => {
        return (
          <ActionsWrapper>
            <Tooltip title={t('edit user', { ns: namespaces.pages.users })}>
              <EyeFilled onClick={() => viewUserModal(record)} />
            </Tooltip>
          </ActionsWrapper>)
      }
    },
  ]

  return (
    <Container>
      <DataTable
        getDataFromApi={getUsers}
        columns={columns}
        create={createUserModal}
        labelBtnCreate={t('new something', { ns: namespaces.common, label: t('user', { ns: namespaces.pages.users }) })}
        title={t('view all users', { ns: namespaces.pages.users })}
        labelSearch={t('search for a user', { ns: namespaces.pages.users })}
        makeRequest={makeRequest}
        search={searchUsers}
        setSearch={setSearchUsers}
      />
    </Container>
  )
}

export default Users
