import React, { useState, useMemo, useEffect } from 'react'
import { CalendarOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { TDateComponent } from 'types/types'

import { formatDateToView, dateRange, getDay, removeTime, getCurrentDate, formatDateToUnix } from 'utils/helpers'

import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon'
import ArrowRightIcon from 'components/Icons/ArrowRightIcon'

import { Content, Date } from './styles'
import { find, isEqual, map, uniqWith } from 'lodash'
import moment from 'moment'


type TProps = {
  onChangeDate(date:TDateComponent):void
  onUpdateCalendar(to:number, from:number):void
  dates: any
  updateCarousel?:(_: any, dt:number)=>void
  defaultPicker:any
  setDefaultPicker:Function
}

const InputDateCustom = ({ onChangeDate, onUpdateCalendar, dates, updateCarousel = () => {}, defaultPicker, setDefaultPicker }: TProps) => {

  const [open, setOpen] = useState<boolean>(false)

  const handleGetDate = (current:any) => {
    filterDates(current)
  }

  const formatDate = (date:any, updateCalendar: boolean, dateUnix?: any) => {

    if(date){
      const range = dateRange(date)

      if(updateCalendar){
        onUpdateCalendar(range.to, range.from)
        setDefaultPicker((old:any) => ({ ...old, dt: '' }))

      }else{
        const item = find(dates, ({ dt }:any) => dt === dateUnix)

        onChangeDate({
          unixFormate: dateUnix,
          date: formatDateToView(date),
          day: getDay(date),
          timeInterval: {
            to: range.to,
            from: range.from
          },
          satellite: item?.type || ''
        })
        setDefaultPicker({ dt: moment.unix(dateUnix), type: item?.type })

        if(formatDateToUnix(getCurrentDate()) !== dateUnix) updateCarousel(1, dateUnix)
      }
    }
  }

  const formatDates = useMemo(
    () => uniqWith(map(dates, ({ dt }:any) => formatDateToView(dt, true)), isEqual)
    , [dates])

  const filterDates = (dateCurrent: any) => {
    const newDate = removeTime(dateCurrent)

    if(dates.length) {
      dates.forEach((date: any) => {
        const dateConvert = moment.unix(Number(date.dt))

        if(newDate === removeTime(dateConvert)) {
          formatDate(dateCurrent, false, date.dt)
        }
      })
    }
  }

  useEffect(() => {
    formatDate( getCurrentDate(), false, formatDateToUnix(getCurrentDate()))
  }, [])

  return (
    <Content>
      <CalendarOutlined
        onClick={() => setOpen(old => !old)}
        style={{ fontSize: '22px', color: '#f37422' }}
      />
      <Date
        defaultPickerValue={defaultPicker?.dt}
        value={defaultPicker?.dt}
        style={{ visibility: 'hidden' }}
        open={open}
        locale={locale}
        prevIcon={<ArrowLeftIcon />}
        nextIcon={<ArrowRightIcon /> }
        superNextIcon={<div />}
        superPrevIcon={<div />}
        onPanelChange={value => formatDate(value, true)}
        dateRender={current => {
          let style:any = {
            width: '32px',
            height: '32px',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            lineHeight: '143%',
            fontWeight: 500,
            fontSize: '14px',
            color: '#D9F9F6 !important',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 16px'
          }

          let haveDate:boolean = false

          let itemClass:string = 'ant-picker-cell-inner'

          if (formatDates.includes(current.format('DD/MM/YYYY'))) {
            style = {
              ...style,
              borderRadius: '10%',
              border: '1px solid #F37422',
            }

            haveDate = true
          }

          if(formatDateToView(defaultPicker.dt, true) === current.format('DD/MM/YYYY')){
            itemClass = `${itemClass} .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner`
          }

          return (
            <div className={ itemClass } style={style} onClick={() => haveDate && handleGetDate(current) }>
              {current.date()
              }
            </div>
          )
        }}
      />
    </Content>
  )
}

export default InputDateCustom
