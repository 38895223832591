import styled from 'styled-components'

export const LotContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    border-bottom: solid 1px ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    cursor: pointer;
`

export const LotImageWrapper = styled.div`
 width: 62px;
  height: 50px;
  svg {
    stroke: #c7c7c7;
    stroke-width: 1px;
  }
`

export const LotInfo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`

export const LotHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

export const LotTitle = styled.p`
    font-size: 14px;
  color: #fff;
`

export const LotSubtitle = styled.p`
    font-size: 12px;
  color: #fff;
`

