import styled from 'styled-components'
import { Collapse } from 'antd'

const { Panel } = Collapse

type TListProps = {
  pointerEvents?: string;
};

type TItemProps = {
  width?: string;
  colorYellow?: boolean;
};

type TsubMenu = {
  colorYellow?: boolean;
  submenu?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  overflow: auto;
  height: 100%;
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.THEME};
    border-bottom-right-radius: 8px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.YELLOW};
  }
  .ant-select {
    span {
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};
    }
  }
  .ant-select-selector {
    border: none !important;
    border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }
  .ant-select-multiple .ant-select-selection-item {
    border: none;
    background: ${({ theme }) => theme.colors.THEME};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.THEME};
  border: none;
  margin-top: 20%;
`

export const HeaderMenuList = styled.div`
  display: flex;
  width: 100%;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  align-items: center;
  line-height: 24px;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.YELLOW};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  padding: 12px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.THEME};
  position: absolute;
  width: 300px;
  z-index:1000;
`

export const AccordionBody = styled(Panel)`
  .ant-collapse-content {
    background: ${({ theme }) => theme.colors.THEME};
    border-top: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  }
`

export const PanelHeader = styled.div<TsubMenu>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: ${({ theme, colorYellow }) =>
    colorYellow ? theme.colors.YELLOW : theme.colors.WHITE};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  div {
    display: flex;
    align-items: center;
    text-align: center;
    span {
      padding-bottom: 8px;
      text-align: start;
    }
    span:nth-child(2) {
      color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`
export const ListContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  gap: 15px;
  flex-direction: column;
  :hover {
    cursor: pointer;
  }
`
export const List = styled.div<TListProps>`
  color: ${({ theme }) => theme.colors.THEME_TWO_BORDER};
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 14px;
  background-color: #04161D;
  border-radius: 4px;
  pointer-events: ${({ pointerEvents }) =>
    pointerEvents ? pointerEvents : 'auto'};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  width: 100%;
  
  div {
    line-height: 20px;
  }
`
export const Item = styled.div<TItemProps>`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  width: 100%;
  font-size: 14px;
  color: ${({ theme, colorYellow }) =>
    colorYellow ? theme.colors.YELLOW : theme.colors.WHITE};
  span {
    width: 100%;
    font-size: 12px;
    padding: 2px;
    color: ${({ theme }) => theme.colors.THEME_TWO_BORDER}
  }
`

export const Icon = styled.div`
 display: flex;
 align-items: center;
`