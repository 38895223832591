import React, { useContext, useEffect, useState } from 'react'
import { Wrapper, TabsWrapper, TabWrapper, TabTitle, AnalysisSwitch } from './styles'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { MdDeleteForever } from 'react-icons/md'
import { useProductContext } from 'hooks/useProductContext'
import { isJSONStrig, isValidURL } from 'utils/helpers'
import ModalRemoveReport from '../../Modals/ModalRemoveReport'
import { DialogContext } from 'dialog/context'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from 'react-sortable-hoc'
import ModalRemoveRecommendation from '../../Modals/ModalRemoveRecommendation'
import ModalRemoveAttach from '../../Modals/ModalRemoveAttach'

interface DraggabLeTabProps {
  productAnalysisControllerMenu: any
  projectSelected:string
}

const DraggabLeTab: React.FC<DraggabLeTabProps> = ( { productAnalysisControllerMenu, projectSelected }) => {
  const {
    chartSelected,
    setChartSelected,
    productProject,
    analysisWithCorrelation,
    setAnalysisWithCorrelation
  } = useProductContext()
  const { id: product_id } = productProject
  const [tabActive, setTabActive] = useState(chartSelected.menu || 'analytical summary')
  const [tabMenu, setTabMenu] = useState(productAnalysisControllerMenu)
  const { createDialog } = useContext(DialogContext)

  const { t } = useTranslation('namespaces')

  const handleRemoveAttach = (attachId: number, attachName: string) => {
    createDialog({
      id: 'modal-remove-report',
      open: false,
      Component: ModalRemoveAttach,
      props: {
        productSelected: product_id,
        fieldId: productProject?.field?.id,
        attachId,
        attachName,
        projectSelected
      }
    })
  }

  const handleRemoveReport = (reportId: number, reportName: string) => {
    createDialog({
      id: 'modal-remove-report',
      open: false,
      Component: ModalRemoveReport,
      props: {
        productSelected: product_id,
        fieldId: productProject?.field?.id,
        reportId,
        reportName,
        projectSelected
      }
    })
  }

  const handleRemoveRecommendation = (recommendationId: number, recommendationName: string) => {
    createDialog({
      id: 'modal-remove-recommendation',
      open: false,
      Component: ModalRemoveRecommendation,
      props: {
        productSelected: product_id,
        fieldId: productProject?.field?.id,
        recommendationId,
        recommendationName,
        projectSelected
      }
    })
  }

  const Handle = SortableHandle(({ tabIndex, children }: any) => (
    <div tabIndex={tabIndex}>
      {children}
    </div>
  ))

  const handleSelectMenu = (menuKey: string) => {
    setChartSelected({ menu: (menuKey || 'analytical summary'), submenu: 'all' })
    setTabActive(menuKey)
  }


  const SortableItem = SortableElement((props: any) => {
    const { value: item } = props
    return (
      <Handle>
        <TabWrapper isAtived={tabActive === item.collapseKey}>
          <TabTitle
            isRemovable={(isValidURL(item.collapseKey) && (item.attachId || item.reportId)) || (isJSONStrig(item.collapseKey) && item.recommendationId)}
            isAtived={tabActive === item.collapseKey}
            onClick={() => handleSelectMenu(item.collapseKey)}
          >
            {(isValidURL(item.collapseKey) && item.attachId) &&
              <MdDeleteForever onClick={() => handleRemoveAttach(item.attachId, item.attachName)} />
            }
            {(isJSONStrig(item.collapseKey) && item.recommendationId) &&
              <MdDeleteForever onClick={() => handleRemoveRecommendation(item.recommendationId, item.collapseTitle)} />
            }
            {(isValidURL(item.collapseKey) && item.reportId) &&
              <>
                <MdDeleteForever onClick={() => handleRemoveReport(item.reportId, item.reportName)} />
                {t('report', { ns: namespaces.pages.field })} {' - '}
              </>
            }
            {item.collapseTitle}
          </TabTitle>
        </TabWrapper>
      </Handle>
    )
  })

  const SortableList = SortableContainer((props: any) => {
    const { items, ...restProps } = props
    return (
      <TabsWrapper>
        {items.map((item: any, index: any) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={item}
            {...restProps}
          />
        ))}
      </TabsWrapper>

    )
  })

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setTabMenu(arrayMove(tabMenu, oldIndex, newIndex))
  }

  useEffect(() => {
    setTabMenu(productAnalysisControllerMenu)
  }, [productAnalysisControllerMenu])

  return (
    <Wrapper>
      <SortableList
        shouldUseDragHandle={true}
        distance={1}
        useDragHandle
        axis='x'
        items={tabMenu}
        onSortEnd={onSortEnd}
      />
      {chartSelected.menu === 'general analysis' && (
        <AnalysisSwitch checked={analysisWithCorrelation} onChange={() => setAnalysisWithCorrelation((old: boolean) => !old)} />
      )}
    </Wrapper>
  )

}

export default DraggabLeTab

