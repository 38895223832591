import styled from 'styled-components'

import { Input, Form } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

type TInputProps = {
  iconcolor?: string;
  disabled?: boolean;
  color?: string
};

export const IconInfo = styled(InfoCircleOutlined)`
  color: ${({ theme }) => theme.colors.YELLOW};
`

export const FormInput = styled(Input)<TInputProps>`
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.THEME} !important`};
  color: ${({ theme, disabled }) => `${disabled ? theme.colors.MEDIUM_GRAY : theme.colors.WHITE} !important`};
  background-color: ${({ theme, disabled, color }) => {
    if(disabled)return theme.colors.BLUE_GRAY

    if(color) return `${color} !important`

    return `${theme.colors.AZUL_SEC_YVY} !important`
  }};
  
  input.ant-input {
    background-color: ${({ theme, disabled, color }) => {
    if(disabled)return theme.colors.BLUE_GRAY

    if(color) return `${theme.colors.WHITE_OPACITY_TWO} !important`

    return `${theme.colors.AZUL_SEC_YVY} !important`
  }};
    color: ${({ theme, disabled }) => disabled ? theme.colors.MEDIUM_GRAY : theme.colors.WHITE};

    :-webkit-autofill {
    -webkit-box-shadow: ${({ theme }) => `0 0 0 30px ${theme.colors.THEME} inset`};
    -webkit-text-fill-color:${({ theme }) => `${theme.colors.WHITE}`};

    :focus{
      -webkit-text-fill-color:${({ theme }) => `${theme.colors.BLACK}`};
    }
    }
  }
  .ant-input-prefix {
    color: ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
    margin-right: 0.5rem;
  }
  svg {
    color: ${({ iconcolor, theme }) =>
    iconcolor ? iconcolor : theme.colors.WHITE};
  }
`

export const FormItem = styled(Form.Item)<{type:string}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-bottom: ${({ type }) => type === 'hidden' ? '-25px' : '0px'};

  .ant-form-item-label,
  label {
    color: ${({ theme }) => theme.colors.YELLOW};
    font-weight: 400;
    font-size: 14px;
    text-align: left;

    :before {
      display: none !important;
    }
  }

  .ant-input-affix-wrapper-disabled{
    background-color: ${({ theme }) => theme.colors.BLUE_GRAY};
  }
  /* input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.colors.THEME} inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.WHITE} !important;
  }
  input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-text-fill-color:  ${({ theme }) => theme.colors.WHITE};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.THEME} inset;
}

filter: saturate(1) hue-rotate(0deg);
input {-webkit-appearance: none; box-shadow: none !important; }
:-webkit-autofill { color: #fff !important; } */
`
