import React from 'react'

type TProps = {
  size?: number;
  color?: string;
};

const ArrowLeftIcon = ({
  size = 18,
  color = '#f37422',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 18 18'
    >
      <path d='M17.0156 8.01562H4.82812L10.4062 2.39062L9 0.984375L0.984375 9L9 17.0156L10.4062 15.6094L4.82812 9.98438H17.0156V8.01562Z' fill={color} />
    </svg>
  )
}

export default ArrowLeftIcon