import React, { useMemo } from 'react'
import { v1 as uuidv1 } from 'uuid'
import { polygons, lines, labels } from './config'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import Stats from 'components/Stats'

import {
  HighchartsChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PolygonSeries,
  LineSeries
} from 'react-jsx-highcharts'
import { Wrapper, Table, TableWrapper, SubTitle } from './styles'
highchartsMore(Highcharts)

interface SheppardDiagramProps {
  series: Array<any>,
  classification: any,
  sheppard_elements: Object,
  stats:any
  fullscreen:any
}

const SheppardDiagram: React.FC<SheppardDiagramProps> = ({ series, classification, sheppard_elements, stats, fullscreen }) => {
  const { t } = useTranslation('namespaces')

  const afterChartCreated = (chart: any, labels: any) => {
    const addLabel = (text: any, x: any, y: any, { size = 14, rotation = 0, weight = 'normal', color = '#000' }) => {
      const xPos = (chart.chartWidth - 60) / 100
      const yPos = (chart.chartHeight - 84) / 100
      chart.renderer.text(text, x * xPos + 20, y * yPos + 46)
        .css({
          color,
          fontSize: `${size}px`,
          fontWeight: weight
        })
        .attr({
          zIndex: 3,
          rotation,
        })
        .add()
    }
    labels.forEach((label: any) => {
      addLabel(label[0], label[1], label[2], label.length > 3 && label[3])
    })
  }

  const polygonsSeries = () => {
    return polygons.map(polygon => (
      <PolygonSeries
        key={uuidv1()}
        name={polygon.type}
        color={polygon.color}
        lineWidth={2}
        data={polygon.data}
      />
    ))
  }

  const linesSeries = () => {
    return lines.map(line => (
      <PolygonSeries
        key={uuidv1()}
        color='white'
        lineColor='#666' //{`#${Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0')}` }
        data={line}
      />
    ))
  }

  const allPolygons = useMemo(() => polygonsSeries(), [])
  const allLines = useMemo(() => linesSeries(), [])


  const plotOptions = {
    polygon: {
      lineColor: '#000',
      lineWidth: 0.5,
      showInLegend: false,
      enableMouseTracking: false,
      marker: {
        enabled: false
      }
    }
  }

  return (
    <Wrapper>
      <HighchartsChart
        plotOptions={plotOptions}
        callback={chart =>
          afterChartCreated(chart, labels(classification, sheppard_elements))
        }
      >
        <Chart spacingLeft={20} spacingBottom={40} spacingRight={40} spacingTop={42} />
        <Tooltip shared />
        <Legend />
        <XAxis
          min={0}
          max={100}
          allowDecimals={false}
          tickInterval={10}
          reversed={true}
          visible={false}
        />
        <YAxis
          min={0}
          max={100}
          allowDecimals={false}
          tickInterval={10}
          visible={false}
        >
          {allPolygons}
          {allLines}
          {series.map(serie => (
            <LineSeries
              key={uuidv1()}
              name={serie.deepness}
              showInLegend={false}
              colorByPoint
              marker={{
                symbol: 'circle',
                lineColor: 'black',
                enabledThreshold: 0,
                lineWidth: 0.5
              }}
              states={{
                hover: {
                  lineWidthPlus: 0,
                },
                inactive: {
                  opacity: 1,
                },
              }}
              opacity={1}
              lineWidth={0}
              data={serie.values}
              tooltip={
                {
                  headerFormat: '',
                  pointFormat:
                            `<b style='fill:#000; text-shadow: 0px 0px transparent;'>${serie.deepness.value}</b>` +
                            `<br/><b style='fill:#000; text-shadow: 0px 0px transparent;'>${t('sample', { ns: namespaces.common })}</b>: <b style='fill:#000; text-shadow: 0px 0px transparent;'>{point.sample}</b><br/>` +
                            `<span style='fill:#000; text-shadow: 0px 0px transparent;'>
                            ${serie.deepness.value === '0-20cm' ? '● ' : '○ '} ${t('sand', { ns: namespaces.pages.field })}</span>: <b style='fill:#000; text-shadow: 0px 0px transparent;'> {point.sand}% </b><br/>` +
                            `<span style='fill:#000; text-shadow: 0px 0px transparent;'>
                            ${serie.deepness.value === '0-20cm' ? '● ' : '○ '} ${t('clay', { ns: namespaces.pages.field })}</span>:<b style='fill:#000; text-shadow: 0px 0px transparent;'> {point.y}% </b><br/>` +
                            `<span style='fill:#000; text-shadow: 0px 0px transparent;'>
                            ${serie.deepness.value === '0-20cm' ? '● ' : '○ '} ${t('silt', { ns: namespaces.pages.field })}</span>: <b style='fill:#000; text-shadow: 0px 0px transparent;'>{point.silt}%</b><br/>`
                }}
            />
          ))}
        </YAxis>
      </HighchartsChart>
      <TableWrapper fullscreen={fullscreen}>
        <Table style={{ marginBottom: 16 }}>
          <thead>
            <tr>
              { series.length > 1 && <th>{series[0].deepness.label}</th>}
              {[t('clay', { ns: namespaces.pages.field }), t('sand', { ns: namespaces.pages.field }), t('silt', { ns: namespaces.pages.field }), t('average textural classification', { ns: namespaces.pages.field })]
                .map(label => <th key={uuidv1()}> {label} </th>
                )}
            </tr>
          </thead>
          <tbody>
            {
              series.map(serie => (
                <tr key={uuidv1()}>
                  { series.length > 1 &&
                          <td>
                            <label style={{ verticalAlign: 'middle' }}>
                              {serie.deepness.value === '0-20cm' ? '● ' : '○ '}
                              {serie.deepness.value}
                            </label>
                          </td>}
                  {serie.average
                    .map((value: any) => <td key={uuidv1()}> {`${value}%`} </td>)
                  }
                  <td>{serie.texture}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        <SubTitle>
          {t('percentage of textural classification', { ns: namespaces.pages.field })}
        </SubTitle>
        <Table>
          <thead>
            { series.length > 1 &&
                <th>
                  {
                    series.map(serie => <td key={uuidv1()} style={{ textAlign: 'left', paddingLeft: 20 }}>{serie.deepness.value}</td>)
                  }
                </th>
            }
          </thead>
          <tr>
            {
              series.map(serie =>
                <td key={uuidv1()}>
                  <div style={{ display: 'flex', flexDirection: 'column', padding: '6px 0' }}>
                    {serie.texture_percent.map(({ label, value }: any) =>
                      <label style={{ textAlign: 'left', paddingLeft: 20 }}key={uuidv1()}>
                        {`${value}% ${label}`}
                      </label>
                    )}
                  </div>
                </td>
              )
            }
          </tr>
        </Table>
        <Stats stats={stats} />
      </TableWrapper>
    </Wrapper>
  )
}

export default withHighcharts(SheppardDiagram, Highcharts)
