import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { TFormRotations } from 'types/rotations'

export const initialValues: TFormRotations = {
  harvest: null,
  variety: null,
  plantingDate: null,
  harvestDate: null,
  productivity: null
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {
    case 'harvest':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('crop', { ns: namespaces.common }) })
      }] as Rule[]

    case 'variety':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('variety', { ns: namespaces.pages.cropRotationst }) })
      }] as Rule[]

    case 'plantingDate':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('planting date', { ns: namespaces.pages.cropRotationst }) })
      }] as Rule[]

    case 'harvestDate':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('harvest date', { ns: namespaces.pages.cropRotationst }) })
      }] as Rule[]

    case 'productivity':
      return [{
        required: true,
        message: t('field is required', { ns: namespaces.common, label: t('productivity', { ns: namespaces.pages.cropRotationst }) })
      },
      ] as Rule[]

    default:
      [] as Rule[]
  }
}
