import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import { Content, Title, TotalArea, ButtonWrapper, UploadBtn, SelectDropdown, FileName } from './styles'
import { MdFileUpload } from 'react-icons/md'
import { Divider, Form, Input } from 'antd'
import { useHistory } from 'react-router-dom'
import useGroups from 'repositories/useGroups'
import { BiTargetLock } from 'react-icons/bi'
import { toast } from 'react-toastify'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'

import { useGlobalContext } from 'hooks/useGlobalContext'
import useRest from 'hooks/useRest'
import { useFieldContext } from 'hooks/useFieldContext'
import { useAuth } from 'hooks/useAuth'

import TextField from 'components/TextField'
import Drawer from 'components/Drawer'
import SelectField from 'components/SelectField'
import Button from 'components/Button'
import SwitchButton from 'components/SwitchButton'

import ModalRemoveField from './ModalRemoveField'
import { DialogContext } from 'dialog/context'

import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import find from 'lodash/find'

import { TMember } from 'types/types'
interface DrawerFormProps {
  handleUploadMapFile: Function
  fieldSelected: any
  projectSelected:string
}
//precisa ser refatorado toda essa tela, para usar corretamente o form
const DrawerForm: React.FC<DrawerFormProps> = ({ handleUploadMapFile, fieldSelected, projectSelected }) => {
  const { saveGroupsSelected, saveFieldSelected } = useGlobalContext()
  const { getAllFarmsByProject, getAllGroupsProject } = useGroups()
  const {
    formType,
    mapFields,
    fieldName,
    setFieldName,
    fieldTotalArea,
    importedFileName,
    lat,
    setLat,
    lng,
    setLng,
    setMapCenter,
    groupField,
    setGroupField,
    farmField,
    setFarmField,
    farmsOptions,
    setFarmsOptions,
    setMapFields,
    setFieldTotalArea,
    setImportedFileName,
    setPolygons,
    handleResetFieldContext,
    setFilekml,
    setUpdateCenter
  } = useFieldContext()

  const { t } = useTranslation('namespaces')
  const [form] = Form.useForm()
  const history = useHistory()
  const { createDialog } = useContext(DialogContext)
  const { post, put } = useRest()
  const { userData } = useAuth()

  const [groupOptions, setGroupOptions] = useState<Array<any>>([])
  const [newGroup, setNewGroup] = useState<string>('')
  const [newFarm, setNewFarm] = useState<string>('')
  const [monitored, setMonitored] = useState<boolean>(false)

  const initialValues = useMemo(
    () => {
      if(!isEmpty(fieldSelected) && formType === 'edit'){
        form.setFieldsValue({ fieldName: fieldSelected.name, lat, lng })
        return {
          fieldName: fieldSelected.name,
          lat,
          lng
        }
      }

      return {
        fieldName: '', lat, lng
      }
    }, [fieldSelected, formType, fieldName, lat, lng])

  const havePermission = useMemo(
    () => {
      if(!isEmpty(userData?.members) && userData?.role === 'user'){
        const permission = find(userData?.members, ({ role }:TMember) => role === 'admin' )

        if(!isEmpty(permission)){
          return true
        }

        return false
      }
      return true
    }, [userData, projectSelected])

  const labelMonitored = useMemo(
    () => {
      if(monitored){
        return t('status satellite monitoring', { status: t('active', { ns: namespaces.common }), ns: namespaces.pages.field })
      }

      return t('status satellite monitoring', { status: t('inactive', { ns: namespaces.common }), ns: namespaces.pages.field })

    }, [monitored, t])

  // List all groups of farms
  const formaterGroup = useCallback((list:any) => {
    const groupArray: any = []

    forEach(list, ({ groups, name, id }) => {
      const listGroupFarms = map(groups, (item:any) => ({ label: item.name, value: item.id }))
      groupArray.push(...listGroupFarms)

      if(isEmpty(groups)){
        groupArray.push({ label: name, value: id })
      }
    })

    setGroupOptions(groupArray)
  }, [])

  // Select the group of the field
  const handleSetGroup = (groupId: any) => {
    setGroupField(groupId)
    getFarms(groupId)
    setFarmField(undefined)
  }

  const updateListGroups = async () => {
    if (!projectSelected) return

    const response = await getAllGroupsProject(projectSelected)
    if (!response?.data) return

    saveGroupsSelected(response.data.records)
    formaterGroup(response.data.records)
  }

  // Filter all farms accordingly with the group selected
  const getFarms = useCallback( async(groupId: number) => {

    const response = await getAllFarmsByProject(projectSelected)

    if (!response?.data) return

    const filterItens = filter(response?.data, (item:any) => item.group.id === groupId)

    const item = map(filterItens, farm => ({ label: farm.name, value: farm.id }))

    setFarmsOptions(orderBy(item, ['name'], ['asc']))
  }, [groupField])

  // Created and edit field request
  const onFinish = async (values:any) => {
    const featuresArray: any = []

    mapFields?.forEach((mapField: any) => {
      featuresArray.push(JSON.stringify(mapField.field))
    })

    const featureCollection = JSON.stringify({
      type: 'FeatureCollection',
      features: featuresArray.map(JSON.parse)
    })

    const url = formType === 'create' ?
      `/v2/companies/${projectSelected}/fields`
      : `/v2/companies/${projectSelected}/fields/${fieldSelected?.id}`

    const data = {
      area: fieldTotalArea,
      farm_id: farmField,
      name: values.fieldName,
      shape: JSON.parse(featureCollection),
      flg_monitored: monitored
    }

    if(formType === 'edit') {
      const response = await put(url, data)
      if(response?.status === 200) {
        saveFieldSelected(response?.data)
        handleResetFieldContext()
        history.goBack()

        const notify = () => toast.success(t('field successfully updated', { ns: namespaces.pages.field }))
        notify()
      }
    } else if (data.area > 3000 || data.area <= 0 || data.name === '') {
      const notify = () => toast.error(t('field error area or name field', { ns: namespaces.pages.field }))
      notify()
    }else {
      const response = await post(url, data)
      if(response?.status === 200) {
        const notify = () => toast.success(t('field successfully created', { ns: namespaces.pages.field }))
        notify()
        handleResetFieldContext()
        saveFieldSelected(response?.data)
      }}

    form.setFieldsValue({ fieldName: '' })
    updateListGroups()
  }

  // Centralize the map
  const handleCentralizeMap = () => {
    if(lat && lng){
      setMapCenter({ lat, lng })
      setUpdateCenter(true)
    }
  }

  //   Create a new group
  const handleCreateNewGroup = async () => {
    if(newGroup?.length > 0){
      const url = `/v2/companies/${projectSelected}/groups`

      const data = {
        name: newGroup,
      }

      const response = await post(url, data)
      if(response?.status === 200) {
        const notify = () => toast.success(t('group successfully created', { ns: namespaces.pages.field }))
        if(!isEmpty(groupOptions)){
          setGroupOptions(groupOptions => [...groupOptions, { label: newGroup, value: response.data.id }])
        }else{
          setGroupOptions([{ label: newGroup, value: response.data.id }])
        }
        notify()
        updateListGroups()
        setGroupField(response.data.id)
      }
    } else {
      const notify = () => toast.error(t('enter a name for the group', { ns: namespaces.pages.field }))
      notify()
    }
  }

  //   Create a new farm
  const handleCreateNewFarm = async () => {
    if(newFarm?.length > 0){
      const url = `/v2/companies/${projectSelected}/farms`

      const data = {
        group_id: groupField,
        name: newFarm,
      }

      const response = await post(url, data)
      if(response?.status === 200) {
        const notify = () => toast.success(t('farm successfully created', { ns: namespaces.pages.field }))
        notify()
        if(!isEmpty(farmsOptions)){
          setFarmsOptions((farmOptions: any) => [...farmOptions, { label: newFarm, value: response.data.id }])
        }else{
          setFarmsOptions([ { label: newFarm, value: response.data.id }])
        }
        setFarmField(response.data.id)
      } else {
        const notify = () => toast.error(t('enter a name for the farm', { ns: namespaces.pages.field }))
        notify()
      }
    }
    updateListGroups()
  }

  // Reset the file uploaded and states
  const handleResetUploadedFile = () => {
    setMapFields([])
    setFieldTotalArea(0)
    setImportedFileName('')
    setPolygons([])
    setLat(-20.4649)
    setLng(-54.6218)
    setFilekml(undefined)
  }

  // Handle open modal of remove field
  const resetFunction = () => {
    handleResetFieldContext()
    history.push(`/dashboard/${projectSelected}`)
  }

  const handleModalRemoveField = () => {
    createDialog({
      id: 'modal-remove-field',
      open: false,
      Component: ModalRemoveField,
      props: {
        fieldId: fieldSelected?.id,
        fieldName: fieldSelected?.name,
        projectSelected,
        resetFunction
      }
    })
  }

  useEffect(() => {
    if(!isEmpty(fieldSelected)){
      setMonitored(fieldSelected?.flg_monitored)
      getFarms(fieldSelected.group.id)
    }
  }, [fieldSelected])

  // Reset name, group and farm context
  useEffect(() => {
    if(projectSelected){
      updateListGroups()
    }

    if(formType === 'create' && isEmpty(fieldSelected)){
      handleResetFieldContext()
      setFarmField()
      setFieldName('')
      handleSetGroup('')
      setLat(-20.4649)
      setLng(-54.6218)
      handleCentralizeMap()
    }
  }, [projectSelected, formType, fieldSelected])

  return (
    <Drawer
      title={ formType === 'create' ? t('new field', { ns: namespaces.pages.field }) : t('edit field', { ns: namespaces.pages.field })}
      callback={() => {
        setFilekml()
        history.goBack()
        handleResetUploadedFile()
      }}
    >
      <>
        <Content>
          <Title>{ t('imported file', { ns: namespaces.common }) }</Title>
          {importedFileName ? (
            <FileName><CloseOutlined onClick={handleResetUploadedFile} />{importedFileName}</FileName>
          ) : (
            <>
              <UploadBtn maxCount={1} accept='.kml' onChange={e => handleUploadMapFile(e)}>
                <MdFileUpload />
                { t('import file', { ns: namespaces.common }) }
              </UploadBtn>
            </>
          )}
        </Content>
        <Form
          name='basic'
          onFinish={onFinish}
          form={form}
          autoComplete='off'
          initialValues={initialValues}
        >
          <Content>
            <Title>{ t('coordinates', { ns: namespaces.common }) }</Title>
            <TextField
              placeholder={ t('latitude', { ns: namespaces.common }) }
              type='number'
              id='input-field-lat'
              endIcon={<BiTargetLock />}
              value={lat}
              name='lat'
              onChange={e => setLat(e.target.value)}
            />
            <br />
            <TextField
              placeholder={ t('longitude', { ns: namespaces.common }) }
              id='input-field-lng'
              name='lng'
              endIcon={<BiTargetLock />}
              value={lng}
              onChange={e => setLng(e.target.value)}
            />
            <Button
              type='primary'
              id='btn-center-map'
              label={ t('center map', { ns: namespaces.pages.field }) }
              onClick={handleCentralizeMap}
            />
            <Title>{ t('general field data', { ns: namespaces.pages.field }) }</Title>
            {havePermission &&
            <SwitchButton
              id='flg_monitored'
              label={ labelMonitored }
              value={monitored}
              onChange={e => setMonitored(e)}
            />}
            <TextField
              label={ t('field name', { ns: namespaces.pages.field }) }
              placeholder={ t('enter the field name', { ns: namespaces.pages.field }) }
              name='fieldName'
              rules={[
                {
                  required: true,
                  message: t('field is required', { ns: namespaces.modals.createRotationst, label: t('field name', { ns: namespaces.pages.field }) })
                }
              ]}
              id='fieldName'
            />
            <SelectField
              label={ t('group', { ns: namespaces.common }) }
              options={groupOptions}
              placeholder={ t('select a group', { ns: namespaces.pages.field }) }
              id='input-group-name'
              handleChange={(e: any) => handleSetGroup(e)}
              showSearch
              value={groupField}
              dropdownRender={(menu: any) => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <SelectDropdown>
                    <Input value={newGroup} onChange={e => setNewGroup(e.target.value)} />
                    <a onClick={handleCreateNewGroup}>
                      <PlusOutlined /> { t('create new group', { ns: namespaces.pages.field }) }
                    </a>
                  </SelectDropdown>
                </div>
              )}
            />
            <SelectField
              label={ t('farm', { ns: namespaces.common }) }
              key={(groupField || 0) + 1}
              options={farmsOptions}
              placeholder={ t('select a farm', { ns: namespaces.pages.field }) }
              id='input-farm-name'
              handleChange={(e: any) => setFarmField(e)}
              showSearch
              value={farmField}
              dropdownRender={(menu: any) => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <SelectDropdown>
                    <Input value={newFarm} onChange={e => setNewFarm(e.target.value)} />
                    <a onClick={handleCreateNewFarm}>
                      <PlusOutlined /> { t('create new farm', { ns: namespaces.pages.field }) }
                    </a>
                  </SelectDropdown>
                </div>
              )}
            />

            <TotalArea>
              <h4>{ t('approximate area', { ns: namespaces.common }) }</h4>
              <span>
                {fieldTotalArea?.toFixed(2)} ha</span>
            </TotalArea>
          </Content>
          {formType === 'edit' && (
            <ButtonWrapper>
              <Button
                id='btn-delete'
                onClick={handleModalRemoveField}
                color='#c42525'
                label={ t('delete field', { ns: namespaces.pages.field }) }
              />
            </ButtonWrapper>
          )}
          <ButtonWrapper>
            <Button
              id='btn-cancel'
              onClick={() => {
                handleResetFieldContext()
                history.goBack()
              }}
              color='#354453'
              label={ t('cancel', { ns: namespaces.common }) }
            />
            <Button
              id='btn-save'
              type='primary'
              htmlType='submit'
              disabled={fieldTotalArea > 3000 || fieldTotalArea <= 0}
              label={ t('save', { ns: namespaces.common }) }
            />
          </ButtonWrapper>
        </Form>
      </>
    </Drawer>
  )
}

export default DrawerForm