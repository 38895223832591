import useRest from 'hooks/useRest'
import { apiKml, apiBackEnd } from 'services/axios'
import useToast from 'hooks/useToast'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { toast } from 'react-toastify'

const useField = () => {
  const global = useGlobalContext()
  const { remove } = useRest()
  const { notify } = useToast()
  const { t } = useTranslation('namespaces')


  const removeField = async (params:any, callback:()=>void) => {
    const url = `v2/companies/${params.project}/fields/${params.fieldId}`
    const response = await remove(url)

    if(response?.status === 200){
      notify({ message: t('item removed with success', { ns: namespaces.common }), success: true })
      callback()
    }
  }

  const handleShapeFile = async (date: number, id: string) => {
    try{
      global.addLoading()

      const url = `/v3/shapes/${id}/field/${date}/compacted`

      const response: any = await apiBackEnd.get(url)

      if(response.data && response.status === 200){
        return response.data
      }
    } catch(error: any){
      const notify = () => toast.error(t('default', { ns: namespaces.errors }))
      notify()
    }finally{
      global.removeLoading()
    }
  }

  const handleKmlFile = async (idFarm: string, farmName: string, idField: string) => {
    try{

      const url = `geoserver/${farmName}/wms?service=WFS&version=1.0.0&request=GetFeature&typeName=${farmName}%3A${farmName}_${idFarm}_${idField}&maxFeatures=50&outputFormat=kml`

      const response: any = await apiKml.get(url)

      if(response.data && response.status === 200){
        return response.data
      }
    } catch(error: any){

      const notify = () => toast.error(t('error KML', { ns: namespaces.errors }))
      notify()
      return null
    }
  }

  return {
    removeField,
    handleShapeFile,
    handleKmlFile
  }
}

export default useField
