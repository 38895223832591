import React from 'react'

type TProps = {
  color?: string;
};

const ErrorIcon = ({
  color = '#FFF',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='34'
      height='24'
      viewBox='0 0 34 24'
    >
      <path d='M19.9062 3H14.0938C13.5781 3 13.0625 3.1875 12.6875 3.60938L8.5625 7.6875C8.1875 8.0625 8 8.57812 8 9.09375V14.9062C8 15.4219 8.1875 15.9375 8.60938 16.3125L12.6875 20.3906C13.0625 20.8125 13.5781 21 14.0938 21H19.9062C20.4219 21 20.9375 20.8125 21.3125 20.3906L25.3906 16.3125C25.8125 15.9375 26 15.4219 26 14.9062V9.09375C26 8.57812 25.8125 8.0625 25.3906 7.6875L21.3125 3.60938C20.9375 3.1875 20.4219 3 19.9062 3ZM20.5625 15.5625C20.1406 15.9375 19.5312 15.9375 19.1094 15.5625L17 13.4062L14.8906 15.5156C14.4688 15.9375 13.8594 15.9375 13.4844 15.5156C13.0625 15.1406 13.0625 14.5312 13.4844 14.1094L15.5938 12L13.4375 9.89062C13.0625 9.46875 13.0625 8.85938 13.4375 8.48438C13.8594 8.0625 14.4688 8.0625 14.8906 8.48438L17 10.5938L19.1094 8.48438C19.5312 8.0625 20.1406 8.0625 20.5156 8.48438C20.9375 8.85938 20.9375 9.46875 20.5156 9.89062L18.4062 12L20.5156 14.1094C20.9375 14.5312 20.9375 15.1406 20.5625 15.5625Z' fill={color} />
    </svg>
  )
}

export default ErrorIcon