import React, { useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { EyeFilled } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'

import map from 'lodash/map'

import useGrowthRotations from 'repositories/useGrowthRotations'

import { DialogContext } from 'dialog/context'
import { TFormRotations } from 'types/rotations'
import { TFields } from 'types/types'
import { formatDateToView, removeTimeFromDateTime } from 'utils/helpers'

import DataTable from 'components/DataTable'

import ModalCreateRotationst from './ModalCreateRotationst'

import { Container } from './styles'

type TProps = {
  field: TFields
}

const GrowthRotationst = ({ field }:TProps) => {
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const growthRotationsApi = useGrowthRotations()
  const params:any = useParams()
  const localLanguage = localStorage.getItem('agfarmusLanguage')

  const [makeRequest, setMakeRequest] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const filterData = (growthRotationst:any) => {
    return map(growthRotationst, item => ({
      harvest: item?.crop?.name || ' - ',
      tillage: item.growth?.name || ' - ',
      variety: item.growth_variety?.name || ' - ',
      plantingDate: item?.start_at || '',
      harvestDate: item?.end_at || '',
      productivity: item?.productivity || ' - ',
      key: item.id,
      growthId: item?.growth?.id
    }))
  }

  const getGrowthRotationst = async (pagination:TablePaginationConfig, changeTotalPages:any) => {
    const resGrowthRotations = await growthRotationsApi.getAllGrowthRotations({
      page: pagination.current,
      size: pagination.pageSize,
      search,
      project: params?.project,
      fields: field.id
    })

    changeTotalPages(resGrowthRotations?.meta?.total_count)
    setMakeRequest(false)
    return filterData(resGrowthRotations.field_crops)
  }

  const createRotationst = useCallback(
    async (rotationst:TFormRotations) => {
      await growthRotationsApi.createGrowthRotations(rotationst, params?.project, field.id )

      setMakeRequest(true)
    }, [])

  const updateRotationst = useCallback(
    async (rotationst:TFormRotations) => {
      await growthRotationsApi.updateGrowthRotations(rotationst, params?.project, field.id)

      setMakeRequest(true)
    }, [])

  const handleDelete = useCallback(
    async (id:any) => {
      await growthRotationsApi.deleteGrowthRotations(id, params?.project, field.id )

      setMakeRequest(true)
    }, [])


  const createRotationstModal = useCallback(
    () => {
      createDialog({
        id: 'modal-create-rotationst',
        open: false,
        Component: ModalCreateRotationst,
        props: {
          handleSave: createRotationst,
          projectSelected: params?.project
        }
      })
    }, [])

  const viewRotationstModal = useCallback(
    (row:TFormRotations) => {
      createDialog({
        id: 'modal-view-rotationst',
        open: false,
        Component: ModalCreateRotationst,
        props: {
          rotationst: row,
          deleteRotationst: handleDelete,
          handleSave: updateRotationst,
          projectSelected: params?.project
        }
      })
    }, [])

  const columns = [
    {
      title: t('harvest / year', { ns: namespaces.pages.cropRotationst }),
      dataIndex: 'harvest',
      key: 'harvest',
    },
    {
      title: t('culture', { ns: namespaces.common }),
      dataIndex: 'tillage',
      key: 'tillage',
    },
    {
      title: t('variety', { ns: namespaces.pages.cropRotationst }),
      dataIndex: 'variety',
      key: 'variety',
    },
    {
      title: t('planting date', { ns: namespaces.pages.cropRotationst }),
      dataIndex: 'plantingDate',
      key: 'plantingDate',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text)
      }
    },
    {
      title: t('harvest date', { ns: namespaces.pages.cropRotationst }),
      dataIndex: 'harvestDate',
      key: 'harvestDate',
      render: (text:string, ) => {
        if(localLanguage === 'en'){
          return removeTimeFromDateTime(text)
        }
        return formatDateToView(text)
      }
    },
    {
      title: t('productivity', { ns: namespaces.pages.cropRotationst }),
      dataIndex: 'productivity',
      key: 'productivity',
    },
    {
      title: t('actions', { ns: namespaces.common }),
      dataIndex: 'actions',
      key: 'actions',
      render: (text:string, record:TFormRotations) => {
        return <EyeFilled onClick={() => viewRotationstModal(record)} />
      }
    },
  ]

  return (
    <Container>
      <DataTable
        getDataFromApi={getGrowthRotationst}
        columns={columns}
        create={createRotationstModal}
        labelBtnCreate={ t('new rotation', { ns: namespaces.pages.cropRotationst })}
        title={t('view all crop rotations', { ns: namespaces.pages.cropRotationst })}
        labelSearch={t('search for a rotation', { ns: namespaces.pages.cropRotationst })}
        makeRequest={makeRequest}
        search={search}
        setSearch={setSearch}
      />
    </Container>
  )
}

export default GrowthRotationst
