import React from 'react'

type TProps = {
  size?: number;
  color?: string;
};

const UserIcon = ({
  size = 14,
  color = '#D9F0F9',
}: TProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      version='1.1'
      viewBox='0 0 14 14'
    >
      <path d='M7 0.34375C3.3125 0.34375 0.34375 3.3125 0.34375 7C0.34375 10.6875 3.3125 13.6562 7 13.6562C10.6875 13.6562 13.6562 10.6875 13.6562 7C13.6562 3.3125 10.6875 0.34375 7 0.34375ZM3.90625 11.3438C4.78125 10.7188 5.84375 10.3438 7 10.3438C8.15625 10.3438 9.21875 10.7188 10.0938 11.3438C9.21875 11.9688 8.15625 12.3438 7 12.3438C5.84375 12.3438 4.78125 11.9688 3.90625 11.3438ZM11.0938 10.4062C9.96875 9.53125 8.53125 9 7 9C5.46875 9 4.03125 9.53125 2.90625 10.4062C2.125 9.5 1.65625 8.3125 1.65625 7C1.65625 4.0625 4.0625 1.65625 7 1.65625C9.9375 1.65625 12.3438 4.0625 12.3438 7C12.3438 8.3125 11.875 9.5 11.0938 10.4062ZM7 3C5.71875 3 4.65625 4.03125 4.65625 5.34375C4.65625 6.625 5.71875 7.65625 7 7.65625C8.28125 7.65625 9.34375 6.625 9.34375 5.34375C9.34375 4.03125 8.28125 3 7 3ZM7 6.34375C6.4375 6.34375 6 5.875 6 5.34375C6 4.78125 6.4375 4.34375 7 4.34375C7.5625 4.34375 8 4.78125 8 5.34375C8 5.875 7.5625 6.34375 7 6.34375Z' fill={color} />
    </svg>
  )
}

export default UserIcon