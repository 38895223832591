import { Rule } from 'antd/lib/form'
import { TFunction } from 'react-i18next'
import { TLogin } from '.'
import { namespaces } from 'i18n/i18n.constants'

export const initialValues: TLogin = {
  email: '',
  password: ''
}

export const validation = (key:string, t: TFunction<'namespaces', undefined> ) => {
  switch (key) {

    case 'email':

      return [{
        required: true,
        message: t('user is required', { ns: namespaces.common })
      },
      {
        type: 'email',
        message: t('it is not a valid email', { ns: namespaces.common })
      }] as Rule[]

    case 'password':

      return [{
        required: true,
        message: t('password is required', { ns: namespaces.common })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}