import styled from 'styled-components'
import { EditControl } from 'react-leaflet-draw'


export const MapDrawer = styled.div<{ customIcons: Array<string>}>`
    flex: 1;
    max-height: calc(100vh - 82px );
    path.leaflet-interactive{
      stroke-opacity: 1;
      stroke-width: 3;
      stroke: #5da05d;
      fill: #5da05d;
    }
  .leaflet-draw-toolbar a{
    background-color: ${({ theme }) => theme.colors.THEME} !important;
    background-image:  ${({ customIcons }) => `url(${customIcons[1]})`} !important;
    background-image: linear-gradient(transparent,transparent),  ${({ customIcons }) => `url(${customIcons[2]})`} !important;
  }
  .leaflet-retina .leaflet-draw-toolbar a{
    background-image: ${({ customIcons }) => `url(${customIcons[0]})`} !important;
    background-image:linear-gradient(transparent,transparent), ${({ customIcons }) => `url(${customIcons[2]})`} !important;
  }
`

export const MapControl = styled(EditControl)`
`