import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const MiniTable = styled.table`
    border: 1px solid #ffffff20;
    padding: 0;
    border-spacing: 0 3px;

    td, th {
        padding: 0.5rem;
        text-align: center;
        font-size: 11px;
    }
    tr:nth-child(odd) td {
        background-color: ${({ theme }) => theme.colors.THEME};
    }

    tr:nth-child(even) td {
        background-color: ${({ theme }) => theme.colors.LIGHT_THEME};
    }
    thead {
    tr,
    th {
      background-color: ${({ theme }) =>
    `${theme.colors.LIGHT_THEME} !important`};
      border: none;
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};

      th::before {
        display: none;
      }
    }
  }
`

export const Title = styled.h4`
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    padding: 0.5rem 0;
`

export const Row = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border: 1px solid #70707030;
    margin-top: -1px;
`

export const LeftColumn = styled.p`
    margin: 0;
    font-size: 12px;
    `

export const RightColumn = styled.strong`
    font-size: 12px;
`

export const ColumnRow = styled.label`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 0.5rem;
    border: 1px solid #70707030;
    margin-top: -1px;
`

export const TableTitle = styled.h4`
  color: ${({ theme }) => theme.colors.LIGHT_THEME};
  background-color: ${({ theme }) => theme.colors.YELLOW} ;
  margin: 0;
  text-align: center;
  margin-bottom: -1rem;
  font-size: 13px;
  font-weight: bold;
  padding: 0.25rem 0;

`