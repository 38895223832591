import styled from 'styled-components'
import { Collapse } from 'antd'

const { Panel } = Collapse

type TListProps = {
  pointerEvents?: string;
};

type TItemProps = {
  width?: string;
  colorYellow?: boolean;
  justify?: 'center'| 'left' | 'right'
};

type TPaletteProps = {
  color: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  overflow: auto;
  height: 100%;

  .ant-select {
    span {
      color: ${({ theme }) => `${theme.colors.YELLOW} !important`};
    }
  }

  .ant-select-selector {
    border: none !important;
    border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY} !important`};
  }

  .ant-select-multiple .ant-select-selection-item {
    border: none;
    background: ${({ theme }) => theme.colors.THEME};
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.THEME};
    border-bottom-right-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.YELLOW};
  }
`

export const AccordionMain = styled(Collapse)`
  background: ${({ theme }) => theme.colors.THEME};
  border: none;

  .ant-collapse-header {
    /* display: block !important; */
    svg {
      color: ${({ theme }) => theme.colors.WHITE};
    }
  }
  .ant-collapse-item {
    border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  }
`

export const AccordionBody = styled(Panel)`
  .ant-collapse-content {
    background: ${({ theme }) => theme.colors.THEME};
    border-top: ${({ theme }) =>
    `1px solid ${theme.colors.MEDIUM_GRAY_OPACITY}`};
  }
`

export const PanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  color: ${({ theme }) => theme.colors.YELLOW};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;

  div {
    display: grid;
    text-align: center;
    grid-template-columns: 162px;

    span {
      padding-bottom: 8px;
      text-align: start;
    }

    span:nth-child(2) {
      color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`

export const List = styled.ul<TListProps>`
  color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  padding: 0;
  pointer-events: ${({ pointerEvents }) =>
    pointerEvents ? pointerEvents : 'auto'};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;

  li:nth-child(1) {
    width: 120px;
  }
  li:nth-child(2) {
    width: 200px;
    margin-left:-30px
  }
  li:nth-child(3) {
    width: 65px;
  }

  :hover {
    background: ${({ theme }) => theme.colors.MEDIUM_GRAY_OPACITY};
  }
`

export const Icons = styled.div`
 display: flex ;
 flex-direction: row;
 
  span{
   margin-left: 2px ;
  }
`

export const Item = styled.li<TItemProps>`
  display: flex;
  justify-content: ${({ justify }) => justify ? justify : 'start'};
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 2px;
  width: ${({ width }) => (width ? `${width} !important` : '0')};
  color: ${({ theme, colorYellow }) =>
    colorYellow ? theme.colors.YELLOW : theme.colors.WHITE}; 
`

export const Palette = styled.div<TPaletteProps>`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  div{
  width: 7px;
  height: 49px;
  padding: 2px;
  margin-left: -17px;
  background: ${({ color }) => color};
  }

  span{
    margin-left: 10px;
  }
`