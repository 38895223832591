import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import { Rule } from 'antd/lib/form'
import { LoadingOutlined } from '@ant-design/icons'

import { IconInfo, FormItem, FormInput } from './styles'

interface IProps {
  id: string
  name?: string | any
  fieldKey?: any
  type?: string
  label?: string
  placeholder?: string
  rules?: Rule[]
  disabled?: boolean
  value?: any
  defaultValue?: string
  loading?: boolean
  startIcon?: React.ReactElement
  endIcon?: React.ReactElement
  haveTooltip?: boolean
  textTooltip?: string
  onChange?: (e: any) => void
  onPressEnter?: (e: any) => void
  iconColor?: string | any
  color?: string
  notIsFormItem?:boolean
}

const TextField = ({
  label,
  type = 'text',
  name,
  fieldKey,
  placeholder,
  rules,
  disabled = false,
  value,
  defaultValue,
  id,
  loading = false,
  startIcon,
  iconColor,
  endIcon,
  haveTooltip = false,
  textTooltip,
  onChange,
  onPressEnter,
  color,
  notIsFormItem = false
}: IProps) => {

  const visible = useMemo(
    () => {
      if(loading ){
        return <LoadingOutlined />
      }

      if(endIcon){
        return endIcon
      }

      if(haveTooltip){
        return <IconInfo />
      }
      return <></>
    },
    [loading, haveTooltip, endIcon, textTooltip, value])

  const componentInput = () => {
    if(type !== 'hidden'){
      return(
        <FormInput
          allowClear
          iconcolor={iconColor}
          onChange={onChange}
          onPressEnter={onPressEnter}
          type = {type}
          placeholder={placeholder}
          disabled ={disabled}
          value={value}
          defaultValue={defaultValue}
          id={id}
          prefix={startIcon}
          suffix={
            (haveTooltip || loading || endIcon) && (
              <Tooltip title={textTooltip}>
                {visible}
              </Tooltip>
            )
          }
          color={color}
        />
      )
    }
    return <div />
  }

  const renderComponent = useMemo(
    () => {
      if(!notIsFormItem){
        return(
          <FormItem
            label={label}
            name={name}
            rules={rules}
            type={type}
            fieldKey={fieldKey}
          >
            {componentInput()}
          </FormItem>
        )
      }

      return componentInput()

    }, [notIsFormItem, disabled])

  return renderComponent
}


export default TextField